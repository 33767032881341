import { Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { ToolService } from './tool.service';
import { User } from '../models/models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ReceiptService } from './receipt.service';
import { MailService } from './mail.service';
import { DataService } from './data.service';
import { MessageService } from './message.service';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};

interface Scripts {
	name: string;
	src: string;
	loaded: boolean;
}

/* ========================================================================= */
@Injectable()
export class ScriptService {

	constructor(
		public router: Router,
		private http: HttpClient,
		private receiptService: ReceiptService,
		private tools: ToolService,
		
		private dataService: DataService, // needed to access from scripts
		private mailService: MailService, // needed to access from scripts
		private messageService: MessageService,// needed to access from scripts
	) {
	}

	private static scripts: Scripts[] = [
		// { name: 'typescript', src: 'https://unpkg.com/typescript@latest/lib/typescriptServices.js', loaded: false },
		{ name: 'typescript', src: 'https://cdn.jsdelivr.net/npm/typescript@latest/lib/typescriptServices.js', loaded: false },
	];

	// Interface properties and functions
	public host: any = null;

	static async getTSInstance(): Promise<any> {
		return new Promise(async resolve => {
			const result = await this.loadScript('typescript');
			if (!result.loaded) { throw (result.status); }

			const ts = (window as any).ts;
			resolve(ts);
		});
	}

	private static loadScript(name: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const script = this.scripts.find(s => s.name === name);
			if (!script) {
				reject('Script not found in library');
			} else if (script.loaded) {
				resolve({ script: name, loaded: true, status: 'Already Loaded' });
			} else {
				// load script
				const elScript = document.createElement('script');
				document.getElementsByTagName('head')[0].appendChild(elScript);
				elScript.type = 'text/javascript';
				elScript.src = script.src;
				elScript.onload = () => {
					script.loaded = true;
					console.log(`${name} Loaded.`);
					resolve({ script: name, loaded: true, status: 'Loaded' });
				};
				elScript.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Not loaded: ' + error });
			}
		});
	}

	private extractData(res: Response) {
		const body = res;
		return body || {};
	}

	private beatifyUrl(url: string): string {
		if (url.startsWith('http') === false) {
			if (url.startsWith('api')) { url = '/' + url; }
			if (url.startsWith('/api') === false) { url = '/api/' + url; }
			url = url.replace('//', '/');
		}

		return url;
	}

	async run(code: string, functionName: string, para: any[], host: any = null) {
		const ts = await ScriptService.getTSInstance();
		const js = ts.transpile(code);
		let runnable: any;

		try {
			runnable = eval(js);
		} catch (e) {
			throw new Error(`Fehler in Script '${functionName}': ${e}`);
		}


		this.host = host;
		const func = runnable[functionName];
		if (func === undefined) {
			throw new Error(`Script '${functionName}' kann nicht gefunden werden`);
		}

		const result = await func.bind(this)(para[0], para[1], para[2], para[3], para[4], para[5], para[6], para[7]);
		return result;
	}

	get currentUser(): User {
		return ToolService.currentUser;
	}

	showSuccess(message) {
		this.tools.showSuccess(message);
	}

	showWarning(message) {
		this.tools.showWarning(message);
	}

	showError(message) {
		this.tools.showError(message);
	}

	hasModule(module: string): boolean {
		return this.tools.hasModule(module, false);
	}

	copyToClipboard(data: string): void {
		ToolService.setClipboardText(data);
	}

	print(content: string, caption = ''): void {
		if (caption) {
			const html = this.tools.getPrintHtml(content, caption);
			ToolService.print(html);
			return;
		}

		// plain print
		const styles = `body {font-family:sans-serif;Helveetica, Arial; margin: 2em 2em 2em 2em; text-align:left;}
	table {width:100%}
    @page {size: 21.0cm 29.7cm; margin: 2em;}

	`;
		const frame1 = document.createElement('iframe');
		frame1.name = 'frame1';
		frame1.style.position = 'absolute';
		frame1.style.top = '-1000000px';
		frame1.title = caption;	
		document.body.appendChild(frame1);
		const frameDoc = frame1.contentDocument;
		frameDoc.open();

		frameDoc.write('<style>' + styles + '</style>');
		frameDoc.write(content);
		frameDoc.close();
		setTimeout(function () {
			window.frames['frame1'].focus();
			window.frames['frame1'].print();
			document.body.removeChild(frame1);
		}, 1500);
	}



	receipt(content: string): void {
		this.receiptService.printReceipt(ReceiptService.pidReceipt, content);
	}

	formatNumber(value, fract = 2): string {
		return ToolService.formatNumber(value, fract);
	}

	parseNumber(value): number {
		return ToolService.parseNumber(value);
	}

	formatDate(value: Date): string {
		if (!value) { return 'n/a'; }
		return value.toLocaleDateTimeString();
	}

	parseDate(value): Date {
		return ToolService.parseDate(value);
	}

	async getSetting(section: string, key: string): Promise<string> {
		return this.tools.settingString(section, key).toPromise();
	}

	async input(value: string, caption: string): Promise<string> {
		return this.tools.inputBox(value, caption).toPromise();
	}

	async httpDelete(url: string): Promise<any> {
		return this.http.delete(this.beatifyUrl(url)).toPromise();
	}

	async httpGet(url: string): Promise<any> {
		return this.http.get(this.beatifyUrl(url)).pipe(
			map(this.extractData)).toPromise();
	}

	async httpPost(url: string, data): Promise<any> {
		return this.http.post(this.beatifyUrl(url), JSON.stringify(data), httpOptions).toPromise();
	}

	async httpPut(url: string, data): Promise<any> {
		return this.http.put(this.beatifyUrl(url), JSON.stringify(data), httpOptions).toPromise();
	}

	private static _addTableElement(el: string, content: any, type = ''): string {
		if (content === null || content === undefined) { return `<${el}></${el.split(' ')[0]}>`; }

		let value = typeof content === 'string' ? content : ToolService.formatValue(content, type);
		let cssClass = type === 'r' || type === 'num' || type === 'cur' || type === 'int' || typeof content === 'number' ? ' class="right" ' : '';
		if (type === 'c') { cssClass = ' class="center" ' }
		const html = `<${el} ${cssClass}>${value}</${el.split(' ')[0]}>`;
		return html;
	}

	addTabeleRow(element, col1, col2?, col3?, col4?, col5?, col6?, col7?, col8?, col9?, col10?, col11?, col12?, col13?, col14?, col15?, col16?) {

		let row = '<tr>';
		for (let i = 1; i < arguments.length; i++) {
			let value = arguments[i];
			let type = ''
			if (typeof value === 'string') {
				const arr = value.split('/');
				if (arr.length > 1 && arr[0].substr(-1) !== '<') { // skip html closing tags
					value = arr[0];
					type = arr[1]
				}
			}

			if (value === 'NaN') { value = ''; }
			row += ScriptService._addTableElement(element, value, type);
		}

		const html = row + '</tr>';
		return html;
	}


}


