import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToolService} from './tool.service';
import { Wiki, Adr, Voucher, SearchResult, Article, Invoice, Journal, User, SubArticle, LocationData, News, AccountInfo, Booking, TJournal, BroadcastData } from '../models/models';
import { Const } from '../models/constants';
import { Settings } from '../models/settings';
import { ReceiptService } from './receipt.service';
import { Cacheable, CacheBuster } from 'ts-cacheable';

const endpointAdr = '/api/adr/';
const endpointArt = '/api/articles/';
const endpointJournal = '/api/journal/';
const endpointReceipt = '/api/receipt/';
const endpointUser = '/api/users/';
const endpointSearch = '/api/search/';
const endpointInvoice = '/api/invoice/';
const endpointReports = '/api/report/';
const endpointWiki = '/api/wiki/';
const endpointMisc = '/api/misc/';
const endpointMessage = '/api/message/';
const endpointVoucher = '/api/voucher/';
const endpointNews = '/api/news/';
const endpointDates = '/api/dates/';
const endpointTJournal = '/api/tjournal/';
const endpointNewsletter= '/api/newsletter/';



const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};


const cacheBusterArticle$ = new Subject<void>();
const cacheBusterUser$ = new Subject<void>();

@Injectable({
	providedIn: 'root'
})
export class DataService {

	constructor(
		private http: HttpClient,
		private tools: ToolService,
	) { }

	private extractData(res: Response) {
		const body = res;
		return body || {};
	}

	private extractArray(res: Response) {
		const body = res;
		return body || [];
	}

	/////////////////////////////////
	// Adressen

	getLocation(query: string, type:string): Observable<LocationData[]> {
		return new Observable<LocationData[]>(obs => {
			if (!query || query.length <= 3) {
				obs.next([]);
				obs.complete();
			} else {
				const result = this.http.get<LocationData[]>(`${endpointAdr}location/${query}?type=${type}`);
				result.subscribe(data => {
					obs.next(data);
					obs.complete();
					console.log(query);
				});
			}
		});
	}

	getAdrType(iType: number): Observable<any> {
		return this.http.get(endpointAdr + 'Type/' + iType).pipe(
			map(this.extractData));
	}

	getAdr(id: number, includeSubAdrs = false): Observable<Adr> {
		if (!id) { return new Observable(); }

		return this.http.get<Adr>(`${endpointAdr}${id}?includeSubAdrs=${includeSubAdrs}`);
	}

	getAdrGridFieldValues(field: string): Observable<string[]> {
		return this.http.get<string[]>(`${endpointAdr}grid/${field}`);
	}

	getAdrGridRows(gridParams): Observable<any> {
		return this.http.post(endpointAdr + 'grid', JSON.stringify(gridParams.request), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getSubAdr(mainID: number): Observable<any> {
		return this.http.get(endpointAdr + 'sub/' + mainID).pipe(
			map(this.extractData));
	}

	getAdrHist(id: number): Observable<any> {
		return this.http.get(endpointAdr + 'hist/' + id).pipe(
			map(this.extractData));
	}

	getAdrStats(id: number): Observable<any> {
		if (!id) { return new Observable(); }

		return this.http.get<Adr>(`${endpointAdr}stats/${id}`);
	}



	getAdrInvID(id: number, re: number): Observable<any> {
		return this.http.get(endpointAdr + 'invid/' + id + '/' + re).pipe(
			map(this.extractData));
	}


	findAdr(searchString: string, iType = -1, maxCount = 50): Observable<any> {
		return new Observable(obs => {
			if (!searchString) {
				obs.next([]);
				obs.complete();
			} else {
				const s = searchString.toString().replace(/\%/g, '');
				this.http.get(`${endpointAdr}find/${iType}/${s}?maxCount=${maxCount}`).pipe(
					map(this.extractData)).subscribe(data => {
						obs.next(data);
						obs.complete();
					});
			}
		});
	}

	addAdr(data: Adr): Observable<any> {
		return this.http.post(endpointAdr, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	importAdr(data): Observable<any> {
		return this.http.post(endpointAdr + 'import', JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateAdr(id: number, data: Adr): Observable<any> {
		data.id = id;
		return this.http.put(endpointAdr + id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	selectAdr(data): Observable<any> {
		return this.http.put(endpointAdr + 'select', JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getAdrSelection(): Observable<any> {
		return this.http.get(endpointAdr + 'select').pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getAdrSelectionCount(): Observable<any> {
		return this.http.get(endpointAdr + 'select/count').pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	deleteAdr(id: number): Observable<any> {
		return this.http.delete(endpointAdr + id, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	createInvID(id: number, re: number): Observable<any> {
		return this.http.put(endpointAdr + 'invid/' + id + '/' + re, null, httpOptions).pipe(
			map(this.extractData));
	}

	getDupes(): Observable<any> {
		return this.http.get(`${endpointAdr}dupes`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	mergeAdr(keepID: number, deleteID: number): Observable<any> {
		return this.http.delete(`${endpointAdr}dupes/${keepID}/${deleteID}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	replaceAdr(field: string, value: string): Observable<any> {
		return this.http.put(`${endpointAdr}replace/${field}/${value}`, null, httpOptions);
	}

	anonymizeAdr(id: number) {
		return this.http.put(`${endpointAdr}anonymize/${id}`, null, httpOptions);
	}

	deAnonymizeAdr(id: number) {
		return this.http.delete(`${endpointAdr}anonymize/${id}`);
	}

	/////////////////////////////////
	// Artikel
	@Cacheable({
		cacheBusterObserver: cacheBusterArticle$,
	})
	getArticles(): Observable<Article[]> {
		return this.http.get<Article[]>(endpointArt).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getArticlesType(iType: number): Observable<Article[]> {
		return new Observable(obs => {
			this.getArticles().subscribe(data => {
				switch (iType) {
					case 0:
						obs.next(data.filter(a => a.id > 0));
						break;
					case 1:
						obs.next(data.filter(a => a.id < 0));
						break;
					default:
						obs.next(data);
				}

				obs.complete();
			});
		});
	}

	getArticle(search: number|string): Observable<Article> {
		return new Observable(obs => {
			if (typeof search === 'number') {
				this.getArticles().subscribe(data => {
					obs.next(data.find(i => i.id === +search));
					obs.complete();
				});
			} else {
				if (!search) {
					obs.next(null);
					obs.complete();
				} else {
					this.getArticles().subscribe(data => {
						search = (search as string).toLowerCase();
						obs.next(data.find(i => i.itemID.toLowerCase() === search || i.ean.toLowerCase() === search));
						obs.complete();
					});
				}
			}
		});
	}

	getArticleNoCache(id: number): Observable<Article> {
		return this.http.get<Article>(endpointArt + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getArticleStock(id: number): Observable<any> {
		return this.http.get(endpointArt + 'stock/' + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArticle$
	})
	addArticle(data: Article): Observable<any> {
		return this.http.post(endpointArt, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArticle$
	})
	updateArticle(id: number, data: Article): Observable<any> {
		data.id = id;
		return this.http.put(endpointArt + id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArticle$
	})
	importArticles(data): Observable<any> {
		return this.http.post(endpointArt + 'import', JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArticle$
	})
	deleteArticle(id: number): Observable<any> {
		return this.http.delete(endpointArt + id, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArticle$
	})
	clearArticleCache(): Observable<any> {
		return new Observable<any>(obs => {
			obs.next(true);
			obs.complete();
		});
	}


	// Get Stored Articles
	findArticle(filter: string, usage = 0): Observable<Article[]> {
		return new Observable(obs => {
			if (!filter) {
				obs.next([]);
				obs.complete();
			} else {
				this.getArticles().subscribe(data => {
					if (typeof filter === 'string') {
						filter = filter.toLowerCase();

						if (filter.startsWith('*')) {
							obs.next(data.filter(art =>
								(art.flagLock === false || art.follow) &&
								((art.name.toLowerCase().indexOf(filter.substr(1)) >= 0 && (usage === 0 || art.usage === 0 || art.usage === usage || filter.length >= 4)) ||
									art.itemID.toLowerCase() === filter))); // Itemid immer suchen

						} else {
							obs.next(data.filter(art =>
								(art.flagLock === false || art.follow) &&
								((art.name.toLowerCase().startsWith(filter) && (usage === 0 || art.usage === 0 || art.usage === usage || filter.length >= 4)) ||
									art.itemID.toLowerCase() === filter || art.ean === filter))); // Itemid und EAN immer suchen
						}

					}
					obs.complete();
				});
			}
		});
	}

	// Get min / max of Articles
	getNextArticleID(payment: boolean): Observable<number> {
		return new Observable(obs => {
			this.getArticles().subscribe(data => {
				let id = 0;
				if (payment) {
					id = Math.min(...data.map(o => o.id), -99) - 1;
				} else {
					id = Math.max(...data.map(o => o.id), 99) + 1;
				}
				obs.next(id);
				obs.complete();
			});
		});
	}

	getArticleTopSeller(count = 24, type = 2): Observable<any> {
		return this.http.get(`${endpointArt}topseller?count=${count}&type=${type}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	static getArticlePrice(art: Article, date: Date, level: number = 0): number {
		let price = art.price;
		if (art.nextDate.getFullYear() > 1900 && art.nextDate.getTime() <= date.getTime()) {
			switch (level) {
				case 0:
					price = art.nextPrice;
					break;
				case 1:
					price = art.nextPrice1;
					break;
				case 2:
					price = art.nextPrice2;
					break;
				case 3:
					price = art.nextPrice3;
					break;
			}
			if (price === 0) { price = art.nextPrice; }
		} else {
			switch (level) {
				case 0:
					price = art.price;
					break;
				case 1:
					price = art.price1;
					break;
				case 2:
					price = art.price2;
					break;
				case 3:
					price = art.price3;
					break;
			}
		}
		if (price === 0) { price = art.price; }

		return price;
	}

	getArticlePriceRules(art: Article, adr: Adr = null, booking: Booking = null, room = null, tableID = 0): Observable<number> {
		return new Observable<number>(obs => {
			let price = DataService.getArticlePrice(art, Date.today());

			this.tools.settingObj('restaurant', 'rules').subscribe(allRules => {
				const rules = allRules.filter(r => r.filter === 0 || (r.filter - 1) === art.field);

				let found = false;
				let rule = null;

				for (let i = 0; i < rules.length; i++) {
					rule = rules[i];
					rule.compare = rule.compare.toLowerCase();
					switch (rule.query) {
						case 0: // Zeitraum
							{
								const arr = rule.compare.split('-');
								const start = ToolService.parseTime(arr[0]);
								const end = ToolService.parseTime(arr.length > 1 ? arr[1] : '23:59');
								const now = ToolService.formatTime(new Date());
								if (now >= ToolService.formatTime(start) && now <= ToolService.formatTime(end)) { found = true; }
							}
							break;

						case 1: // Gast - Tag
							if (adr && (adr.tags.toLowerCase() + ',').indexOf(rule.compare + ',') >= 0) { found = true; }
							break;
						case 2: // Buchungs - Tag
							if (booking && (booking.tags.toLowerCase() + ',').indexOf(rule.compare + ',') >= 0) { found = true; }
							break;
						case 3: // Arrangement
							if (booking && (booking.arrangementID.toLowerCase() + ',').indexOf(rule.compare + ',') >= 0) { found = true; }
							break;
						case 4: // Zimmerkategorie
							if (room && (room.category.toLowerCase() + ',').indexOf(rule.compare + ',') >= 0) { found = true; }
							break;
						case 5: // Table range
							{
								const arr = rule.compare.split('-');
								const start = +arr[0];
								const end = arr.length > 1 ? +arr[1] : start;
								if (start > 0 && start <= tableID && end >= tableID) { found = true; }
							}
							break;
					}

					if (found) { break; }
				}

				if (rule && found) {
					switch (rule.op) {
						case 0: // Price
							price = DataService.getArticlePrice(art, Date.today(), +rule.value);
							break;
						case 1: // Offset
							price += ToolService.parseNumber(rule.value, 2);
							break;
						case 2:
							price *= (100 + ToolService.parseNumber(rule.value, 2)) / 100;
							break;
					}
				}

				obs.next(price);
				obs.complete();
			});
		});
	}


	/////////////////////////////////
	// SubArticles
	getSubArticles(artID: number): Observable<SubArticle[]> {
		return this.http.get<SubArticle[]>(`${endpointArt}components/${artID}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getSubArticle(id: number): Observable<SubArticle> {
		return this.http.get<SubArticle>(`${endpointArt}component/${id}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	addSubArticle(data: SubArticle): Observable<any> {
		return this.http.post(endpointArt + 'component', JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateSubArticle(id: number, data: SubArticle): Observable<any> {
		data.id = id;
		return this.http.put(`${endpointArt}component/${id}`, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	deleteSubArticle(id: number): Observable<any> {
		return this.http.delete(`${endpointArt}component/${id}`, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	/////////////////////////////////
	// Invoices
	getInvoices(fromDate: Date, toDate: Date, filter: string, house: number): Observable<Invoice[]> {
		return this.http.get<Invoice[]>(`${endpointInvoice}${fromDate.toISODateString()}/${toDate.toISODateString()}/${filter}?house=${house}`).pipe(
			catchError(ToolService.handleHttpError),
		);
	}

	getInvoice(invID: number): Observable<Invoice> {
		return this.http.get <Invoice>(endpointInvoice + invID).pipe(
			catchError(ToolService.handleHttpError),
		);
	}

	getInvoiceInvoiceID(invoiceID: number): Observable<any> {
		return this.http.get(endpointInvoice + 'invoiceid/' + invoiceID).pipe(
			map(this.extractData));
	}

	createInvoice(invID: number, custID: number, house: number): Observable<any> {
		return this.http.post(`${endpointInvoice}${invID}?custID=${custID}&house=${house}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	closeInvoice(invID: number, custID: number, payment: number, house: number, outlet: number, profitCenter: number): Observable<any> {
		return this.http.put(`${endpointInvoice}${invID}?custID=${custID}&payment=${payment}&house=${house}&outlet=${outlet}&profitCenter=${profitCenter}&receiptWidth=-1`, null, httpOptions).pipe(
			map(this.extractData));
	}

	closeInvoiceToAccount(invID: number, targetInvID: number): Observable<any> {
		return this.http.put(`${endpointInvoice}${invID}/${targetInvID}?receiptWidth=-1`, '', httpOptions).pipe(
			map(this.extractData));
	}

	cancelInvoice(invID: number, reactivate = 0): Observable<any> {
		return this.http.delete(endpointInvoice + invID + '?reactivate=' + reactivate).pipe(
			map(this.extractData));
	}

	updateInvoiceHead(invID: number, invoice): Observable<any> {
		return this.http.put(endpointInvoice + 'head/' + invID, invoice, httpOptions).pipe(
			map(this.extractData));
	}

	updateInvoiceOwner(invID: number, newOwner: number): Observable<any> {
		return this.http.put(`${endpointInvoice}owner/${invID}/${newOwner}`, null, httpOptions);
	}

	getInvoicePrintout(invID: number, details: number): Observable<any> {
		return this.http.get(`${endpointReceipt}invoice/${invID}?details=${details}`).pipe(
			catchError(ToolService.handleHttpError),
			map(this.extractData));
	}

	getInvoiceReceipt(invID: number, details: number, receiptWidth = 0): Observable<any> {
		return this.http.get(`${endpointReceipt}bon/${invID}?details=${details}&receiptWidth=${receiptWidth}`).pipe(
			map(this.extractData));
	}

	getInvoiceFormat(invID: number, details: number, format: string): Observable<HttpEvent<Blob>> {
		return this.http.request(new HttpRequest('GET', `${endpointReceipt}${format}/${invID}?details=${details}`, null, { reportProgress: true, responseType: 'blob' })).pipe(
			catchError(ToolService.handleHttpErrorBlob));
	}

	getInvoicesPdf(fromDate: Date, toDate: Date): Observable<HttpEvent<Blob>> {
		return this.http.request(new HttpRequest('GET', `${endpointReceipt}date/${fromDate.toISODateString()}/${toDate.toISODateString()}`, null, { reportProgress: true, responseType: 'blob' })).pipe(
			catchError(ToolService.handleHttpErrorBlob));
	}

	getRoomVoucher(invID: number, targetInvID: number, group = ''): Observable<any> {
		const receiptWidth = ReceiptService.getPrinterWidth(ReceiptService.pidReceipt);
		return this.http.get(`${endpointReceipt}voucher/${invID}?targetInvID=${targetInvID}&group=${group}&details=1&receiptWidth=${receiptWidth}`).pipe(
			map(this.extractData));
	}

	/////////////////////////////////
	// Journal
	getJournal(id: number): Observable<Journal> {
		return this.http.get<Journal>(`${endpointJournal}id/${id}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getJournalRows(invID: number, filter = 0): Observable<Journal[]> {
		return this.http.get<Journal[]>(`${endpointJournal}${invID}?statusFilter=${filter}`);
	}

	addJournal(data: Journal): Observable<any> {
		if (data.discount) {
			data.price = data.basePrice * (100.0 - data.discount) / 100.00;
		}

		return this.http.post(endpointJournal, JSON.stringify(data), httpOptions).pipe(
			map(this.extractData));
	}

	bookJournal(rows: Journal[], checkInvoice: boolean): Observable<any> {
		const data = { rows: rows };

		return this.http.put(`${endpointJournal}book?checkInvoice=${checkInvoice}`, JSON.stringify(data), httpOptions).pipe(
			map(this.extractData));
	}

	updateJournal(journal: Journal): Observable<any> {
		return this.http.put(endpointJournal + journal.id, JSON.stringify(journal), httpOptions).pipe(
			map(this.extractData));
	}

	getNextPackageID(): Observable<number> {
		return this.http.get<number>(endpointJournal + 'packageID');
	}

	bookArticle(invID: number, artID: number, quantity: number, basePrice: number, text = '', discount = 0, date: Date = null, outlet = 0, profitCenter = 0, status = 0, bid = 0): Observable<any> {
		const data = {
			invID: invID,
			artID: artID,
			quantity: quantity,
			status: status,
			text: text,
			date: date ? date : new Date(),
			bid: bid,
			outlet: outlet,
			profitCenter: profitCenter,
			discount: discount,
			basePrice: basePrice,
			commissionUser: 0,
		} as Journal;

		data.price = data.basePrice * (100.0 - discount) / 100.00;

		return this.http.post(endpointJournal, JSON.stringify(data), httpOptions).pipe(
			map(this.extractData));
	}

	deleteJournal(id: number, deleteRow = true, text = ''): Observable<any> {
		return this.http.delete(`${endpointJournal}${id}/${deleteRow}?text=${text}`, httpOptions).pipe(
			map(this.extractData));
	}

	getOpenAccounts(filter: string): Observable<any> {
		return this.http.get(endpointJournal + 'open/' + filter).pipe(
			map(this.extractData));
	}

	getAccountInfo(invID: number, calcBalance = false): Observable<AccountInfo> {
		return this.http.get<AccountInfo>(`${endpointJournal}info/${invID}?calcBalance=${calcBalance}`);
	}

	splitToTable(invID: number, tableID: number, groupFilter: string) {
		return this.http.put(`${endpointJournal}table/${invID}/${tableID}?groupText=${groupFilter}`, null, httpOptions);
	}

	splitJournal(id: number, targetInvID: number, quantity = 0, amount = 0): Observable<any> {
		return this.http.put(`${endpointJournal}split/${id}/${targetInvID}?quantity=${quantity}&amount=${amount}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	splitJournalAmount(id: number, targetInvID: number, amount: number): Observable<any> {
		return this.http.put(`${endpointJournal}splitAmount/${id}/${targetInvID}?amount=${amount}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	splitJournalGroup(sourceInvID: number, targetInvID: number, groupText: string): Observable<any> {
		return this.http.put(`${endpointJournal}split/${sourceInvID}/${targetInvID}/${encodeURIComponent(groupText)}`, null, httpOptions).pipe(
			map(this.extractData));
	}


	/////////////////////////////////
	// Search
	getSearchResult(searchText: string, options = ''): Observable<SearchResult[]> {
		return new Observable(obs => {
			if (!searchText || typeof searchText !== 'string' ||  searchText.trim().length < 2) {
				obs.next([]);
				obs.complete();
				return;
			}

			searchText = searchText.replace(/\//g, '§');

			const result = this.http.get<SearchResult[]>(`${endpointSearch}${searchText}?options=${options}`);
			result.subscribe(data => {
				if (searchText.length > 3 && options.indexOf('setting') >= 0) {
					searchText = searchText.toLocaleLowerCase();

					// find labels
					let settings = Settings.controls.filter(c => c.level !== 2 && c.label.toLocaleLowerCase().indexOf(searchText) >= 0 && c.type !== 'header').slice(0, 10);
					let settingsResult = settings.map(s => ({icon: 'settings', text: s.label.replace(':', ''), route: `/settings?filter=${s.section}&key=${s.key}`, type: Const.searchTypeSetting } as SearchResult));
					data = [...data, ...settingsResult];

					// find labels headers
					settings = Settings.controls.filter(c => c.level !== 2 && c.label.toLocaleLowerCase().indexOf(searchText) >= 0 && c.type === 'header').slice(0, 10);
					settingsResult = settings.map(s => ({ icon: 'settings', text: s.label.replace(':', ''), route: `/settings?filter=${s.section}`, type: Const.searchTypeSetting } as SearchResult));
					data = [...data, ...settingsResult];

					// find sections
					settings = Settings.controls.filter(c => c.level !== 2 && c.section.toLocaleLowerCase().indexOf(searchText) >= 0 && c.type === 'header').slice(0, 5);
					settingsResult = settings.map(s => ({ icon: 'settings', text: s.label, route: '/settings?filter=' + s.section, type: Const.searchTypeSetting } as SearchResult));
					data = [...data, ...settingsResult];
				}
				data = data.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

				obs.next(data);
				obs.complete();
			});
		});
	}



	getSearchInvID(searchResult: SearchResult): Observable<any> {
		return this.http.put(endpointSearch + 'invid', searchResult, httpOptions).pipe(
			map(this.extractData));
	}


	/////////////////////////////////
	// Reports
	getReport(fromDate: Date, toDate: Date, type: string, filter = '', dueDate: Date = null): Observable<any> {
		const d = dueDate ? dueDate.toISODateString() : '1899-12-30';
		return this.http.get(`${endpointReports}${type}/${fromDate.toISODateString()}/${toDate.toISODateString()}?filter=${filter}&dueDate=${d}` ).pipe(
			map(this.extractArray));
	}



	/////////////////////////////////
	// User
	@Cacheable({
		cacheBusterObserver: cacheBusterUser$,
	})
	getUsers(): Observable<User[]> {
		return this.http.get<User[]>(endpointUser);
	}

	getUser(id: number, fromCache = true): Observable<User> {
		if (fromCache) {
			return new Observable(obs => {
				this.getUsers().subscribe(users => {
					obs.next(users.find(u => u.id === id));
					obs.complete();
				});
			});
		}

		return this.http.get<User>(endpointUser + id);
	}

	getUsername(id: number): Observable<string> {
		return new Observable(obs => {
			if (!id || id === -1) {
				obs.next('n/a');
				obs.complete();
			} else if (id > 0) {
				this.getUser(id).subscribe(user => {
					obs.next(user ? user.name : `User ${id}`);
					obs.complete();
				});
			} else {
				obs.next('Adr-ID' + (-id));
				obs.complete();
			}
		});
	}

	getUserImage(id: number): Observable<string> {
		return new Observable(obs => {
			if (!id || id < 0) {
				obs.next(Const.defaultUserImage);
				obs.complete();
				return;
			}

			this.getUser(id).subscribe(user => {
				let imageData = user ? user.image : '';
				if (!imageData) {
					imageData = Const.defaultUserImage;
				}
				obs.next(imageData);
				obs.complete();
			});
		});
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterUser$
	})
	addUser(user: User): Observable<any> {
		return this.http.post(endpointUser, JSON.stringify(user), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterUser$
	})
	updateUser(id: number, user): Observable<any> {
		return this.http.put(endpointUser + id, JSON.stringify(user), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterUser$
	})
	deleteUser(id: number): Observable<any> {
		return this.http.delete(endpointUser + id, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterUser$
	})
	clearUserCache(): Observable<any> {
		return new Observable<any>(obs => {
			obs.next(true);
			obs.complete();
		});
	}

	// Get max of Users
	getNextUserID(): Observable<number> {
		return new Observable(obs => {
			this.getUsers().subscribe(data => {
				const id = Math.max(...data.map(o => o.id), 1) + 1;
				obs.next(id);
				obs.complete();
			});
		});
	}


	/////////////////////////////////
	// Wiki
	getWikis(): Observable<Wiki[]> {
		return this.http.get<Wiki[]>(endpointWiki);
	}

	getWiki(id: number): Observable<Wiki> {
		return this.http.get<Wiki>(endpointWiki + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getWikiNameHTML(name: string, nameSpace = ''): Observable<Wiki> {
		return this.http.get<Wiki>(`${endpointWiki}${name}?ns=${nameSpace}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getWikiFOIDHTML(foid: string, nameSpace = ''): Observable<Wiki> {
		return this.http.get<Wiki>(`${endpointWiki}foid/?id=${foid}&ns=${nameSpace}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	findWiki(searchString: string): Observable<Wiki[]> {
		return this.http.get<Wiki[]>(endpointWiki + 'search/' + searchString).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	addWiki(data: Wiki): Observable<any> {
		return this.http.post(endpointWiki, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateWiki(id, data: Wiki): Observable<any> {
		data.id = id;
		return this.http.put(endpointWiki + id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	deleteWiki(id: number): Observable<any> {
		return this.http.delete(endpointWiki + id, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}


	/////////////////////////////////
	// Misc
	getDBInfo(): Observable<any> {
		return this.http.get(endpointMisc + 'dbinfo').pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getQRCode(content: string): Observable<any> {
		return this.http.get(endpointMisc + 'qr?content=' + content, { responseType: 'text' }).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	analyseMrz(image: string): Observable<any> {
		return this.http.post(`${endpointMisc}mrz`, JSON.stringify(image), httpOptions).pipe(
			map(this.extractData));
	}

	saveClickMe(xml: string): Observable<any> {
		return this.http.post(endpointMisc + 'clickMe', JSON.stringify(xml), httpOptions);
	}

	getGuestAppLink(id: number, type: string): Observable<string> {
		return this.http.get(`${endpointMisc}guestapp/?id=${id}&type=${type}`, { responseType: 'text' }).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	/////////////////////////////////
	// Message
	sendBroadcast(cmd: string, data): Observable<any> {
		return this.http.post(`${endpointMessage}broadcast/${cmd}`, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	sendtoDisplay(cmd: string, data: BroadcastData): Observable<any> {
		data.deviceID = localStorage.getItem('display-deviceid');
		return this.http.post(`${endpointMessage}broadcast/${cmd}`, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}


	/////////////////////////////////
	// Voucher
	getVouchers(showAll = false): Observable<Voucher[]> {
		return this.http.get<Voucher[]>(`${endpointVoucher}?showAll=${showAll}`);
	}

	getVoucher(itemID: string): Observable<Voucher> {
		return this.http.get<Voucher>(endpointVoucher + itemID);
	}

	getVoucherID(id: number): Observable<Voucher> {
		return this.http.get<Voucher>(`${endpointVoucher}id/${id}`);
	}

	getVoucherHist(itemID: string): Observable<Voucher[]> {
		return this.http.get<Voucher[]>(`${endpointVoucher}hist/${itemID}`);
	}

	addVoucher(data: Voucher): Observable<any> {
		return this.http.post(endpointVoucher, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateVoucher(data: Voucher): Observable<any> {
		return this.http.put(endpointVoucher + data.id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	deleteVoucher(id: number): Observable<any> {
		return this.http.delete(endpointVoucher + id, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	redeemVoucher(itemID: string, invID: number, amount: number, outlet: number) {
		return this.http.put(`${endpointVoucher}redeem/${itemID}/${invID}/${amount}?outlet=${outlet}`, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	sendVoucherMail(template: string, itemID: string, mailRef: string): Observable<any> {
		return this.http.put(`${endpointVoucher}send/${template}/${itemID}/${mailRef}`, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	/////////////////////////////////
	// News
	getAllNews(type: number, language: string): Observable<News[]> {
		return this.http.get<News[]>(`${endpointNews}?type=${type}&language=${language}`);
	}

	getNewsTitles(language: string): Observable<string[]> {
		return this.http.get<string[]>(`${endpointNews}titles?language=${language}`);
	}

	getNewsScopes(language: string): Observable<string[]> {
		return this.http.get<string[]>(`${endpointNews}scopes?language=${language}`);
	}

	getNews(id: number): Observable<News> {
		return this.http.get<News>(endpointNews + id);
	}

	addNews(data: News): Observable<any> {
		return this.http.post(endpointNews, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateNews(data: News): Observable<any> {
		return this.http.put(endpointNews + data.id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	deleteNews(id: number): Observable<any> {
		return this.http.delete(endpointNews + id, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	/////////////////////////////////
	// Debug
	getDebugLog(type: string): Observable<any> {
		return this.http.get(`${endpointMisc}debug/${type}`, {responseType: 'text'});
	}

	addDebugLog(message: string): Observable<any> {
		const data = { message: message };
		return this.http.post(endpointMisc + 'debug', data, httpOptions);
	}

	truncateDebugLog(): Observable<any> {
		return this.http.delete(endpointMisc + 'debug', httpOptions);
	}

	addLog(message: string, invID: number = 0, bid: number = 0): Observable<any> {
		const data = { message: message, invID: invID, bid: bid };
		return this.http.post(endpointMisc + 'log', data, httpOptions);
	}


	/////////////////////////////////
	// Dates
	getDateReceipt(ids: number[]): Observable<any> {
		const receiptWidth = ReceiptService.getPrinterWidth(ReceiptService.pidReceipt);
		return this.http.get(`${endpointDates}receipt/${ids.join(',')}?receiptWidth=${receiptWidth}`).pipe(
			map(this.extractData));
	}

	getDate(id: number): Observable<any> {
		return this.http.get<any>(endpointDates + id);
	}

	/////////////////////////////////
	// TJournal
	getTJournals(start: Date, end: Date): Observable<TJournal[]> {
		return this.http.get<TJournal[]>(`${endpointTJournal}${start.toISODateString()}/${end.toISODateString()}`);
	}

	getTJournal(id: number): Observable<TJournal> {
		return this.http.get<TJournal>(endpointTJournal + id);
	}

	addTJournal(data: TJournal, repeat = 0, limit: Date = null): Observable<any> {
		const date = limit ? limit : new Date(1899, 11, 30);
		return this.http.post(`${endpointTJournal}?repeatDays=${repeat}&limit=${date.toISODateString()}`, JSON.stringify(data), httpOptions);
	}

	updateTJournal(data: TJournal): Observable<any> {
		return this.http.put(endpointTJournal + data.id, JSON.stringify(data), httpOptions)
	}

	deleteTJournal(id: number, future: boolean): Observable<any> {
		return this.http.delete(`${endpointTJournal}${id}?future=${future}`, httpOptions);
	}

	/////////////////////////////////////////
	// Newsletter
	export2Mailjet(listName = ''): Observable<any> {
		return this.http.post(`${endpointNewsletter}export?listName=${listName}`, null, httpOptions);
	}

	importMailjet(listName = ''): Observable<any> {
		return this.http.post(`${endpointNewsletter}import?listName=${listName}`, null, httpOptions);
	}



}
