import { Component, Inject, ViewChild, ViewChildren, QueryList, AfterViewInit, Pipe, PipeTransform, ChangeDetectionStrategy, Input, Directive, OnInit, ElementRef, HostListener, ComponentRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray, CdkDragHandle, CdkDrag } from '@angular/cdk/drag-drop';
import { mergeAll, map, delay, startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { zip } from 'lodash-es';
import { ImageService, ToolService } from '../../_services';
import { ImageOptimizeComponent } from './image-optimize.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

/*
 *
 * @Directive({
	selector: '[cdkDropList][actualContainer]',
})
export class CdkDropListAcualContainer {
	@Input('actualContainer') actualContainer: string;
	originalElement: ElementRef<HTMLElement>;

	constructor(cdkDropList: CdkDropList) {
		cdkDropList._dropListRef.beforeStarted.subscribe(() => {
			if (!this.originalElement) {
				this.originalElement = cdkDropList.element;
			}

			if (this.actualContainer) {
				const element = this.originalElement.nativeElement.closest(this.actualContainer) as HTMLElement;
				cdkDropList.element = new ElementRef<HTMLElement>(element);
				cdkDropList._dropListRef.element = element;
			} else {
				cdkDropList.element = this.originalElement;
				cdkDropList._dropListRef.element = cdkDropList.element.nativeElement;
			}

		});
	}
}

*/

@Component({
	selector: 'image-tooltip',
	template: `<div @tooltip><img [src]="src" /></div>`,
	styles: [':host { display: block;}', 'div {}', 'img {  max-width:200px; max-height:200px;}'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('tooltip', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate(300, style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate(300, style({ opacity: 0 })),
			]),
		]),
	],
})
export class ImageTooltipComponent {

	@Input() src = '';
}

@Directive({ selector: '[imageTooltip]' })
export class ImageTooltipDirective implements OnInit {

	@Input('imageTooltip') text = '';
	private overlayRef: OverlayRef;

	constructor(private overlay: Overlay,
		private overlayPositionBuilder: OverlayPositionBuilder,
		private elementRef: ElementRef) {
	}

	ngOnInit(): void {
		const positionStrategy = this.overlayPositionBuilder
			.flexibleConnectedTo(this.elementRef)
			.withPositions([{
				originX: 'center',
				originY: 'top',
				overlayX: 'center',
				overlayY: 'bottom',
				offsetY: -8,
			}]);

		this.overlayRef = this.overlay.create({ positionStrategy });
	}

	@HostListener('mouseenter')
	show() {
		const tooltipRef: ComponentRef<ImageTooltipComponent>
			= this.overlayRef.attach(new ComponentPortal(ImageTooltipComponent));
		tooltipRef.instance.src = this.text;
	}

	@HostListener('mouseout')
	hide() {
		this.overlayRef.detach();
	}
}




@Component({
	selector: 'fo-array-box',
	templateUrl: './array-box.component.html',
	styles: [
		'.mat-column-cmd {flex: 0 0 48px;}',
		'.mat-column-rowid {flex: 50px 0 0; padding:0}',
		'.mat-mdc-cell { border: none; padding: 0 .5em }',
		'.cdk-drag-animating { transition: transform 250ms cubic-bezier(0, 0, 0.2, 1); }',
		`.cdk-drag-preview { box-sizing: border-box;  border-radius: 4px;
			box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),0 3px 14px 2px rgba(0, 0, 0, 0.12);}`]
})
export class ArrayBoxComponent implements AfterViewInit {
	public caption: string;
	public displayedColumns = ['rowid'];
	public columns = [];
	public isJSON = true;
	public dataSource = new MatTableDataSource<any>();

	@ViewChild('table', { static: true }) table: MatTable<any>;

	@ViewChildren(CdkDragHandle) handles: QueryList<CdkDragHandle>;
	@ViewChildren(CdkDrag) drags: QueryList<CdkDrag>;

	constructor(
		public dialogRef: MatDialogRef<ArrayBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public tools: ToolService,
		private dialog: MatDialog,
	) {
		this.caption = data.caption;

		data.value = (data.value || '');

		this.isJSON = data.value[0] === '[' || (data.columns || '').length > 1;

		if (this.isJSON) {
			if (data.value.startsWith('[')) {
				try {
					this.dataSource.data = JSON.parse(data.value);
				} catch (e) {
					console.error(e);
					this.dataSource.data = [];
				}
			} else if (data.value) { // convert Array to json
				const oldArr = data.value.split('|');
				oldArr.forEach(s => this.dataSource.data.push({ name: s }));
			}

			// Add Caption field
			data.columns.forEach(h => {
				const opt = h.split('/');
				const arr = opt[0].split(':');
				const col = { name: arr[0], caption: arr[arr.length - 1], option: opt[1] || '' };

				this.columns.push(col);
				this.displayedColumns.push(arr[0]);
			});

			// Set default to zero
			this.dataSource.data.forEach(row => {
				this.columns.forEach(col => {
					if (!row[col.name] && (col.option.startsWith('iselect') || ['int', 'cur', 'percent'].indexOf(col.option) >= 0)) {
						row[col.name] = 0;
					}
				});
			});

		} else { // simple array
			const rows = [];
			const d = (data.value || '').split('|');
			d.forEach(item => {
				rows.push({ name: item });
			});

			const col = { name: 'name', caption: data.columns ? data.columns : 'Bezeichnung', option: '' };
			this.columns.push(col);
			this.displayedColumns.push('name');

			this.dataSource.data = rows;
		}

		this.displayedColumns.push('cmd');
	}

	ngAfterViewInit() {
		combineLatest([this.drags.changes, this.handles.changes])
			.pipe(
				startWith([]),
				map(() => zip(this.drags.toArray(), this.handles.toArray())),
				delay(100),
				mergeAll()
			)
			// @ts-ignore
			.subscribe(([drag, handle]) => {
				if (drag && handle) { drag._dragRef.withHandles([handle.element]); }
			});
	}

	onDelete(index) {
		this.dataSource.data.splice(index, 1);
		this.table.renderRows();
	}

	onAdd(): void {
		this.dataSource.data.push({});
		this.table.renderRows();
	}

	onOk(): void {
		if (this.isJSON) {
			// convert array -> string
			this.columns.forEach(col => {
				if (col.option.startsWith('mselect')) {
					this.dataSource.data.forEach(row => {
						if (Array.isArray(row[col.name])) { row[col.name] = row[col.name].join('|'); }
					});
				}

				if (col.option === 'percent') {
					this.dataSource.data.forEach(row => {
						if (!row[col.name]) { row[col.name] = ToolService.formatNumber(0); }
					});
				}

			});

			this.dialogRef.close(JSON.stringify(this.dataSource.data));
		} else {
			const arr = [];
			this.dataSource.data.forEach(s => arr.push(s.name));
			this.dialogRef.close(arr.join('|'));
		}
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		this.table.renderRows();
	}

	private loadImage2imgBB(imageData: string): Promise<string> {
		return new Promise(async (resolve, reject) => {
			const apiKey = await this.tools.settingString('global', 'imgbb').toPromise(); // 'd37b083c6606e84e688d64204aaa69f2';

			const file = ToolService.dataURLtoFile(imageData, 'FOCloudImage');

			const form = new FormData();
			form.append('image', file);

			fetch(`https://api.imgbb.com/1/upload?key=${apiKey}`, {
				method: 'POST',
				body: form,
			})
				.then(async response => {
					if (response.ok) {
						const json = await response.json();
						resolve(json.data.url);
					} else {
						reject(await response.text());
					}
				})
				.catch(err => reject(err));

		});
	}

	onUploadImage(data, col) {
		const fileUpload = document.getElementById('imageUpload') as HTMLInputElement;
		fileUpload.onchange = async (event: any) => {
			let imageData = await ImageService.loadImageResize(fileUpload.files[0]).toPromise();
			event.target.value = null;

			const dialogRef = this.dialog.open(ImageOptimizeComponent, { data: { image: imageData } });
			imageData = await dialogRef.afterClosed().toPromise();
			if (!imageData) { return; }

			this.tools.isLoading = true;
			const url = await this.loadImage2imgBB(imageData)
				.catch((err) => this.tools.showError(err));

			this.tools.isLoading = false;

			if (url) {
				console.log('Image uploaded to imgBB:', url);
				data[col.name] = url;
			}
		};
		fileUpload.click();
	}


}


