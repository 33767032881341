import {Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PinStore, User } from '../models/models';
import { SettingService } from './setting.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ToolService } from './tool.service';
import { SignalRService } from './signalR.service';
import { Const } from '../models/constants';

const endpointUser = '/api/users/';

@Injectable()
export class AuthenticationService {

	constructor(
		private http: HttpClient,
		private settingService: SettingService,
	) { }

	@Output()
	currentUser: EventEmitter<any> = new EventEmitter();

	public static getDbName() : string {
		const dbName = (window['_app_base'] || '');

		return dbName;
	}

	static getCurrentUser(): User {
		let user: User;
		const dbName = (window['_app_base'] || '');
		const json = localStorage.getItem('currentUser-' + dbName);
		user = json ? JSON.parse(json) : null;
		return user;
	}

	static getRoleID(role: string): number {
		const roles = ['guest', 'user', 'manager', 'admin'];
		for (let i = 0; i < roles.length; i++) {
			if (role === roles[i]) { return i; }
		}

		return -1;
	}

	static getPinStore(): PinStore[] {
		let pinStore = [];
		const b64 = localStorage.getItem('pin-store');
		if (b64) {
			const plain = atob(b64);
			pinStore = JSON.parse(plain);
		}

		return pinStore;
	}

	static getPin(login: string, pinStore: PinStore[] = null): PinStore {
		if (!pinStore) { pinStore = AuthenticationService.getPinStore(); }
		const ps = pinStore.find(p => login === p.login);

		return ps;
	}

	static updatePin(psNew: PinStore, pinStore: PinStore[] = null) {
		if (!pinStore) { pinStore = AuthenticationService.getPinStore(); }

		let ps = pinStore.find(p => psNew.login === p.login);
		if (!ps) {
			ps = {} as PinStore;
			pinStore.push(ps);
		}

		ps.login = psNew.login;
		ps.passwordHash = psNew.passwordHash;
		ps.pinHash = psNew.pinHash;

		const b64 = btoa(JSON.stringify(pinStore));
		localStorage.setItem('pin-store', b64);
	}

	static deletePin(login: string, pinStore: PinStore[] = null) {
		if (!pinStore) { pinStore = AuthenticationService.getPinStore(); }

		const index = pinStore.findIndex(ps => ps.login === login);
		pinStore.splice(index, 1);

		const b64 = btoa(JSON.stringify(pinStore));
		localStorage.setItem('pin-store', b64);
	}

/*
 *	import * as jwt_decode from 'jwt-decode';

 *
 *	static isTokenValid(token: string, dbName: string): boolean {
		if (!token) { return true; }
		let date: Date;

		const decoded = jwt_decode(token);
		if (decoded.exp !== undefined) {
			date = new Date(0);
			date.setUTCSeconds(decoded.exp);
		}

		const storedDbName = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
		if (storedDbName.toLowerCase() !== dbName.toLowerCase()) {
			console.log('Clearing stored login due to db-mismatch. stored:' + storedDbName + ' / startup:' + dbName);
			return false;
		}

		if (date !== undefined && date.valueOf() < new Date().valueOf()) { // expired
			console.log('Clearing stored login due to expiration');
			return false;
		}

		return true;
	}
*/


	login(username: string, password: string, securityCode: string): Observable<any> {
		const dbName = AuthenticationService.getDbName();
		const p256dh = localStorage.getItem('p256dh');
		const localIP = localStorage.getItem('localIP');

		return this.http.post<any>(`${endpointUser}authenticate?db=${dbName}&ip=${localIP}`,
			{ login: username, password: password, securityCode: securityCode, p256dh: p256dh })
			.pipe(map(user => {
				if (user && user.id) {
					localStorage.setItem('currentUser-' + dbName, JSON.stringify(user));
					this.currentUser.emit(user); // Send to all subscribers
				}

				return user;
			}));
	}

	logout() {
		const dbName = AuthenticationService.getDbName();

		// remove user from local storage to log user out
		localStorage.removeItem('currentUser-' + dbName);
		sessionStorage.clear();
		this.settingService.clearSettingCache().subscribe();

		setTimeout(() => this.currentUser.emit(null));
	}

	setPassword(old: string, password: string) {
		return this.http.put(endpointUser + 'password', { login: old, password: password });
	}


	emitStoredLogin() {
		const user = AuthenticationService.getCurrentUser();
		if (user) {
			this.currentUser.emit(user);
		}
	}
}






@Injectable()
export class AuthGuard  {

	constructor(
		private router: Router,
		private signalRService: SignalRService,
		private tools: ToolService,
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const user = ToolService.currentUser;

		// not logged in so redirect to login page with the return url
		if (!user) {
			this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
			return false;
		}

		if (user.enabled === false) {
			this.tools.showError('Der Benutzer ist gesperrt.');
			return false;
		}

		// Check modules
		let module = '';
		let child = route.root.firstChild;
		while (child) {
			module = child.data['module'] as string;
			child = child.firstChild;
		}

		if (!!module && this.tools.hasModule(module, true) === false) {
			return false;
		}

		// Register user, retry onecs
		this.signalRService.setUserInfo('FORoom', user.name, module || 'n/a')
			.catch(async () => {
				await new Promise(resolve => setTimeout(resolve, 1000));
				this.signalRService.setUserInfo('FORoom', user.name, module || 'n/a').catch(() => { });
		});

		// Check module count
		const moduleLicense = this.tools.license.modules.find(m => m.id === module);
		if (moduleLicense && moduleLicense.maxUser) {
			const users = this.signalRService.connections.filter(c => c.route === module && c.userName !== 'SiTec').length;
			if (users > moduleLicense.maxUser) {
				this.tools.showWarning(`Die Anzahl der gleichzeitigen Benutzer des Moduls ${module} (${moduleLicense.maxUser}) wurde überschritten.`);
			}
		}

		// Admin all access
		if (user.role === Const.roleAdmin) { return true; }

		// Check Groups
		let groups: Array<string> = [];
		child = route.root.firstChild;
		while (child) {
			groups = child.data['groups'] as Array<string>;
			child = child.firstChild;
		}

		let foundGroup = false;
		if (groups) {
			for (let i = 0; i < groups.length; i++) {
				const group = groups[i].toLowerCase();
				if (user.groups.toLowerCase().indexOf(group) >= 0) {
					foundGroup = true;
					break;
				}
			}

			if (foundGroup === false) {
				this.tools.showError(`Ihre Benutzerrechte reichen für diese Aktion nicht aus. Sie müssen Mitglied einer dieser Gruppen sein: '${groups.toString()}'.`);
				return false;
			}
		}

		// Can also be a route
		const currentRoute = state.url;
		let hasPath = false;
		for (const group of user.groups.split('|').filter(f => f.startsWith('/'))) {
			if (currentRoute.startsWith(group)) {
				return true;
			}
			hasPath = true;
		}
		// new if user has Path and does not fit -> lock him out
		if (hasPath && currentRoute.length > 1 && currentRoute !== '/home' && currentRoute !== '/login') {
			this.tools.showError(`Ihre Benutzerrechte reichen für diese Aktion nicht aus (Route).`);
			return false;
		}

		// Deny route route
		for (const group of user.groups.split('|').filter(f => f.startsWith('!/'))) {
			if (currentRoute.startsWith(group.substr(1))) {
				this.tools.showError(`Ihre Benutzerrechte reichen für diese Aktion nicht aus (Route gesperrt).`);
				return false;
			}
		}


		// Check user role
		const roles = route.data['roles'] as Array<string>;
		if (roles == null && (user.role > Const.roleGuest || foundGroup)) { // Guest locked out everywhere per Design (if not in group)
			return true;
		}

		if (roles) {
			for (let i = 0; i < roles.length; i++) {
				const role = roles[i];
				const iRole = AuthenticationService.getRoleID(role);
				if (user.role >= iRole) {
					return true;
				}
			}
		}

		if (currentRoute !== '/home' && currentRoute !== '/') {
			this.tools.showError('Ihre Benutzerrechte reichen für diese Aktion nicht aus.');
		}
		return false;
	}
}


export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard  {
	canDeactivate(component: CanComponentDeactivate,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot) {

		return component.canDeactivate ? component.canDeactivate() : true;
	}
}
