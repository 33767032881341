import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingService } from './setting.service';
import { InputBoxComponent, MsgBoxComponent, PinpadComponent, ConfirmBoxComponent, GetAdrComponent, IconSnackBarComponent, InputDateDialogComponent, InputDateRangeDialogComponent, InputEmailBoxComponent, GetBookingComponent, TipPinpadComponent } from '../modules/shared/tool.components';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Const } from '../models/constants';
import { IDictionary, FileStore, User, Tag, License, MrzData, House } from '../models/models';
import * as MD5 from 'md5';
import { LazyDialogLoader } from './lazy-dialog.service';
import { cloneDeep, defaults, defaultsDeep, isEqual, remove, zip } from 'lodash-es'; // put in shared module
import { ArrayBoxComponent } from '../modules/shared/array-box.component';
import { ImageOptimizeComponent } from '../modules/shared/image-optimize.component';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '.';
import { time } from 'console';



/* ========================================================================= */
@Injectable()
export class ToolService {

	public set isLoading(value: boolean|number) {
		if (value !== false) {
			this._loadingTimerStarted = true;

			if (typeof value === 'boolean') {
				setTimeout(() => { if (this._loadingTimerStarted) { this._loadingValue.next(0); } }, 1000);
			} else {
				this._loadingValue.next(+value);
			}
		} else {
			this._loadingTimerStarted = false;
			this._loadingValue.next(-1);
		}
	}

	public get isLoading(): boolean|number {
		return this._loadingTimerStarted;
	}

	public static country = 'A';
	public static currentUser: User;


	static hrNoSuccess = 1;

	private allHouses: House[] = [{id: 0, name: 'Main', outlet: 0, email: '' }];
	public outlets = ['*** Default ***'];
	public vat: number[] = [];
	public categories = [];

	public allModules = [
		{ id: 'hotel', name: 'Hotel' },
		{ id: 'restaurant', name: 'Restaurant' },
		{ id: 'cash', name: 'Cash' },
		{ id: 'code', name: 'Code-Editor' },
		{ id: 'wellness', name: 'Wellness' },
		{ id: 'ec', name: 'EC-Terminal' },
		{ id: 'be', name: 'Booking-Engine' },
		{ id: 'cm', name: 'Channel-Manager' },
		{ id: 'analysis', name: 'Daten-Analyse' },
		{ id: 'fibu', name: 'Fibu-Export' },
		{ id: 'guest', name: 'Gäste-App' },
		{ id: 'voucher', name: 'Gutschein' },
		{ id: 'housekeeping', name: 'Housekeeping' },
		{ id: 'cb', name: 'Kassabuch' },
		{ id: 'key', name: 'Key I/F' },
		{ id: 'slideshow', name: 'Kundendisplay' },
		{ id: 'kitchen', name: 'Küchenmonitor' },
		{ id: 'mw', name: 'Meldewesen' },
		{ id: 'msg', name: 'Nachrichten' },
		{ id: 'newsletter', name: 'Newsletter' },
		{ id: 'checkin', name: 'Online-CheckIn' },
		{ id: 'payment', name: 'Online-Payment' },
		{ id: 'pci', name: 'PCI-Proxy' },
		{ id: 'pos', name: 'POS I/F' },
		{ id: 'resource', name: 'Ressource-Verwaltung' },
		{ id: 'roster', name: 'Dienstplan' },
		{ id: 'schank', name: 'Schankanlagen I/F' },
		{ id: 'mice', name: 'Seminar' },
		{ id: 'tel', name: 'Telefon I/F' },
		{ id: 'tplan', name: 'Tischplan' },
		{ id: 'ww', name: 'Warenwitschaft' },
		{ id: 'webshop', name: 'Webshop' },
		{ id: 'yield', name: 'Yield' },
	];

	public modules: IDictionary = [];
	public license: License = { name: '', modules: [] } as License;
	public isDebug = false;
	public showSideNav = new BehaviorSubject(false);

	public theme = '';
	public enviroment = '';
	public plattform = '';

	public printLoadPrinters: Function;

	public scrollTo = new EventEmitter<string>();
	public scanResult = new EventEmitter<string | MrzData>();
	public scannerRunning = false;

	public _loadingValue = new BehaviorSubject<number>(-1);
	private _loadingTimerStarted = false;

	public isMobile: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
		.pipe(
			map(result => result.matches)
		);

	public isTablet: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.TabletPortrait)
		.pipe(
			map(result => result.matches)
	);

	constructor(
		private snackBar: MatSnackBar,
		public dialog: MatDialog,
		public router: Router,
		private settingService: SettingService,
		private breakpointObserver: BreakpointObserver,
		private lazyDialog: LazyDialogLoader,
		private authenticationService: AuthenticationService,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.loadBasicSettings();
		this.authenticationService.currentUser.subscribe(u => ToolService.currentUser = u);
	}


	private static preLoadComponents() { // Dummy to preload several components
		throw new Error(('Never call this'));

		const x1 = cloneDeep({});
		const x2 = defaults();
		const x3 = defaultsDeep();
		const x5 = isEqual(1, 2);
		const x6 = remove();
		const x7 = zip();
		const x8 = new ArrayBoxComponent(null, null, null, null);
		const x9 = new ImageOptimizeComponent(null, null, null);
	}

	static isNumber(value: string | number): boolean {
		return (value !== null && value !== undefined && isFinite(Number(value.toString())));
	}

	static zeroPad(num, places) {
		return String(num).padStart(places, '0');
	}

	static formatNumber(value: number | string, fractionSize = 2): string {
		// HACK Transfer string values to recognised numbers
		if (typeof value === 'string' && value.substr(value.length - 3, 1) === ',') {
			const index = value.length - 3;
			value = value.substr(0, index) + '.' + value.substr(index + 1);
		}

		if (this.isNumber(value) === false) {
			return isNaN(+value) || value === Infinity || value == null ? '' : '' + value;
		}

		// min int  = empty
		if (+value <= -2000000000) {
			return '';
		}

		value = ToolService.round(+value, fractionSize);

		let comma = ',';
		let separator = '.';
		if (ToolService.country === 'CH') {
			comma = '.';
			separator = `'`;
		}

		const padding = '000000';
		let [integer, fraction = ''] = ('' + value || '').split('.'); // always dot (s. above)

		fraction = fractionSize > 0 ? comma + (fraction + padding).substring(0, fractionSize) : '';
		integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

		if (isNaN(parseFloat(integer))) {
			integer = '0';
		}
		return integer + fraction;
	}

	static formatPercent(value: number | string, fractionSize = 2): string {
		const strVal = '' + value;
		const num = ToolService.parseNumber(strVal.replace('%', ''), fractionSize);
		const str = ToolService.formatNumber(num, fractionSize);

		const isPercent = strVal.indexOf('%') > 0 && str !== '';
		return str + (isPercent ? '%' : '');
	}

	static formatCurrency(value: number | string) {
		const num = ToolService.formatNumber(value);
		if (!num) { return ''; }

		switch (ToolService.country) {
			case 'CH':
				return 'CHF\u00A0' + num;
			default:
				return num + '\u00A0€';
		}
	}

	static formatSize(bytes: number, decimals = 2) {
		if (bytes === 0) { return '0 Bytes'; }
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return this.formatNumber(bytes / Math.pow(k, i), dm) + ' ' + sizes[i];
	}

	static formatValue(value: any, type = '') {
		if (!type) {
			if (typeof value === 'number') { type = 'num' }
			if (value instanceof Date) { type = 'date' }
		}

		if (type === 'percent') {
			return isNaN(value) ? '' : ToolService.formatNumber(value) + '%';
		} else if (type === 'int') {
			return value === -2147483648 ? '' : ToolService.formatNumber(value, 0);
		} else if (type === 'cur') {
			return ToolService.formatCurrency(value);
		} else if (type === 'date') {
			return ToolService.formatDate(value);
		} else if (type === 'dateTime') {
			return ToolService.formatDateTime(value);
		} else if (type === 'boolean') {
			return value ? 'X' : '';
		} else if (typeof (value) === 'number') {
			return isNaN(value) ? '' : ToolService.formatNumber(value, 2);
		} else {
			return value === 'NaN' ? '' : value;
		}

	}

	static fix(s: string, length: number): string {
		if (length > 0) {
			s = s.substr(0, length);
			while (s.length < length) {
				s += ' ';
			}
		} else {
			s = s.substr(length);
			while (s.length < -length) {
				s = ' ' + s;
			}
		}

		return s;
	}

	static parseNumber(value: number | string, fractionSize = 2, language: string = null): number {
		if (typeof value === 'number') { return value; }

		value = (value || '').replace('\u00A0', '').replace('€', '').replace('CHF', '');

		let en = (language || '').toLowerCase() === 'en';
		if (!language && value.length >= 4 ) { // try to guess 1.00
			if (value[value.length - 3] === '.') { en = true; }
		}
		if (!language && value.length >= 3) { // try to guess 1.0
			if (value[value.length - 2] === '.') { en = true; }
		}

		const prefix = '';
		const suffix = '';
		const decimalSeparator = en ? '.' : ',';
		const thousandsSeparator = en ? ',' : '.';

		const padding = '000000';
		let [integer, fraction = ''] = value.replace(prefix, '')
			.replace(suffix, '')
			.split(decimalSeparator);

		integer = integer.replace(thousandsSeparator, '');
		fraction = fractionSize > 0 ? '.' + (fraction + padding).substring(0, fractionSize) : '';

		return +(integer + fraction);
	}

	static round(value: number, fractionSize = 2): number {
		const multiplier = Math.pow(10, fractionSize || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	static inRange(value: number, rangeString: string): boolean {
		const ranges = rangeString.split(',');
		for (let i = 0; i < ranges.length; i++) {
			const range = ranges[i];
			const borders = range.split('-');
			const min = +borders[0];
			const max = borders.length > 1 ? +borders[1] : min;
			if (value >= min && value <= max) { return true; }
		}

		return false;
	}

	static testFlag(flags: number, flag: number): boolean {
		return (flags & flag) > 0;
	}

	static setFlag(flags: number, flag: number, bSet = true): number {
		if (bSet) {
			return flags | flag;
		} else {
			return flags & (~flag);
		}
	}

	static append(text: string, toAppend: string, delimiter = ' '): string {
		if (!text) { return toAppend; }
		if (!toAppend) { return text; }

		return text + delimiter + toAppend;
	}

	static secureURL(url: string): string {
		url = url.replace(/[;/?:@&=+$\<\>]/g, '_');

		return url;
	}

	static escapeHTML(str: string) {
		str.replace(/[&<>'"]/g,
			tag => ({
				'&': '&amp;',
				'<': '&lt;',
				'>': '&gt;',
				"'": '&#39;',
				'"': '&quot;'
			}[tag]));
		return str;
	}

	static htmlToText(html: string): string {
		const text = html.replace(/<[^>]+>/g, '');
		return text;
	}

	static dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}

	static getArrayVal(arrayString: string, index: number, returnDefault = false): string {
		if (!arrayString) { return ''; }

		const arr = arrayString.split('|');
		let val: string;

		if (index >= 0 && index < arr.length) {
			val = arr[index];
		} else {
			val = returnDefault ? arr[0] : '';
		}

		return val;
	}

	static formatDate(value): string {
		if (value === 'NaN') { return ''; }
		if (typeof value === 'string') { return value; }
		return new Date(value).toLocaleDateString();

	}

	static formatDateTime(value): string {
		if (typeof value === 'string') { return value; }
		return new Date(value).toLocaleDateTimeString();
	}

	static parseDate(value, expand = true): Date | null {
		if (value === '' || value === undefined) { return new Date(1899, 11, 30); } // empyt date

		if (Object.prototype.toString.call(value) === '[object Date]') {
			return  isNaN(value) ? null : value;
		}

		if (typeof value === 'number') {
			const timestamp = typeof value === 'number' ? value : Date.parse(value);
			return isNaN(timestamp) ? null : new Date(timestamp);
		}

		if (typeof value === 'string') {
			let day, month, year: number;

			if (value.indexOf('.') > 0) {
				const str = value.split('.');
				day = parseInt(str[0], 10);
				month = parseInt(str[1], 10);
				year = parseInt(str[2], 10);
			} else if (value.indexOf('-') > 0) { // ISO Date 2020-02-12T00:00
				const str = value.split('-');
				year = parseInt(str[0], 10);
				month = parseInt(str[1], 10);
				day = parseInt(str[2].substr(0, 2), 10);
			} else if (value.indexOf('/') > 0) { // US Date 12/31/2020
				const str = value.split('/');
				year = parseInt(str[2], 10);
				month = parseInt(str[0], 10);
				day = parseInt(str[1], 10);
			} else {
				day = parseInt(value.substr(0, 2), 10);
				month = parseInt(value.substr(2, 2), 10);
				year = parseInt(value.substr(4, 4), 10);
			}

			// fix for hungarian date
			if (day > 1900) {
				const temp = year;
				year = day;
				day = temp;
			}

			if (expand && (isNaN(day) || day === 0)) {
				day = new Date().getDay();
			}

			if (expand && (isNaN(month) || month === 0)) {
				month = new Date().getMonth() + 1;
			}

			if (expand && (isNaN(year) || year === 0)) {
				year = new Date().getFullYear();
			}

			if (year < 40) {
				year += 2000;
			}

			if (isNaN(day) || isNaN(month) || isNaN(year)) {
				return null;
			}
			const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
			if ((!(year % 4) && year % 100) || !(year % 400)) { daysInMonth[1] = 29; }
			if (day < 1 || month < 1 || month > 12 || year > 2500) {
				return null;
			}

			if (day > daysInMonth[month - 1]) {
				return null;
			}

			return new Date(year, month - 1, day);
		}

		return null;
	}

	static parseTime(value: string): Date | null {
		if (!value) {
			return null;
		}

		let hour = 0;
		let minute = 0;
		let second = 0;

		const str = value.trim().split(':');
		hour = + str[0];
		if (str.length > 1) { minute = +str[1]; }
		if (str.length > 2) { second = +str[2]; }

		if (hour < 0 || hour > 23) { return null; }
		if (minute < 0 || minute > 59) { return null; }
		if (second < 0 || second > 59) { return null; }

		return new Date(0, 0, 0, hour, minute, second);
	}


	static formatTime(value: Date): string {
		let s = ('00' + value.getHours()).slice(-2) + ':' + ('00' + value.getMinutes()).slice(-2);
		if (value.getSeconds() > 0) { s += ':' + ('00' + value.getSeconds()).slice(-2); }

		return s;
	}

	static dateDiff(d1: string | Date, d2: string | Date) {
		const date1 = new Date(d1);
		date1.setMinutes(date1.getMinutes() - date1.getTimezoneOffset());

		const date2 = new Date(d2);
		date2.setMinutes(date2.getMinutes() - date2.getTimezoneOffset());

		const diff = date2.getTime() - date1.getTime();
		return Math.floor(diff / (1000 * 3600 * 24));
	}

	static dateRange2Str(date1: Date, date2: Date): string {
		let s: string;

		if (date1.getFullYear() === date2.getFullYear()) {
			if (date1.getMonth() === date2.getMonth()) {
				s = `${ this.zeroPad(date1.getDate(), 2) }.-${ date2.toLocaleDateString() }`;
			} else {
				s = `${ this.zeroPad(date1.getDate(), 2)}.${this.zeroPad(date1.getMonth() + 1, 2)}.-${date2.toLocaleDateString() }`;
			}
		} else {
			s = `${ date1.toLocaleDateString() }-${ date2.toLocaleDateString() }`;
		}
		return s;
	}


	static handleHttpError(error: HttpErrorResponse) {
		return throwError(ToolService.getHttpErrorMessage(error));
	}

	static handleHttpErrorBlob(err: HttpErrorResponse): Observable<any> {
		const reader: FileReader = new FileReader();

		const result = new Observable(obs => {
			reader.onloadend = () => {
				obs.error(reader.result);
				obs.complete();
			};
		});
		reader.readAsText(err.error);
		return result;
	}


	static getHttpErrorMessage(error: HttpErrorResponse): string {
		let msg = 'Unbekannter Fehler';
		const err = error.error ? error.error : error;

		if (err.message) {
			msg = err.message;
		} else if (err.status) {
			msg = err.status + ': ' + err.title;
			if (err.errors) { // one or more validation Error
				for (const field of Object.keys(err.errors)) {
					msg += `\n${field}: ${err.errors[field][0].split(',')[0]}`;
				}
			}
		} else if (typeof err === 'object') {
			msg = '';
			const o = Object.entries(err);
			o.forEach(row => {
				msg += row[0] + ':' + row[1] + ' ';
			});
		} else {
			msg = err;
		}

		if (!msg) {
			msg = error.statusText;
		}

		return msg; // for huge outlook errors
	}

	static readFile(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = () => {
				resolve(reader.result);
			};

			reader.onerror = reject;
			reader.readAsArrayBuffer(file);
		});
	}




	static setClipboardText(text: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = text;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}


	static print(printHtml: string, timeout = 250) {
		const frame1 = document.createElement('iframe');
		frame1.name = 'frame1';
		frame1.style.position = 'absolute';
		frame1.style.top = '-1000000px';
		document.body.appendChild(frame1);
		const frameDoc = frame1.contentDocument;
		frameDoc.open();
		frameDoc.write(printHtml);
		frameDoc.close();
		setTimeout(function () {
			window.frames['frame1'].focus();
			window.frames['frame1'].print();
			document.body.removeChild(frame1);
		}, timeout);
	}

	static calcUnit(qty: number, fromUnit: string, toUnit: string): number {
		const from = Const.units.find(u => u.name === fromUnit);
		const to = Const.units.find(u => u.name === toUnit);

		if (!from || !to || !qty || from.base !== to.base) { return NaN; }

		const base = qty * from.factor;
		const result = base / to.factor;

		return result;
	}

	static makeTag(tagList: Tag[], value: string): Tag {
		value = value.trim();
		const t = tagList.find(x => x.name.toLowerCase() === value.toLowerCase());
		const tag = { name: value, color: t ? t.color : null } as Tag;
		return tag;
	}

	static filterTags(tagList: string[], filterList: Tag[]): string[] {
		const filteredTags: string[] = [];

		tagList.forEach(t => {
			if (filterList && filterList.length) {
				const found = filterList.find(f => f.name.toLowerCase() === t.toLowerCase());
				if (found) { filteredTags.push(t); }

			} else {
				filteredTags.push(t); // if no filter: always
			}
		});

		return filteredTags;
	}



	static transformedBoundingBox(el) {
		const bb = el.getBBox();
		const svg = el.ownerSVGElement;
		const m = el.parentNode.getScreenCTM().inverse().multiply(el.getScreenCTM());

		// Create an array of all four points for the original bounding box
		const pts = [
			svg.createSVGPoint(), svg.createSVGPoint(),
			svg.createSVGPoint(), svg.createSVGPoint()
		];
		pts[0].x = bb.x; pts[0].y = bb.y;
		pts[1].x = bb.x + bb.width; pts[1].y = bb.y;
		pts[2].x = bb.x + bb.width; pts[2].y = bb.y + bb.height;
		pts[3].x = bb.x; pts[3].y = bb.y + bb.height;

		// Transform each into the space of the parent,and calculate the min/max points from that.
		let xMin = Infinity, xMax = -Infinity, yMin = Infinity, yMax = -Infinity;
		pts.forEach(function (pt) {
			pt = pt.matrixTransform(m);
			xMin = Math.min(xMin, pt.x);
			xMax = Math.max(xMax, pt.x);
			yMin = Math.min(yMin, pt.y);
			yMax = Math.max(yMax, pt.y);
		});

		// Update the bounding box with the new values
		bb.x = xMin;
		bb.width = xMax - xMin;
		bb.y = yMin;
		bb.height = yMax - yMin;
		return bb;
	}

	static sumBy(arr, func) {
		return arr.reduce((acc, item) => acc + func(item), 0);
	}

	static groupBy(arr, criteria) {
		return arr.reduce((obj, item) => {
			const key = typeof criteria === 'function' ? criteria(item) : item[criteria];

			if (!obj.hasOwnProperty(key)) { obj[key] = []; }
			obj[key].push(item);

			return obj;
		}, {});
	};


	static b64toBlob(b64Data: string, contentType: string, sliceSize?: number): Blob {
		contentType = contentType || '';
		sliceSize = sliceSize || 512;

		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	static stringtoBlob(str: string, contentType: string,): Blob {
		contentType = contentType || '';

		const blob = new Blob([str], { type: contentType });

		return blob;
	}

	///////////////////////////////
	// nonstatic members

	handleDownloadResult(result) {
		result.subscribe(data => {
			switch (data.type) {
				case HttpEventType.DownloadProgress:
					this.isLoading = Math.round((data.loaded / data.total) * 100);
					break;

				case HttpEventType.Response:
					this.isLoading = false;

					const file: Blob = data.body;

					const cd = data.headers.get('content-disposition');
					const i = cd.indexOf(`filename*=UTF-8''`);
					const filename = i > 0 ? decodeURIComponent(cd.substr(i + 17)) : cd.split('filename=')[1].split(';')[0];

					const anchor = document.createElement('a');
					anchor.download = filename;
					anchor.href = URL.createObjectURL(file);
					anchor.click();
					break;
			}
		},
			err => {
				this.isLoading = false;
				this.showError(err);
		});
	}


	getPrintHtml(text: string, caption = '', styles = ''): string {
		if (!styles) {
			styles = Const.printStyles.join(' ');
		}

		const license = `${this.license.name}<br/>${this.license.name1}<br/>${this.license.street}<br/>${this.license.city}<br/>`;
		const userName = ToolService.currentUser?.name;
		const printHeader = `<html>
<head>
<meta charset="utf-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
<meta name="viewport" content="width=device-width, initial-scale=1">
<style>
	body {font-family:sans-serif;Helvetica,Arial; margin: 2em 2em 2em 2em; text-align:left;}
	table {border:none; width:100%; border-collapse: collapse;}
	.header {font-size: .8em;}
	h2 {font-size: 1.2em}
	${styles}
</style>
</head>
<body>
<table>
	<tr>
		<td class='header'>${license}</td>
		<td align="right" class='header'>Benutzer: ${userName}<br>${new Date().toLocaleDateTimeString()}</td>
	</tr>
	<tr><td colspan="2">
		<h2>${caption}</h2>
	</td><tr>
</table>
<br/>
<div class="report">
${text}
</div>
</body></html>`;

		return printHeader;
	}

	getHouses(): House[] {
		const user = ToolService.currentUser;
		if (!user) { return [{ id: -1, name: 'n/a' } as House] }
		const houseFilter = user.houses ? user.houses.split('|').map(i => Number(i)) : [];
		const allHouses = houseFilter.length === 0;

		const houses: House[] = [];
		for (let i = 0; i < this.allHouses.length; i++) {
			const house = this.allHouses[i];
			if (allHouses || houseFilter.includes(house.id)) { houses.push(house); }
		}

		return houses;
	}

	getHouse(id: number): House {
		const houses = this.getHouses();
		const house = houses.find(h => h.id === id);
		return house;
	}

	hasAllHouses(): boolean{
		const houses = this.getHouses();
		return houses.length === this.allHouses.length;
	}



	loadLicense(): Promise<License> {
		let license = { name: '', modules: [] } as License;

		return new Promise<License>(async (resolve, reject) => {

			const json = await this.settingString('conf', 'license').toPromise().catch(() => { });
			if (json) {
				license = JSON.parse(json);

				// check sig
				const payload: License = JSON.parse(json);
				payload.signature = '';
				const payloadStr = JSON.stringify(payload).replace(/ |"|:/g, '');
				const signature = MD5(payloadStr);

				if (license.signature !== signature) {
					reject('Ungültige Lizenz-Signatur');
					return;
				}
			}

			resolve(license);
		});
	}

	async loadBasicSettings() {
		this.settingString('conf', 'modules').subscribe(para => {
		});

		this.license = await this.loadLicense();

		this.allModules.forEach(m => this.modules[m.id] = false);
		this.license.modules.forEach(module => this.modules[module.id] = true);

		const c = this.license.city.split('-');
		if (c.length > 1) { ToolService.country = c[0]; }

		this.settingObj('global', 'houses').subscribe(para => {
			this.allHouses = para;
			for (let i = 0; i < this.allHouses.length; i++) {
				this.allHouses[i].id = i;
			}
		});
		this.settingArray('global', 'outlets').subscribe(para => this.outlets = para);
		this.settingArray('global', 'vat').subscribe(para => {
			para.forEach(vat => this.vat.push(ToolService.parseNumber(vat.replace('%', ''))));
		});

		this.settingObj('global', 'category').subscribe(para => {
			this.categories = para;


			// convert old data
			let maxID = Math.max(...this.categories.map(o => o.id || -1));
			let needFix = false;
			for (let i = 0; i < this.categories.length; i++) {
				if (this.categories[i].id === undefined || this.categories[i].id === null) {
					maxID++;
					this.categories[i].id = maxID;
					needFix = true;
				} else {
					this.categories[i].id = +this.categories[i].id;
				}
			}
			if (needFix) {
				this.updateSetting('global', 'category', JSON.stringify(this.categories)).toPromise();
			}
		});

		this.settingArray('global', 'colors').subscribe(colors => {
			Const.colors = [...Const.defColors];

			for (let i = 0; i < colors.length; i++) {
				if (colors[i]) { Const.colors[i] = colors[i]; }
			}
		});

		this.settingArray('hotel', 'colors').subscribe(colors => {
			Const.hotelColors = [...Const.defHotelColors];

			for (let i = 0; i < colors.length; i++) {
				if (colors[i]) { Const.hotelColors[i] = colors[i]; }
			}
		});

		this.settingString('global', 'debug').subscribe(s => this.isDebug = (s === 'true'));

		if (this.printLoadPrinters) {
			this.printLoadPrinters();
		}

		// Set type of navigator
		const ua = navigator.userAgent;
		let isWebView = false;
		let isPWA = false;

		// Mozilla/5.0 (Windows NT 10.0; WOW64; WebView/3.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 Edge/18.18363
		// Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.77 Safari/537.36 Edg/91.0.864.41
		if (/Windows/i.test(ua)) {
			isWebView = /WebView/i.test(ua) || /FOCloudApp/i.test(ua);
			isPWA = /MSAppHost/i.test(ua);
			this.plattform = 'windows';
		}

		if (/(android)/i.test(ua)) { // Android ?
			isWebView = /FOCloudApp/i.test(ua);
			isPWA = (window.matchMedia('(display-mode: standalone)').matches);
			this.plattform = 'android';
		}

		if (/ipad|iphone|ipod/i.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) { // iOS
			isWebView = /safari/i.test(ua) === false;
			isPWA = !!((window.navigator as any).standalone);
			this.plattform = 'ios';
		}

		this.enviroment = isWebView ? 'app' : (isPWA ? 'pwa' : 'web');

		// Carbon Hack-> Carbon are not compatible any more 240823
/*		if (this.enviroment === 'app' && /N86/i.test(ua)) {
			const n86Styles = `
.mat-icon-button { background-color: transparent; }
.mat-stroked-button { border: 1px solid #e0e0e0!important; }
.mat-footer-row, .mat-header-row, .mat-row, td.mat-cell, td.mat-footer-cell, th.mat-header-cell { border-color: #e0e0e0 !important; }
.mat-form-field-underline {	background-color: #949494!important; }
.mat-slide-toggle-bar {	background-color: #9e9e9e; }
`;

			const links = this.document.getElementsByTagName('link');
			const link = links[links.length - 1];
			const style = this.document.createElement('style');
			style.innerHTML = n86Styles;
			link.parentNode.insertBefore(style, link.nextSibling);

			console.log('Legacy styles applied.')
        }
	*/
	}

	showMessage(msg: string, icon = '', actionFunction: Function = null, panelClass = 'snackbar-warning', duration = 1500) {
		const snackBarRef = this.snackBar.openFromComponent(IconSnackBarComponent,
			{
				data: { message: msg, icon: icon, onClose: () => snackBarRef.dismiss(), onAction: actionFunction },
				duration: duration * (actionFunction ? 2 : 1), panelClass: [panelClass]
			}
		);
	}

	showWarning(msg: string, icon = '', actionFunction: Function = null) {
		if (!msg || msg === 'none') { return; }
		const timeout = 3000 + msg.length / 80 * 1000;
		this.showMessage(msg, icon, actionFunction, 'snackbar-warning', timeout);
	}

	showSuccess(msg: string, icon = '', actionFunction: Function = null) {
		if (!msg || msg === 'none') { return; }
		const timeout = 2000 + msg.length / 80 * 1000;
		this.showMessage(msg, icon, actionFunction, 'snackbar-success', timeout);
	}

	showError(error): void {
		const msg = ToolService.getHttpErrorMessage(error);
		if (!msg || msg === 'none') { return; }

		const snackBarRef = this.snackBar.openFromComponent(IconSnackBarComponent,
			{
				data: { message: msg.substr(0, 300), icon: 'content_copy', onClose: () => snackBarRef.dismiss(), onAction: () => ToolService.setClipboardText(msg)  },
				duration: 5000, panelClass: ['snackbar-danger']
			}
		);
	}

	showResult(successMsg: string, error: string, warning?: string) {
		if (error) {
			this.showError(error);
		} else if (warning) {
			this.showWarning(warning);
        }else if (successMsg) {
			this.showSuccess(successMsg);
		}
	}

	handleResult(result: Observable<any>, successFunction?: Function, errorFunction?: Function, flags = 0) {
		result.subscribe(data1 => {
				const data = data1 || {};
				this.isLoading = false;

				if (data.error) {
					if (errorFunction) {
						errorFunction();
					}

					if (data.stacktrace) {
						console.log(data.stacktrace);
					}

					this.showError(data.error);
				} else {
					if (data.message && ToolService.testFlag(flags, ToolService.hrNoSuccess) === false) {
						this.showSuccess(data.message);
					}

					if (data.warning) {
						this.showWarning(data.warning);
					}

					/* 230318

					if (data.receipt) {
						this.printReceipt ? this.printReceipt(1, data.receipt) : console.error('No printReceipt instance');
	}
					*/

					if (successFunction) {
						successFunction(data);
					}

				}
			},
			err => {
				this.isLoading = false;

				if (errorFunction) {
					errorFunction(err);
				}

				this.showError(err);
			}
		);
	}

	hasModule(module: string, showError = false): boolean {
		if (!module || !this.allModules.find(x => x.id === module)) { return true; }

		const m = this.modules[module];
		if (m === false && showError) {
			const name = this.allModules.find(x => x.id === module)?.name || module;
			this.showError(`Das Modul '${name}' ist nicht installiert.`);
		}

		return m;
	}

	settingString(section: string, key: string): Observable<string> {
		return new Observable<string>(obs => {
			const result = this.settingService.getSettings();
			result.subscribe(settings => {
				const setting = settings.find(s => s.section === section?.toLowerCase() && s.key === key?.toLowerCase());
				obs.next(setting ? setting.value : '');
				obs.complete();
			});
		});
	}

	settingCredentials(section: string, key: string): Observable<any> {
		return new Observable<any>(obs => {
			const result = this.settingString(section, key);
			result.subscribe(s => {
				let arr = [];
				if (s !== '') { arr = s.split('|'); }

				obs.next({ username: arr[0], password: arr[1] });
				obs.complete();
			});
		});
	}

	settingArray(section: string, key: string): Observable<string[]> {
		return new Observable <any>(obs => {
			const result = this.settingString(section, key);
			result.subscribe(s => {
				let arr: string[] = [];
				if (s !== '') { arr = s.split('|'); }

				obs.next(arr);
				obs.complete();
			});
		});
	}

	settingObj(section: string, key: string): Observable<Array<any>> {
		return new Observable<any>(obs => {
			const result = this.settingString(section, key);
			result.subscribe(s => {
				let arr = [];
				if (s.startsWith('[')) {
					arr = JSON.parse(s);
				} else if (s) { // convert Array
					const oldArr = s.split('|');
					oldArr.forEach(x => arr.push({ name: x }));
				}

				obs.next(arr);
				obs.complete();
			});
		});
	}

	updateSetting(section: string, key: string, data: string): Observable<any> {
		if (data === null)
			return this.settingService.deleteSetting(section, key);
		else
			return this.settingService.updateSetting(section, key, data);
	}

	getFiles(type = -1, thumbSize = 64): Observable<FileStore[]> {
		return this.settingService.getFiles(type, thumbSize);
	}

	getFile(name: string): Observable<FileStore> {
		return this.settingService.getFile(name);
	}

	addFile(file: FileStore): Observable<any> {
		return this.settingService.addFile(file);
	}

	updateFile(file: FileStore): Observable<any> {
		return this.settingService.updateFile(file.id, file);
	}

	navigateAlways(route: any[], options = {}) {
		this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() =>
			this.router.navigate(route, options));
	}

	navigateByUrlAlways(url: string) {
		this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() =>
			this.router.navigateByUrl(url));
	}

	inputBox(value: string, caption: string, cssClass = '', multiline = false): Observable<string> {
		value = value || '';
		const width = (value.indexOf('\n') > 0) ? '90%' : '600px';
		const dialogRef = this.dialog.open(InputBoxComponent,
			{ width: width, maxWidth: '100vw', data: { value: value, caption: caption, cssClass: cssClass, multiline: multiline } });

		const result = dialogRef.afterClosed();
		return result;
	}

	inputDateBox(date: Date, caption: string) {
		const dialogRef = this.dialog.open(InputDateDialogComponent, { data: { date: date, caption: caption } });
		const result = dialogRef.afterClosed();
		return result;
	}

	inputDateRangeBox(start: Date, end: Date, caption: string) {
		const dialogRef = this.dialog.open(InputDateRangeDialogComponent, { data: { start: start, end: end, caption: caption } });
		const result = dialogRef.afterClosed();
		return result;
	}

	inputEMailBox(sender: string, recipient: string, caption: string) {
		const dialogRef = this.dialog.open(InputEmailBoxComponent, { data: { sender: sender, recipient:recipient, caption: caption } });
		const result = dialogRef.afterClosed();
		return result;
	}

	msgBox(value: string, caption: string, cssClass?: string, width?: string): Observable<string> {
		const dialogRef = this.dialog.open(MsgBoxComponent,
			{ width: width || '600px', maxWidth: '100vw', data: { value: value, caption: caption, cssClass: cssClass } });

		const result = dialogRef.afterClosed();
		return result;
	}

	confirmBox(value: string, caption: string, cssClass?: string): Observable<string> {
		const dialogRef = this.dialog.open(ConfirmBoxComponent,
			{ width: '600px', maxWidth: '100vw', data: { value: value, caption: caption, cssClass: cssClass } });

		const result = dialogRef.afterClosed();
		return result;
	}

	getAdrBox(value: number, caption: string): Observable<number> {
		const dialogRef = this.dialog.open(GetAdrComponent,
			{ width: '600px', maxWidth: '100vw', data: { value: value, caption: caption } });

		return dialogRef.afterClosed();
	}


	getBookingBox(value: number, caption: string): Observable<number> {
		const dialogRef = this.dialog.open(GetBookingComponent,
			{ width: '600px', maxWidth: '100vw', data: { value: value, caption: caption } });

		return dialogRef.afterClosed();
	}

	pinpad(value: string, caption: string, mode: string): Observable<any> {
		const dialogRef = this.dialog.open(PinpadComponent,
			{ width: '18em', data: { value: value, caption: caption, mode: mode } });

		const result = dialogRef.afterClosed();
		return result;
	}

	tipPinpad(total: number): Observable<any> {
		const dialogRef = this.dialog.open(TipPinpadComponent, { width: '18em', data: total });

		const result = dialogRef.afterClosed();
		return result;
	}

	playAudio(soundFile: string) {
		const audio = new Audio();
		audio.src = 'assets/' + soundFile;
		audio.load();
		audio.play();
	}


	delay(delay: number) {
		return new Promise(resolve => {
			setTimeout(resolve, delay);
		});
	}

	async startScan(mode: 'qr' | 'mrz' | 'check' = 'qr', barcode?: string) {
		if (this.scannerRunning) { return; }
		this.scannerRunning = true;

		const data = { mode: mode, barcode: barcode };
		const result = await this.lazyDialog.open('dialogs/scanner', data);

		this.scannerRunning = false;

		if (result) {
			this.scanResult.next(result as any);
		}
	}

	dropFile(file: File, adrID = 0) {
		const data = { file: file, adrID: adrID };
		this.lazyDialog.open('dialogs/dropper/drop', data);
	}

	async openDialog(route: string, data) {
		return await this.lazyDialog.open('dialogs/' + route, data);
	}

	stopEvent(e: UIEvent) {
		e?.preventDefault();
		e?.stopPropagation();
	}



}


