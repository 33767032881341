import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_services';
import { HomeComponent } from './components/app/home.component';
import { WelcomeComponent } from './components/app/welcome.component';
import { LoginComponent } from './components/login/login.component';
import { WikiComponent } from './components/wiki/wiki.component';
import { CustDisplayComponent } from './components/display/display.component';
import { RemoteVideoComponent } from './components/app/remote-video.component';
import { PinLoginComponent } from './components/login/pin.component';
import { ECSlaveComponent } from './components/app/ec-slave.component';


const routes: Routes = [
	// Lazy loading Dialogs
	{ path: 'dialogs/scanner', loadChildren: () => import('./modules/misc/scanner/scanner.module').then(m => m.ScannerModule), canActivate: [AuthGuard] },
	{ path: 'dialogs/dropper/drop', loadChildren: () => import('./modules/misc/dropper/dropper.module').then(m => m.DropperModule), canActivate: [AuthGuard] },
	{ path: 'dialogs/base/adrdialog', loadChildren: () => import('./modules/base/base.module').then(m => m.BaseModule), canActivate: [AuthGuard] },
	{ path: 'dialogs/base/artdialog', loadChildren: () => import('./modules/base/base.module').then(m => m.BaseModule), canActivate: [AuthGuard] },
	{ path: 'dialogs/hotel/roomdialog', loadChildren: () => import('./modules/hotel/hotel.module').then(m => m.HotelModule), canActivate: [AuthGuard] },
	{ path: 'dialogs/hotel/bookingdialog', loadChildren: () => import('./modules/hotel/hotel.module').then(m => m.HotelModule), canActivate: [AuthGuard] },
	{ path: 'dialogs/restaurant/reservationdialog', loadChildren: () => import('./modules/restaurant/rest.module').then(m => m.RestModule), canActivate: [AuthGuard] },

	{ path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
	{ path: 'ec', component: ECSlaveComponent, canActivate: [AuthGuard], data: { module: 'ec' } },
	{ path: 'welcome', component: WelcomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'remote/:connectionId', component: RemoteVideoComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'remote/c/:connectionId', component: RemoteVideoComponent, pathMatch: 'full', canActivate: [AuthGuard] },

	// Core
	{ path: 'login/pin', component: PinLoginComponent },
	{ path: 'login/pin/set', component: PinLoginComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'wiki', component: WikiComponent },
	{ path: 'wiki/:subject', component: WikiComponent },

	{ path: 'display', component: CustDisplayComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { module: 'slideshow' }  },
	{ path: 'display/:deviceID', component: CustDisplayComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { module: 'slideshow' } },

	// Base module
	{ path: 'base', loadChildren: () => import('./modules/base/base.module').then(m => m.BaseModule), canActivate: [AuthGuard] },

	// Dashboard
	{ path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },

	// Finance module
	{ path: 'finance', loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule), canActivate: [AuthGuard] },

	// Hotel module
	{ path: 'booking', loadChildren: () => import('./modules/hotel/hotel.module').then(m => m.HotelModule), canActivate: [AuthGuard], data: { groups: ['rezeption'], module: 'hotel' } },

	// Housekeeping
	{ path: 'housekeeping', loadChildren: () => import('./modules/housekeeping/housekeeping.module').then(m => m.HousekeepingModule), canActivate: [AuthGuard], data: { groups: ['rezeption', 'housekeeping'], module: 'housekeeping' } },

	// Mail builder module
	{ path: 'mail', loadChildren: () => import('./modules/mail-builder/ip-email-builder.module').then(m => m.IpEmailBuilderModule), canActivate: [AuthGuard], data: { groups: ['rezeption', 'backoffice'] } },

	// Message module
	{ path: 'message', loadChildren: () => import('./modules/message/message.module').then(m => m.MessageModule), canActivate: [AuthGuard], data: { module: 'msg' } },

	// Restaurant module
	{ path: 'restaurant', loadChildren: () => import('./modules/restaurant/rest.module').then(m => m.RestModule), canActivate: [AuthGuard] },

	// Roster module
	{ path: 'roster', loadChildren: () => import('./modules/roster/roster.module').then(m => m.RosterModule), canActivate: [AuthGuard], data: { role: ['manager'] } },

	// Settings module
	{ path: 'settings', loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard] },

	// misc module
	{ path: 'misc', loadChildren: () => import('./modules/misc/misc.module').then(m => m.MiscModule), canActivate: [AuthGuard] },

	// Wellness module
	{ path: 'wellness', loadChildren: () => import('./modules/wellness/wellness.module').then(m => m.WellnessModule), canActivate: [AuthGuard], data: { groups: ['rezeption', 'therapeut'], module: 'wellness' } },

	// Ressource module
	{ path: 'resource', loadChildren: () => import('./modules/resource/resource.module').then(m => m.ResourceModule), canActivate: [AuthGuard], data: { groups: ['rezeption'], module: 'resource' } },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }


