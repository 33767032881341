import { Injectable } from '@angular/core';


export interface FsDocumentElement extends HTMLElement {
	msRequestFullscreen?: () => void;
	mozRequestFullScreen?: () => void;
	webkitRequestFullscreen?: () => void;
}

export interface FsDocument extends HTMLDocument {
	fullscreenElement: Element;
	mozFullScreenElement?: Element;
	webkitFullscreenElement?: Element;
	msFullscreenElement?: Element;
	msExitFullscreen?: () => void;
	webkitExitFullscreen?: () => void;
	mozCancelFullScreen?: () => void;
}


@Injectable({
	providedIn: 'root'
})
export class FullScreenService {

	constructor() { }

	detectFullScreen(): boolean {
		const fsDoc = <FsDocument>document;
		return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
	}

	setFullScreen(full: boolean): void {
		const fsDoc = <FsDocument>document;

		if (full) {
			const fsDocElem = <FsDocumentElement>document.documentElement;

			if (fsDocElem.requestFullscreen) {
				fsDocElem.requestFullscreen();
			} else if (fsDocElem.msRequestFullscreen) {
				fsDocElem.msRequestFullscreen();
			} else if (fsDocElem.mozRequestFullScreen) {
				fsDocElem.mozRequestFullScreen();
			} else if (fsDocElem.webkitRequestFullscreen) {
				fsDocElem.webkitRequestFullscreen();
			}
		} else {
			if (fsDoc.exitFullscreen) {
				fsDoc.exitFullscreen();
			} else if (fsDoc.msExitFullscreen) {
				fsDoc.msExitFullscreen();
			} else if (fsDoc.mozCancelFullScreen) {
				fsDoc.mozCancelFullScreen();
			} else if (fsDoc.webkitExitFullscreen) {
				fsDoc.webkitExitFullscreen();
			}
		}
	}

}
