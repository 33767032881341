import { Component } from '@angular/core';

@Component({
	selector: 'app-hom',
	template: '<div></div>',
	styles: ['']
})

export class HomeComponent {
	constructor() {

	}
}
