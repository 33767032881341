import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '../app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
	return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}


// Orginal
platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.log(err));

//   -> This ist the Hack ==>
/*
 * platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  if ('serviceWorker' in navigator && environment.production) {
		navigator.serviceWorker.register('./ngsw-worker.js');
	}
}).catch(err => console.log(err));
*/
