<h4 mat-dialog-title mat-dialog-title-draggable>{{caption}}</h4>
<div mat-dialog-content (keydown.enter)="onOk()" (keydown.escape)="dialogRef.close(null)" class="drag-container" style="height:60vh">
  <mat-table #table [dataSource]="dataSource"
        cdkDropList [cdkDropListData]="dataSource.data" (cdkDropListDropped)="drop($event)">

    <ng-container matColumnDef="rowid">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let i = index" style="cursor:move;padding-bottom:5px;" cdkDragHandle>
        <svg width="24" height="24" viewBox="0 0 32 32" fill="#9e9e9e" style="min-width:20px">
          <path d="M8 24h-2v-4h2v4zM14 24h-2v-4h2v4zM20 24h-2v-4h2v4zM26 24h-2v-4h2v4zM8 18h-2v-4h2v4zM14 18h-2v-4h2v4zM20 18h-2v-4h2v4zM26 18h-2v-4h2v4zM8 12h-2v-4h2v4zM14 12h-2v-4h2v4zM20 12h-2v-4h2v4zM26 12h-2v-4h2v4z"></path>
        </svg>
        <b>{{ i }}:</b>
      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let col of columns; let i = index" [matColumnDef]="col.name">
      <mat-header-cell *matHeaderCellDef>{{col.caption}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
          <mat-form-field *ngIf="col.option === ''" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <input matInput [value]="element[col.name]" [(ngModel)]="element[col.name]" autocomplete="off">
          </mat-form-field>

          <mat-form-field *ngIf="col.option === 'int'" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <input matInput numberInput [value]="element[col.name]" [(ngModel)]="element[col.name]">
          </mat-form-field>

          <mat-form-field *ngIf="col.option === 'cur'" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <input matInput currencyInput [value]="element[col.name]" [(ngModel)]="element[col.name]">
          </mat-form-field>

          <mat-form-field *ngIf="col.option === 'percent'" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <input matInput numberPercentInput [value]="element[col.name]" [(ngModel)]="element[col.name]">
          </mat-form-field>

          <fo-art-input *ngIf="col.option === 'article'" class="full-width" usage="wellness"
                        [label]="col.caption" [value]="element[col.name]" [(ngModel)]="element[col.name]">
          </fo-art-input>

          <fo-date-input *ngIf="col.option === 'date'" class="full-width" style="margin-bottom:10px;" [toolbar]="true" [label]="col.caption" [(ngModel)]="element[col.name]"></fo-date-input>


          <!--        <mat-form-field *ngIf="col.option === 'date'" class="full-width" subscriptSizing="dynamic">
                <mat-label>{{ col.caption }}</mat-label>
                <input matInput dateInput [matDatepicker]="i" [value]="element[col.name]" [(ngModel)]="element[col.name]" [ngModelOptions]="{updateOn: 'blur'}">
                <mat-datepicker-toggle matSuffix [for]="i" tabindex="-1"></mat-datepicker-toggle>
                <mat-datepicker #i></mat-datepicker>
            </mat-form-field>
    -->
          <mat-form-field *ngIf="col.option === 'time'" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <input matInput type="time" [value]="element[col.name]" [(ngModel)]="element[col.name]">
          </mat-form-field>

          <mat-checkbox *ngIf="col.option==='check'" [value]="element[col.name]" [(ngModel)]="element[col.name]"></mat-checkbox>

          <fo-color-select *ngIf="col.option === 'color'" [toolbar]="true" class="full-width" [(ngModel)]="element[col.name]"></fo-color-select>

          <fo-color-picker *ngIf="col.option === 'picker'" [toolbar]="true" class="full-width input" [(ngModel)]="element[col.name]"></fo-color-picker>

          <mat-form-field *ngIf="col.option.startsWith('select')" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <mat-select [(ngModel)]="element[col.name]">
                  <mat-option *ngFor="let o of col.option.substr(7).split(',')" [value]="o">{{ o }}</mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="col.option.startsWith('mselect')" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <mat-select [ngModel]="element[col.name] | splitArray" (ngModelChange)="element[col.name] = $event" multiple>
                  <mat-option *ngFor="let o of col.option.substr(8).split(',')" [value]="o">{{ o }}</mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="col.option.startsWith('imselect')" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <mat-select [(ngModel)]="element[col.name]" multiple>
                  <mat-option *ngFor="let o of col.option.substr(9).split(','), let i = index" [value]="i">{{ o }}</mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="col.option.startsWith('iselect')" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <mat-select [(ngModel)]="element[col.name]">
                  <mat-option *ngFor="let o of col.option.substr(8).split(','), let i = index" [value]="i">{{ o }}</mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="col.option.startsWith('idselect')" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <mat-select [(ngModel)]="element[col.name]">
                  <mat-option *ngFor="let o of col.option.substr(9).split(','), let i = index" [value]="+o.split(':')[0]">{{ o.split(':')[1] }}</mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="col.option.startsWith('img')" class="full-width" subscriptSizing="dynamic">
              <mat-label>{{ col.caption }}</mat-label>
              <input typpe="url" matInput [value]="element[col.name]" [(ngModel)]="element[col.name]" [imageTooltip]="element[col.name]" autocomplete="off" spellcheck="false">
              <button matSuffix mat-icon-button type="button" (click)="onUploadImage(element, col)" tabindex="-1">
                  <mat-icon>cloud_upload</mat-icon>
              </button>
          </mat-form-field>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cmd">
      <mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="onAdd()" tabindex="-1"><mat-icon>add_circle_outline</mat-icon></button>
      </mat-header-cell>
      <mat-cell *matCellDef="let i = index">
        <button mat-icon-button (click)="onDelete(i)" tabindex="-1"><mat-icon>clear</mat-icon></button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag></mat-row>
  </mat-table>
</div>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onOk()">Ok</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="">Abbrechen</button>
    <div style="width: calc(100% - 212px)"></div>
    <button mat-icon-button (click)="onAdd()" tabindex="-1"><mat-icon>add_circle_outline</mat-icon></button>
 </mat-dialog-actions>
 
<input type="file" id="imageUpload" accept="image/*" style="display:none;" />

