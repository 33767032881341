import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Wiki } from 'app/models/models';
import { DataService, ToolService, FormService, MailService } from 'app/_services';
import { NonNullableFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';

@Component({
	selector: 'app-wiki',
	templateUrl: './wiki.component.html',
	styleUrls: ['./wiki.component.css'],
	encapsulation: ViewEncapsulation.None,
})

export class WikiComponent implements OnInit, OnDestroy {
	caption = '';
	mode = '';
	markup = '';
	highlight = '';

	public form: FormGroup;

	wiki = {} as Wiki;

	private paramsSubscription: Subscription;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private tools: ToolService,
		private dataService: DataService,
		private mailService: MailService,
		public location: Location,
		public formBuilder: NonNullableFormBuilder,

	) {
		this.wiki.text = '';
	}

	ngOnInit() {
		// built contact form
		this.form = this.formBuilder.group({
			id: [0],
			name: ['', [Validators.required]],
			namespace: [''],
			foid: [''],
			text: ['', [Validators.required]],
		});

		let result;
		const foid = this.route.snapshot.queryParamMap.get('url');
		this.highlight = this.route.snapshot.queryParamMap.get('h');
		this.router.navigate(['.'], { relativeTo: this.route, replaceUrl: true }); // remove Query

		this.paramsSubscription = this.route.params.subscribe(params => {
			let search = '';
			if (foid) {
				const arr = foid.split('/');
				arr.forEach(segment => {
					if (segment.length > 0 && segment[0] >= 'A' && segment <= 'z') {
						search += (search === '') ? segment : '/' + segment;
					}
				});
				this.form.controls['foid'].patchValue(search);
				result = this.dataService.getWikiFOIDHTML(search);
			} else {
				search = params['subject'] || 'home';
				this.form.controls['name'].patchValue(search);
				result = this.dataService.getWikiNameHTML(search);
			}

			result.subscribe(data => {
				this.wiki = data;
				this.caption = data.name;
			}, () => {
				this.wiki.text = '';
				this.caption = '\'' + search + '\' konnten nicht gefunden werden.';
			});
		});
	}

	ngOnDestroy() {
		this.paramsSubscription.unsubscribe();
	}

	validationMsg(fieldName: string): string {
		return FormService.validationMsg(this.form, fieldName);
	}

	@HostListener('click', ['$event'])
	onClicked(event: MouseEvent) {
		if (event.ctrlKey || event.metaKey) {
			return;
		}

		let elm = event.target as HTMLElement;
		while (elm.nodeName.toLowerCase() !== 'a') {
			if (elm === document.body || !(elm = elm.parentElement)) {
				return;
			}
		}


		let href = elm.getAttribute('href');
		href = href.replace('<mark>', '').replace('</mark>', '');

		/* gone 2300720, does not work if text is marked
		// external http(s) link (do not remove!)
		if (elm.getAttribute('target')) {
			return;
		}
		*/

		event.preventDefault();

		if (href.startsWith('http') || href.startsWith('mailto:') || href.startsWith('/swagger')) { // always new tab
			window.open(href, '_blank');
		} else { // Internal linnk = route
			this.router.navigateByUrl(href);
		}
	}

	onEdit() {
		this.mode = 'edit';

		if (this.wiki.id > 0) {
			const result = this.dataService.getWiki(this.wiki.id);
			FormService.setValue(this.form, result);
		}
	}

	onNew() {
		this.wiki.id = 0;
		this.mode = 'edit';
	}

	onCancel() {
		if (this.mode) {
			this.mode = '';
		} else {
			this.location.back();
		}
	}

	onClose() {
		this.location.back();
	}

	reload(subject: string) {
		this.mode = '';
		this.tools.navigateAlways(['/wiki', subject]);
	}

	onSubmit(formData: any) {
		const data = formData.value;
		data.id = data.id || 0;
		const result = this.dataService.updateWiki(data.id, data);
		this.tools.handleResult(result, () => { this.mode = ''; this.reload(data.name); });
	}

	async onDelete() {
		if (this.wiki.id === 0) { return; }

		if (await this.tools.confirmBox('Wollen Sie den Artikel \'' + this.wiki.name + '\' wirklich löschen ?', 'Löschen bestätigen').toPromise() === 'Ok') {
			const result = this.dataService.deleteWiki(this.wiki.id);
			this.tools.handleResult(result, () => this.location.back());
		}
	}

	onCopy() {
		if (!this.wiki.id) { return; }

		this.mailService.getDocLink('W', this.wiki.id).subscribe(data => {
			if (data) {
				ToolService.setClipboardText(data.link);
				this.tools.showSuccess('Der Link wurde erfolgreich in die Zwischenablage kopiert.');
			}
		});
	}


}
