﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿<div class="chat-container mat-elevation-z4" ngClass.xs="chat-container-xs" [hidden]="!visible" fxLayout="column">

	<div class="caption" fxLayoutAlign="start center" fxLayoutGap=".5em">
		<img class="avatar30" [src]="recipientID | userimage | async" *ngIf="recipientID"/>
		<div fxFlex *ngIf="recipientID" style="cursor:pointer" (click)="recipientID = 0">{{ recipientID | username | async }}</div>
		<div fxFlex *ngIf="!recipientID">Benutzer online</div>
		<button type="button" mat-icon-button tybindex="-1" (click)="onClose();"><mat-icon>clear</mat-icon></button>
	</div>

	<div class="content" fxFlex>
		<perfect-scrollbar #perfectScroll>
			<mat-list class="full-width" *ngIf="!recipientID">
				<mat-list-item class="user-item" *ngFor="let user of userList" (click)="onUser(user.id)">
					<img class="avatar30" [src]="user.id | userimage | async" />
					{{ user.name }}
				</mat-list-item>
			</mat-list>

			<div *ngIf="recipientID">
				<div *ngFor="let message of messages">
					<div *ngIf="message.senderID === userID" fxLayoutAlign="start end" class="bubble-container">
						<div class="full-width my-bubble">
							{{ message.text}}
						</div>
						<img class="avatar30" src="{{ message.senderID | userimage | async }}" style="margin-left:1em;margin-right:10px;">
					</div>

					<div *ngIf="message.senderID !== userID" fxLayout="row" fxLayoutAlign="start end" class="bubble-container">
						<img class="avatar30" src="{{ message.senderID | userimage | async }}" style="margin-right:1em;cursor:pointer" (click)="onUser(message.senderID)">

						<div class="full-width foreign-bubble select-all">
							{{ message.text }}
						</div>
					</div>
				</div>
			</div>
		</perfect-scrollbar>
	</div>


	<div class="footer" fxLayoutAlign="start center">
		<input matInput class="full-width" placeholder="Aa" [(ngModel)]="text" (keydown.enter)="onSend()" (keydown.control.delete)="messages=[]" #input>
		<button type="button" mat-icon-button tybindex="-1" (click)="onSend()" [disabled]="!text || !recipientID"><mat-icon>send</mat-icon></button>
	</div>
</div>
