import { Component, Inject } from '@angular/core';
import { Voucher } from '../../models/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService, ToolService } from '../../_services';
import { NonNullableFormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-redeem-voucher',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>Gutschein einlösen</h4>
<form (ngSubmit)="onSubmit(form)" [formGroup]="form" class="form" autocomplete="off" spellcheck="false">
	<div mat-dialog-content (keydown.escape)="dialogRef.close(null)">
		<div class="row">
			<mat-form-field class="full-width col">
				<mat-label>Gutschein Nummer</mat-label>
				<input matInput formControlName="itemID" (change)="onItemIDChange()" focusInitial>
			</mat-form-field>

			<mat-form-field class="full-width col">
				<mat-label>Betrag</mat-label>
				<input matInput currencyInput formControlName="amount">
			</mat-form-field>
		</div>

		<div class="row">
			<div class="col">{{ voucher?.text }}&nbsp;</div>
		</div>
		<br/>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button type="submit" color="primary" [disabled]="form.invalid" >OK</button>
		<button mat-raised-button type="button" color="primary" [mat-dialog-close]>Abbrechen</button>
	</div>
</form>
`,
	styles: []
})
export class VoucherRedeemComponent {
	voucher: Voucher;
	form: FormGroup;

	invID = 0;
	outlet = 0;

	constructor(
		public dialogRef: MatDialogRef<VoucherRedeemComponent>,
		public dataService: DataService,
		public tools: ToolService,
		public formBuilder: NonNullableFormBuilder,
		@Inject(MAT_DIALOG_DATA) public data,
	) {
		this.form = this.formBuilder.group({
			itemID: ['' + data.itemID, [Validators.required]],
			amount: [{ value: data.amount, disabled: true }, [Validators.min(0.1)]],
		});
		this.invID = data.invID;
		this.outlet = data.outlet || 0;

		if (data.itemID) {
			this.onItemIDChange();
		}
	}

	onItemIDChange() {
		this.dataService.getVoucher(this.form.controls['itemID'].value).subscribe(v => {
			this.voucher = v;
			const ctrl = this.form.controls['amount'];
			if (v) {
				const old = ctrl.value;
				ctrl.patchValue(old > 0 ? Math.min(old, v.amount) : v.amount);
				ctrl.enable();
			} else {
				ctrl.disable();
			}

		});
	}

	onSubmit(data) {
		if (!this.voucher) { return; }
		const result = this.dataService.redeemVoucher(this.voucher.itemID, this.invID, data.value.amount, this.outlet);
		this.tools.handleResult(result, () => this.dialogRef.close());
	}
}

