import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { ToolService, DataService, MailService, FullScreenService, HotelService, SignalRService, ReceiptService, RestService } from '../../_services';
import { DocJournal, BroadcastData, Adr } from '../../models/models';
import { ActivatedRoute } from '@angular/router';
import SignaturePad from 'signature_pad';
import { Const } from '../../models/constants';
import { DOCUMENT } from '@angular/common';


@Component({
	selector: 'app-custdisplay',
	templateUrl: 'display.component.html',
	styles: [
		'.display-container { height: 100vh; display: flex; flex-direction: column; }',
		'.logo {height:40px; margin:.5em 1em .5em 0; float:right;}',
		'.tip {width:300px; margin-bottom:.5em;}'
	]
})
export class CustDisplayComponent implements OnInit, OnDestroy {
	public html = '';
	public caption = '';
	public isLoading = false;
	public canSave = false;

	public bid = 0;
	public dids: number[] = [];
	public hasSignature = false;
	public mode: string;

	private originalForm = '';
	private total = 0;
	private signaturePad: SignaturePad;
	private timerID: any;

	private invID = 0;
	private toInvID = 0;
	private subject = '';
	private outlet = 0;

	@ViewChild('content', {static: false}) content: ElementRef<HTMLElement>;

	imageUrls = [
		'assets/FOCloud.png',
	];

	constructor(
		public tools: ToolService,
		private ds: DataService,
		private hotelService: HotelService,
		private restService: RestService,
		private dataService: DataService,
		private receipt: ReceiptService,
		private mailService: MailService,
		private signalRService: SignalRService,
		private route: ActivatedRoute,
		public fullScreenService: FullScreenService,
		@Inject(DOCUMENT) private document: Document,
	) {
	}

	ngOnInit() {
		const myDeviceID = this.route.snapshot.paramMap.get('deviceID');
		
		this.signalRService.connection.on('castDisplay', (data: BroadcastData) => {
			if (data?.deviceID && myDeviceID && data.deviceID !== myDeviceID) { return; }

			this.clear();
			this.mode = data.route.split('/')[0];
			this.canSave = ['regForm', 'voucher', 'wvoucher'].find(m => m === this.mode) != null;
			switch (this.mode) {
				case 'invoice':
					this.loadInvoice(data.id, data.data1);
					break;
				case 'regForm':
					this.loadRegForm(data.id);
					break;
				case 'voucher':
					this.loadVoucher(data.id, data.data1, data.senderID); // HACK senderID -> outlet
					break;
				case 'wvoucher':
					this.loadWVoucher(data.route.split('/')[1]);
					break;
				case 'close':
					this.clear();
					break;
			}

			this.tools.settingString('restaurant', 'roomtext').subscribe(s => this.subject = s + ' ' + new Date().toLocaleDateTimeString());
		});

		this.signalRService.connection.onclose = (error) => {
			console.error(error);
			console.log('Trying to reconnect...');
			this.signalRService.startConnection();
		};

		this.tools.settingObj('slideshow', 'img').subscribe(img => this.imageUrls = img);
	}

	ngOnDestroy() {
		this.signalRService.connection.off('castDisplay');

		if (this.timerID) {
			clearInterval(this.timerID);
		}
	}

	clear() {
		this.mode = '';
		this.html = '';
		this.caption = '';
		this.bid = 0;
		this.dids = [];
		this.invID = 0;
		this.toInvID = 0;
		this.total = 0;
		this.outlet = 0;
		this.hasSignature = false;
	}

	initSignaturePad(): boolean {
		const options = {
			backgroundColor: 'rgb(255,255,255)',
			maxWidth: 1.5,
			penColor: '#5067b7',
		};

		const canvas = document.getElementById('canvas') as HTMLCanvasElement;
		if (canvas) {
			canvas.addEventListener('touchmove', (event) => event.stopPropagation());

			this.signaturePad = new SignaturePad(canvas, options);
			this.signaturePad.addEventListener('endStroke', () => this.hasSignature = true);
		}

		return !!canvas;
	}


	async loadInvoice(invID: number, details: number) {
		this.isLoading = true;
		const invoice = await this.ds.getInvoicePrintout(invID, details).toPromise()
			.catch((err) => this.tools.showError(err));
		this.isLoading = false;

		if (!invoice) { return; }

		const html = invoice.html;
		this.caption = 'Rechnung ' + invoice.invoiceID;
		this.html = html;
	}

	async loadVoucher(invID: number, toInvID: number, outlet: number) {
		this.invID = invID;
		this.toInvID = toInvID;
		this.outlet = outlet;

		this.caption = 'Zimmerbeleg';

		this.isLoading = true;
		const data = await this.dataService.getRoomVoucher(this.invID, this.toInvID).toPromise()
			.catch((err) => this.tools.showError(err));
		this.isLoading = false;

		if (!data) { return; }
		this.originalForm = data.receipt;
		this.total = data.total;

		const sigCode = '<canvas style="border:solid red 1px; height:150px; width:300px;margin:.5em;" id="canvas"></canvas>';
		const receiptWidth = ReceiptService.getPrinterWidth(ReceiptService.pidReceipt);
		const replaceText = '<br><br>' + '.'.repeat(receiptWidth);

		this.html = `<p class="receipt-display">${await this.receipt.buildHtml(data.receipt)}</p>`.replace(replaceText, sigCode);

		if (this.timerID) {
			clearInterval(this.timerID);
		}

		this.timerID = setInterval(() => {
			if (this.initSignaturePad()) {
				clearInterval(this.timerID);
				this.timerID = null;
			}
		}, 150);
	}


	async saveVoucher(bookTip) {
		const signature = this.signaturePad.toDataURL('image/jpeg', 0.5);

		if (bookTip) {
			const tip = await this.tools.tipPinpad(this.total).toPromise();
			if (tip === undefined) { return; }
			if (tip > 0) {
				await this.dataService.bookArticle(this.invID, Const.artidTip, 1, tip, '', 0, null, this.outlet, Const.pcRestaurant).toPromise()
					.catch(err => this.tools.showError(err));

				const data = await this.dataService.getRoomVoucher(this.invID, this.toInvID).toPromise()
					.catch((err) => this.tools.showError(err));

				if (!data) { return; }
				this.originalForm = data.receipt;
			}
		}

		await this.restService.closeTableToAccount(this.invID, this.toInvID, this.subject).toPromise()
			.catch((err) => this.tools.showError(err));

		const receiptWidth = ReceiptService.getPrinterWidth(ReceiptService.pidReceipt);
		const replaceText = '<br><br>' + '.'.repeat(receiptWidth);

		let html = `<p class="receipt-display">${await this.receipt.buildHtml(this.originalForm)}</p>`;
		html = html.replace(replaceText, `<p><img src="${signature}"></p>`);

		const account = await this.dataService.getAccountInfo(this.toInvID).toPromise()
			.catch((err) => this.tools.showError(err));
		if (!account) { return; }

		const docJournal = {
			bid: account.bid,
			custID: account.custID,
			description: this.subject,
			text: html,
		} as DocJournal;

		const result = this.mailService.addDocJournal(docJournal);
		this.tools.handleResult(result, () => {
			this.clear();
			this.ds.sendBroadcast('closeVoucher', null).subscribe();
		});
	}

	async loadWVoucher(ids: string) {
		this.caption = 'Terminbestätigung';

		this.dids = ids.split(',').map(Number);

		this.tools.isLoading = true;
		const data = await this.dataService.getDateReceipt(this.dids).toPromise()
			.catch((err) => this.tools.showError(err));
		this.tools.isLoading = false;

		if (!data) { return; }
		this.originalForm = data.receipt;
		this.total = data.total;

		const sigCode = '<canvas style="border:solid red 1px; height:150px; width:300px;margin:.5em;" id="canvas"></canvas>';
		const receiptWidth = ReceiptService.getPrinterWidth(ReceiptService.pidReceipt);
		const replaceText = '<br><br>' + '.'.repeat(receiptWidth);

		this.html = `<p class="receipt-display">${await this.receipt.buildHtml(data.receipt)}</p>`.replace(replaceText, sigCode);
		this.html = this.html.replace('Unterschrift:', 'Unterschrift / Trinkgeld:');

		if (this.timerID) {
			clearInterval(this.timerID);
		}

		this.timerID = setInterval(() => {
			if (this.initSignaturePad()) {
				clearInterval(this.timerID);
				this.timerID = null;
			}
		}, 150);
	}

	async saveWVoucher() {
		const date = await this.dataService.getDate(this.dids[0]).toPromise();
		if (!date) { return; }

		const signature = this.signaturePad.toDataURL('image/jpeg', 0.5);
		const receiptWidth = ReceiptService.getPrinterWidth(ReceiptService.pidReceipt);
		const replaceText = '<br><br>' + '.'.repeat(receiptWidth);

		let html = `<p class="receipt-display">${await this.receipt.buildHtml(this.originalForm)}</p>`;
		html = html.replace(replaceText, `<p><img src="${signature}"></p>`);

		const docJournal = {
			bid: 0,
			custID: date.custID,
			description: `Terminbestätigung für ${ToolService.formatDateTime(date.date)}`,
			text: html,
		} as DocJournal;

		const result = this.mailService.addDocJournal(docJournal);
		this.tools.handleResult(result, () => {
			this.clear();
			this.ds.sendBroadcast('closeVoucher', null).subscribe();
		});
	}

	async loadRegForm(bid: number) {
		this.bid = bid;

		this.isLoading = true;
		const data = await this.mailService.getMailHtml('regform', bid).toPromise()
			.catch((err) => this.tools.showError(err));
		if (!data) { return; }

		const booking = await this.hotelService.getBooking(this.bid).toPromise()
			.catch(err => this.tools.showError(err));
		if (!booking) { return; }

		const adr = await this.dataService.getAdr(booking.guestID, true).toPromise()
			.catch(err => this.tools.showError(err));
		if (!adr) { return; }
		this.isLoading = false;

		const error = await this.hotelService.checkRegFormFields(adr, booking);
		if (error) { this.tools.showWarning(error); }

		this.originalForm = data.html;

		this.caption = 'Meldeblatt';

		let i = 0;
		const checkTimer = setInterval(() => {
			const dsgvo = document.getElementById('dsgvo') as HTMLInputElement;
			if (dsgvo && adr.dsgvo) {
				clearInterval(checkTimer);
				dsgvo.checked = true;
			}

			const newsletter = document.getElementById('newsletter') as HTMLInputElement;
			if (newsletter && adr.newsletter) {
				clearInterval(checkTimer);
				newsletter.checked = true;
			}
			i++;
			if (i > 8) {
				clearInterval(checkTimer);
			}
		}, 250);

		const sigCode = '<canvas style="border:solid red 1px; height:150px; width:300px;margin:.5em;" id="canvas"></canvas>';
		this.html = data.html.replace('{signature}', sigCode);

		if (this.timerID) {
			clearInterval(this.timerID);
		}

		this.timerID = setInterval(() => {
			if (this.initSignaturePad()) {
				clearInterval(this.timerID);
				this.timerID = null;
			}
		}, 250);
	}


	async saveRegForm() {
		const booking = await this.hotelService.getBooking(this.bid).toPromise()
			.catch(err => this.tools.showError(err));
		if (!booking) { return; }

		const adr = await this.dataService.getAdr(booking.guestID, true).toPromise()
			.catch(err => this.tools.showError(err));
		if (!adr) { return; }

		const signature = this.signaturePad.toDataURL('image/jpeg', 0.5);
		let html: string = this.content.nativeElement.innerHTML.replace(/\n|\t/g, '');

		const startPos = html.indexOf('<canvas');
		const endPos = html.indexOf('</canvas>') + 9;
		html = html.substr(0, startPos) + `<img src="${signature}">` + html.substr(endPos);

		if (!booking) { return; }

		// Next RegID
		if (!booking.regID) {
			const result = await this.hotelService.setNextRegID(booking).toPromise().
				catch(err => { this.tools.showError(err); });

			if (result) {
				booking.regID = result.booking.regID;
				html = html.replace('*** Neu ***', '' + booking.regID);
			}
		}

		// remove Edit attr
		html = html.replace(/contenteditable=\"true\"/g, ' ');
		const docJournal = {
			bid: this.bid,
			description: `Meldeblatt ${booking ? '' + Math.abs(booking.regID) : ''} zu Buchung ${this.bid}`,
			text: html,
		} as DocJournal;

		// store changed field into adr
		const checkFields = ['dsgvo', 'newsletter'];
		checkFields.forEach(field => {
			const el: any = document.getElementById(field);
			if (el) {
				adr[field] = el.checked;
				html = html.replace(`id="${field}"`, el.checked ? 'checked  disabled="disabled"' : 'disabled="disabled"');
			}
		});

		let validationErrors = false;
		const textFields = ['name', 'firstname', 'addresses', 'title', 'street', 'street1', 'country', 'zipcode', 'city',
			'email', 'telephone', 'telephone1', 'birthdate', 'nation', 'passportID', 'carID'];
		textFields.forEach(field => {
			const el: any = document.getElementById(field);
			if (el) {
				var text = el.innerHTML.replace(/<br>/g, '').replace(/&nbsp;/g, '').trim();

				adr[field] = field === 'birthdate' ? ToolService.parseDate(text) : text;
				if (el.attributes["required"] && !el.innerHTML) {
					el.style.background = '#ef9a9a';
					validationErrors = true;
				} else {
					el.style.background = '';
				}
			}
		});
		if (validationErrors) {
			this.tools.showError('Bitte füllen Sie die fehlenden Felder aus.');
			return;
		}

		const error = await this.hotelService.checkRegFormFields(adr, booking);
		if (error) {
			this.tools.showWarning(error);
			return;
		}

		this.dataService.updateAdr(adr.id, adr).subscribe(() => { }, err => this.tools.showError(err));

		// sync sub adrs
		for (let i = 1; i <= 8; i++) {
			const el = document.getElementById(`adr${i}.name`);
			if (!el) { continue; }
			var text = el.innerHTML.replace(/<br>/g, '').replace(/&nbsp;/g, '').trim();
			const arr = text.split(' ');
			const firstname = arr[0];
			if (!firstname) { continue; }

			arr.splice(0, 1);
			const name = arr.join(' ');
			const birthdate = ToolService.parseDate(document.getElementById(`adr${i}.birthdate`)?.innerHTML || '');

			let sub = adr.subAdrs.find(s => s.firstname === firstname);
			if (!sub) {
				sub = { id: 0, mainID: adr.id, nation: adr.nation, type: 0 } as Adr;
			}

			sub.firstname = firstname;
			sub.name = name ? name : adr.name;
			sub.country = adr.country;
			sub.birthdate = birthdate;
			if (sub.id) {
				this.dataService.updateAdr(sub.id, sub).subscribe(() => { }, err => this.tools.showError(err));
			} else {
				this.dataService.addAdr(sub).subscribe(() => { }, err => this.tools.showError(err));
			}
		}

		const result = this.mailService.addDocJournal(docJournal);
		result.subscribe(data => {
			this.clear();
			this.ds.sendBroadcast('closeRegForm', { id: data.id }).subscribe();
		}, err => this.tools.showError(err));

	}

	async onSave(bookTip = false) {
		if (this.mode === 'regForm') { this.saveRegForm(); }
		if (this.mode === 'voucher') { this.saveVoucher(bookTip); }
		if (this.mode === 'wvoucher') { this.saveWVoucher(); }
	}


	onFullScreen() {
		this.fullScreenService.setFullScreen(true);
	}




}

