import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DataService, ToolService } from '../../_services';

@Component({
	selector: 'app-invoice-head',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>Rechnungskopf</h4>
<form (ngSubmit)="onSubmit(form)" [formGroup]="form" class="form" autocomplete="off" spellcheck="false">
<div mat-dialog-content (keydown.escape)="dialogRef.close(null)">
    <div class="row">
		<fo-adr-input formControlName="custID" class="full-width col" label="Adresse suchen"></fo-adr-input>
    </div>

    <div formArrayName="rows" class="row" *ngFor="let row of form['controls'].rows['controls']; let i = index">
		<mat-form-field class="full-width col">
			<mat-label>Adresszeile {{i+1}}</mat-label>
			<input matInput maxLength="80" [formControlName]="i">
		</mat-form-field>
    </div>
    <div class="row">
		<mat-form-field class="full-width col">
			<mat-label>Betreff</mat-label>
			<input matInput maxLength="80" formControlName="subject">
		</mat-form-field>
    </div>
    <div class="row">
		<mat-form-field class="full-width col">
			<mat-label>Bemerkung</mat-label>
			<input matInput maxLength="80" formControlName="remark">
		</mat-form-field>
    </div>
	<div class="row">
		<mat-form-field class="full-width col">
			<mat-label>Haus</mat-label>
			<mat-select formControlName="house">
				<mat-option *ngFor="let house of houses" [value]="house.id">{{ house.name }}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field class="full-width col">
			<mat-label>Rechnungs-Formular</mat-label>
			<mat-select formControlName="form">
				<mat-option [value]="">*** Standard ***</mat-option>
				<mat-option *ngFor="let form of forms" [value]="form.name">{{ form.description }} ({{ form.name }})</mat-option>
			</mat-select>
		</mat-form-field>

	</div>

</div>
<div mat-dialog-actions>
	<button mat-raised-button type="submit" color="primary">OK</button>
	<button mat-raised-button type="button" color="primary" [mat-dialog-close]="">Abbrechen</button>
</div>
</form>
`,
	styles: []
})
export class InvoiceHeadComponent implements OnInit, OnDestroy {
	public form: FormGroup;
	public forms = [];
	public countries = [];
	public houses = this.tools.getHouses();

	private custID = 0;
	private changeSubscription: Subscription;

	constructor(
		public dialogRef: MatDialogRef<InvoiceHeadComponent>,
		public ds: DataService,
		public formBuilder: NonNullableFormBuilder,
		public tools: ToolService,
		@Inject(MAT_DIALOG_DATA) public data,
	) {
		let adrRows = new Array<string>(6);
		let form = '';
		let house = 0;
		let subject = '';
		let remark = '';

		if (data) {
			this.custID = data.custID;
			adrRows = data.rows;
			form = data.form;
			house = data.house;
			subject = data.subject;
			remark = data.remark;
		}

		this.form = this.formBuilder.group({
			custID: [this.custID],
			rows: this.formBuilder.array(adrRows),
			subject: [subject],
			remark: [remark],
			house: [house],
			form: [form],
		});
	}

	async ngOnInit() {
		this.tools.getFiles().subscribe(f => this.forms = f.filter(fc => fc.name.startsWith('invoice') && fc.name.endsWith('.html')));

		this.countries = (await this.tools.settingObj('global', 'nations').toPromise()).filter(n => n.id.indexOf('(') < 0);

		this.changeSubscription = this.form.controls['custID'].valueChanges.subscribe(adrID => {
			const ctrls = (this.form.controls['rows'] as FormArray).controls;

			const result = this.ds.getAdr(adrID);
			result.subscribe(adr => {
				if (!adr) { return; }

				const a = ['', '', '', '', '', ''];
				a[0] = adr.addresses;
				a[1] = `${adr.title} ${ adr.firstname } ${ adr.name }`.replace(/  /g, ' ').trim(); // double space -> space;
				a[2] = adr.name1;
				a[3] = adr.street;
				a[4] = adr.street1;
				a[5] = `${adr.zipcode} ${adr.city}`;

				for (let i = 1; i < 6; i++) {
					if (!a[i]) {
						for (let j = i; j < 5; j++) {
						a[j] = a[j + 1];
						}
						a[5] = '';
					}
				}

				if (a[5] === '' && adr.country !== 'A') {
					const country = this.countries.find(c => c.id === adr.country);
					a[5] = country ? country.name : '';
				}

				if (adr.uid) {
					a[5] = 'UID: ' + adr.uid;
				}

				this.custID = adrID;
				for (let i = 0; i < 6; i++) {
					ctrls[i].patchValue(a[i]);
				}
			});
		});
	}

	ngOnDestroy() {
		this.changeSubscription.unsubscribe();
	}

	onSubmit(f) {
		const custID = f.value['custID'];
		const rows = f.value['rows'];
		const subject = f.value['subject'];
		const remark = f.value['remark'];
		const form = f.value['form'];
		const house = f.value['house'];

		this.dialogRef.close(
			{ custID: custID, rows: rows, house: house, form: form, subject: subject, remark: remark }
		);
	}
}

