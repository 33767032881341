<div class="app-container"
	 (document:keydown.f1)="tools.stopEvent($event); onHelpContent()"
	 (document:keydown.control.e)="tools.stopEvent($event); onSearch()"
	 (document:keydown.control.shift.alt.d)="tools.stopEvent($event); onToggleDebug()"
	 (document:keydown.control.shift.alt.r)="tools.stopEvent($event); onReloadPage()"
	 (document:keydown.control.shift.alt.l)="tools.stopEvent($event); router.navigate(['settings/license'])"

	 (document:keydown.shift.escape)="tools.stopEvent($event); tools.stopEvent($event); onLogout()"
	 
	 (document:keydown.control.shift.a)="tools.stopEvent($event); router.navigate(['base/adr'])"
	 (document:keydown.control.shift.b)="tools.stopEvent($event); router.navigate(['base/user'])"
	 (document:keydown.control.shift.c)="tools.stopEvent($event); onShowChat()"
	 (document:keydown.control.shift.d)="tools.stopEvent($event); router.navigate(['settings/files'])"
	 (document:keydown.control.shift.e)="tools.stopEvent($event); router.navigate(['settings'])"
	 (document:keydown.control.shift.h)="tools.stopEvent($event); router.navigate(['booking/plan'])"
	 (document:keydown.control.shift.i)="tools.stopEvent($event); router.navigate(['finance/invoice'])"
	 (document:keydown.control.shift.k)="tools.stopEvent($event); router.navigate(['finance/accounts'])"
	 (document:keydown.control.shift.l)="tools.stopEvent($event); router.navigate(['wellness'])"
	 (document:keydown.control.shift.m)="tools.stopEvent($event); router.navigate(['mail'])"
	 (document:keydown.control.shift.o)="tools.stopEvent($event); router.navigate(['resource/plan'])"
	 (document:keydown.control.shift.p)="tools.stopEvent($event); router.navigate(['/misc/log'])"
	 (document:keydown.control.shift.r)="tools.stopEvent($event); router.navigate(['finance/report'])"
	 (document:keydown.control.shift.s)="tools.stopEvent($event); tools.stopEvent($event); tools.startScan()"
	 (document:keydown.control.shift.z)="tools.stopEvent($event); router.navigate(['settings/rates'])"
>

	<mat-toolbar color="accent" fxLayoutAlign="start center" (appDropZone)="onDrop($event)" [class.hidden]="isDisplay" style="height:56px" matAutocompleteOrigin #originXS="matAutocompleteOrigin">
		<div style="padding-bottom:3px">
			<button mat-icon-button (click)="onToggleSidenav()" *showAuthed="true" tabindex="-1"><mat-icon>menu</mat-icon></button>
		</div>

		<div class="title" ngClass.xs="title-xs" (click)="gotoHomeRoute(true)" (press)="onReload()">
			<img src="assets/icons/logo.svg" style="height:28px; cursor: pointer" draggable="false">
			<span class="license" ngClass.xs="license-xs"><br />{{tools.license.name}}</span>
		</div>

		<div fxFlex style="height:100%" matAutocompleteOrigin #origin="matAutocompleteOrigin">
			<form class="search-form" [class.search-form-xs]="searchModeXS" [class.hidden]="!searchOpen" *showUser="true" autocomplete="off" spellcheck="false">
				<mat-form-field class="full-width" subscriptSizing="dynamic">
					<input matInput placeholder="Suche" [matAutocomplete]="auto" [formControl]="searchCtrl" [matAutocompleteConnectedTo]="searchModeXS ? originXS : origin" #search>
					<button mat-icon-button matSuffix type="button" tabindex="-1" (click)="onSearchClear()">
						<mat-icon>{{ searchCtrl.value || searchModeXS ? 'clear' : 'search' }}</mat-icon>
					</button>
				</mat-form-field>
			</form>
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="autocompleteSelected($event.option.value)">
				<mat-option *ngFor="let data of searchData | async" [value]="data.route">
					<mat-icon>{{data.icon}}</mat-icon>
					{{ data.text }}
				</mat-option>
			</mat-autocomplete>
		</div>

		<div *showAuthed="true" fxLayoutAlign="start center">
			<div class="user" ngClass.xs="user-xs">
				{{now | date:'HH:mm:ss'}}<br />
				{{ userName }}
			</div>

			<button mat-icon-button routerLink="ec" tabindex="-1" *ngIf="isMyPOS" class="small-icon-button"><mat-icon>dialpad</mat-icon></button>
			<button mat-icon-button (click)="onStartVideo(false)" (press)="onStartVideo()" tabindex="-1" *ngIf="signalRService.othersOnline | async" [matTooltip]="signalRService.othersOnline | async"
					[@pulse]="animState" (@pulse.done)="animDone()" class="small-icon-button">
				<mat-icon color="warn">voice_chat</mat-icon>
			</button>
			<button mat-icon-button routerLink="misc/debug" tabindex="-1" *ngIf="tools.isDebug" class="small-icon-button"><mat-icon color="warn">bug_report</mat-icon></button>
			<button mat-icon-button (click)="onToggleSearch()" tabindex="-1" *ngIf="searchModeXS" class="small-icon-button"><mat-icon>search</mat-icon></button>
			<button mat-icon-button [matMenuTriggerFor]="messageMenu" tabindex="-1" class="small-icon-button">
				<mat-icon [matBadge]="notificationCount" matBadgePosition="above after" matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="notificationCount <= 0">
					{{ sleeping ? 'notifications_off' : 'notifications_none' }}
				</mat-icon>
			</button>
			<button mat-icon-button [disableRipple]="true" (click)="onHelpContent()" tabindex="-1" class="small-icon-button"><mat-icon>help_outline</mat-icon></button>
			<button mat-icon-button (click)="onToggleFullScreeen()" tabindex="-1" fxHide.gt-md *ngIf="tools.enviroment === 'web'"><mat-icon>{{ window.innerHeight === screen.height ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon></button>

			<img [matMenuTriggerFor]="userMenu" tabindex="-1" src="{{ userID | userimage | async }}" class="avatar24 avatar" alt="" />
		</div>
	</mat-toolbar>

	<mat-sidenav-container [class.display-sidenav]="isDisplay">
		<mat-sidenav #sidenav [mode]="navMode" [opened]="tools.showSideNav | async" role="navigation">
			<perfect-scrollbar>
				<div class="mt-3">
					<mat-nav-list>
						<a mat-list-item routerLink="dashboard"><mat-icon>home</mat-icon><ng-container i18n>Home</ng-container></a>
						<a mat-list-item [routerLink]="hotelService.hotelRoute" routerLinkActive="link-active" *ngIf="tools.modules['hotel']"><mat-icon>hotel</mat-icon><ng-container i18n>Hotel</ng-container></a>
						<a mat-list-item routerLink="booking/mice" routerLinkActive="link-active" *ngIf="tools.modules['mice']"><mat-icon>groups</mat-icon><ng-container i18n>Seminar</ng-container></a>
						<a mat-list-item routerLink="restaurant" routerLinkActive="link-active" [routerLinkActiveOptions]="{exact:true}" *ngIf="tools.modules['restaurant']"><mat-icon>restaurant</mat-icon><ng-container i18n>Restaurant</ng-container></a>
						<a mat-list-item routerLink="restaurant/cash" routerLinkActive="link-active" *ngIf="tools.modules['cash']"><mat-icon>computer</mat-icon><ng-container i18n>Kasse</ng-container></a>
						<a mat-list-item routerLink="wellness" routerLinkActive="link-active" *ngIf="tools.modules['wellness']"><mat-icon>spa</mat-icon><ng-container i18n>Wellness</ng-container></a>
						<a mat-list-item routerLink="resource/plan" routerLinkActive="link-active" *ngIf="tools.modules['resource']"><mat-icon>sports_tennis</mat-icon><ng-container i18n>Sportanlagen</ng-container></a>
						<a mat-list-item routerLink="finance/accounts" routerLinkActive="link-active"><mat-icon>shopping_cart</mat-icon><ng-container i18n>Konten</ng-container></a>
						<a mat-list-item routerLink="finance/invoice" routerLinkActive="link-active"><mat-icon>library_books</mat-icon><ng-container i18n>Rechnungen</ng-container></a>
						<a mat-list-item routerLink="finance/voucher" routerLinkActive="link-active" *ngIf="tools.modules['voucher']"><mat-icon>card_giftcard</mat-icon><ng-container i18n>Gutscheine</ng-container></a>
						<a mat-list-item routerLink="finance/report" routerLinkActive="link-active"><mat-icon>poll</mat-icon><ng-container i18n>Reports</ng-container></a>
						<a mat-list-item routerLink="finance/analyse" routerLinkActive="link-active" *ngIf="tools.modules['analysis']"><mat-icon>trending_up</mat-icon><ng-container i18n>Daten-Analyse</ng-container></a>
						<a mat-list-item routerLink="message" routerLinkActive="link-active" (click)="messageCount=0" *ngIf="tools.modules['msg']">
							<div style="top:8px;right:8px;" [matBadge]="messageCount" matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="messageCount <= 0"></div>
							<mat-icon>mail_outline</mat-icon>
							<ng-container i18n>Nachrichten</ng-container>
						</a>
						<a mat-list-item routerLink="base/docs" routerLinkActive="link-active"><mat-icon>inbox</mat-icon><ng-container i18n>Dokumente</ng-container></a>
						<a mat-list-item routerLink="housekeeping" routerLinkActive="link-active" *ngIf="tools.modules['housekeeping']"><mat-icon>local_laundry_service</mat-icon><ng-container i18n>Housekeeping</ng-container></a>
					</mat-nav-list>

					<mat-accordion multi="true" displayMode="flat">
						<mat-expansion-panel class="no-shadow nav">
							<mat-expansion-panel-header fxLayout="row">
								<mat-panel-title i18n>Stammdaten</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-nav-list>
								<a mat-list-item routerLink="base/adr" routerLinkActive="link-active"><mat-icon>people_outline</mat-icon><ng-container i18n>Adressen</ng-container></a>
								<a mat-list-item routerLink="base/article" routerLinkActive="link-active"><mat-icon>store</mat-icon><ng-container i18n>Artikel</ng-container></a>
								<a mat-list-item routerLink="base/payment" routerLinkActive="link-active"><mat-icon>credit_card</mat-icon><ng-container i18n>Zahlungsarten</ng-container></a>
								<a mat-list-item routerLink="base/user" routerLinkActive="link-active"><mat-icon>supervisor_account</mat-icon><ng-container i18n>Benutzer</ng-container></a>
							</mat-nav-list>
						</mat-expansion-panel>

						<mat-expansion-panel class="no-shadow nav">
							<mat-expansion-panel-header fxLayout="row">
								<mat-panel-title i18n>Verwaltung</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-nav-list>
								<a mat-list-item routerLink="mail" routerLinkActive="link-active"><mat-icon>color_lens</mat-icon><ng-container i18n>Mail-Designer</ng-container></a>
								<a mat-list-item routerLink="settings/files" routerLinkActive="link-active"><mat-icon>insert_drive_file</mat-icon><ng-container i18n>Dateimanager</ng-container></a>
								<a mat-list-item routerLink="misc/service"><mat-icon>extension</mat-icon><ng-container i18n>Hilfsprogramme</ng-container></a>
								<a mat-list-item routerLink="settings/ide/code" routerLinkActive="link-active" *ngIf="userID === 1 || tools.modules['code']"><mat-icon>code</mat-icon><ng-container i18n>Code-Editor</ng-container></a>
								<a mat-list-item routerLink="settings/news" routerLinkActive="link-active" *ngIf="tools.modules['guest']"><mat-icon>record_voice_over</mat-icon><ng-container i18n>Gäste-App</ng-container></a>
								<a mat-list-item routerLink="settings/rates" routerLinkActive="link-active" *ngIf="tools.modules['hotel']"><mat-icon>card_travel</mat-icon><ng-container i18n>Zimmerpreise</ng-container></a>
								<a mat-list-item routerLink="settings" routerLinkActive="link-active" [routerLinkActiveOptions]="{exact:true}"><mat-icon>settings</mat-icon><ng-container i18n>Einstellungen</ng-container></a>
							</mat-nav-list>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-nav-list>
						<a mat-list-item routerLink="wiki" (click)="onHelpMain()"><mat-icon>help_outline</mat-icon><ng-container i18n>Hilfe</ng-container></a>
						<a mat-list-item routerLinkActive="link-active" (click)="onLogout()"><mat-icon>exit_to_app</mat-icon><ng-container i18n>Logout</ng-container></a>
					</mat-nav-list>
					<br />

				</div>
			</perfect-scrollbar>
		</mat-sidenav>

		<mat-sidenav-content #content ngClass.xs="mat-sidenav-content-xs" [class.display-content]="isDisplay">
			<perfect-scrollbar #perfectScroll>
				<div [ngStyle.gt-xs]="{'padding-right':'1em'}" [class.display-content]="isDisplay">
					<router-outlet></router-outlet>
				</div>
			</perfect-scrollbar>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>

<mat-menu #messageMenu="matMenu" [overlapTrigger]="false" class="message-menu">
	<button *ngFor="let n of notifications" mat-menu-item (click)="onNotification(n)" routerLinkActive="link-active" [class.unread]="n.isUnread">
		<mat-icon>{{ n.icon ? n.icon : 'mail_outline' }}</mat-icon>{{ n.subject }}
	</button>
</mat-menu>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false">
	<button mat-menu-item [matMenuTriggerFor]="themeMenu"><mat-icon>color_lens</mat-icon><ng-container i18n>Farbschema</ng-container></button>
	<button mat-menu-item (click)="onShowChat()"><mat-icon>wechat</mat-icon><ng-container i18n>Chat öffnen</ng-container></button>
	<button mat-menu-item (click)="onStartVideo()"><mat-icon>voice_chat</mat-icon><ng-container i18n>Video-Meeting</ng-container></button>
	<button mat-menu-item (click)="onCreatePin()" routerLinkActive="link-active" *ngIf="!hasPin"><mat-icon>dialpad</mat-icon><ng-container i18n>PIN erstellen</ng-container></button>
	<button mat-menu-item (click)="onUserProfile()" routerLinkActive="link-active"><mat-icon>person</mat-icon><ng-container i18n>Benutzer Profil</ng-container></button>
	<a mat-menu-item href="https://app/settings" routerLinkActive="link-active" *ngIf="tools.enviroment==='app'"><mat-icon>settings</mat-icon><ng-container i18n>App-Einstellungen</ng-container></a>
	<button mat-menu-item (click)="onReload()"><mat-icon>refresh</mat-icon><ng-container i18n>App neu laden</ng-container></button>
	<button mat-menu-item routerLinkActive="link-active" (click)="onLogout()"><mat-icon>exit_to_app</mat-icon><ng-container i18n>Logout</ng-container></button>
</mat-menu>

<mat-menu #themeMenu="matMenu">
	<button mat-menu-item (click)="onSetTheme('default-theme')" i18n>Standard</button>
	<button mat-menu-item (click)="onSetTheme('blue-theme')" i18n>Alternativ</button>
	<button mat-menu-item (click)="onSetTheme('brown-theme')" i18n>Bronze</button>
	<button mat-menu-item (click)="onSetTheme('light-theme')" i18n>Hell</button>
	<button mat-menu-item (click)="onSetTheme('dark-theme')" i18n>Dunkel</button>
</mat-menu>


<div class="video-board mat-elevation-z2" fxLayoutGap="5px" cdkDrag (cdkDragStarted)="dragging=true" *ngIf="signalRService.online && !router.url.startsWith('/remote')">
	<div *ngFor="let con of signalRService.videoConnections" fxFlex="none" style="position:relative">
		<video class="video" autoplay [muted]="con.local" playsInline [srcObject]="con.videoStream ? con.videoStream : con.canvasStream"
			poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" (click)="onVideoClicked(con)"></video>
		<div class="video-overlay" (click)="onVideoButton(con)">{{ con.userName }} {{ !!con.canvasStream ? '(+Bildschirm)' : '' }}</div>
		<mat-icon class="video-button icon12" (click)="onVideoButton(con)" *ngIf="con.local || !!con.canvasStream">{{ con.local ? 'clear' : 'cast'}}</mat-icon>
	</div>
</div>

<app-chat [(visible)]="showChat"></app-chat>

<div loading-indicator *ngIf="!isDisplay && (tools._loadingValue | async) >= 0">
	<div class="loading-overlay"></div>
	<mat-progress-bar mode="buffer" [bufferValue]="tools._loadingValue | async" class="loading-indicator"></mat-progress-bar>
</div>

