import { NgModule, LOCALE_ID, Injectable } from '@angular/core';
import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, TransferState } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DateAdapter } from '@angular/material/core';
import {
	FormService, ToolService, DataService, AuthenticationService, GermanDateAdapter,
	MessageService, DropZoneDirective, SettingService, MailService, FullScreenService, ReceiptService, ScriptService, ImageService, SignalRService, FinanceService, CanDeactivateGuard, AuthGuard, OfflineComponent } from './_services';
import './_services/date.extensions';
import { AppComponent, ShowNewsComponent } from './components/app/app.component';
import { HomeComponent } from './components/app/home.component';
import { WelcomeComponent } from './components/app/welcome.component';
import { LoginComponent } from './components/login/login.component';
import { WikiComponent } from './components/wiki/wiki.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { CustDisplayComponent } from './components/display/display.component';
import '@angular/common/locales/global/de';
import { environment } from '../src/environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { RemoteVideoComponent } from './components/app/remote-video.component';
import { PinLoginComponent } from './components/login/pin.component';
import { ECSlaveComponent } from './components/app/ec-slave.component';
import { ChatComponent } from './components/app/chat.component';
import { AuthInterceptor } from './_services/authInterceptor.service';
import { APP_BASE_HREF } from '@angular/common';
import { DbNameInterceptor } from './_services/dbnameInterceptor.service';
import {DemoGeneratorService} from "./_services/demogenerator.service";

// registerLocaleData(localeDe, 'de');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@Injectable()
class CustomHammerConfig extends HammerGestureConfig {
	overrides = {
		'press': { time: 300 },
		'swipe': { direction: Hammer.DIRECTION_ALL },
	};
}

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		ECSlaveComponent,
		RemoteVideoComponent,
		ChatComponent,
		WelcomeComponent,
		LoginComponent, PinLoginComponent,
		DropZoneDirective,
		WikiComponent,
		CustDisplayComponent, ShowNewsComponent,
		OfflineComponent,
	],
	imports: [
		BrowserModule, BrowserAnimationsModule, HammerModule,
		HttpClientModule,
		SharedModule,
		AppRoutingModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, scope: './', registrationStrategy: 'registerImmediately' }),
	],
	exports: [
	],
	providers: [
		{ provide: APP_BASE_HREF, useValue: '/' + AuthenticationService.getDbName() }, // needed for Azure
		{ provide: LOCALE_ID, useValue: 'de-DE' },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: DbNameInterceptor, multi: true,
		},
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
		{ provide: DateAdapter, useClass: GermanDateAdapter },
		{ provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
		FormService,
		ToolService,
		ReceiptService,
		DataService,
		ImageService,
		SettingService,
		MessageService,
		AuthenticationService,
		CanDeactivateGuard,
		AuthGuard,
		MailService,
		FullScreenService,
		SignalRService,
		TransferState,
		FinanceService,
		ScriptService,
		DemoGeneratorService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private dateAdapter: DateAdapter<Date>) {
		this.dateAdapter.setLocale('de');
	}
}

/*
 * Profiler
platformBrowserDynamic().bootstrapModule(AppModule).then(moduleRef => {
	const applicationRef = moduleRef.injector.get(ApplicationRef);
	const componentRef = applicationRef.components[0];
	// allows to run `ng.profiler.timeChangeDetection();`
	enableDebugTools(componentRef);
}).catch(err => window['console'].error(err));

*/
