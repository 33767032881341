<div class="container" [class.hidden]="isSuccessful">
	<form [formGroup]="form" (ngSubmit)="onSubmit(form)" autocomplete="off">
		<mat-card ngClass="login-card" ngClass.xs="login-card-xs">
			<mat-card-title>
				<div h2 i18n fxFlex>Benutzeranmeldung</div>
				<div><button mat-icon-button type="button" tabindex="-1" (click)="onAppSettings()" *ngIf="tools.enviroment === 'app' "><mat-icon>settings</mat-icon></button></div>
			</mat-card-title>

			<mat-card-content>
				<div [class.hidden]="phase === 1">
					<mat-form-field class="full-width">
						<mat-label>Benutzername</mat-label>
						<input matInput formControlName="login" required autocomplete="off" spellcheck="false" #login>
						<mat-error>{{ validationMsg("login") }}</mat-error>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Passwort</mat-label>
						<input matInput formControlName="password" required autocomplete="off" [type]="hide ? 'password' : 'text'">
						<button mat-icon-button matSuffix type="button" (click)="hide = !hide" tabindex="-1"><mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon></button>
						<mat-error>{{ validationMsg("password") }}</mat-error>
					</mat-form-field>
				</div>

				<div [class.hidden]="phase === 0">
					<mat-form-field class="full-width">
						<mat-label>Sicherheits-Code</mat-label>
						<input matInput formControlName="securityCode" autocomplete="off" spellcheck="false" #securityCode>
					</mat-form-field>
				</div>

				<div fxLayoutGap=".5em">
					<div fxFlex>
						<button type="button" mat-raised-button i18n (click)="setPhase(0)" *ngIf="phase === 1">Zurück</button>
						<button type="button" mat-raised-button [disabled]="isLoading" routerLink="/login/pin" i18n *ngIf="hasPIN">Anmelden mit PIN</button>
					</div>
					<button type="submit" color="primary" mat-raised-button [disabled]="isLoading" i18n>Login</button>
				</div>
			</mat-card-content>
		</mat-card>
	</form>
</div>
