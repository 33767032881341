import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route, Routes, ROUTES, ResolveData, ResolveFn } from '@angular/router';
import { Observable, isObservable, from, of, forkJoin, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Injectable, NgModuleRef, Type, Compiler, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

/** Lazy Dialog Module Loader. Use this class as a canActivate guard within a regular lazy loading route. */
@Injectable({
	providedIn: 'root'
})
export class LazyDialogLoader {

	private dialogs = new Map<string, Route>();

	constructor(
		private router: Router,
		private injector: Injector
	) {
	}

	public open<T, R>(dialog: string, data?: T): Promise<R> {

		const routeConfig = this.seekDialog(dialog);

		if (!routeConfig) {
			return Promise.reject(new Error(`Unable to find the requested dialog "${dialog}". Make sure the corresponding Route exists within the same module this DialogLoader instance is provided.`));
		}

		return this.loadDialog<T, R>(routeConfig, data).toPromise();
	}

	private loadDialog<T, R>(routeConfig: Route, data?: T, route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<R> {

		return this.loadRouteConfig(routeConfig).pipe(switchMap(({ module, routes }) => {

			// Gets the module's MatDialog instance.
			const dialog = module.injector.get(MatDialog);
			if (!dialog) {
				return throwError(new Error(`Unable to inject the MatDialog service from the given module "${module.constructor}". Make sure your dialog module correctly imports the MatLegacyDialogModule.`));
			}

			// Seeks for the primary child route where to find the dialog component
			const dialogName = routeConfig.path.split('/').pop();
			const root = routes.find(({ path }) => path === dialogName || (routes.length === 1 && path === ''));
			if (!root) {
				return throwError(new Error(`Unable to find the dialog route.`));
			}

			const component = root.component;
			if (!component) {
				return throwError(new Error(`Unable to find the dialog component within the module's routes.`));
			}

			// Runs the Route resolvers
			return this.runResolvers(root?.resolve, module, data, route, state).pipe(

				switchMap(() => {
					const config: MatDialogConfig = root.data?.dialogConfig;
					return dialog.open<unknown, T, R>(component, { ...config, data }).afterClosed();
				})
			);
		}));
	}

	private loadRouteConfig(routeConfig: Route): Observable<{ module: NgModuleRef<any>, routes: Routes }> {
		const module: NgModuleRef<any> = (routeConfig as any)._loadedConfig?.module;
		if (module) {
			const routes: Routes = (routeConfig as any)?._loadedConfig?.routes || [];
			return of({ module, routes });
		}

		// Gets the loader function otherwise
		const loader = routeConfig.loadChildren;
		if (!loader || typeof loader !== 'function') {
			return throwError(new Error(`The matching Route "${routeConfig.path}" misses the proper loadChildren function.`));
		}

		return from((loader as () => Promise<Type<any>>)()).pipe(
			switchMap(moduleType => {
				const compiler = this.injector.get(Compiler);
				return compiler.compileModuleAsync(moduleType);
			}),

			map(moduleFactory => {
				const module = moduleFactory.create(this.injector);
				return { module, routes: this.routes(module) };
			}),

			tap(config => (routeConfig as any)._loadedConfig = config)
		);
	}

	private runResolvers(resolve: ResolveData, module: NgModuleRef<any>, data?: any, route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<any> {
		if (!resolve || Object.keys(resolve).length <= 0) {
			return of(data || {});
		}

		// Ensures a valid route/state pair to be used by resolvers
		state = state || this.router.routerState.snapshot;
		route = route || state.root.firstChild;

		return forkJoin(Object.keys(resolve).map(key => {
			const resolver: {
    resolve: ResolveFn<any>;
} = module.injector.get(resolve[key] as Type<any>);
			if (typeof resolver.resolve !== 'function') { return of(null); }

			return this.toObservable(resolver.resolve(route, state))
				.pipe(map(data1 => ({ key, data1 })));

		})).pipe(map(resolvedArray => resolvedArray.reduce((data2, item) => (data2[item.key] = item.data1, data2), data || {})));
	}

	private seekDialog(dialog: string): Route {

		const route1 = this.dialogs.get(dialog);
		if (route1) { return route1; }

		const parseTree = (dialog1: string, match1: Route, routes: Routes) => {
			return routes.reduce((match, route) => {

				if ('children' in route) { return parseTree(dialog1, match, route.children); }
				if ('_loadedConfig' in route) { return parseTree(dialog1, match, (route as any)._loadedConfig.routes || []); }

				// Stores the dialog in the cache for the future
				if (route.path.startsWith('dialog')) {
					this.dialogs.set(route.path, route);
					if (route.path === dialog1) { return route; }
				}

				return match;

			}, match1);
		};

		return parseTree(dialog, undefined, this.router.config);
	}

	/** Retrives the Routes array for the given Module defaulting to the current Module when not specified */
	private routes(module?: NgModuleRef<any>): Routes {
		const routes = (module?.injector || this.injector).get(ROUTES, []);
		return Array.prototype.concat.apply([], routes);
	}

	/** Asses the given value and return an Observable of it */
	private toObservable<T>(value: T | Promise<T> | Observable<T>): Observable<T> {
		if (isObservable(value)) { return value as Observable<T>; }
		if (Promise.resolve(value) === value) { return from(value as Promise<T>); }

		return of(value as T);
	}
}
