<div class="display-container">
	<div *ngIf="!html" class="full-width" style="display:block;">
		<div>
			<button mat-icon-button (click)="onFullScreen()" *ngIf="!fullScreenService.detectFullScreen() && tools.enviroment!=='app'"><mat-icon>fullscreen</mat-icon></button>
			<img src="assets/icons/logo.svg" class="logo" draggable="false">
		</div>
		<slideshow #slideshow [imageUrls]="imageUrls" *ngIf="imageUrls.length >= 1"
			height="calc(100vh - 56px)"
			[autoPlay]="true"
			[autoPlayInterval]="20000"
	  ></slideshow>
		<img src="assets/FOCloud.png" *ngIf="imageUrls.length < 1" class="absolute-center"/>
	</div>
	<mat-card *ngIf="html">
		<div fxLayoutAlign="space-between start">
			<div fxFlexlayout>{{ caption }}</div>
			<div fxFlexlayout fxLayoutAlign="end" fxLayoutGap="0.5rem">
				<button mat-raised-button color="primary" (click)="onSave()" [disabled]="isLoading || !hasSignature" *ngIf="canSave"> Speichern </button>
				<button mat-icon-button (click)="clear()" tybindex="-1"><mat-icon>clear</mat-icon></button>
			</div>
		</div>
		<mat-card-content>
			<mat-progress-bar [style.visibility]="isLoading ? 'visible' : 'hidden'" mode="indeterminate"></mat-progress-bar>
			<div class="invoice-container" [innerHTML]="html | safeHtml" #content></div>
			<div *ngIf="mode.indexOf('voucher') >= 0 && hasSignature" class="tip">
				<button mat-raised-button color="primary" (click)="onSave(true)"> Trinkgeld </button>
				<div fxFlex></div>
				<button mat-raised-button (click)="onSave(false)"> Kein Trinkgeld </button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
