import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const demoGeneratorEndpoint = '/api/DemoGenerator';

@Injectable({
	providedIn: 'root',
})
export class DemoGeneratorService {
	constructor(private http: HttpClient) {}

	/**
	 * Generates a demo house with the provided house name.
	 * @param houseNameValue The name of the house to generate.
	 * @returns An observable with the generated demo house data.
	 */
	async generateDemoHouse(houseName: string): Promise<void> {
		try {
			const requestBody = { HouseName: houseName };
			await this.http
				.post<any>(demoGeneratorEndpoint, requestBody)
				.toPromise();
		} catch (error) {
			console.error('Error sending HTTP request:', error);
		}
	}

	/**
	 * Retrieves a list of all available houses.
	 * @remarks
	 * This method fetches a list of all houses from the server using an HTTP GET request.
	 * @returns An observable emitting the data containing names of all available houses.
	 */
	getAllHouses(): Observable<any> {
		console.log('House List Fetching Started');
		return this.http.get<any>(demoGeneratorEndpoint);
	}
}
