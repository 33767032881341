import { HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, tap } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { ToolService } from "./tool.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)*/;

	constructor(
		private router: Router,
	) { }


	isIsoDateString(value: any): boolean {
		if (value == null) {
			return false;
		}
		if (typeof value === 'string') {
			return this._isoDateFormat.test(value);
		}
		return false;
	}

	mapDateFields(body: any) {
		if (body == null) {
			return body;
		}
		if (typeof body !== 'object') {
			return body;
		}
		for (const key of Object.keys(body)) {
			const value = body[key];
			if (this.isIsoDateString(value)) {
				body[key] = +value.substr(0, 4) < 1000 ? new Date(1899, 11, 30) : new Date(value);
			} else if (typeof value === 'object') {
				this.mapDateFields(value);
			}
		}
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// dont add anythiing to external urls
		if (request.url.startsWith('http')) {
			return next.handle(request);
		}

		let dbName = AuthenticationService.getDbName();
		const token = ToolService.currentUser ? ToolService.currentUser.token : '';

		const prefix = dbName !== '' ? '/' + dbName : '';

		request = request.clone({
			url: `https://focloud-schulung.azurewebsites.net${prefix}${request.url}`,
			setHeaders: { Authorization: `Bearer ${token}` /*, dbName: dbName */ }
		});

		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					this.mapDateFields(event.body); // convert ISO to dates
				}
			}, (error: any) => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === 401) {
						this.router.navigate(['/login']);
					} else {
						console.warn('Backend says: ' + ToolService.getHttpErrorMessage(error));
						const err = error.error ? error.error : error;
						if (err.debug) { err.debug.forEach(d => console.log(d)); }
						if (err.stacktrace) { console.log(err.stacktrace); }
					}
				}
			})
		);
	}

}


