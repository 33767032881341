import { Component, Inject, Input, Output, EventEmitter, OnInit, HostListener, forwardRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { Const } from '../../models/constants';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ColorEvent } from 'ngx-color';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { DataService, FormService, ToolService, DemoGeneratorService } from '../../_services';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';
import { Adr, Article, Booking, Journal } from '../../models/models';

@Component({
	selector: 'fo-input-box',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{caption}}</h4>
<mat-dialog-content (keydown.escape)="$event.stopPropagation();dialogRef.close(null)">
	<mat-form-field class="full-width">
		<input matInput [(ngModel)]="value" (keydown.enter)="onOk()" cdkFocusInitial [ngClass]="cssClass" (focus)="onFocus($event)" *ngIf="!multiline" autocomplete="off">
		<textarea matInput [(ngModel)]="value" rows="30" cdkFocusInitial [ngClass]="cssClass" (focus)="onFocus($event)" *ngIf="multiline"></textarea>
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onOk()">Ok</button>
	<button mat-raised-button color="primary" [mat-dialog-close]="">Abbrechen</button>
</mat-dialog-actions>
`,
	styles: ['.mat-mdc-dialog-content {overflow-y: hidden;}']
})
export class InputBoxComponent {
	public value: string;
	public caption: string;
	public cssClass: string;
	public multiline = false;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<InputBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.value = data.value;
		this.caption = data.caption;
		this.cssClass = data.cssClass;
		this.multiline = data.multiline;
	}

	onFocus(event) {
		if (this.value.indexOf('\n') < 0) {
			event.target.select();
		}
	}


	onOk(): void {
		this.dialogRef.close(this.value.trim());
	}
}


@Component({
	selector: 'fo-msg-box',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable
	 (document:keydown.control.c)="tools.stopEvent($event); onCopy()"
>{{caption}}</h4>
<mat-dialog-content style="display:block;" fxLayout="column"  (keydown.enter)="onOk()">
	<div [innerHTML]="value | safeHtml" [ngClass]="cssClass" class="select-text" style="white-space: pre-wrap;"></div>
	<br />
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onOk()">Ok</button>
</mat-dialog-actions>
`,
	styles: []
})
export class MsgBoxComponent {
	public value: string;
	public caption: string;
	public cssClass: string;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<MsgBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.value = data.value;
		this.caption = data.caption;
		this.cssClass = data.cssClass;
	}

	onCopy() {
		if (this.value[0] === '<') { return; } // dont´t copy html


		// ToolService.setClipboardText(this.value); // circular reference
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = this.value;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

	onOk() {
		this.dialogRef.close('');
	}

}

@Component({
	selector: 'fo-confirm-box',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{caption}}</h4>
<mat-dialog-content (keydown.enter)="onOk()" (keydown.escape)="$event.stopPropagation();dialogRef.close(null)">
	<div [innerHTML]="value" [ngClass]="cssClass"></div>
	<br />
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onOk()">Ok</button>
	<button mat-raised-button color="primary" [mat-dialog-close]="">Abbrechen</button>
</mat-dialog-actions>
`,
	styles: ['.mat-mdc-dialog-content {overflow-y:hidden;}']
})
export class ConfirmBoxComponent {

	public value: string;
	public caption: string;
	public cssClass: string;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<ConfirmBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.value = data.value;
		this.caption = data.caption;
		this.cssClass = data.cssClass;
	}

	onOk() {
		this.dialogRef.close('Ok');
	}
}

@Component({
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{ caption }}</h4>
<div mat-dialog-content (keydown.enter)="onOk()" (keydown.escape)="$event.stopPropagation();dialogRef.close(null)">
	<mat-form-field class="full-width">
		<mat-label>Datumsbereich</mat-label>
		<mat-date-range-input [rangePicker]="picker">
			<input matStartDate [(ngModel)]="start">
			<input matEndDate [(ngModel)]="end">
		</mat-date-range-input>
		<mat-datepicker-toggle matSuffix [for]="picker" tabindex="-1"></mat-datepicker-toggle>
		<mat-date-range-picker #picker></mat-date-range-picker>
	</mat-form-field>
</div>
<br/>
<div mat-dialog-actions>
	<button mat-raised-button type="submit" color="primary" (click)="onOk()">OK</button>
	<button mat-raised-button type="button" color="primary" [mat-dialog-close]="">Abbrechen</button>
</div>
`,
	styles: []
})
export class InputDateRangeDialogComponent {
	public start: Date;
	public end: Date;
	public caption: string;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<InputDateRangeDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) {
		if (data) {
			this.start = new Date(data.start);
			this.end = new Date(data.end);
			this.caption = data.caption;
		}
	}

	onOk(): void {
		this.dialogRef.close({ start: this.start, end: this.end });
	}
}

@Component({
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{ caption }}</h4>

<mat-dialog-content (keydown.enter)="onOk()" (keydown.escape)="$event.stopPropagation();dialogRef.close(null)">
	<fo-date-input class="full-width" label="Datum" [(ngModel)]="date"></fo-date-input>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button type="submit" color="primary" (click)="onOk()">OK</button>
	<button mat-raised-button type="button" color="primary" [mat-dialog-close]="">Abbrechen</button>
</mat-dialog-actions>
`,
	styles: []
})
export class InputDateDialogComponent {
	public date: Date;
	public caption: string;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<InputDateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) {
		if (data) {
			this.date = new Date(data.date);
			this.caption = data.caption;
		}
	}

	onOk(): void {
		this.dialogRef.close(this.date);
	}
}



@Component({
	selector: 'fo-pinpad',
	template: `
	<h4 mat-dialog-title mat-dialog-title-draggable fxLayoutGap=".5em" style="padding:10px;">
		<div fxFlex>{{caption}}</div>
		<button mat-icon-button tabindex="-1" [mat-dialog-close]=""><mat-icon>clear</mat-icon></button>
	</h4>
	<div mat-dialog-content>
		<div class="value" (swipeleft)="handleInput('Backspace')"><span [class.selected]="!typed">{{ value }}</span></div>

		<mat-grid-list cols="3" rowHeight="1:1" gutterSize="1em" class="pinpad">
			<mat-grid-tile *ngFor="let b of buttons">
				<button mat-fab class="pinpad-button" (click)="handleInput(b.action)"  tabindex="-1"><mat-icon *ngIf=b.icon>{{b.icon}}</mat-icon>{{b.text}}</button>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
	`,
	styles: [`.pinpad-button {font-size:2em!important; background-color:#fff;box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 3px 0px rgba(0, 0, 0, 0.13), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);}`, '.pinpad {height:100%}',
		'.selected {background-color: #3390ff; color:#fff}',
		'.value {width:100%; height:2em; font-size:20px; text-align:center; background-color:#f0f0f0; margin:.5em 0; padding-top:5px;}'
	]
})

export class PinpadComponent implements OnInit {
	buttons = [
		{ action: '1', text: '1' },
		{ action: '2', text: '2' },
		{ action: '3', text: '3' },
		{ action: '4', text: '4' },
		{ action: '5', text: '5' },
		{ action: '6', text: '6' },
		{ action: '7', text: '7' },
		{ action: '8', text: '8' },
		{ action: '9', text: '9' },
		{ action: 'Backspace', icon: 'backspace' },
		{ action: '0', text: '0' },
		{ action: 'Enter', text: '', icon: 'keyboard_return' },
	];

	@Input()
	mode: string;

	@Input()
	value: string;

	@Output()
	public valueChange: EventEmitter<string> = new EventEmitter<string>();

	caption: string;
	typed = false;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<PinpadComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.value = data.value;
		this.caption = data.caption;
		this.mode = data.mode;
	}

	ngOnInit() {
		if (this.mode === 'float') {
			this.buttons[9] = { action: ',', text: ',' };
		}
	}


	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.handleInput(event.key);
	}

	handleInput(ch) {

		if (ch === 'Backspace') {
			this.value = this.value.substr(0, this.value.length - 1);
		} else if (ch === 'Enter') {
			this.dialogRef.close(ToolService.parseNumber(this.value));
		} else if (ch >= '0' && ch <= '9') {
			if (this.typed === false) {
				this.value = '';
				this.typed = true;
			}
			this.value += ch;
		} else if (this.mode === 'float' && (ch === ',' || ch === '.')) {
			if (this.typed === false) {
				this.value = '';
				this.typed = true;
			}
			this.value += this.value === '' ? '0,' : ',';
		} else {
			return;
		}

		this.valueChange.emit(this.value);
	}
}



@Component({
	selector: 'fo-tippinpad',
	template: `
	<h4 mat-dialog-title mat-dialog-title-draggable fxLayoutGap=".5em" style="padding:10px;">
		<div fxFlex>Trinkgeld hinzufügen</div>
		<button mat-icon-button [mat-dialog-close]="" tabindex="-1"><mat-icon>clear</mat-icon></button>
	</h4>
	<div mat-dialog-content>
		<div class="value"><span [class.selected]="!typed">{{ value }}</span></div>
		<div fxLayoutGap=".5em"  style="margin-bottom:1em;">
			<button fxFlex type="button" mat-raised-button color="primary" tabindex="-1" (click)="onPercent(p)" *ngFor="let p of percent">{{ p }}%</button>
		</div>
		<mat-grid-list cols="3" rowHeight="1:1" gutterSize="1em" class="pinpad">
			<mat-grid-tile *ngFor="let b of buttons">
				<button mat-fab class="pinpad-button" (click)="handleInput(b.action)" tabindex="-1"><mat-icon *ngIf=b.icon>{{b.icon}}</mat-icon>{{b.text}}</button>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
	`,
	styles: [`.pinpad-button {font-size:2em!important; background-color:#fff;box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 3px 0px rgba(0, 0, 0, 0.13), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);}`, '.pinpad {height:100%}',
		'.selected {background-color: #3390ff; color:#fff}',
		'.value {width:100%; height:2em; font-size:20px; text-align:center; background-color:#f0f0f0; margin:.5em 0; padding-top:5px;}'
	]
})

export class TipPinpadComponent {
	percent = [5, 10, 15];
	buttons = [
		{ action: '1', text: '1' },
		{ action: '2', text: '2' },
		{ action: '3', text: '3' },
		{ action: '4', text: '4' },
		{ action: '5', text: '5' },
		{ action: '6', text: '6' },
		{ action: '7', text: '7' },
		{ action: '8', text: '8' },
		{ action: '9', text: '9' },
		{ action: ',', text: ',' },
		{ action: '0', text: '0' },
		{ action: 'Enter', text: '', icon: 'keyboard_return' },
	];

	total: number;
	value = '0,00';
	typed = false;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<TipPinpadComponent>,
		@Inject(MAT_DIALOG_DATA) public data: number,
	) {
		this.total = data;
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.handleInput(event.key);
	}

	handleInput(ch) {
		if (ch === 'Backspace') {
			this.value = this.value.substr(0, this.value.length - 1);
		} else if (ch === 'Enter') {
			this.dialogRef.close(ToolService.parseNumber(this.value));
		} else if (ch >= '0' && ch <= '9') {
			if (this.typed === false) {
				this.value = '';
				this.typed = true;
			}
			this.value += ch;
		} else if (ch === ',' || ch === '.') {
			if (this.typed === false) {
				this.value = '';
				this.typed = true;
			}
			this.value += this.value === '' ? '0,' : ',';
		} else {
			return;
		}
	}

	onPercent(p) {
		this.value = ToolService.formatNumber(this.total * p / 100);
		this.typed = false;
	}
}


@Component({
	selector: 'fo-tristate-checkbox',
	template: `
<mat-checkbox [checked]="value" (click)="next()" [disabled]="disabled" [indeterminate]="value === null || value === undefined">
	<ng-content></ng-content>
</mat-checkbox>
`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TristateCheckboxComponent),
			multi: true,
		},
		{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop', color: 'accent' } as MatCheckboxDefaultOptions },
	],
})
export class TristateCheckboxComponent implements ControlValueAccessor {

	tape = [null, false, true];

	value: boolean;

	disabled: boolean;

	private onChange: (val: boolean) => void;
	private onTouched: () => void;

	writeValue(value: boolean) {
		this.value = value;
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	next() {
		this.onChange(this.value = this.tape[(this.tape.indexOf(this.value) + 1) % this.tape.length]);
		this.onTouched();
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

}


@Component({
	selector: 'app-getadr',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{ caption }}</h4>
<div mat-dialog-content (keydown.enter)="onOk()" (keydown.escape)="$event.stopPropagation();dialogRef.close(null)">
	<fo-adr-input [(ngModel)]="adrID" label="Adresse" class="full-width"></fo-adr-input>
</div>

<div mat-dialog-actions>
	<button mat-raised-button type="submit" color="primary" [mat-dialog-close]="adrID">OK</button>
	<button mat-raised-button type="button" color="primary" [mat-dialog-close]="">Abbrechen</button>
</div>
`,
	styles: ['.mat-mdc-list-item{ font-size:1.2em; cursor:pointer;}']
})
export class GetAdrComponent {
	adrID: number;
	caption: string;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<GetAdrComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.caption = data.caption;
		this.adrID = data.value;
	}

	onOk() {
		this.dialogRef.close(this.adrID);
	}
}

@Component({
	selector: 'app-getbooking',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{ caption }}</h4>
<div mat-dialog-content (keydown.enter)="onOk()" (keydown.escape)="$event.stopPropagation();dialogRef.close(null)">
	<mat-label>Buchung</mat-label>
	<fo-booking-input [(ngModel)]="bid" class="full-width"></fo-booking-input>
</div>

<div mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onOk()" [disabled]="!bid">OK</button>
	<button mat-raised-button type="button" color="primary" [mat-dialog-close]="">Abbrechen</button>
</div>
`,
	styles: ['.mat-mdc-list-item{ font-size:1.2em; cursor:pointer;}']
})
export class GetBookingComponent {
	bid: number;
	caption: string;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<GetBookingComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.caption = data.caption;
		this.bid = data.value;
	}
	onOk() {
		if (!this.bid) { return; }
		this.dialogRef.close(this.bid);
	}
}

@Component({
	selector: 'fo-color-select',
	template: `
<mat-form-field [ngClass]="class" style="padding:0" [subscriptSizing]="toolbar ? 'dynamic' : 'fixed'">
	<mat-label>Farbe</mat-label>
	<mat-select [formControl]="ctrl" (selectionChange)="onSelectionChange($event)">
		<mat-select-trigger>
			<span *ngIf="(ctrl.value > 0 || hasDefaultColor === false);else defaultColor">
				<mat-icon [style.color]="colors[ctrl.value]" class="icon12">lens</mat-icon>&nbsp;<ng-container i18n>Farbe</ng-container> {{ ctrl.value }}
			</span>
			<ng-template #defaultColor><mat-icon class="icon12">panorama_fish_eye</mat-icon>&nbsp;<ng-container i18n>Standard</ng-container> </ng-template>
		</mat-select-trigger>
		<mat-option *ngFor="let clr of colors, let i = index" [value]="i">
			<span *ngIf="(i > 0 || hasDefaultColor === false);else defaultColor1">
				<mat-icon [style.color]="clr">lens</mat-icon><ng-container i18n>Farbe</ng-container> {{ i }}
			</span>
			<ng-template #defaultColor1><mat-icon>panorama_fish_eye</mat-icon><ng-container i18n>Standard</ng-container></ng-template>
		</mat-option>
	</mat-select>
</mat-form-field>
`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ColorSelectComponent),
			multi: true,
		}
	],
})
export class ColorSelectComponent implements ControlValueAccessor {

	@Input()
	hasDefaultColor = true;

	@Input()
	class = '';

	@Input()
	toolbar = false;

	colors = Const.colors;

	ctrl = new FormControl();

	private disabled: boolean;
	private onChange: (val: number) => void;
	private onTouched: () => void;

	onSelectionChange(e) {
		this.onChange(e.value);
	}

	writeValue(value: number) {
		this.ctrl.patchValue(value);
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}
}

@Component({
	selector: 'fo-color-picker-dialog',
	template: `
<div mat-dialog-content>
	<color-github width="100%" triangle="hide" (onChange)="onChangeColor($event)" [color]="color" [colors]="colors" width="262px"></color-github>
</div>
`,
	styles: ['']
})
export class ColorPickerDialogComponent {
	color = '';
	colors = Const.colorPalette;

	constructor(
		public dialogRef: MatDialogRef<ColorPickerDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) {
		this.color = data;
	}

	onChangeColor(event: ColorEvent) {
		this.color = event.color.hex;
		this.dialogRef.close(this.color);
	}
}

@Component({
	selector: 'fo-color-picker',
	template: `
<div style="position:relative">
	<mat-form-field [ngClass]="class" style="padding:0"  [subscriptSizing]="toolbar ? 'dynamic' : 'fixed'">
		<mat-label>Farbwert</mat-label>
		<input matInput [(ngModel)]="value" (ngModelChange)="onInputChange()">
		<mat-icon matSuffix [style.color]="value" class="icon12">lens</mat-icon>
		<button matSuffix mat-icon-button type="button" (click)="onColorPicker($event)" tabindex="-1"><mat-icon>colorize</mat-icon></button>
	</mat-form-field>
</div>
`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ColorPickerComponent),
			multi: true,
		}
	],
})
export class ColorPickerComponent implements ControlValueAccessor {
	@Input()
	class = '';

	@Input()
	toolbar= false;

	@Input()
	value = '';

	disabled: boolean;
	showColorPicker = false;

	constructor(
		private dialog: MatDialog
	) { }

	private onChange: (val: string) => void;
	private onTouched: () => void;

	writeValue(value: string) {
		if (!value) { return; }
		this.value = value;
		this.onChange(value);
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	onInputChange() {
		this.onChange(this.value);
	}

	onColorPicker(event) {
		const dialogRef = this.dialog.open(ColorPickerDialogComponent,
			{
				width: '262px', panelClass: 'picker-container', backdropClass: 'white-backdrop',
				position: { top: (event.clientY + 16) + 'px', left: (event.clientX - 262) + 'px' },
				data: this.value,
			});

		const result = dialogRef.afterClosed();
		result.subscribe(c => this.writeValue(c));
	}

}

@Component({
	selector: 'fo-icon-snackbar',
	template: `<div class="icon-snackbar" (click)="onClose()">
		<span style="msg">{{ data?.message }}</span>
		<button mat-icon-button tabindex="-1" (click)="tools.stopEvent($event); onAction()" *ngIf="data?.icon"><mat-icon class="icon-12">{{ data?.icon }}</mat-icon></button>
	</div>`,
	styles: [
		'.icon-snackbar { display: flex; justify-content: space-between; align-items: center; line-height: 20px; opacity: 1;}',
		'.msg { white-space: pre-wrap;}',
	]
})
export class IconSnackBarComponent {
	constructor(
		public tools: ToolService,
		@Inject(MAT_SNACK_BAR_DATA) public data: any
	) { }

	fadeOut = false;

	onAction() {
		this.data?.onAction();
	}

	onClose() {
		this.data?.onClose();
	}
}

@Component({
	selector: 'fo-date-input',
	template: `
<mat-form-field class="full-width" [class.toolbar]="toolbar" [subscriptSizing]="toolbar ? 'dynamic' : 'fixed'">
		<mat-label>{{  label }}</mat-label>
		<input matInput dateInput [matDatepicker]="picker" [formControl]="control" (dateChange)="onDateChange($event)" [matTooltip]="tooltip">
		<mat-datepicker-toggle matSuffix [for]="picker" tabindex="-1"></mat-datepicker-toggle>
		<mat-datepicker [touchUi]="(tools.isMobile | async)" (opened)="onOpenDatepicker()" #picker></mat-datepicker>
		<mat-icon matSuffix class="warning" [matTooltip]="warning" *ngIf="warning">warning</mat-icon>
		<mat-error *ngIf="!toolbar">{{ validationMsg() }}</mat-error>
</mat-form-field>
`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DateInputComponent),
			multi: true,
		}
	],
	styles: [
		'.warning { color: #ffc107; vertical-align:bottom; }',
		'.toolbar { margin-top: 16px; }'
	]
})
export class DateInputComponent implements ControlValueAccessor, OnInit {
	@Input()
	label = '';

	@Input()
	startControl: DateInputComponent;

	@Input()
	endControl: DateInputComponent;

	@Input()
	warning = '';

	@Input()
	minDayDiff = 0;

	@Input()
	required = false;

	@Input()
	tooltip = ''

	@Input()
	toolbar = false;

	@Output()
	dateChange = new EventEmitter<MatDatepickerInputEvent<Date>>();

	control = new FormControl(Date.today());

	private disabled: boolean;
	private onChange: (val: Date) => void;
	private onTouched: () => void;

	constructor(
		public tools: ToolService,
	) { }

	ngOnInit() {
		this.control.addValidators(this.required ? FormService.dateRequired : FormService.dateValidator);
	}

	validationMsg(): string {
		for (const propertyName in this.control.errors) {
			if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
				return FormService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
			}
		}
	}

	onOpenDatepicker() {
		const date: Date = this.control.value;
		const isEmpty = !date || isNaN(date.getTime()) || date.getFullYear() < 1900;

		if (this.startControl) {
			const start: Date = new Date(this.startControl.control.value);
			if (start && (isEmpty || date?.getTime() < start.getTime())) {
				this.control.patchValue(start.addDays(1));
			}
		} else if (isEmpty) {
			this.control.patchValue(Date.today());
			this.onChange(Date.today());
			this.control.markAsTouched();
		}
	}

	onDateChange(e: MatDatepickerInputEvent<Date>) {
		if (!e.value) { return; }

		if (this.startControl) {
			const start: Date = new Date(this.startControl.control.value);
			if (start && e.value.getTime() < start.getTime()) {
				const today = Date.today();

				const d = e.value.getDate();
				let m = e.value.getMonth();
				if (m === today.getMonth()) { m = start.getMonth(); }
				let y = e.value.getFullYear();
				if (y === today.getFullYear()) { y = start.getFullYear(); }

				let newEnd = new Date(y, m, d, e.value.getHours(), e.value.getMinutes(), e.value.getSeconds());
				if (newEnd.getTime() > start.getTime()) {
					e.value = newEnd;
				} else {
					if (m === today.getMonth()) {
						newEnd = newEnd.addMonths(1);
					} else {
						newEnd = newEnd.addMonths(12);
					}

					e.value = newEnd;
				}
			}
		}

		if (this.endControl) {
			const end: Date = new Date(this.endControl.control.value);
			if (!end.getTime() || e.value.getTime() > end?.getTime()) {
				const newEnd = e.value.addDays(this.minDayDiff);
				this.endControl.control.patchValue(newEnd);
				this.endControl.onChange(newEnd);
			}
		}

		this.control.patchValue(e.value);
		this.onChange(e.value);
		this.control.markAsTouched();
		this.dateChange.emit(e);
	}

	writeValue(value: Date) {
		this.control.patchValue(value, { emitEvent: false });
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}
}

@Component({
	selector: 'app-email-box',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{ caption }}</h4>
<mat-dialog-content (keyup.enter)="onOk()" (keydown.escape)="$event.stopPropagation();dialogRef.close(null)">
	<mat-form-field class="full-width">
		<mat-label>Absender</mat-label>
		<mat-select [(ngModel)]="sender">
				<mat-option *ngFor="let s of senders" [value]="s"> {{ s }} </mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field class="full-width">
		<mat-label>Empfänger</mat-label>
		<input matInput [(ngModel)]="recipient" cdkFocusInitial [matAutocomplete]="auto"
			(focus)="onFocus($event)" (ngModelChange)="onChangeRecipient()" autocomplete="off" spellcheck="false">
	</mat-form-field>

	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setValue($event.option.value)">
		<mat-option *ngFor="let adr of filteredData" [value]="adr.email">
			{{ adr2String(adr) }}
		</mat-option>
	</mat-autocomplete>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onOk()" [disabled]="!hasEmail">Ok</button>
	<button mat-raised-button color="primary" [mat-dialog-close]="">Abbrechen</button>
</mat-dialog-actions>
`,
	styles: ['.mat-mdc-dialog-content {overflow-y: hidden;}']
})
export class InputEmailBoxComponent {
	caption: string;

	sender: string;
	recipient: string;
	custID = 0;
	hasEmail: boolean;

	senders: string[] = [];
	filteredData: Adr[] = [];

	constructor(
		public dialogRef: MatDialogRef<InputEmailBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public tools: ToolService,
		private dataService: DataService,

	) {
		this.senders.push(this.tools.license.email);
		this.tools.getHouses().forEach(h => {
			if (h.email && this.senders.indexOf(h.email) < 0) { this.senders.push(h.email); }
		});

		this.caption = data.caption;
		this.recipient = data.recipient || '';
		this.sender = data.sender;
		this.hasEmail = this.checkEmail(this.recipient);
		if (!this.sender) { this.sender = sessionStorage.getItem('def-email'); }
		if (!this.sender) { this.sender = this.tools.license.email; }
	}

	adr2String(adr: Adr, allInfo = true): string {
		if (!adr) { return ''; }

		let text = adr.name;
		text = ToolService.append(text, adr.firstname);
		text = ToolService.append(text, adr.name1);
		if (allInfo && adr.agencyName) { text = ToolService.append(text, `(${adr.agencyName})`); }
		text = ToolService.append(text, adr.city, ', ');
		if (allInfo && adr.country && adr.country !== ToolService.country) { text += `(${adr.country})`; }

		return text;
	}

	checkEmail(eMail: string): boolean {
		if (!eMail) { return false; }

		// RFC 2822 compliant regex
		return eMail.toLowerCase().match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/) != null;
	}

	onChangeRecipient() {
		this.dataService.findAdr(this.recipient).subscribe(data => this.filteredData = data.filter(a => a.email));
		this.custID = 0;
		this.hasEmail = this.checkEmail(this.recipient);
	}

	setValue(email: string) {
		if (this.filteredData.length === 0) { return; } // HACK fix for doubleclick

		const adr = this.filteredData.find(a => a.email === email);
		this.custID = adr?.id || 0;
		this.recipient = email;
		this.hasEmail = this.checkEmail(this.recipient);
	}

	onFocus(event) {
		event.target.select();
	}

	onOk() {
		sessionStorage.setItem('def-email', this.sender);
		this.dialogRef.close({ sender: this.sender, recipient: this.recipient, custID: this.custID });
	}
}



@Component({
	selector: 'fo-choose-payment',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{ caption }}</h4>
<mat-dialog-content (keydown.enter)="onOk()" (keydown.escape)="$event.stopPropagation();dialogRef.close(null)">
	<div>Zahlungsart für Abschluss (Saldo: {{ balance }})</div>
	<mat-form-field class="full-width">
		<mat-label>Zahlungsart</mat-label>
		<mat-select [(ngModel)]="payment" required>
			<mat-option *ngFor="let art of payments" [value]="art.id"> {{ art.name }} </mat-option>
		</mat-select>
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onOk()" [disabled]="!payment">Ok</button>
	<button mat-raised-button color="primary" [mat-dialog-close]="">Abbrechen</button>
</mat-dialog-actions>
`,
	styles: []
})
export class ChoosePaymentComponent {
	public payment: number;
	public payments = [];
	public caption: string;
	public balance: string;
	public usage = 0;


	constructor(
		public tools: ToolService,
		public dataService: DataService,
		public dialogRef: MatDialogRef<ChoosePaymentComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.caption = data.caption;
		this.balance = ToolService.formatNumber(data.balance);
		this.usage = +data.usage;

		const result = dataService.getArticlesType(1);
		result.subscribe(p => {
			this.payments = p.filter(a => (a.id === Const.artidCash || a.id === Const.artidDebitor || a.id <= -100) &&
				a.name.startsWith('Anz') === false && !a.flagLock && !a.flagPassThrough &&
				(this.usage === 0 || this.usage === a.usage || a.usage === 0))
				.sort((a1, a2) => (this.buildSortID(a1) > this.buildSortID(a2)) ? 1 : -1);
		});
	}

	buildSortID(article) {
		return article.itemID + ('      ' + (-article.id)).slice(-6);
	}

	onOk(): void {
		this.dialogRef.close(this.payment);
	}
}

@Component({
	selector: 'app-house-name-selector',
	template: `
		<div class="container">
			<h4
				mat-dialog-title
				mat-dialog-title-draggable
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<div>Reset House</div>
			</h4>
			<div mat-dialog-content class="mb-4">
				<div class="mb-3">
					<mat-form-field class="w-100">
						<mat-label>Select House Name</mat-label>
						<mat-select [(ngModel)]="selectedHouseNames" multiple>
							<mat-option (click)="clearSelected()"
								>Clear All</mat-option
							>
							<mat-option (click)="selectAll()"
								>Select All</mat-option
							>
							<mat-option
								*ngFor="let houseName of houseNames"
								[value]="houseName"
							>
								{{ houseName }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div mat-dialog-actions class="d-flex justify-content-end">
					<button
						mat-button
						color="primary"
						class="me-2"
						(click)="onSelect()"
					>
						Reset
					</button>
					<button mat-button color="warn" (click)="onCancel()">
						Cancel
					</button>
				</div>
				<div class="mt-4">
					<mat-progress-bar
						*ngIf="showProgressBar"
						mode="determinate"
						[value]="progressValue"
					></mat-progress-bar>
					<div
						*ngIf="showProgressBar"
						class="mt-2 text-center"
						[innerHTML]="textBox"
					></div>
					<div
						*ngIf="progressValue > 90"
						mat-dialog-actions
						class="d-flex justify-content-end"
					>
						<button
							mat-button
							color="primary"
							class="me-2"
							(click)="onAcknowledge()"
						>
							Ok
						</button>
					</div>
				</div>
			</div>
		</div>
	`,
})
export class HouseNameSelectorComponent {
	houseNames: string[] = [];
	selectedHouseNames: string[] = [];
	showProgressBar: boolean = false;
	progressValue: number = 0;
	textBox: string = '';

	constructor(
		private demoGenerator: DemoGeneratorService,
		public dialogRef: MatDialogRef<HouseNameSelectorComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (data && data.houseNamesList) {
			this.houseNames = data.houseNamesList;
		}
	}

	async onSelect(): Promise<void> {
		this.showProgressBar = true;
		this.updateProgress(0);
		this.textBox += `Process Started<br>`;
		let progressStep = 100 / this.selectedHouseNames.length;

		for (let i = 0; i < this.selectedHouseNames.length; i++) {
			await this.demoGenerator.generateDemoHouse(
				this.selectedHouseNames[i]
			);
			console.log('Resetting ' + this.selectedHouseNames[i]);
			this.progressValue = (i + 1) * progressStep;
			this.textBox += `${this.selectedHouseNames[i]} complete<br>`;
		}
		this.textBox += `Process completed<br>`;
	}

	onCancel(): void {
		this.dialogRef.close();
	}

	onAcknowledge(): void {
		this.dialogRef.close();
	}

	clearSelected(): void {
		this.selectedHouseNames = [];
	}

	selectAll(): void {
		this.selectedHouseNames = this.houseNames;
	}

	updateProgress(value: number): void {
		this.progressValue = value;
	}
}

@Component({
	selector: 'fo-date-box',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>
	Datum wählen
</h4>
<div mat-dialog-content>
	<div class="row">
		<div class="col">
			<div fxLayout>
				<fo-date-input style="width:9em;margin-right:1em;" label="Datum" [(ngModel)]="date" (dateChange)="onDateChange()" focusInitial></fo-date-input>
				<mat-form-field class="full-width">
					<mat-label>Kalenderwoche</mat-label>
					<input matInput numberInput [(ngModel)]="week" (change)="onWeekChange()">
				</mat-form-field>
			</div>
			<mat-grid-list cols="3" rowHeight="3em" gutterSize="1em" class="buttons">
				<mat-grid-tile *ngFor="let b of buttons">
					<button mat-raised-button (click)="onSelect(b.value)"  tabindex="-1"> {{ b.text }} </button>
				</mat-grid-tile>
			</mat-grid-list>
		</div>

		<div class="col">
			<mat-calendar [selected]="date" [startAt]="date" (selectedChange)="onSelect($event)" tabindex="-1"></mat-calendar>
		</div>
	</div>
</div>
`,
	styles: ['.buttons {margin-top:15px;}']
})
export class SelectDateComponent {
	date: Date;
	week: number;
	startWeek: number;

	buttons = [];


	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<SelectDateComponent>,
		@Inject(MAT_DIALOG_DATA) public startDate: Date,
	) {
		this.date = new Date(startDate);
		this.week = this.date.getWeek();
		this.startWeek = this.week;

		let d = new Date(this.date.getFullYear(), this.date.getMonth() - 2, 1);
		for (let i = 0; i < 15; i++) {
			const month = d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);
			const button = { text: month + '/' + d.getFullYear(), value: d.toISODateString() };
			this.buttons.push(button);

			d = new Date(d.setMonth(d.getMonth() + 1));
		}
	}

	onSelect(d) {
		this.dialogRef.close(d);
	}

	onToday() {
		this.dialogRef.close(new Date().addDays(-3));
	}

	onDateChange() {
		this.dialogRef.close(this.date);
	}

	onWeekChange() {
		const year = this.date.getFullYear() + ((+this.week) < this.startWeek ? 1 : 0);
		const date = Date.weekStart(year, this.week);
		this.dialogRef.close(date);
	}
}

@Component({
	selector: 'fo-combo-input',
	template: `
<mat-form-field class="full-width">
	<mat-label>{{ label }}</mat-label>
	<input matInput maxLength="50" [formControl]="control" (change)="setValue($event.target.value)" [matAutocomplete]="auto">
	<button matSuffix mat-icon-button type="button" tabindex="-1" (click)="control.patchValue('')"><mat-icon>clear</mat-icon></button>

	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setValue($event.option.value)">
			<mat-option *ngFor="let item of filteredItems" [value]="item"> {{ item }} </mat-option>
	</mat-autocomplete>
</mat-form-field>
	`,
	styles: [],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ComboInputComponent),
			multi: true
		}
	]
})
export class ComboInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

	@Input()
	label: string;

	@Input()
	value: string;

	@Input()
	set items(value: string[]) {
		this._items = value;
		this.filteredItems = value;
	}

	@Output()
	change = new EventEmitter();

	private disabled = false;
	private changeSubscription: Subscription;
	private _items: string[] = [];

	control = new FormControl('');
	filteredItems: string[] = [];

	/** search ctor */
	constructor(
	) { }

	// Function prototypes
	onChange = (value: string) => { };
	onTouched = () => { };

	ngOnInit() {
		this.changeSubscription = this.control.valueChanges.subscribe(item => {
			this.filteredItems = this._items.filter(o => o.toLowerCase().startsWith(item.toLowerCase()));
		});
	}

	ngOnDestroy() {
		this.changeSubscription.unsubscribe();
	}

	setValue(result: string) {
		this.value = result;

		this.control.patchValue(result);
		this.onChange(this.value);
		this.change.emit(this.value);
	}


	async writeValue(value) {
		this.value = value;
		this.control.patchValue(value, { emitEvent: false });
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (isDisabled) {
			this.control.disable();
		} else {
			this.control.enable();
		}
	}
}



@Component({
	selector: 'app-addon',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>Zusatzartikel ({{ persCount }} Personen)</h4>
<div mat-dialog-content (keydown.insert)="onAdd()" (keydown.escape)="$event.stopPropagation();dialogRef.close(null)">
	<perfect-scrollbar style="height:380px;">
		<div class="row" *ngFor="let addOn of addOns">
			<mat-form-field class="full-width col-sm-1 pad-right-0">
				<mat-label>Anzahl</mat-label>
				<input matInput numberInput [(ngModel)]="addOn.quantity">
			</mat-form-field>

			<fo-art-input class="full-width col-sm-4" [(ngModel)]="addOn.artID" usage="hotel" (ngModelChange)="onArtChange(addOn)"></fo-art-input>

			<mat-form-field class="full-width col-sm-2">
				<mat-label>Preis</mat-label>
				<input matInput currencyInput [(ngModel)]="addOn.basePrice">
			</mat-form-field>

			<mat-form-field class="full-width col-sm-1">
				<mat-label>Rabatt %</mat-label>
				<input matInput currencyInput [(ngModel)]="addOn.discount">
			</mat-form-field>

			<mat-form-field class="full-width col-sm-3">
				<mat-label>Text</mat-label>
				<input matInput [(ngModel)]="addOn.text">
			</mat-form-field>

			<div class="col-sm-1">
				<button mat-icon-button type="button" (click)="onDelete(addOn)" matTooltip="Zeile löschen" i18n-matTooltip tabindex="-1"><mat-icon>clear</mat-icon></button>
			</div>
		</div>
	</perfect-scrollbar>
</div>
<div mat-dialog-actions>
	<button mat-raised-button color="primary" type="submit" (click)="onOk()">Ok</button>
	<button mat-raised-button color="primary" type="button" [mat-dialog-close]="">Abbrechen</button>
	<div fxFlex></div>

	<mat-form-field class="full-width col">
		<mat-label>Package</mat-label>
		<mat-select [(ngModel)]="packageID" (selectionChange)="bookPackage()">
			<mat-option [value]="0"> Package wählen </mat-option>
			<mat-option *ngFor="let p of packages" [value]="p.id">{{ p.name }}</mat-option>
		</mat-select>
	</mat-form-field>
	<button mat-icon-button type="button" (click)="onAdd()" matTooltip="AddOn hinzufügen" i18n-matTooltip tabindex="-1"><mat-icon>add_circle_outline</mat-icon></button>
</div>

`,
	styles: []
})
export class AddOnComponent implements OnInit {
	addOns: Journal[] = [];
	booking: Booking;
	persCount = 0;

	packages: Article[] = [];
	packageID = 0;

	constructor(
		public tools: ToolService,
		public dataService: DataService,
		public dialogRef: MatDialogRef<AddOnComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) {
		this.booking = data.booking;
		this.persCount = data.persCount;
	}

	ngOnInit() {
		if (this.booking.addOnList) {
			this.booking.addOnList.forEach(j => {
				j.quantityOld = j.quantity;
			});
		} else {
			this.booking.addOnList = [];
		}
		if (this.booking.addOnList.length === 0) { this.booking.addOnList.push({ quantity: 1, quantityOld: 0, basePrice: 0, discount: 0, text: '' } as Journal); }
		this.addOns = this.booking.addOnList;

		this.dataService.getArticles().subscribe(articles => {
			this.packages = articles.filter(a => a.usage < 2 && a.follow);
		});
	}

	onAdd() {
		this.booking.addOnList.push({ quantity: 1, quantityOld: 0, basePrice: 0, discount: 0, text: '' } as Journal);
		this.addOns = this.booking.addOnList.filter(r => r.quantity);
	}

	onDelete(row: Journal) {
		row.quantity = 0;
		if (!row.quantityOld) {
			const index = this.booking.addOnList.findIndex(r => r == row);
			this.booking.addOnList.splice(index, 1);
		}

		this.addOns = this.booking.addOnList.filter(r => r.quantity);
	}

	async onArtChange(row) {
		const art = await this.dataService.getArticle(row.artID).toPromise()
			.catch(err => this.tools.showError(err));
		if (!art) { return; }

		row.basePrice = DataService.getArticlePrice(art, this.booking.from);
	}

	async bookPackage() {
		// delete all temp rows
		for (let i = this.booking.addOnList.length - 1; i >= 0; i--) {
			if (this.booking.addOnList[i].quantityOld === 0) {
				this.booking.addOnList.splice(i, 1);
			}
		}

		if (this.packageID === 0) { return; }
		const art = this.packages.find(p => p.id === this.packageID);
		if (!art) { return; }

		if (!art.flagLock) {
			const journal = { quantity: this.persCount, artID: art.id, quantityOld: 0, basePrice: art.price, discount: 0, text: '' } as Journal;
			journal.groupText = art.name;
			this.booking.addOnList.push(journal);
		}
		const included = !art.flagLock;

		const parts = art.follow.split('+');
		for (let i = 0; i < parts.length; i++) {
			const part = parts[i];
			if (!part) { continue; }

			const part1 = part.split('*');
			let quantity = 1;
			if (part1.length > 1) {
				quantity = +part1[0] ? +part1[0] : this.persCount;
			}
			const itemIDPrice = part1[part1.length - 1].split(':');
			const itemID = itemIDPrice[0];
			const price = ToolService.parseNumber(itemIDPrice[1]);

			const partArt = await this.dataService.getArticle(itemID).toPromise();
			if (!partArt) { continue; }
			const partPrice = included ? 0 : (price > 0 ? price : DataService.getArticlePrice(partArt, this.booking.from, 1));

			const journal = { quantity: quantity, artID: partArt.id, quantityOld: 0, basePrice: partPrice, discount: 0, text: '' } as Journal;
			journal.groupText = art.name;
			this.booking.addOnList.push(journal);
		}
	}

	onOk() {
		this.dialogRef.close(this.booking.addOnList);
	}

}
