import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToolService } from '../../_services';

@Component({
	selector: 'fo-label-box',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{caption}}</h4>
<div mat-dialog-content (keydown.enter)="onOk()" (keydown.escape)="dialogRef.close(null)" style="height:60vh">
	<mat-table #table [dataSource]="dataSource">
		<ng-container *ngFor="let col of columns; let i = index" [matColumnDef]="col.name">
			<mat-header-cell *matHeaderCellDef>{{col.caption}}</mat-header-cell>
			<mat-cell *matCellDef="let element; let j = index">
				<span *ngIf="col.option === 'label' && j < labels.length">{{ this.labels[j] }}</span>

				<mat-form-field *ngIf="col.option === ''" class="input" subscriptSizing="dynamic">
					<mat-label>{{ col.caption }}</mat-label>
					<input matInput [value]="element[col.name]" [(ngModel)]="element[col.name]" autocomplete="off">
				</mat-form-field>

				<mat-form-field *ngIf="col.option === 'cur'" class="input" subscriptSizing="dynamic">
					<mat-label>{{ col.caption }}</mat-label>
					<input matInput currencyInput [value]="element[col.name]" [(ngModel)]="element[col.name]" autocomplete="off">
				</mat-form-field>

				<mat-form-field *ngIf="col.option === 'percent'" class="input" subscriptSizing="dynamic">
					<mat-label>{{ col.caption }}</mat-label>
					<input matInput numberPercentInput [value]="element[col.name]" [(ngModel)]="element[col.name]" autocomplete="off">
				</mat-form-field>

				<fo-art-input *ngIf="col.option === 'article'" class="input" usage="wellness"
								[label]="col.caption" [value]="element[col.name]" [(ngModel)]="element[col.name]">
				</fo-art-input>

				<mat-form-field *ngIf="col.option === 'date'" class="date input" autocomplete="off">
					<mat-label>{{ col.caption }}</mat-label>
					<input matInput dateInput [matDatepicker]="i" [value]="element[col.name]" [(ngModel)]="element[col.name]" [ngModelOptions]="{updateOn: 'blur'}">
					<mat-datepicker-toggle matSuffix [for]="i" tabindex="-1"></mat-datepicker-toggle>
					<mat-datepicker #i></mat-datepicker>
				</mat-form-field>

				<mat-checkbox *ngIf="col.option==='check'" [value]="element[col.name]" [(ngModel)]="element[col.name]"></mat-checkbox>

				<fo-color-select  *ngIf="col.option === 'color'" [toolbar]="true" class="input" [(ngModel)]="element[col.name]"></fo-color-select>

				<fo-color-picker *ngIf="col.option === 'picker'" [toolbar]="true" class="full-width input" [(ngModel)]="element[col.name]"></fo-color-picker>

				<mat-form-field *ngIf="col.option.startsWith('select')" class="input" subscriptSizing="dynamic">
					<mat-label>{{ col.caption }}</mat-label>
					<mat-select [(ngModel)]="element[col.name]" >
						<mat-option *ngFor="let o of col.option.substr(7).split(',')" [value]="o">{{ o }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field *ngIf="col.option.startsWith('iselect')" class="input" subscriptSizing="dynamic">
					<mat-label>{{ col.caption }}</mat-label>
					<mat-select [(ngModel)]="element[col.name]">
						<mat-option *ngFor="let o of col.option.substr(8).split(','), let i = index" [value]="i">{{ o }}</mat-option>
					</mat-select>
				</mat-form-field>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>
</div>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onOk()">Ok</button>
	<button mat-raised-button color="primary" [mat-dialog-close]="">Abbrechen</button>
 </mat-dialog-actions>
`,
	styles: [
		'.input {width:100%;}',
		'.mat-mdc-cell { border: none; padding: 0 .5em }',
	]
})
export class LabelBoxComponent {
	public caption: string;
	public displayedColumns = [];
	public columns = [];
	public labels = [];
	public isJSON = true;
	public dataSource = new MatTableDataSource<any>();

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<LabelBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.caption = data.caption;


		// Add Caption field
		data.columns.forEach(h => {
			const opt = h.split('/');
			const arr = opt[0].split(':');

			if (arr[0] === 'label') {
				const col = { name: 'label', caption: '', option: 'label' };
				this.columns.push(col);
				this.displayedColumns.push(arr[0]);

				const labels = arr[1].split(',');
				labels.forEach(l => this.labels.push(l));
			} else {
				const col = { name: arr[0], caption: arr[arr.length - 1], option: opt[1] || '' };

				this.columns.push(col);
				this.displayedColumns.push(arr[0]);
			}
		});

		this.isJSON = data.value && (data.value[0] === '[' || (data.columns || '').length > 2);
		let rows = [];
		if (this.isJSON) {
			data.value = (data.value || '[]');
			rows = JSON.parse(data.value);
		} else {
			const d = (data.value || '').split('|');
			d.forEach(item => {
				rows.push({ name: item });
			});
		}

		while (rows.length < this.labels.length) {
			rows.push({});
		}

		this.dataSource.data = rows;
	}

	onOk(): void {
		let result: string;

		if (this.isJSON) {
			result = JSON.stringify(this.dataSource.data);
		} else {
			const arr = [];
			result = this.dataSource.data.map(s => s.name).join('|');
		}

		this.dialogRef.close(result);
	}


}


