import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpHeaders,
} from '@angular/common/http';

@Injectable()
export class DbNameInterceptor implements HttpInterceptor {
	private getCookie(name: string): string | null {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
		return null;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const dbName = this.getCookie('dbName');

		const modifiedReq = dbName
			? req.clone({
					headers: req.headers.set('dbname', dbName),
			  })
			: req;

		return next.handle(modifiedReq);
	}
}
