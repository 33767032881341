<div class="form-container" style="padding-bottom:64px;" (document:keydown.escape)="onCancel()">
	<mat-card *ngIf="mode === ''" ngClass.xs="mat-card-xs">
		<mat-card-title>
			<div fxFlex>{{ caption }}</div>
			<button type="button" mat-icon-button (click)="onCopy()" matTooltip="Link zum Artikel kopieren" tabindex="-1"><mat-icon>link</mat-icon></button>
			<div *showAdmin="true">
				<button type="button" mat-icon-button (click)="onNew()" tabindex="-1"><mat-icon>add_circle_outline</mat-icon></button>
				<button type="button" mat-icon-button (click)="onEdit()" tabindex="-1"><mat-icon>edit</mat-icon></button>
			</div>
			<button type="button" mat-icon-button (click)="onClose()" tabindex="-1"><mat-icon>clear</mat-icon></button>
		</mat-card-title>
		<mat-card-content>
			<div [innerHTML]="wiki.text | highlight: highlight" class="markup"></div>
		</mat-card-content>
		<mat-card-actions>
			<mat-divider></mat-divider>
			<br />
			<div class="d-flex justify-content-between small" *ngIf="wiki.creationDate">
				<div><span i18n>Erstellt</span> {{wiki.creationDate | dateTime }} <span i18n>von</span> {{ wiki.creationUser | username | async }}</div>
				<div float="right"><span i18n>Geändert</span> {{wiki.modifyDate | dateTime }} <span i18n>von</span> {{ wiki.modifyUser | username | async }}</div>
			</div>
		</mat-card-actions>
	</mat-card>
	<mat-card *ngIf="mode === 'edit'" ngClass.xs="mat-card-xs">
		<form (ngSubmit)="onSubmit(form)" [formGroup]="form" class="form" autocomplete="off">
			<input type="hidden" formControlName="id" />

			<mat-card-title>
				<div class="row">
					<mat-form-field class="full-width col">
						<mat-label>Subjekt</mat-label>
						<input matInput maxLength="80" formControlName="name" required focusInitial>
						<mat-error>{{validationMsg("name")}}</mat-error>
					</mat-form-field>
				</div>
			</mat-card-title>
			<mat-card-content>
				<div class="row">
					<mat-form-field class="full-width col">
						<mat-label>Namespace</mat-label>
						<input matInput maxLength="40" formControlName="namespace">
					</mat-form-field>
					<mat-form-field class="full-width col">
						<mat-label>FOID</mat-label>
						<input matInput maxLength="80" formControlName="foid">
					</mat-form-field>
				</div>
				<mat-form-field class="full-width col mat-subtitle-2">
					<mat-label>Markup</mat-label>
					<textarea matInput formControlName="text" class="full-width markup-edit" rows="30" required></textarea>
					<mat-error>{{validationMsg("text")}}</mat-error>
				</mat-form-field>
			</mat-card-content>
			<mat-card-actions>
				<div class="row">
					<div class="col">
						<button type="submit" color="primary" mat-raised-button [disabled]="form.invalid" i18n>Speichern</button>
						<button mat-raised-button color="primary" type="button" (click)="onCancel()" i18n>Abbrechen</button>
					</div>
					<div class="col" align="end">
						<button mat-raised-button type="button" (click)="onDelete()" i18n>Löschen</button>&nbsp;
					</div>
				</div>
			</mat-card-actions>
		</form>
	</mat-card>
</div>
