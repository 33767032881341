export class Settings {
	public static controls = [
/*		{ type: 'header', label: 'CORONA', section: 'corona' },
		{ type: 'checkbox', label: 'Corona MwSt-Reduktion von Restaurant Artikeln auf MwSt Satz 4 (5%)', section: 'corona', key: 'vat', value: false },
		{ type: 'header', label: 'Next-Generation-Programm', section: 'beta' },
		{ type: 'checkbox', label: 'Next-Generation-Version aktivieren (Restaurant)', section: 'beta', key: 'all', value: false },
*/

		{ type: 'header', label: 'Arbeitsplatz Einstellungen', section: 'local' },
		{ type: 'route', label: 'App-Einstellungen', section: 'local', key: 'app', options: 'https://app/settings' },
		{ type: 'dropdown', label: 'Startseite:', section: 'local', key: 'home', options: 'Dashboard|Hotel|Restaurant|Seminar|Wellness|Nachrichten|Housekeeping|EC-Terminal' },
		{ type: 'dropdown', label: 'Standard Haus:', section: 'local', key: 'house', options: '[houses1]' },
		{ type: 'dropdown', label: 'Lokaler Bondrucker:', section: 'local', key: 'printer-type', options: '*** Standard ***|ePos-Netzwerkdrucker|Bluetooth Drucker|USB-Drucker|MyPOS-Drucker|Windows|Kein Drucker' },
		{ type: 'string', label: 'Bondrucker DNS-Name:', section: 'local', key: 'printer-name' },
		{
			type: 'json', label: 'Küchendrucker Zuweisungen', section: 'local', key: 'printer-map',
			options: 'id:Drucker/iselect,[printer]|name:DNS-Name|print:Küchenbons senden an/iselect,Drucker+Küchenmonitor,Drucker,Küchenmonitor', 
			level: 1
		},
		{ type: 'string', label: 'EC-Terminal ID:', section: 'local', key: 'ecTerminal', level: 1 },
		{ type: 'string', label: 'Key-Encoder ID:', section: 'local', key: 'keyEncoder', level: 1 },
		{ type: 'dropdown', label: 'Autoabmeldung', section: 'local', key: 'autologout', options: 'Inaktiv|1 Min|5 Min|15 Min', value: 0 },
		{ type: 'checkbox', label: 'Unterschriften-Pad verwenden', section: 'local', key: 'sigpad', value: true },

		{ type: 'header', label: 'Hotel Einstellungen', section: 'hotel' },
		{ type: 'route', label: 'Zimmer und Zimmer-Kategorien:', section: 'hotel', key: 'rooms', options: '/settings/rooms' },
		{ type: 'route', label: 'Arrangements und Pakete', section: 'hotel', key: 'arrangements', options: '/settings/arrangements' },
		{ type: 'route', label: 'Zimmerpreise und Rabatte', section: 'hotel', key: 'rates', options: '/settings/rates' },
		{ type: 'json', label: 'Buchungs-Status:', section: 'hotel', key: 'bookingstatus', options: 'name:Bezeichnung|color:Farbe/picker', level: 1 },
		{ type: 'json', label: 'Buchungs-Tags: ', section: 'hotel', key: 'bookingtags', options: 'name:Bezeichnung|color:Farbe/color' },
		{
			type: 'json', label: 'Channel-Manager:', section: 'cm', key: 'config',
			options: 'type:Typ/select,*** Auto ***,Cultuzz,d-Edge,Dirs21,Feratel,HNS,Hotelpartner,Hotelspider,MappingMaster,Lux,Rateboard,Salesbox,Seekda,Siteminder,Viato,Vioma,Websline|name:Hotel-ID|user:Benutzer|password:Passwort|filter:Filter/iselect:*** Alle Häuser ***,[houses]|inactive:Inaktiv/check', level: 1
		},
		{ type: 'array', label: 'Etagen:', section: 'hotel', key: 'floors', level: 1 },
		{ type: 'json', label: 'Events:', section: 'hotel', key: 'events',options: 'name:Bezeichnung|from:Beginn/date|to:Ende/date|color:Farbe/color' },
		{
			type: 'json', label: 'Farb-Regeln:', section: 'hotel', key: 'colorrules',
			options: 'name:Feld/select,Arrangement,Market-Code,Fixiert,Anzahlung erhalten,Kostenträger-ID,Source-Code,Status,Tag,Workflow|op:Funktion/iselect,Gleich,Ungleich,Beginnt mit,Enthält|value:Wert|color:Farbe/color', level: 1
		},
		{ type: 'label', label: 'Farben Zimmerplan: ', section: 'hotel', key: 'colors', options: 'label:CheckIn,CheckOut,Vorläufig,Angebot,Wochenende,Event,Buchung,Stockwerks-Trenner,Kontingent,Heute|name:Farbe/picker' },
		{ type: 'json', label: 'Häuser*: ', section: 'global', key: 'houses', options: 'name:Bezeichnung|email:Email|outlet:Outlet/iselect,[outlets]', level: 2 },
		{ type: 'array', label: 'Herkunft/Source-Codes: ', section: 'hotel', key: 'origins' },
		{ type: 'json', label: 'Kinderpreise', section: 'hotel', key: 'kidsprice', options: 'perscat:Personen-Kategorie/iselect,[perscat]|start:Ab Datum/date|ratecode:Raten-Code/iselect,[ratecodes]|arrangement:Arrangement/mselect,[arrangements]|category:Zimmer-Kategorie/mselect,[roomcats]|price:Preis oder Rabatt%/percent' },

		{ type: 'array', label: 'Market-Codes: ', section: 'hotel', key: 'marketcodes'},
		{ type: 'json', label: 'Personen-Kategorien:', section: 'hotel', key: 'perscat', options: 'name:Bezeichnung (Einzahl)|tax:Ortstaxe/check|mapping:Mapping', level: 1 },
		{ type: 'json', label: 'Raten-Codes/Saisonen:', section: 'hotel', key: 'ratecodes', options: 'name:Bezeichnung|color:Farbe/color', level: 1 },
		{ type: 'json', label: 'Quick-Steps:', section: 'hotel', key: 'quicksteps', options: 'name:Bezeichnung|action:Funktion/iselect,Route,Script|param:Parameter|color:Farbe/color', level: 1 },
		{ type: 'dropdown', label: 'Workflow-Tage für PreCheckin', section: 'hotel', key: 'precheckin', options: '*** Auto ***|1|2|3|4|5|6|7' },
		{ type: 'dropdown', label: 'Workflow-Tage für PostCheckout', section: 'hotel', key: 'postcheckout', options: '*** Auto ***|1|2|3|4|5|6|7' },
		{ type: 'array', label: 'Zimmer-Ausstattung:', section: 'hotel', key: 'features'},
		{ type: 'checkbox', label: 'Channel-Manager automatisch abfragen', section: 'cm', key: 'auto', value: false },
		{ type: 'checkbox', label: 'Kinderpreise ab der ersten Person', section: 'hotel', key: 'kids1', value: false, level: 1 },
		{ type: 'checkbox', label: 'Preise automatisch fixieren', section: 'hotel', key: 'lockrates', value: false },
		{ type: 'checkbox', label: 'Zimmer bei importierten Buchungen automatisch zuweisen', section: 'cm', key: 'assign', value: false },
		{ type: 'checkbox', label: 'Zimmerpreis inklusive Ortstaxe', section: 'hotel', key: 'taxincl', value: false, level: 1 },

		{ type: 'checkbox', label: 'Europäische Reiseversicherung Schnittstelle aktivieren', section: 'erv', key: 'active', value: false },
		{ type: 'string', label: 'ERV AgencyNr (ERV_TEST zum Test)*', section: 'erv', key: 'agency', level: 2 },
		{ type: 'dropdown', label: 'ERV Product Code*', section: 'erv', key: 'product', options: 'Hotelstorno Plus|Hotelstorno Premium', level: 2 },

		{
			type: 'json', label: 'Ortstaxe spezielle Berechnung:', section: 'hotel', key: 'taxcalc',
			options: 'op:Funktion/iselect,Aufschlag pro Buchung,Prozent pro Buchung,Fixpreis pro Person (0=Artikelpreis),Prozent pro Person|filter:Filter/select,,[perscat]|name:Betrag', level: 1
		},
		{ type: 'route', label: 'Meldewesen', section: 'mw', key: 'mw', options: '/settings/mw' },


		{ type: 'header', label: 'Restaurant Einstellungen', section: 'restaurant' },
		{ type: 'json', label: 'Restaurants:', section: 'restaurant', key: 'restaurants', options: 'name:Name|quick:Menükarte|outlet:Outlet/iselect,[outlets]|house:Haus/iselect,[houses]' },
		{ type: 'route', label: 'Menükarten', section: 'restaurant', key: 'quick', options: '/settings/quick' },
		{
			type: 'json', label: 'Happy Hour/Preisregeln:', section: 'restaurant', key: 'rules',
			options: 'name:Name|filter:Artikel/iselect,*** Alle ***,[fields]|query:Bedingung/iselect,Zeitraum,Gast-Tag,Buchungs-Tag,Arrangement,Zimmerkategorie,Tischbereich|' +
			'compare:Vergleichswert|op:Funktion/iselect,Preis (1-3),Aufschlag,Prozent|value:Wert'
		},

		{ type: 'json', label: 'Bonier Buttons*:', section: 'restaurant', key: 'actions', options: 'name:Beschriftung|action:Befehl|color:Farbe/picker', level: 2 },
		{ type: 'json', label: 'Tischplan Buttons*:', section: 'restaurant', key: 'tableactions', options: 'name:Beschriftung|action:Befehl|color:Farbe/picker', level: 2 },
		{ type: 'string', label: 'Restaurant-Übernahmetext*:', section: 'restaurant', key: 'roomtext', level: 2 },
		{ type: 'array', label: 'Gänge:', section: 'restaurant', key: 'course', value: '*** kein Gang ***|Vorspeisen|Zwischengerichte|Hauptspeisen|Nachspeisen|Sonstige', level: 1 },
		{ type: 'label', label: 'Öffnungszeiten:', section: 'restaurant', key: 'open', options: 'label:Montag,Dienstag,Mittwoch,Donnerstag,Freitag,Samstag,Sonn- und Feiertag|name:Öffnungszeiten' },
		{ type: 'json', label: 'Reservierungs-Tags: ', section: 'restaurant', key: 'tags', options: 'name:Bezeichnung|color:Farbe/color' },
		{ type: 'array', label: 'Speise-Info:', section: 'restaurant', key: 'foodinfo' },
		{ type: 'checkbox', label: 'Abfrage der Personenzahl', section: 'restaurant', key: 'perscount', level: 1 },
		{ type: 'checkbox', label: 'Bonieren auf Sitznummern', section: 'restaurant', key: 'seat', level: 1 },
		{ type: 'checkbox', label: 'Bonieren auf fremde Tische', section: 'restaurant', key: 'foreign', level: 1 },

		{ type: 'header', label: 'Kassen Einstellungen', section: 'cash' },
		{ type: 'route', label: 'Kassen-Menükarten:', section: 'cash', key: 'quick', options: '/settings/quick?section=cash', level: 1 },
		{ type: 'json', label: 'Kassen-Buttons*:', section: 'cash', key: 'actions', options: 'name:Beschriftung|action:Befehl|color:Farbe/picker', level: 2 },
		{ type: 'array', label: 'Artikel-Zusatztexte:', section: 'cash', key: 'foodinfo' },
		{ type: 'string', label: 'Kassen-Übernahmetext*:', section: 'cash', key: 'roomtext', value: 'Konsumation vom', level: 2 },

		{ type: 'header', label: 'Bondruck', section: 'print' },
		{ type: 'string', label: 'Belegdrucker DNS-Name:', section: 'print', key: 'receipt' },
		{ type: 'string', label: 'Passdrucker DNS-Name:', section: 'print', key: 'pass' },
		{ type: 'string', label: `Abrufbon Drucker ('auto' für Küchendrucker):`, section: 'print', key: 'order' },
		{ type: 'string', label: `Tellerbon Drucker ('auto' für Küchendrucker):`, section: 'print', key: 'finish' },
		{ type: 'json', label: 'Küchendrucker:', section: 'print', key: 'kitchen', options: 'name:Bezeichnung|url:DNS-Name (z.B. printer1.foprinter.com)|print:Küchenbons senden an/iselect,Drucker+Küchenmonitor,Drucker,Küchenmonitor' },
		{	type: 'json', label: 'Drucker-Umleitungen:', section: 'print', key: 'mapping',
			options: 'source:Drucker/iselect,,Belegdrucker,[printer]|restaurant:Restaurant/iselect,[restaurants]|target:Umleiten auf/iselect,Kein Druck,Belegdrucker,[printer]'
		},
		{ type: 'array', label: 'Fußzeilen Beleg:', section: 'print', key: 'footer' },
		{ type: 'array', label: 'Fußzeilen Proforma:', section: 'print', key: 'footerproforma' },
		{ type: 'array', label: 'Fußzeilen Zimmerbeleg:', section: 'print', key: 'footerroom' },

		{ type: 'header', label: 'Seminar-Verwaltung', section: 'mice' },
		{ type: 'json', label: 'Buchungs-Tags: ', section: 'mice', key: 'bookingtags', options: 'name:Bezeichnung|color:Farbe/color' },

		{ type: 'header', label: 'Sportanlagen-Verwaltung', section: 'resource' },
		{ type: 'json', label: 'Preisregeln:', section: 'resource', key: 'rules',
			options: 'rules:Bedingung/iselect,Gast mit Tag,Kostenträger mit Tag,Buchungs mit Tag|para:Vergleichswert|value:Wert (Diff oder %)/percent'
		},
		{ type: 'header', label: 'Wellness', section: 'wellness' },
		{ type: 'json', label: 'Orte:', section: 'wellness', key: 'resources', options: 'name:Bezeichnung|count:Belegbarkeit|grid:Raster(min)|pers:Std.Therapeut/idselect,0:*** Keiner ***,[pers]', level: 1 },
		{ type: 'route', label: 'Behandlungen:', section: 'wellness', key: 'articles', options: '/base/article' },
		{ type: 'route', label: 'Therapeuten:', section: 'wellness', key: 'pers', options: '/base/user' },
		{ type: 'json', label: 'Quick-Steps:', section: 'wellness', key: 'quicksteps', options: 'name:Bezeichnung|action:Funktion/iselect,Route,Script|param:Parameter|color:Farbe/color', level: 1 },

		{ type: 'header', label: 'Housekeeping', section: 'housekeeping' },
		{ type: 'json', label: 'Minibar Inhalt:', section: 'housekeeping', key: 'minibar', options: 'name:Artikel/article|qty:Anzahl|house:Häuser/imselect,[houses]' },
		{ type: 'array', label: 'Reparatur-Defekte:', section: 'housekeeping', key: 'repairs' },
		{ type: 'json', label: 'Houskeeping-Tags: ', section: 'housekeeping', key: 'tags', options: 'name:Bezeichnung|color:Farbe/color' },

		{ type: 'header', label: 'Kassabuch', section: 'cb' },
		{ type: 'string', label: 'Startdatum:', section: 'cb', key: 'start' },

		{ type: 'header', label: 'Kunden-Display', section: 'slideshow' },
		{ type: 'json', label: 'Slideshow:', section: 'slideshow', key: 'img', options: 'name:Titel|url:Bild-Url (Adresse)/img|caption:Untertitel (optional)'},

		{ type: 'header', label: 'Booking-Engine', section: 'be' },
		{ type: 'string', label: 'URL bei erfolgter Buchung:', section: 'be', key: 'successurl', level: 1 },
		{ type: 'route', label: 'Übersetzungen:', section: 'be', key: 'translations', options: '/settings/translate/be' },
		{ type: 'checkbox', label: 'Zurückgehaltene Zimmer ignorieren', section: 'be', key: 'ignoreholdback' },

		{ type: 'header', label: 'Webshop', section: 'webshop' },
		{ type: 'array', label: 'Artikel-Eigenschaften:', section: 'webshop', key: 'filters', level: 1  },
		{
			type: 'json', label: 'Rabattregeln:', section: 'webshop', key: 'rules',
			options: 'name:Name|filter:Artikel/iselect,*** Alle ***,[fields]|from:Von Datum/date|to:Bis Datum/date|op:Funktion/iselect,Preis (1-3),Aufschlag,Prozent|value:Wert'
		},
		{ type: 'array', label: 'Liefergebiet-Länder (ISO 639-1):', section: 'webshop', key: 'countries' },

		{ type: 'header', label: 'Zahlungsmodul', section: 'payment' },
		{ type: 'string', label: 'Kunden-Nummer: ', section: 'payment', key: 'custid', level: 1 },
		{ type: 'string', label: 'Terminal-ID (Optional, Komma getrennt: MOTO-TID): ', section: 'payment', key: 'terminalid', level: 1 },
		{ type: 'credentials', label: 'Zahlungsanbieter-Zugangsdaten: ', section: 'payment', key: 'credentials', level: 1 },

		{ type: 'header', label: 'EC-Terminal', section: 'ec' },
		{ type: 'credentials', label: 'Zahlungsanbieter-Zugangsdaten: ', section: 'ec', key: 'credentials', level: 1  },

		{ type: 'header', label: 'Fiskalisierung', section: 'fiscal' },
		{ type: 'dropdown', label: 'Fiskalisierung-Typ:', section: 'fiscal', key: 'type', value: '1', options: '*** Keine ***|AUT-RKSV|DE-Kas­sen­SichV|CH', level: 1 },
		{ type: 'string', label: 'Steuernummer: ', section: 'fiscal', key: 'taxid', level: 1 },
		{ type: 'string', label: 'UID: ', section: 'fiscal', key: 'uid', level: 1 },
		{ type: 'string', label: 'Kassen ID: ', section: 'fiscal', key: 'cashboxid', level: 1 },
		{ type: 'string', label: 'TSE ID (D): ', section: 'fiscal', key: 'tssid', level: 1 },
		{ type: 'credentials', label: 'Zertifikat/TSE-Zugangsdaten: ', section: 'fiscal', key: 'credentials', level: 1 },
		{ type: 'string', label: 'Finanz-Teilnehmer ID (A): ', section: 'fiscal', key: 'wsid', level: 1 },
		{ type: 'credentials', label: 'Finanz-Online Webservice (A): ', section: 'fiscal', key: 'wscredentials', level: 1 },

		{ type: 'header', label: 'Mailversand', section: 'mail' },
		{ type: 'string', label: 'Absender (Anzeigename):', section: 'mail', key: 'sender' },
		{ type: 'string', label: 'Mail zusätzlich senden an (Bcc):', section: 'mail', key: 'bcc' },
		{ type: 'string', label: 'SMTP-Server (Name:Port)', section: 'mail', key: 'smtpserver', level: 1 },
		{ type: 'credentials', label: 'SMTP-Zugangsdaten: ', section: 'mail', key: 'credentials', level: 1 },
		{ type: 'credentials', label: 'Mailjet-Zugangsdaten (ApiKey,SecretKey): ', section: 'mail', key: 'mailjet', level: 1 },
		{ type: 'checkbox', label: 'Benachrichtigung bei neuen Online-Buchungen, bestätigten Angeboten', section: 'mail', key: 'notify', value: false },
		{ type: 'mdropdown', label: 'Automatischer Mailversand', section: 'mail', key: 'auto', options: 'PreCheckin|PostCheckout' },

		{ type: 'header', label: 'Nachrichten', section: 'msg' },
		{ type: 'checkbox', label: 'Konversationen verwenden*', section: 'msg', key: 'chat', value: false, level: 2 },
		{ type: 'checkbox', label: 'Mail-Benachrichtigung bei neuen Nachricht', section: 'msg', key: 'notify', value: false },

		{ type: 'header', label: 'Sicherheit', section: 'global' },
		{ type: 'array', label: 'IP-Adressbereich (öffentlich): ', section: 'global', key: 'iprange', level: 1 },
		{ type: 'array', label: 'IP-Adressbereich Schnittstellen (öffentlich): ', section: 'global', key: 'iprangeif', level: 1 },
		{ type: 'string', label: 'ImgBB.com API-Key:', section: 'global', key: 'imgbb' },
		{ type: 'checkbox', label: 'Zwei-Faktor-Authentifizierung*', section: 'global', key: '2fa', value: false, level: 2 },

		{ type: 'header', label: 'Grundlagen', section: 'global' },
		{ type: 'array', label: 'Adr-Benutzer definierte Felder*: ', section: 'global', key: 'adruserfields', level: 2 },
		{ type: 'array', label: 'Adr-Pflichtfelder*: ', section: 'hotel', key: 'mandatory', level: 2 },
		{ type: 'array', label: 'Anreden: ', section: 'global', key: 'addresses', value: '|Herr|Frau|Firma' },
		{ type: 'array', label: 'Benutzergruppen*: ', section: 'global', key: 'groups', level: 2 },
		{
			type: 'json', label: 'Benutzerrechte*:', section: 'global', key: 'rights',
			options: 'name:Funktion|role:Rolle/idselect,0:Gast,1:User,2:Manager,3:Admin', level: 2
		},
		{
			type: 'json', label: 'Budgets (netto): ', section: 'global', key: 'budgets',
			options: 'year:Jahr|category:Warengruppe/idselect,[categories]|1:Jan./cur|2:Feb./cur|3:März/cur|4:Apr./cur|5:Mai/cur|6:Juni/cur|7:Juli/cur|8:Aug./cur|9:Sep./cur|10:Okt./cur|11:Nov./cur|12:Dez./cur', level: 1
		},
		{ type: 'array', label: 'Farben*:', section: 'global', key: 'colors', options: '/picker', level: 2 },
		{ type: 'label', label: 'Fibu Parameter: ', section: 'global', key: 'fibu', options: 'label:Format (datev;bmd;rzl),Berater Nr.,Mandant,Zwischenkonto,Kostenstelle je Haus (Komma getrennt)|name:Wert' },
		{ type: 'string', label: 'Link Prefix für Mail-Designer: ', section: 'global', key: 'prefix', level: 1 },
		{ type: 'string', label: 'Lokaler Dokumenten-Pfad: ', section: 'global', key: 'docpath', level: 1 },
		{ type: 'json', label: 'Marketing-Tags: ', section: 'global', key: 'adrtags', options: 'name:Bezeichnung|color:Farbe/color' },
		{ type: 'array', label: 'MwSt: ', section: 'global', key: 'vat', level: 1 },
		{ type: 'json', label: 'Nationen: ', section: 'global', key: 'nations', options: 'id:Kurzbezeichnung|name:Name|code:Code|iso2:ISO 2-stellig|iso3:ISO 3-stellig', level: 1 },
		{ type: 'array', label: 'Outlets: ', section: 'global', key: 'outlets', level: 1 },
		{ type: 'json', label: 'Sparten: ', section: 'global', key: 'field', options: 'name:Bezeichnung|color:Farbe/color', level: 1 },
		{ type: 'json', label: 'Warengruppen*: ', section: 'global', key: 'category', options: 'id:ID|name:Name|account:Konto', level: 2 },
		{ type: 'checkbox', label: 'Debug-Mode', section: 'global', key: 'debug'},
		{ type: 'route', label: 'Setup*:', section: 'global', options: '/settings/setup', level: 2 },
		{ type: 'route', label: 'Lizenz*:', section: 'global', options: '/settings/license', level: 2 },
	];

	public static mwControls = [
		{ type: 'header', label: 'Meldewesen', section: 'mw' },
		{ type: 'int', label: 'Nächster Meldeschein:', section: 'mw', key: 'nextid' },
		{ type: 'int', label: 'Höchster Meldeschein:', section: 'mw', key: 'maxid' },
		{ type: 'int', label: 'Gemeinde-Nummer:', section: 'mw', key: 'cityid' },
		{ type: 'string', label: 'Betriebs-Nummer:', section: 'mw', key: 'companyid' },
		{
			type: 'json', label: 'Ortstaxe spezielle Berechnung:', section: 'mw', key: 'taxcalc',
			options: 'op:Funktion/iselect,Aufschlag pro Buchung,Prozent pro Buchung,Fixpreis pro Person (0=Artikelpreis),Prozent pro Person|filter:Filter/select,,[perscat]|name:Betrag'
		},
		{ type: 'credentials', label: 'Meldewesen-Zugangsdaten', section: 'mw', key: 'credentials' },
	];

}
