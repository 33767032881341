import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import '../../_services/date.extensions';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
	FocusDirective, CurrencyInputDirective, NumberInputDirective, DateInputDirective, NumberPercentInputDirective, KeyInputDirective } from '../../_services';
import {
	InputBoxComponent, MsgBoxComponent, PinpadComponent, TipPinpadComponent, ConfirmBoxComponent, TristateCheckboxComponent,
	GetAdrComponent, GetBookingComponent, ColorSelectComponent, IconSnackBarComponent, ColorPickerComponent, ColorPickerDialogComponent, InputDateRangeDialogComponent, InputDateDialogComponent, DateInputComponent,
	InputEmailBoxComponent, ChoosePaymentComponent, SelectDateComponent, ComboInputComponent, AddOnComponent, HouseNameSelectorComponent
} from './tool.components';
import { AdrInputComponent, ArtInputComponent, AccountInputComponent, BookingInputComponent } from './search.components';
import { SafeUrlPipe, SafeHtmlPipe, FloatPipe, AgePipe, DateTimePipe, CurrencyPipeEx, UsernamePipe, FormatValuePipe, UserImagePipe, HighlightPipe, ArrayPipe, TestFlagsPipe, AdrRelationPipe, RecipientPipe, SplitArrayPipe, EllipsisPipe, SafeStrylePipe, TimePipe } from './pipies';
import { RouterModule } from '@angular/router';
import { ArrayBoxComponent, ImageTooltipComponent, ImageTooltipDirective } from './array-box.component';
import { EcStatusComponent } from './ec-status.component';
import { VoucherRedeemComponent } from './voucher.component';
import { DialogDraggableTitleDirective, ShowAuthedDirective, ShowManagerDirective, ShowAdminDirective, ShowUserDirective } from './directives';
import { ImageOptimizeComponent } from './image-optimize.component';
import { InvoiceHeadComponent } from './invoice-head.component';
import { ColorGithubModule } from 'ngx-color/github';
import { MaterialModule } from './shared-material.module';
import { LabelBoxComponent } from './label.box.component';
import { SlideshowComponent } from './slideshow.component';


@NgModule({
	declarations: [
		InputBoxComponent, InputDateDialogComponent, InputDateRangeDialogComponent, InputEmailBoxComponent,
		MsgBoxComponent,
		ConfirmBoxComponent,
		PinpadComponent, TipPinpadComponent,
		DateInputComponent,
		AdrInputComponent, ArtInputComponent, BookingInputComponent, AccountInputComponent,
		TristateCheckboxComponent, ColorSelectComponent, ColorPickerComponent, ColorPickerDialogComponent, IconSnackBarComponent,
		SafeHtmlPipe, SafeUrlPipe, SafeStrylePipe, TestFlagsPipe, FloatPipe, AgePipe, EllipsisPipe, ArrayPipe, SplitArrayPipe, DateTimePipe, TimePipe, CurrencyPipeEx, UsernamePipe, UserImagePipe, AdrRelationPipe, FormatValuePipe, HighlightPipe,
		ImageOptimizeComponent,
		CurrencyInputDirective, NumberInputDirective, NumberPercentInputDirective, DateInputDirective, KeyInputDirective, FocusDirective,
		ShowAuthedDirective, ShowUserDirective, ShowManagerDirective, ShowAdminDirective, DialogDraggableTitleDirective,
		GetAdrComponent,
		GetBookingComponent,
		ChoosePaymentComponent, SelectDateComponent, ComboInputComponent,
		ArrayBoxComponent, ImageTooltipComponent, ImageTooltipDirective, LabelBoxComponent,
		EcStatusComponent,
		VoucherRedeemComponent,
		InvoiceHeadComponent,
		RecipientPipe,
		SlideshowComponent,
		AddOnComponent,
		HouseNameSelectorComponent
	],
	imports: [
		RouterModule,
		FlexLayoutModule,
		FormsModule, ReactiveFormsModule,
		MaterialModule,
		PerfectScrollbarModule,
		ImageCropperModule,
		ColorGithubModule,
		NgxMatSelectSearchModule,
	],
	exports: [
		AdrInputComponent, ArtInputComponent, BookingInputComponent, AccountInputComponent,
		DateInputComponent,
		TristateCheckboxComponent, ColorSelectComponent, ColorPickerComponent, ColorPickerDialogComponent, IconSnackBarComponent,
		VoucherRedeemComponent,
		InvoiceHeadComponent,
		SafeHtmlPipe, SafeUrlPipe, SafeStrylePipe, TestFlagsPipe, FloatPipe, AgePipe, EllipsisPipe, ArrayPipe, SplitArrayPipe, DateTimePipe, TimePipe, CurrencyPipeEx, UsernamePipe, UserImagePipe, AdrRelationPipe, FormatValuePipe, HighlightPipe,
		CurrencyInputDirective, NumberInputDirective, NumberPercentInputDirective, DateInputDirective, KeyInputDirective, FocusDirective,
		FlexLayoutModule,
		FormsModule, ReactiveFormsModule,
		MaterialModule,
		PerfectScrollbarModule,
		ImageCropperModule, ImageOptimizeComponent,
		ShowAuthedDirective, ShowUserDirective, ShowManagerDirective, ShowAdminDirective, DialogDraggableTitleDirective,
		ColorGithubModule,
		ComboInputComponent,
		ArrayBoxComponent, LabelBoxComponent,
		RecipientPipe,
		SlideshowComponent,
		AddOnComponent,
		NgxMatSelectSearchModule,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'de' },
	],
})
export class SharedModule {
}
