import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Booking, DocJournal, MailPara } from '../models/models';
import { ToolService } from './tool.service';

const endpointMail = '/api/mail/';
const endpointDocJournal = '/api/docjournal/';
const endpointExternal = '/api/external/';
const endpointBookings = '/api/bookings/';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};

@Injectable({
	providedIn: 'root'
})
export class MailService {

	constructor(
		private http: HttpClient,
	) { }

	private extractData(res: Response) {
		const body = res;
		return body || {};
	}

	/////////////////////////////////
	// DocJournal
	getDocJournalFilter(fromDate: Date, toDate: Date, filter: string): Observable<DocJournal> {
		return this.http.get<DocJournal>(`${endpointDocJournal}filter/${fromDate.toISODateString()}/${toDate.toISODateString()}/${filter}`);
	}


	getDocJournal(id: number): Observable<DocJournal> {
		return this.http.get<DocJournal>(endpointDocJournal + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	findDocJournal(custID: number, bid: number, text: string): Observable<any> {
		return this.http.get<DocJournal[]>(`${endpointDocJournal}${custID}/${bid}/${text}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getDocJournalPdf(id: number): Observable<HttpEvent<Blob>> {
		return this.http.request(new HttpRequest('GET', `${endpointDocJournal}pdf/${id}`, null, { reportProgress: true, responseType: 'blob' })).pipe(
			catchError(ToolService.handleHttpErrorBlob));
	}

	addDocJournal(dj: DocJournal, bids: number[] = null, newStatus = 0): Observable<any> {

		return this.http.post(`${endpointDocJournal}?bids=${bids?.toString()}&newStatus=${newStatus}`, JSON.stringify(dj), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateDocJournal(id: number, dj: DocJournal): Observable<any> {
		return this.http.put(endpointDocJournal + id, JSON.stringify(dj), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	deleteDocJournal(id: number): Observable<any> {
		return this.http.delete(endpointDocJournal + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	//////////////////////
	// Mails
	renderTemplate(mjmlOrJson: string): Observable<any> {
		return this.http.post(endpointMail + 'render', JSON.stringify(mjmlOrJson), httpOptions).pipe(
			map(this.extractData));
	}

	sendMail(sender: string, recipient: string, body: string, subject: string, saveToHist, attachment: string = null, custID = 0, htmlInsert = ''): Observable<any> {
		const para = {
			sender: sender,
			recipient: recipient,
			subject: subject,
			body: body,
			templateFile: '',
			bids: '',
			custID: custID,
			newStatus: 0,
			saveToHist: saveToHist,
			htmlAttachment: attachment,
			htmlInsert: htmlInsert,
		} as MailPara;

		return this.http.post(endpointMail, JSON.stringify(para), httpOptions).pipe(
			map(this.extractData));
	}

	sendBookingMail(sender: string, recipient: string, body: string, bids: number[], 
		subject: string, saveToHist, attachment: string, custID: number, invID: number): Observable<any> {
		const para = {
			sender: sender,
			recipient: recipient,
			subject: subject,
			body: body,
			templateFile: '',
			bids: bids?.toString(),
			invID: invID,
			custID: custID,
			saveToHist: saveToHist,
			htmlAttachment: attachment,
			htmlInsert: '',
		} as MailPara;

		return this.http.post(endpointMail, JSON.stringify(para), httpOptions).pipe(
			map(this.extractData));
	}

	composeMail(sender: string, recipient: string, body: string, subject: string, saveToHist, attachment: string = null, custID = 0, htmlInsert = ''): Observable<any> {
		const para = {
			sender: sender,
			recipient: recipient,
			subject: subject,
			body: body,
			templateFile: '',
			bids: '',
			custID: custID,
			newStatus: 0,
			saveToHist: saveToHist,
			htmlAttachment: attachment,
			htmlInsert: htmlInsert,
		} as MailPara;

		return this.http.put(endpointMail + 'compose', JSON.stringify(para), httpOptions).pipe(
			map(this.extractData));
	}

	composeMailPara(para: MailPara): Observable<any> {
		return this.http.put(endpointMail + 'compose', JSON.stringify(para), httpOptions).pipe(
			map(this.extractData));
	}

	composeAdrMail(template: string, custID: number): Observable<any> {
		return this.http.put(`${endpointMail}compose/${template}/${-custID}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	composeBookingMail(template: string, refID: string): Observable<any> {
		return this.http.put(`${endpointMail}compose/${template}/${refID}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	composeInvoiceMail(invID: number, custID: number, email: string, sender: string, details: number): Observable<any> {
		return this.http.put(`${endpointMail}invoice/${invID}/${custID}?email=${email}&sender=${sender}&details=${details}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	getMailFields(bidAdrID: number, language: string, invID: number, voucherItemID: string, toAdrType: number): Observable<any> { // > 0 = BID < 0 = adr
		return this.http.get(`${endpointMail}fields/${bidAdrID}?lang=${language}&toAdrType=${toAdrType}&invID=${invID}&voucherItemID=${voucherItemID}`).pipe(
			map(this.extractData));
	}

	calcERVFields(booking: Booking, language: string): Observable<any> {
		return this.http.put(`${endpointMail}erv?lang=${language}`, JSON.stringify(booking), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getMailHtml(template: string, id: number): Observable<any> {
		return this.http.get(`${endpointMail}${template}/${id}`).pipe(
			map(this.extractData));
	}

	getMailPdf(jsonTemplate, filename: string): Observable<HttpEvent<Blob>> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

		return this.http.request(new HttpRequest('PUT', `${endpointMail}pdf?filename=${filename}`,
			JSON.stringify(JSON.stringify(jsonTemplate)), { headers: headers, reportProgress: true, responseType: 'blob' })).pipe(
			catchError(ToolService.handleHttpErrorBlob));
	}

	/////////////////////
	// External
	getDocLink(type: string, id: number, expiration?: Date): Observable<any> {
		return this.http.get(`${endpointExternal}doclink/${type}/${id}${expiration ? '?expiration=' + expiration.toISODateString() : ''}`).pipe(
			catchError(ToolService.handleHttpError));
	}

	/////////////////////
	// Bookings
	getBookingLanguage(bid: number): Observable<any> {
		if (!bid) {
			return new Observable(obs => {
				obs.next({language:''});
				obs.complete();
			});
		}
		return this.http.get(`${endpointBookings}language/${bid}`);
	}



}
