import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { ToolService } from './tool.service';
import { Const } from '../models/constants';

export { };

declare global {
	interface Date {
		toDate(): Date;
		addDays(days: number): Date;
		addMonths(months: number): Date;
		addYears(years: number): Date;
		addHours(h: number): Date;
		addMinutes(m: number): Date;
		datePart(): Date;
		getWeek(): number;

		isToday(): boolean;
		isSameDate(date: Date): boolean;
		isWeekend(): boolean;
		isBirthday(date?: Date): boolean;
		calcAge(dueDate?: Date): number;

		toShortDateString(): string;
		toLocaleDateString(): string;
		toLocaleDateTimeString(): string;

		toISODateString(): string;
		toISOTimeString(): string;
		toISODateTimeString(): string;
	}

	interface DateConstructor {
		today: () => Date;
		weekStart: (year: number, week: number) => Date;
	}
}

Date.today = function (): Date {
	const date = new Date;
	date.setHours(0, 0, 0, 0);
	return date;
};

Date.weekStart = function (year: number, week: number): Date {
	const getZeroBasedIsoWeekDay = date => (date.getDay() + 6) % 7;
	const getIsoWeekDay = date => getZeroBasedIsoWeekDay(date) + 1;

	let days = ((week - 1) * 7) + 1;
	const firstDayOfYear = new Date(year, 0, 1);
	const firstIsoWeekDay = getIsoWeekDay(firstDayOfYear);
	const zeroBasedFirstIsoWeekDay = getZeroBasedIsoWeekDay(firstDayOfYear);

	if (firstIsoWeekDay > 4) {
		days += 8 - firstIsoWeekDay;
	} else {
		days -= zeroBasedFirstIsoWeekDay;
	}

	return new Date(year, 0, days);
};


Date.prototype.toJSON = function () {
	return this.toISOTimeString();
};

Date.prototype.toDate = function (): Date {
	return this;
};

Date.prototype.addDays = function (days: number): Date {
	if (!days) { return this; }
	const date = new Date(this);
	date.setDate(date.getDate() + days);

	return date;
};

Date.prototype.addMonths = function (months: number): Date {
	if (!months) { return this; }
	const date = new Date(this);
	date.setMonth(date.getMonth() + months);

	return date;
};

Date.prototype.addYears = function (years: number): Date {
	if (!years) { return this; }
	const date = new Date(this);
	date.setFullYear(date.getFullYear() + years);

	return date;
};

Date.prototype.addHours = function (h: number) {
	if (!h) { return this; }
	const date = new Date(this);
	date.setTime(this.getTime() + (h * 60 * 60 * 1000));
	return date;
};

Date.prototype.addMinutes = function (m: number) {
	if (!m) { return this; }
	const date = new Date(this);
	date.setTime(this.getTime() + (m * 60 * 1000));
	return date;
};

Date.prototype.datePart = function (): Date {
	const date = new Date(this);
	date.setHours(0, 0, 0, 0);
	return date;
};

Date.prototype.getWeek = function () {
	var onejan = new Date(this.getFullYear(), 0, 1);
	return Math.ceil((((this.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
}

Date.prototype.isToday = function (): boolean {
	const today = new Date();
	return this.isSameDate(today);
};
Date.prototype.isSameDate = function (date: Date): boolean {
	return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};

Date.prototype.isWeekend = function (): boolean {
	return this.getDay() === 0 || this.getDay() === 6;
};

Date.prototype.isBirthday = function (date?: Date): boolean {
	if (!date) { date = new Date(); }
	return this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};

Date.prototype.calcAge = function (dueDate: Date = null): number {
	if (this.getFullYear() < 1900) { return 0; }
	if (!dueDate) { dueDate = Date.today(); }

	let age = dueDate.getFullYear() - this.getFullYear();
	const m = dueDate.getMonth() - this.getMonth();
	if (m < 0 || (m === 0 && dueDate.getDate() < this.getDate())) {
		age--;
	}
	return age;
};

Date.prototype.toLocaleDateString = function (): string {
	if (isNaN(this.getTime()) || this.getFullYear() < 1900) {
		return '';
	}

	return ('0' + this.getDate()).slice(-2) + '.' + ('0' + (this.getMonth() + 1)).slice(-2) + '.' + this.getFullYear();
};

Date.prototype.toLocaleDateTimeString = function (): string {
	if (isNaN(this.getTime()) || this.getFullYear() < 1900) {
		return '';
	}

	return this.toLocaleDateString() + (this.getHours() === 0 && this.getMinutes() === 0 && this.getSeconds() === 0 ? '' : ' ' + ToolService.formatTime(this).substr(0, 5));
};

Date.prototype.toShortDateString = function (): string {
	if (isNaN(this.getTime()) || this.getFullYear() < 1900) {
		return '';
	}

	return Const.weekDays[this.getDay()] + ',' + ('0' + this.getDate()).slice(-2) + '.' + ('0' + (this.getMonth() + 1)).slice(-2) + '.';
};


Date.prototype.toISODateString = function (): string {
	if (isNaN(this.getTime())) {
		return '1899-12-30T00:00:00';
	}
	return this.getFullYear() + '-' + ('0' + (this.getMonth() + 1)).slice(-2) + '-' + ('0' + this.getDate()).slice(-2) + 'T00:00:00';
};

Date.prototype.toISOTimeString = function (): string {
	const tzoffset = this.getTimezoneOffset() * 60000; // offset in milliseconds
	if (isNaN(this.getTime())) { return '';}

	return (new Date(this - tzoffset)).toISOString().slice(0, -5);
};

Date.prototype.toISODateTimeString = function (): string {
	const tzoffset = this.getTimezoneOffset() * 60000; // offset in milliseconds

	return (new Date(this - tzoffset)).toISOString().replace('Z', '');
}



@Injectable()
export class GermanDateAdapter extends NativeDateAdapter {

	constructor() {
		super('de');
	}

	getFirstDayOfWeek(): number {
		return 1;
	}

	format(date: Date, displayFormat: Object): string {
		if (displayFormat === 'DD.MM.') {
			return ToolService.formatDate(date).substr(0, 6);
		} else {
			const year = date.getFullYear();
			if (year < 1900) {
				return '';
			}

			return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + year;
		}
	}

	parse(value: any): Date | null {
		return ToolService.parseDate(value);
	}
}

