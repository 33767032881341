import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToolService, DataService, MessageService } from '../../_services';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { Const } from '../../models/constants';


@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) { }
	transform(value) {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	}
}

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) { }
	transform(value) {
		if (!value) {
			return ''; // need to avoid null
		}
		return this.sanitizer.bypassSecurityTrustResourceUrl(value);
	}
}

@Pipe({ name: 'safeStyle' })
export class SafeStrylePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) { }
	transform(value) {
		if (!value) {
			return ''; // need to avoid null
		}
		return this.sanitizer.bypassSecurityTrustStyle(value);
	}
}

@Pipe({
	name: 'testFlag'
})
export class TestFlagsPipe implements PipeTransform {

	transform(flags: number, value: number): boolean {
		return ToolService.testFlag(flags, value);
	}

}

@Pipe({ name: 'float' })
export class FloatPipe implements PipeTransform {
	constructor() { }
	transform(value) {
		return ToolService.formatNumber(value);
	}
}

@Pipe({ name: 'cur' })
export class CurrencyPipeEx implements PipeTransform {

	transform(value) {
		return ToolService.formatCurrency(value);
	}
}

@Pipe({ name: 'age' })
export class AgePipe implements PipeTransform {
	constructor() { }
	transform(value) {
		const age = new Date(value).calcAge();
		return age ? age + ' Jahre' : '';
	}
}

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {
	constructor() { }
	transform(value: string, charCount: number) {
		if (value.length < charCount) { return value; }
		return value.substr(0, charCount-3) + '...';
	}
}

@Pipe({ name: 'array' })
export class ArrayPipe implements PipeTransform {
	constructor() { }
	transform(value, index) {
		if (!value) { return ''; }
		const arr = value.split('|');

		return (+index >= 0 || +index < arr.length) ? arr[index] : '';
	}
}


@Pipe({ name: 'splitArray' })
export class SplitArrayPipe implements PipeTransform {
	constructor() { }
	transform(value) {
		return typeof value === 'string' ? value.split('|') : value;
	}
}

@Pipe({ name: 'dateTime' })
export class DateTimePipe extends DatePipe implements PipeTransform {
	transform(value: null | undefined, args?: string): null;
	transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;
	transform(value: Date | string | number, args?: string): string | null {
		const d = new Date(value);
		if (d.getFullYear() < 1900) { return ''; }
		return super.transform(value, d.getHours() > 0 ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy');
	}
}

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
	transform(value: Date): string {
		return ToolService.formatTime(value);
	}
}


@Pipe({ name: 'username' })
export class UsernamePipe implements PipeTransform {
	constructor(private dataService: DataService) { }
	transform(value) {
		return this.dataService.getUsername(value);
	}
}

@Pipe({ name: 'userimage' })
export class UserImagePipe implements PipeTransform {
	constructor(private dataService: DataService) { }
	transform(value) {
		return this.dataService.getUserImage(value);
	}
}

@Pipe({ name: 'recipient' })
export class RecipientPipe implements PipeTransform {
	constructor(private messageService: MessageService) { }
	transform(value) {
		return this.messageService.getRecipientName(value);
	}
}

@Pipe({ name: 'relation' })
export class AdrRelationPipe implements PipeTransform {
	transform(value) {
		return Const.relations[value] || '';
	}
}

@Pipe({ name: 'format' })
export class FormatValuePipe implements PipeTransform {
	constructor(private dataService: DataService) { }

	transform(value, type: string) {
		return new Observable<string>(obs => {
			if (value == null) {
				obs.next('');
			} else if (type === 'user' && typeof value === 'number') {
				this.dataService.getUsername(value).subscribe(u => obs.next(u));
			} else {
				obs.next(ToolService.formatValue(value, type));
			}

			obs.complete();
		});
	}
}

@Pipe({	name: 'highlight'})
export class HighlightPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) { }

	escapeHTML(s: string): string {
		return s.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&apos;');
	}

	transform(text: string, search: string, encode = false): SafeHtml {
		if (encode) { text = this.escapeHTML(text); }

		if (search) {
			let pattern = search.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
			pattern = pattern.split(' ').filter(t => {
				return t.length > 0;
			}).join('|');

			const regex = new RegExp(pattern, 'gi');

			text = text.replace(regex, (match) => `<mark>${match}</mark>`);
		}
		return this.sanitizer.bypassSecurityTrustHtml(text);
	}
}
