import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToolService } from './tool.service';
import { KitchenReceipt, Invoice, Booking, IDictionary } from '../models/models';
import { Const } from '../models/constants';
import { ReceiptService } from './receipt.service';

const endpointInvoice = '/api/invoice/';
const endpointMessage = '/api/message/';
const endpointKitchen = '/api/kitchenreceipts/';
const endpointReservation = '/api/reservations/';


const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};

@Injectable({
	providedIn: 'root'
})

export class RestService {


	public static restCommands = [
		{ id: 'closepp', name: 'Abschluss mit EK-Preis', para: 'PLU der Zahlungsart (optional)', icon: 'receipt_long' },
		{ id: 'closeinternal', name: 'Abschluss mit Preis 0', para: 'PLU der Zahlungsart (optional)', icon: 'receipt_long' },
		{ id: 'close', name: 'Abschluss Bar', para: '', icon: 'money' },
		{ id: 'room', name: 'Abschluss auf Zimmer/Konto', para: 'Adr-ID (optional)', icon: 'hotel' },
		{ id: 'payment', name: 'Abschluss mit Zahlungsart', para: 'PLU der Zahlungsart (opt.)', icon: 'receipt_long' },
		{ id: '*', name: 'Alle Artikel der Sparte', para: 'Sparte', noCaption: true },
		{ id: '=', name: 'Artikel/Zahlungsart', para: 'PLU', noCaption: true },
		{ id: 'partition', name: 'Aufteilen', para: '', icon: 'pie_chart_outlined' },
		{ id: 'book', name: 'Änderungen verbuchen', para: '', icon: 'production_quantity_limits' },
		{ id: 'reload', name: 'Änderungen verwerfen', para: '', icon: 'cancel' },
		{ id: 'userreport', name: 'Benutzer-Abrechnung', para: '', icon: 'poll' },
		{ id: 'S', name: 'Button mit Artikeln der Sparte', para: 'Sparte', noCaption: true },
		{ id: 'ec', name: 'EC-Terminal Zahlung', para: 'Tisch abschließen (0/1)', icon: 'phonelink_ring' },
		{ id: 'stockreport', name: 'Fassungsliste', para: '', icon: 'poll' },
		{ id: 'given', name: 'Gegeben', para: 'Rest als Trinkgeld buchen (0/1)', icon: 'autorenew' },
		{ id: 'voucher', name: 'Gutschein einlösen', para: '', icon: 'card_giftcard' },
		{ id: 'small', name: 'Kleine Portion', para: 'Preis(1-3)/Differenz(-)/Rabatt(%)', icon: 'child_care' },
		{ id: 'qplus', name: 'Menge +1', para: '', icon: 'exposure_plus_1' },
		{ id: 'qminus', name: 'Menge -1', para: '', icon: 'exposure_minus_1' },
		{ id: 'quick', name: 'Menükarte erzeugen', para: 'PLU´s, Platzhalter Komma getrennt' },
		{ id: 'loadmenu', name: 'Menükarte laden', para: 'Name der Menükarte', icon: 'menu_book' },
		{ id: 'next', name: 'Nächster Sitzplatz', para: '', icon: 'event_seat' },
		{ id: '/', name: 'Navigation zu Route', para: 'Route', icon: 'navigation' },
		{ id: 'proforma', name: 'Proforma Rechnung', para: '', icon: 'receipt' },
		{ id: 'discount', name: 'Rabatt', para: 'Rabatt-Prozent (optional)', icon: 'local_offer' },
		{ id: 'editinvoice', name: 'Rechnungkopf', para: '', icon: 'playlist_play' },
		{ id: 'text', name: 'Speiseinfo und Gangwechsel', para: '', icon: 'info_outline' },
		{ id: 'scan', name: 'Scanner starten', para: '', icon: 'qr_code_scanner' },
		{ id: 'script', name: 'Script ausführen', para: 'Funktion', icon: 'code' },
		{ id: 'cancel', name: 'Storno', para: 'Mit Text (0/1)', icon: 'cancel' },
		{ id: 'split', name: 'Split', para: 'Direkt auf Subtisch (0/1)', icon: 'call_split' },
		{ id: 'move', name: 'Tisch Umzug', para: '', icon: 'library_books' },
		{ id: 'trans', name: 'Tisch Übergabe', para: '', icon: 'transfer_within_a_station' },
		{ id: 'top', name: 'Top-Seller', para: 'Anzahl', icon: 'trending_up' },
		{ id: 'pinpad', name: 'Ziffernblock anzeigen', para: '', icon: 'dialpad' },
		{ id: 'back', name: 'Zurück', para: '', icon: 'arrow_back' },
	];

	public static tableCommands = [
		{ id: 'none', name: '*** Keines ***', para: '', icon: '' },
		{ id: 'userreport', name: 'Benutzer-Abrechnung', para: '', icon: 'poll' },
		{ id: 'stockreport', name: 'Fassungsliste', para: '', icon: 'table_rows' },
		{ id: 'next', name: 'Gang abrufen', para: 'Gang (leer = auto)', icon: 'skip_next' },
		{ id: 'lastreceipt', name: 'Letzter Bon', para: '', icon: 'receipt' },
		{ id: '/', name: 'Navigation zu Route', para: 'Route', icon: 'navigation' },
		{ id: 'reserve', name: 'Neue Reservierung', para: '', icon: 'event_available' },
		{ id: 'log', name: 'Protokoll anzeigen', para: '', icon: 'assignment' },
		{ id: 'rest', name: 'Restaurant wählen', para: 'Restaurant ID', icon: 'restaurant' },
		{ id: 'scan', name: 'Scanner starten', para: '', icon: 'qr_code_scanner' },
		{ id: 'script', name: 'Script ausführen', para: 'Funktion', icon: 'code' },
		{ id: 'sub', name: 'Sub-Tisch', para: '', icon: '' },
		{ id: 'assign', name: 'Tisch zuweisen', para: '', icon: 'input' },
		{ id: 'tplan', name: 'Tisch-Planer', para: '', icon: 'table_restaurant' },
		{ id: 'print', name: 'Tischplan Drucken', para: '', icon: 'print' },
	];

	public static usedTableColor = '#ff8080'; // '#ff5656';

	public restaurants = [];
	public tableRest: IDictionary = [];

	public lastRoomInvID = 0;
	public lastRoomUserID = 0;
	public lastRoomGrouptext = '';


	constructor(
		private http: HttpClient,
		private tools: ToolService,
		private receipt: ReceiptService,
	) { }

	private static getIndicesOf(str: string, searchStr: string) {
		const searchStrLen = searchStr.length;
		let startIndex = 0, index;
		const indices = [];

		if (searchStrLen) {
			while ((index = str.indexOf(searchStr, startIndex)) > -1) {
				indices.push(index);
				startIndex = index + searchStrLen;
			}
		}
		return indices;
	}

	private extractData(res: Response) {
		const body = res;
		return body || {};
	}

	getTables(): Observable<Invoice[]> {
		return this.http.get<Invoice[]>(endpointInvoice + '1900-1-1/2999-12-31/opentable').pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	openTable(table: string, house: number, persCount: number): Observable<any> {
		return this.http.post(`${endpointInvoice}table/${table.replace(/\+/, '*')}?house=${house || 0}&persCount=${persCount}`, '', httpOptions).pipe(
			map(this.extractData));
	}

	deleteEmptyTable(invID: number): Observable<any> {
		return this.http.delete(`${endpointInvoice}empty/${invID}`, httpOptions);
	}

	closeTable(invID: number, payment: number, outlet: number, width): Observable<any> {
		return this.http.put(`${endpointInvoice}${invID}?receiptWidth=${width}&payment=${payment}&outlet=${outlet}&profitCenter=${Const.pcRestaurant}`, '', httpOptions).pipe(
			map(this.extractData));
	}

	closeTableToAccount(invID: number, targetInvID: number, groupText: string = ''): Observable<any> {
		if (!groupText) {
			groupText = `Restaurant vom ${new Date().toLocaleDateTimeString()}`;
		}

		this.lastRoomInvID = targetInvID;
		this.lastRoomUserID = ToolService.currentUser.id;
		this.lastRoomGrouptext = groupText;

		const width = ReceiptService.getPrinterWidth(ReceiptService.pidReceipt);
		return this.http.put(`${endpointInvoice}${invID}/${targetInvID}?groupText=${encodeURIComponent(groupText)}&receiptWidth=${width}`, '', httpOptions).pipe(
			map(this.extractData));
	}

	partitionTable(table: number, partitionCount: number): Observable<any> {
		return this.http.post(`${endpointInvoice}partition/${table}/${partitionCount}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	moveInvoice(invID: number, newInvID: number): Observable<any> {
		return this.http.put(`${endpointInvoice}move/${invID}/${newInvID}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	getTableLabels() {
		return new Observable<any>(obs => {
			const result = this.tools.settingObj('restaurant', 'tablelabels');
			result.subscribe(data => {
				obs.next(data);
				obs.complete();
			});
		});
	}

	async getRestaurant(table: string|number): Promise<number> {
		if (!this.restaurants.length) {
			this.restaurants = await this.tools.settingObj('restaurant', 'restaurants').toPromise();
		}

		if (!table) { return 0; }
		const tableID = +(('' + table).replace('+', ''));

		if (!this.tableRest.length) {
			for (let i = 0; i < this.restaurants.length; i++) {
				if (!this.restaurants[i].name) { continue; }
				const fileStore = await this.tools.getFile(`rest${i + 1}.svg`).toPromise()
					.catch(() => { });

				if (fileStore) {
					const restID = +fileStore.name.split('.')[0].substr(4) - 1;
					if (isNaN(restID)) { return; }

					const svg = fileStore.content;
					const tablePos = RestService.getIndicesOf(svg, 'data-table-id');
					tablePos.forEach(pos => {
						const table1 = svg.substr(pos + 15);
						const i1 = table1.indexOf('"');
						const tableID1 = +table1.substr(0, i1);
						this.tableRest[tableID1] = restID;
					});
				}
			}
		}

		const restaurantID = this.tableRest[Math.floor(+tableID || 0)] || 0;
		return restaurantID;
	}

	/////////////////////////////////
	// Message
	sendBroadcast(cmd: string, data): Observable<any> {
		return this.http.post(`${endpointMessage}broadcast/${cmd}`, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}


	/////////////////////////////////
	// Reservations
	getReservations(start: Date, end: Date = null, house = -1): Observable<Booking[]> {
		if (!end) { end = start }
		return this.http.get<Booking[]>(`${endpointReservation}${start.toISODateTimeString()}?endDate=${end.toISODateTimeString() }&house=${house}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	assignTable(bid: number, table: number): Observable<any> {
		return this.http.put(`${endpointReservation}table/${bid}/${table}`, null, httpOptions).pipe(
			map(this.extractData));
	}


	getReservation(id: number): Observable<Booking> {
		return this.http.get<Booking>(endpointReservation + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateReservation(id: number, reservation: Booking, confirmation: boolean): Observable<any> {
		return this.http.put(`${endpointReservation}${id}?confirmation=${confirmation}`, reservation, httpOptions).pipe(
			map(this.extractData));
	}

	addReservation(reservation: Booking, confirmation: boolean): Observable<any> {
		return this.http.post(`${endpointReservation}?confirmation=${confirmation}`, reservation, httpOptions).pipe(
			map(this.extractData));
	}

	deleteReservation(id: number): Observable<any> {
		return this.http.delete(endpointReservation + id, httpOptions);
	}

	/////////////////////////////////
	// KitchenReceipt
	getKitchenReceipts(slipID: number): Observable<KitchenReceipt[]> {
		return this.http.get<KitchenReceipt[]>(`${endpointKitchen}?slipID=${slipID}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getKitchenReceipt(id: number): Observable<KitchenReceipt> {
		return this.http.get<KitchenReceipt>(endpointKitchen + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getKitchenReceiptHist(slipID: number): Observable<any> {
		return this.http.get(`${endpointKitchen}history?slipID=${slipID}`);
	}

	updateKitchenReceipt(id: number, receipt: KitchenReceipt): Observable<any> {
		return this.http.put(endpointKitchen + id, receipt, httpOptions).pipe(
			map(this.extractData));
	}

	setKitchenReceiptStatus(ids: number[], status: number): Observable<any> {
		return this.http.put(`${endpointKitchen}status/${ids.toString()}/${status}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	getKitchenReceiptStatus(invID: number): Observable<any> {
		return this.http.get<KitchenReceipt>(`${endpointKitchen}status/${invID}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	setKitchenReceiptStatusInvID(invID: number, status: number, course = -1): Observable<any> {
		return this.http.put(`${endpointKitchen}status/${invID}/${course}/${status}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	undoKitchenReceiptStatus(invID: number, course: number): Observable<any> {
		return this.http.delete(`${endpointKitchen}status/${invID}/${course}`);
	}

	setKitchenReceiptSort(ids: number[]): Observable<any> {
		return this.http.put(`${endpointKitchen}sort/${ids.toString()}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	setKitchenReceiptTableColor(invID: number, color: string): Observable<any> {
		return this.http.put(`${endpointKitchen}color/${invID}/${color.replace('#', '')}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	addKitchenReceipts(receipts: KitchenReceipt[]): Observable<any> {
		return this.http.post(endpointKitchen, receipts, httpOptions).pipe(
			map(this.extractData));
	}

	deleteKitchenReceipts(slipID: number): Observable<any> {
		return this.http.delete<any>(`${endpointKitchen}?slipID=${slipID}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}
	/*
	async printOrderReceipt(tableID: number, course: string) {
		const printerAddr = ReceiptService.getPrinter(ReceiptService.pidOrder);
		if (!printerAddr || printerAddr === 'none') { return; }

		const cr = '\r\n';

		let s = '';
		const width = ReceiptService.getPrinterWidth(ReceiptService.pidOrder);
		const restaurantID = await this.getRestaurant(tableID);
		const restaurant = this.restaurants[restaurantID]?.name;
		s = cr + cr + cr + ToolService.fix('h1:Tisch ' + tableID + ' ' + restaurant, width / 2) + cr;
		s += '-'.repeat(width) + cr;
		s += 'h1:Abruf ' + ToolService.fix(course, (width - 14) / 2) + cr;
		s += '-'.repeat(width) + cr + ToolService.fix(ToolService.currentUser?.name, width - 16) + new Date().toLocaleDateTimeString() + cr + 'be:';
		this.receipt.printReceipt(ReceiptService.pidOrder, s, false);
	}
	*/
	/*
	async printFinishReceipt(slipItems: KitchenReceipt[], defPrinterID: number) {
		if (slipItems.length === 0) { return; }

		const courses = await this.tools.settingArray('restaurant', 'course').toPromise();
		const cr = '\r\n';
		const printerAddr = ReceiptService.getPrinter(defPrinterID);
		if (!printerAddr || printerAddr === 'none') { return; }
		const autoPrinters = printerAddr === 'auto';
		const restaurantID = await this.getRestaurant(slipItems[0].table);

		// print to receipt printer
		const items = slipItems.sort((a, b) => {
			if (a.course === b.course) {
				return a.artName.localeCompare(b.artName)
			}
			return a.course > b.course ? 1 : -1;
		});


		for (let logicalPrinterID = ReceiptService.pidReceipt; logicalPrinterID <= ReceiptService.kitchenPrinters.length + ReceiptService.pidReceipt; logicalPrinterID++) {

			const printerID = ReceiptService.mapPrinter(autoPrinters ? logicalPrinterID : defPrinterID, restaurantID)
			const width = ReceiptService.getPrinterWidth(printerID);
			let s = '';
			let count = 0;

			const printItems = items.filter(item => (autoPrinters ? item.printSlip > 0 : item.printSlip === logicalPrinterID) && item.quantity !== 0 && item.status !== Const.krCancel);
			if (!printItems.length) { continue; }

			const restaurant = this.restaurants[restaurantID]?.name;
			s = cr + cr + cr + ToolService.fix('h1:Tisch ' + printItems[0].table + ' ' + restaurant, width / 2) + cr;
			s += 'b:' + courses[printItems[0].course] + cr;
			s += '-'.repeat(width) + cr;

			for (let i = 0; i < printItems.length; i++) {
				const item = printItems[i];
				s += 'h2:' + ToolService.fix('' + item.quantity, 6) + ToolService.fix(item.artName, width - 6) + cr;
				count++;
			}

			if (count > 0) {
				s += '-'.repeat(width) + cr + ToolService.fix(ToolService.currentUser?.name, width - 16) + new Date().toLocaleDateTimeString() + cr + 'be:';
				this.receipt.printReceipt(printerID, s, false);
			}

			if (!autoPrinters) { return; }
		}

	}
	*/

	printFinishReceipt(slipItems: KitchenReceipt[], defPrinterID: number) {
		const printerAddr = ReceiptService.getPrinter(defPrinterID);
		if (!printerAddr || printerAddr === 'none') { return; }
		const autoPrinters = printerAddr === 'auto';

		this.printKitchenReceipts(slipItems, false, autoPrinters ? ReceiptService.pidNone : defPrinterID);
	}


	async printKitchenReceipts(slipItems: KitchenReceipt[], addToScreen = true, defPrinterID = ReceiptService.pidNone) {
		const kpAuto = 0;
		const kpPrinter = 1;
		const kpDisplay = 2;

		if (slipItems.length === 0) { return; }

		// map printers
		const restaurantID = await this.getRestaurant(slipItems[0].table);
		for (let i = 0; i < slipItems.length; i++) {
			const item = slipItems[i];
			const printerID = ReceiptService.mapPrinter(item.printSlip, restaurantID);
			item.printSlip = printerID;
		}

		if (addToScreen && this.tools.hasModule('kitchen')) {
			const displayPrinters = [];
			for (let i = 0; i < ReceiptService.kitchenPrinters.length; i++) {
				if (ReceiptService.kitchenPrinters[i].print !== kpPrinter) { displayPrinters.push(i + 2); }
			}

			// do not add receipt printer items
			const kitchenItems = slipItems.filter(s => displayPrinters.indexOf(s.printSlip) >= 0);
			if (kitchenItems.length > 0) {
				const result = this.addKitchenReceipts(kitchenItems);
				result.subscribe(() => { }, err => this.tools.showError(err));
			}
		}

		// print to receipt printer
		const items = slipItems.sort((a, b) => {
			if (a.course === b.course) {
				return a.artName.localeCompare(b.artName) 
			}
			return a.course > b.course ? 1 : -1;
		});

		const courses = await this.tools.settingArray('restaurant', 'course').toPromise();
		const cr = '\r\n';


		// 1 = Receipt
		// 2 = kitcen 1,...
		for (let printerID = ReceiptService.pidPass; printerID <= ReceiptService.kitchenPrinters.length + ReceiptService.pidReceipt; printerID++) {

			// if not add to screen, print always to printer (Tellerbon)
			if (addToScreen && printerID >= ReceiptService.pidKitchen1 && ReceiptService.kitchenPrinters[printerID - ReceiptService.pidKitchen1].print === kpDisplay) {
				continue;
			}

			if (printerID === 0) { continue; }

			if (defPrinterID > ReceiptService.pidNone) { printerID = defPrinterID; }

			const width = ReceiptService.getPrinterWidth(printerID);

			// do not print empty pass printer
			const printerAddr = ReceiptService.getPrinter(printerID);
			if (printerID === ReceiptService.pidPass && !printerAddr) { continue; } 

			let s = '';
			let old = null;
			let count = 0;

			const printAll = printerID === ReceiptService.pidPass || defPrinterID > ReceiptService.pidNone;
			const printItems = items.filter(item => printAll ? item.printSlip > 0 && item.course > 0 : item.printSlip === printerID);

			for (let i = 0; i < printItems.length; i++) {
				const item = printItems[i];

				if (item.quantity === 0) { continue; }

				// Neuer Gang
				if (old && old.course !== item.course) {
					if (ReceiptService.kitchenPrinters.length <= 1) {
						s += '-'.repeat(width) + cr + ToolService.fix(ToolService.currentUser.name, width - 16) + new Date().toLocaleDateTimeString() + cr + 'be:';
						this.receipt.printReceipt(printerID, s, false);

						old = null;
					} else {
						s += cr + 'b:' + courses[item.course] + cr;
						s += '-'.repeat(width) + cr;
					}
				}

				// Header, start receipt
				if (!old) {
					const restaurant = this.restaurants[restaurantID]?.name;
					const caption = defPrinterID == ReceiptService.pidOrder ? 'Abruf Tisch' : 'Tisch'

					s = cr + cr + cr + 'h1:' + ToolService.fix(`${caption} ${item.table % 1000} ${restaurant}`, width / 2) + cr;
					s += 'b:' + courses[item.course] + cr;
					s += '-'.repeat(width) + cr;
				}
				old = item;

				if (item.status === Const.krCancel) {
					s += '   ** STORNO **' + cr;
				}

				s += 'h2:' + ToolService.fix('' + item.quantity, 6) + ToolService.fix(item.artName, width - 6) + cr;

				// Seat / Text
				const text = ((item.seats ? 'Sitz ' + item.seats + ' ' : '') + item.text.replace('|', ' ')).trim();
				if (text.length < width - 10 - 10) {
					s += ToolService.fix('      ' + text, width - 10) + ToolService.fix(ToolService.formatCurrency(item.price), -10);
				} else {
					const regEx = new RegExp(`.{1,${ width - 6 }}`, 'g');
					const chunks = text.match(regEx);
					chunks.forEach(c => s += '      ' + c + cr);
					s += ToolService.fix(ToolService.formatCurrency(item.price), -width);
				}
				s += cr;
				count++;
			}

			if (count > 0) {
				s += '-'.repeat(width) + cr + ToolService.fix(ToolService.currentUser?.name, width - 16) + new Date().toLocaleDateTimeString() + cr + 'be:';
				this.receipt.printReceipt(printerID, s, false);
			}

			if (defPrinterID > ReceiptService.pidNone) { break; }

		}

	}
}
