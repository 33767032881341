import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from './data.service';
import { Adr, Article, Invoice, Journal, KeyValuePair } from '../models/models';
import { InvoiceHeadComponent } from '../modules/shared/invoice-head.component';
import { ToolService } from './tool.service';
import { catchError, Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { EcStatusComponent } from '../modules/shared/ec-status.component';

const endpointJournal = '/api/journal/';
const endpointEC = '/api/ec/';
const endpointPayment = '/api/payment/';
const endpointInvoice = '/api/invoice/';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};

/* ========================================================================= */
@Injectable()
export class FinanceService {

	private static cardCodes = [ 'AX', 'BC', 'BL', 'CB', 'DN', 'DS', 'EC', 'JC', 'LC', 'MA', 'MC', 'SO', 'CU', 'TP', 'VE', 'VI' ];
	private static cardNames = [ 'American Express', 'Bank Card', 'Carte Bleu', 'Carte Blanche', 'Diners Club', 'Discover Card', 'Eurocard', 'Japanese Credit', 'Local Card', 'Maestro',
		'Master Card', 'Solo', 'Union Pay', 'Universal Air Travel', 'Visa Electron', 'Visa' ];



	constructor(
		private http: HttpClient,
		private dialog: MatDialog,
		private tools: ToolService,
		private dataService: DataService,
	) {
	}

	public static getCCInfo(adr: Adr) {
		if (!adr || !adr.ccToken) { return ''; }

		const arr = adr.ccToken.split('|');
		const index = FinanceService.cardCodes.indexOf(arr[4]);
		const brand = index >= 0 ? FinanceService.cardNames[index] : '';
		const number = 'xxxx-xxxx-xxxx-' + arr[1].substr(arr[1].length - 4, 4);
		const type = arr[0] === 'ccredit' ? ' (Terminal)' : ''

		return `Gespeicherte ${brand} ${number} vom ${arr[6]}${type}`;
	}

	lockMonth(): Observable<any> {
		return this.http.put(`${endpointJournal}lockMonth`, null, httpOptions);
	}

	unlockMonth(): Observable<any> {
		return this.http.delete(`${endpointJournal}lockMonth`);
	}

	/////////////////////////////////
	// Invoice
	exportFIBU(fromDate: Date, toDate: Date): Observable<HttpEvent<Blob>> {
		return this.http.request(new HttpRequest('GET', `${endpointInvoice}export/${fromDate.toISODateString()}/${toDate.toISODateString()}`, null, { reportProgress: true, responseType: 'blob' })).pipe(
			catchError(ToolService.handleHttpErrorBlob));
	}

	/////////////////////////////////
	// EC
	getTerminalID(): number {
		const terminalID = +localStorage.getItem('ecTerminal');
		if (!terminalID) {
			this.tools.showError('Es ist kein EC-Terminal in den Einstellungen konfiguriert.');
			return 0;
		}

		const terminalIDs = this.tools.license.terminalIDs.split('|').map(i => +i);
		if (!terminalIDs.includes(terminalID)) {
			this.tools.showError(`Es kann keine Lizenz für das Terminal (TID: ${terminalID}) gefunden werden.`);
			return 0;
		}

		return terminalID;
	}

	ecCloseDay(): Observable<any> {
		const terminalID = this.getTerminalID();
		if (!terminalID) { return; }
		return this.http.post(`${endpointEC}closeday/${terminalID}`, null, httpOptions);
	}

	getECReceipt(jid: number): Observable<any> {
		return this.http.get(`${endpointEC}receipt/${jid}`);
	}

	ecPay(invID: number, amount: number, outlet: number, profitCenter: number, successFunction?: Function, errorFunction?: Function, userID = 0, custID: number = 0, useToken: boolean = false): Observable<any> {
		return new Observable(obs => {
			const terminalID = this.getTerminalID();
			if (!terminalID) {
				if (errorFunction) { errorFunction(); }
				obs.complete();
				return;
			}

			const dialogRef = this.dialog.open(EcStatusComponent, { width: '600px', maxWidth: '100vw', disableClose: true, data: { amount: amount } });
			dialogRef.afterClosed().subscribe(success => {
				if (!success) {
					if (errorFunction) { errorFunction(); }
					return;
				}

				if (successFunction) { successFunction(); }
				obs.complete();
			});

			const isMyPos = localStorage.getItem('myPOS') === '1';
			if (isMyPos) {
				sessionStorage.setItem('ecpay', '1'); // do not close table during pay
				const cents = ToolService.round(amount * 100, 2);
				location.href = `https://app/mypos/P/${invID}/${cents}/${userID}`;

				setTimeout(() => {
					dialogRef.componentInstance.status = 'Transaktion aktiv...';
					dialogRef.componentInstance.canClose = true;
				}, 300);
			} else {
				if (!this.tools.hasModule('ec', true)) { return; }

				sessionStorage.setItem('ecpay', '1'); // do not close table during pay
				const payInfo = { invID: invID, amount: amount, outlet: outlet, profitCenter: profitCenter, terminalID: terminalID, custID: custID, useToken: useToken };
				this.http.post(endpointEC, payInfo, httpOptions).subscribe(data => {
					// do NOT call success fn. because this will close invoice
					obs.next(data);
					obs.complete();
				}, err => {
					/*
					dialogRef.close();
					this.tools.showError(err);
					*/
					if (errorFunction) { errorFunction(); }
				});
			}
		});
	}

	/*
	ecPay(invID: number, amount: number, outlet:number, successFunction?: Function, errorFunction?: Function, userID = 0, custID: number = 0, useToken: boolean = false): Observable<any>{
		return new Observable(obs => {
			const terminalID = this.getTerminalID();
			if (!terminalID) {
				if (errorFunction) {
					errorFunction();
				}
				obs.complete();
				return;
			}

			const dialogRef = this.dialog.open(EcStatusComponent, { width: '600px', maxWidth: '100vw', disableClose: true, data: { amount: amount } });

			const isMyPos = localStorage.getItem('myPOS') === '1';
			if (isMyPos) {
				dialogRef.afterClosed().subscribe(success => {
					obs.complete();

					if (!success) {
						if (errorFunction) { errorFunction(); }
						return;
					}
					if (successFunction) { successFunction(); }
				});

				sessionStorage.setItem('ecpay', '1'); // do not close table during pay
				const cents = ToolService.round(amount * 100, 2);
				location.href = `https://app/mypos/P/${invID}/${cents}/${userID}`;

				setTimeout(() => {
					dialogRef.componentInstance.status = 'Transaktion aktiv...';
					dialogRef.componentInstance.canClose = true;
				}, 300);
			} else {
				if (!this.tools.hasModule('ec', true)) { return; }

				sessionStorage.setItem('ecpay', '1'); // do not close table during pay
				const payInfo = { invID: invID, amount: amount, text: '', outlet:outlet, terminalID: terminalID, custID: custID, useToken: useToken };
				this.http.post(endpointEC, payInfo, httpOptions).subscribe(data => {
					if (successFunction) { successFunction(); }
					obs.next(data);
					obs.complete();
				}, err => {
					dialogRef.close();
					this.tools.showError(err);
					if (errorFunction) { errorFunction(); }
					obs.complete();
				});
			}
		});

	}

	*/
	async ecReversal(journal: Journal, successFunction?: Function): Promise<any> {

		const terminalID = this.getTerminalID();
		if (!terminalID) {
			return new Promise(resolve => resolve(null));
		}

		const dialogRef = this.dialog.open(EcStatusComponent, { width: '600px', maxWidth: '100vw', data: { amount: -journal.price } });
		dialogRef.afterClosed().subscribe(success => {
			if (success && successFunction) {
				successFunction();
			}
			return new Promise(resolve => resolve(null));
		});

		const isMyPos = localStorage.getItem('myPOS') === '1';
		if (isMyPos === false) {
			return this.http.delete(`${endpointEC}${terminalID}/${journal.id}`).toPromise();
		}


		// mypos Terminal
		const receipt = await this.getECReceipt(journal.id).toPromise()
			.catch(err => this.tools.showError(err));

		const rows: string = receipt.receipt.split('\r\n');
		const dateTime = rows[0].substr(10).trim().split(' ');
		const d1 = ToolService.parseDate(dateTime[0]);
		const d2 = ToolService.parseTime(dateTime[1]);
		const date = `${d1.getFullYear() % 100}${d1.getMonth() + 1}${d1.getDate()}${d2.getHours()}${d2.getMinutes()}${d2.getSeconds()}`;
		const auth = rows[6].substring(10).trim().split(' ')[0];
		const stan = -journal.bid;

		location.href = `https://app/mypos/C/${stan}/${auth}/${date}`;
		return new Promise(resolve => resolve(null));
	}

	ecMyPOSResult(data: KeyValuePair[]): Observable<any> {
		return this.http.post(endpointEC + 'mypos', data, httpOptions);
	}

	createPayment(invID: number, amount: number, custID: number) {
		return this.http.post(`${endpointPayment}transaction/${invID}/${amount}/${custID}`, null, httpOptions);
	}


	editInvoiceHead(invID: number, successFunction?: Function): Promise<Invoice> {
		return new Promise<Invoice>(async (resolve, reject) => {
			let invoice: Invoice = await this.dataService.getInvoice(invID).toPromise();
			if (!invoice) {
				const house = +localStorage.getItem('house');
				invoice = await this.dataService.createInvoice(invID, 0, house).toPromise();
			}

			const rows = new Array<string>(6);
			rows[0] = invoice.adr1;
			rows[1] = invoice.adr2;
			rows[2] = invoice.adr3;
			rows[3] = invoice.adr4;
			rows[4] = invoice.adr5;
			rows[5] = invoice.adr6;

			const dialogRef = this.dialog.open(InvoiceHeadComponent,
				{ minWidth: '50%', data: { custID: invoice.custID, rows: rows, form: invoice.form, house: invoice.house, subject: invoice.subject, remark: invoice.remark } });

			const dlgResult = dialogRef.afterClosed();
			dlgResult.subscribe(data => {
				if (!data) {
					resolve(null);
					return;
				}

				// Compress rows
				for (let i = 1; i < 6; i++) {
					if (data.rows[i] === '') {
						for (let j = i; j < 5; j++) {
							data.rows[j] = data.rows[j + 1];
						}
						data.rows[5] = '';
					}
				}

				invoice.custID = data.custID;
				invoice.adr1 = data.rows[0];
				invoice.adr2 = data.rows[1];
				invoice.adr3 = data.rows[2];
				invoice.adr4 = data.rows[3];
				invoice.adr5 = data.rows[4];
				invoice.adr6 = data.rows[5];
				invoice.subject = data.subject;
				invoice.remark = data.remark;
				invoice.house = data.house;
				invoice.form = data.form;

				const result = this.dataService.updateInvoiceHead(invID, invoice);
				this.tools.handleResult(result, successFunction);
				resolve(invoice);
			});
		});
	}

}
