import { Component, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { AuthenticationService } from './authentication.service';
import { ToolService } from './tool.service';
import { SignalRUser } from '../models/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

const peerConnectionConfig = {
	iceServers: [
		{ urls: 'stun:stun.l.google.com:19302' },
		{ urls: 'stun:stun.gmx.de:3478' },
		{ urls: 'turn:focloud.sitec.com', username: 'focloud1', credential: 'd1vOrs_Br' }
	],
};

/*
 const peerConnectionConfig = {
	sdpSemantics: 'plan-b',
	iceServers: [
		{ urls: 'stun:stun.l.google.com:19302' },
		{ urls: 'stun:stun.gmx.de:3478' },
		{ urls: 'stun:stun.schlund.de:3478' },
		{ urls: 'turn:relay.backups.cz', username: 'webrtc', credential: 'webrtc' }
	],
};
*/

@Component({
	selector: 'app-internet-offline',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable class="center" style="background-color: #ef9a9a;">Internet offline</h4>
<mat-dialog-content class="center" >
	<b>Die Internet-Verbindung wurde unterbrochen</b>
	<p>Neue Verbindungsversuche werden im Hintergrund durchgeführt.</p>
	<button mat-raised-button color="primary" *ngIf="showReload" (click)="onOk()">Neu laden</button>
</mat-dialog-content>
`,
	styles: ['.center { text-align: center;}']
})
export class OfflineComponent {
	public showReload = false;

	constructor(
		public dialogRef: MatDialogRef<OfflineComponent>,
	) {
		setTimeout(() => this.showReload = true, 10000);
	}

	onOk() {
		window.location.reload();
	}
}

type PascalToCamelCase<S extends string> = Uncapitalize<S>

@Injectable({
	providedIn: 'root'
})
export class SignalRService {
	private _hubConnection: signalR.HubConnection;
	private _warningBox: MatDialogRef<OfflineComponent>;

	public connections: SignalRUser[] = [];
	public connectionChange = new BehaviorSubject<SignalRUser[]>([]);
	public videoConnections: SignalRUser[] = [];
	public othersOnline = new BehaviorSubject<string>('');
	public online = false;

	constructor(
		private tools: ToolService,
		private dialog: MatDialog,
	) {
	}


	static getLocalIp() {
		return new Promise((resolve, reject) => {
			if (typeof window.RTCPeerConnection === 'undefined') {
				return reject('WebRTC not supported by browser');
			}

			const pc = new RTCPeerConnection();
			const ips = [];

			pc.createDataChannel('');
			pc.createOffer()
				.then(offer => pc.setLocalDescription(offer))
				.catch(err => reject(err));

			pc.onicecandidate = event => {
				if (!event || !event.candidate) {
					if (ips.length === 0) {
						return reject('WebRTC disabled or restricted by browser');
					}

					return resolve(ips[0]);
				}

				const parts = event.candidate.candidate.split(' ');
				const [base, componentId, protocol, priority, ip, port, , type, ...attr] = parts;

				if (!ips.some(e => e === ip)) {
					ips.push(ip);
				}
			};
		});
	}

	private createBlackSilince(): MediaStream {
		const canvas = document.createElement('canvas') as HTMLCanvasElement;
		const ctx = canvas.getContext('2d');
		ctx.fillStyle = '#e0e0e0';
		ctx.fillRect(0, 0, 1, 1);

		return (canvas as any).captureStream();
	}

	public async startConnection() {
		const dbName = AuthenticationService.getDbName();
		const url = dbName ? `https://focloud-schulung.azurewebsites.net/${dbName}/api/signalr` : 'https://focloud-schulung.azurewebsites.net/api/signalr';

		this._hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(url)
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: retryContext => {
					if (retryContext.elapsedMilliseconds < 5000) { return 500; }
					else if (retryContext.elapsedMilliseconds < 60000) { return 1000; } // 1 min
					else if (retryContext.elapsedMilliseconds < 900000) { return 5000; } // 15 min
					return null;
				}
			})
			.configureLogging(this.tools.isDebug ? signalR.LogLevel.Debug : signalR.LogLevel.Error)
			.build();

		await this._hubConnection.start()
			.then(() => this.loadUsers())
			.catch(err => console.error('SignalR: Error while starting connection: ' + err));

		this._hubConnection.on('ReceiveOffer', async (offer, connectionID) => this.onReceiveOffer(offer, connectionID));
		this._hubConnection.on('ReceiveAnswer', async (answer, connectionID) => this.onReceiveAnswer(answer, connectionID));
		this._hubConnection.on('AddIceCandidate', (iceCandidate, connectionID) => this.onAddIceCandidate(iceCandidate, connectionID));
		this._hubConnection.on('PeerHasLeft', (connectionID) => this.onPeerHasLeft(connectionID));
		this._hubConnection.on('AllUsers', (userList) => this.onAllUsers(userList));

		this._hubConnection.onreconnecting(error => {
			this._warningBox = this.dialog.open(OfflineComponent, { width: '100vw', maxWidth: '100vw', height: '100vh' });
		});

		this._hubConnection.onreconnected(connID => {
			if (this._warningBox) {
				this._warningBox.close();
				this._warningBox = null;
			}
		});
	}

	public async stopConnection() {
		if (!this._hubConnection) { return; }

		this._hubConnection.off('ReceiveOffer');
		this._hubConnection.off('ReceiveAnswer');
		this._hubConnection.off('AddIceCandidate');
		this._hubConnection.off('PeerHasLeft');
		this._hubConnection.off('AllUsers');

		await this._hubConnection.stop().then(() => this._hubConnection = null);
	}

	public get connection() {
		return this._hubConnection;
	}

	public async connect(room: string, userName: string, canvasID: string, videoID: string, route: string): Promise<SignalRUser> {
		return new Promise<SignalRUser>(async (resolve, reject) => {
			let connection = this.connections.find(c => c.connectionID === this._hubConnection.connectionId);
			if (connection && (canvasID == null || connection.canvasID === canvasID) && (videoID == null || connection.canvasID === videoID) &&
				(route == null || connection.route === route)) {
				resolve(connection);
				return;
			}

			if (this._hubConnection.state !== signalR.HubConnectionState.Connected) {
				reject('Verbindung ist nicht hergestellt.');
				return;
			}

			if (!connection) {
				connection = { roomID: room, connectionID: this._hubConnection.connectionId, local: true } as SignalRUser;
				this.connections.push(connection);
				this.connectionChange.next(this.connections);
			}

			if (canvasID) { connection.canvasID = canvasID; }
			if (videoID) { connection.videoID = videoID; }
			if (userName) { connection.userName = userName; }
			if (route) { connection.route = route; }
			const ip = localStorage.getItem('localIP');

			await this._hubConnection.invoke('Connect', room, userName, canvasID, videoID, route, ip).catch(err => console.error(err));
			resolve(connection);
		});
	}

	public async disconnect() {
		this._hubConnection.invoke('Disconnect', this._hubConnection.connectionId).catch(err => console.error(err));
		this.onPeerHasLeft(this._hubConnection.connectionId);
		this.online = false;
	}

	public async setUserInfo(room: string, userName: string, route: string): Promise<SignalRUser> {
		return new Promise<SignalRUser>(async (resolve, reject) => {
			if (this._hubConnection.state !== signalR.HubConnectionState.Connected) {
				console.warn('SignalR-connection in invalid state:', this._hubConnection.state);
				reject('Verbindung ist nicht hergestellt.');
				return;
			}

			// nopthing changed ?
			let connection = this.connections.find(c => c.connectionID === this._hubConnection.connectionId);
			if (connection && (route == null || connection.route === route)) {
				resolve(connection);
				return;
			}

			// new conenction
			if (!connection) {
				connection = await this.connect(room, userName, null, null, route);
				console.log(`SignalR-connection registered, ${userName} @ ${route}`);
			}

			// Update connection
			const ip = localStorage.getItem('localIP');
			await this._hubConnection.invoke('SetUserInfo', room, userName, route, ip).catch(err => console.error(err));

			resolve(connection);
		});
	}

	onPeerHasLeft(connectionID: string) {
		const index = this.connections.findIndex(x => x.connectionID === connectionID);
		if (index >= 0) {
			this.connections.splice(index, 1);
		}
	}

	onAllUsers(userList: SignalRUser[]) {
		const connections: SignalRUser[] = [];
		let newPeer = false;

		for (let i = 0; i < userList.length; i++) {
			const user = userList[i];

			let peering = this.connections.find(x => x.connectionID === user.connectionID);
			if (!peering) {
				peering = { connectionID: user.connectionID } as SignalRUser;
				if (user.videoID || user.canvasID) { newPeer = true; }
			}
			connections.push(peering);

			peering.roomID = user.roomID;
			peering.userName = user.userName;
			peering.canvasID = user.canvasID;
			peering.videoID = user.videoID;
			peering.route = user.route;
			peering.localIP = user.localIP;
			peering.publicIP = user.publicIP;
		}

		this.connections = connections;
		this.connectionChange.next(this.connections);

		this.videoConnections = connections.filter(c => c.videoID || c.canvasID);
		this.othersOnline.next(this.videoConnections.map(c => c.userName).toString());
		this.online = !!this.videoConnections.find(c => c.local);

		if (newPeer) {
			this.tools.playAudio('low-beep.mp3');
		}


	}

	private async loadUsers() {
		const str = await this._hubConnection.invoke('GetAllActiveConnectionsInRoom', 'FORoom');
		const otherUsers = JSON.parse(str) as SignalRUser[];

		let others = '';
		otherUsers.forEach(peering => others = ToolService.append(others, peering.userName, ', '));
		this.othersOnline.next(others);
	}

	public async initVideoconference(display: boolean) {
		if (!ToolService.currentUser) { return; }

		let videoStream: MediaStream;
		let canvasStream: MediaStream;

		if (navigator.mediaDevices) {
			const constraints = {
				'video': true,
				'audio': {
					echoCancellation: true,
					noiseSuppression: true,
				}
			};

			try {
				videoStream = await navigator.mediaDevices.getUserMedia(constraints);
			} catch (err) {
				console.error(err);
			}

			if (display) {
				try {
					canvasStream = await (<any>navigator.mediaDevices).getDisplayMedia(constraints);
				} catch (err) {
				}
			}
		} else {
			this.tools.showError('Dieser Browser unterstützt kein Medien-API.');
			return;
		}

		const videoID = !videoStream && !canvasStream ? '*' : videoStream?.id;
		const connection = await this.connect('FORoom', ToolService.currentUser.name, canvasStream?.id, videoID, null);
		connection.videoStream = videoStream;
		connection.canvasStream = canvasStream;

		try {
			const str = await this._hubConnection.invoke('GetAllActiveConnectionsInRoom', connection.roomID);
			const allCurrentUserInRoom = JSON.parse(str);
			for (let index = 0; index < allCurrentUserInRoom.length; index++) {
				const signalRuser = allCurrentUserInRoom[index] as SignalRUser;

				let peering = this.connections.find(x => x.connectionID === signalRuser.connectionID);
				if (!peering) {
					peering = { connectionID: signalRuser.connectionID, userName: signalRuser.userName } as SignalRUser;
					this.connections.push(peering);
					this.connectionChange.next(this.connections);
				}

				await this.createPeerConnection(peering);

				const offer = await peering.rtcConnection.createOffer();
				await peering.rtcConnection.setLocalDescription(offer);

				await this._hubConnection.invoke('SendOffer', JSON.stringify(offer), signalRuser.connectionID);
				if (this.tools.isDebug) { console.log('STEP 1: offer sent and inserted as local description. ' + signalRuser.connectionID); }
			}
		} catch (err) {
			this.tools.showError(err);
		}
	}

	public async quitVideoconference() {
		this.connections.forEach(connection => {
			if (connection.videoStream) {
				connection.videoStream.getTracks().forEach(track => track.stop());
				connection.videoStream = null;
			}

			if (connection.canvasStream) {
				connection.canvasStream.getTracks().forEach(track => track.stop());
				connection.canvasStream = null;
			}
		});

		this.connect('FORoom', null, '', '', null);
	}

	private createPeerConnection(remoteUser: SignalRUser) {
		remoteUser.rtcConnection = new RTCPeerConnection(peerConnectionConfig);

		const myConnection = this.connections.find(x => x.connectionID === this._hubConnection.connectionId);
		if (!myConnection) { return; }

		const videoStream = myConnection.videoStream;
		if (videoStream) {
			videoStream.getTracks().forEach(track => {
				remoteUser.rtcConnection.addTrack(track, videoStream);
			});
		} else {
			remoteUser.rtcConnection.addTrack(this.createBlackSilince().getVideoTracks()[0]);
		}

		const canvasStream = myConnection.canvasStream;
		if (canvasStream) {
			canvasStream.getTracks().forEach(track => {
				remoteUser.rtcConnection.addTrack(track, canvasStream);
			});
		} else {
			remoteUser.rtcConnection.addTrack(this.createBlackSilince().getVideoTracks()[0]);
		}

		remoteUser.rtcConnection.onicecandidate = (event) => this.onDetectIceCandidate(event, remoteUser.connectionID);
		remoteUser.rtcConnection.ontrack = (event) => this.onGotRemoteStream(event, remoteUser.connectionID);
	}

	// Peer connection events
	onDetectIceCandidate(event, remoteconnectionID) {
		if (!event.candidate) { return; }

		const candidate = new RTCIceCandidate(event.candidate);
		this._hubConnection.invoke('SendIceCandidate', JSON.stringify(candidate), remoteconnectionID);
		/* if (this.tools.isDebug) {
			console.log('Ice candidate sent: ' + remoteconnectionID);
			console.log(event.candidate);
		}
		*/
	}

	async onGotRemoteStream(event: RTCTrackEvent, remoteconnectionID) {
		if (event.track.kind !== 'video') { return; }

		const peering = this.connections.find(x => x.connectionID === remoteconnectionID);
		if (!peering) {
			console.error('Peer not found: ' + remoteconnectionID);
			return;
		}
		if (this.tools.isDebug) { console.log(`OnTrack received with ${event.track.kind} stream: ${event.streams[0]?.id} ${event.streams[0]?.id === peering.canvasID ? '(Canvas)' : ''} from ${remoteconnectionID}`); }

		if (event.streams[0]?.id === peering.canvasID) {
			peering.canvasStream = event.streams[0];
		} else if (!peering.videoStream) {
			peering.videoStream = event.streams[0];
		}
	}

	// Server events
	async onReceiveOffer(offer, connectionID) {
		if (this.tools.isDebug) { console.log('STEP 2: offer received ' + connectionID); }
		offer = new RTCSessionDescription(JSON.parse(offer));

		let peering = this.connections.find(x => x.connectionID === connectionID);
		if (!peering) {
			peering = { connectionID: connectionID } as SignalRUser;
			this.connections.push(peering);
			this.connectionChange.next(this.connections);
			this.tools.playAudio('low-beep.mp3');
		}

		this.createPeerConnection(peering);

		// insert the offer as the remote description
		await peering.rtcConnection.setRemoteDescription(offer);

		// ONLY THEN we create answer
		const answer = await peering.rtcConnection.createAnswer();
		await peering.rtcConnection.setLocalDescription(answer);
		await this._hubConnection.invoke('SendAnswer', JSON.stringify(answer), connectionID);
		if (this.tools.isDebug) { console.log('STEP 3: answer sent and inserted as local description. ' + connectionID); }
	}

	async onReceiveAnswer(answer, connectionID: string) {
		const peering = this.connections.find(x => x.connectionID === connectionID);

		// insert the answer as the remote description
		answer = new RTCSessionDescription(JSON.parse(answer));
		await peering.rtcConnection.setRemoteDescription(answer);

		if (this.tools.isDebug) { console.log('STEP 4: answer received. ' + connectionID); }
	}

	onAddIceCandidate(iceCandidate, connectionID: string) {
		iceCandidate = JSON.parse(iceCandidate);
		iceCandidate = new RTCIceCandidate(iceCandidate);

		const peering = this.connections.find(x => x.connectionID === connectionID);
		if (!peering) {
			console.error('Remote User not found ' + connectionID);
			return;
		}

		peering.rtcConnection.addIceCandidate(iceCandidate);
		/*if (this.tools.isDebug) {
			console.log('Ice candidate added: ' + connectionID);
			console.log(iceCandidate);
		}
		*/
	}




}
