import { Component, forwardRef, Input, AfterViewInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, UntypedFormControl } from '@angular/forms';
import { DataService, ToolService } from '../../_services';
import { switchMap } from 'rxjs/operators';
import { SearchResult, Article, Adr } from '../../models/models';
import { Const } from '../../models/constants';
import { Subscription } from 'rxjs';

@Component({
	selector: 'fo-adr-input',
	template: `
		<mat-form-field class="full-width" [subscriptSizing]="toolbar ? 'dynamic' : 'fixed'">
			<mat-label>{{ labelID }}</mat-label>
			<input matInput [matAutocomplete]="auto" [formControl]="control" 
				(blur)="onBlur()" (focus)="onFocus($event)" spellcheck="false" #input id='fo-adr-input'>
			<button matSuffix mat-icon-button type="button" matTooltip="Klick zum Bearbeiten Strg+Klick für neue Adresse" (click)="onEdit($event.ctrlKey || $event.metaKey)" tabindex="-1" [disabled]="!canEdit">
				<mat-icon>edit</mat-icon>
			</button>
			<mat-hint *ngIf="!toolbar">
				<span *ngIf="blacklist" class="blacklist"><mat-icon class="icon12" style="margin-top:-2px;">dangerous</mat-icon>&nbsp;Blacklist&nbsp;</span>
				{{ remark }}
			</mat-hint>
		</mat-form-field>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setValue($event.option.value)">
			<mat-option *ngFor="let adr of filteredData" [value]="adr">
				{{ adr2String(adr) }}
			</mat-option>
		</mat-autocomplete>
	`,
	styles: [
		'.adr-input{padding: 0}',
		'.blacklist {color:red;}',
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AdrInputComponent),
			multi: true
		}
	]
})
export class AdrInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

	@Input()
	label: string;

	@Input()
	value: number;

	@Input()
	toolbar = false;

	@Input()
	usage: string;

	@Input()
	adrtype = -1;

	@Input()
	bid = 0;

	private disabled = false;
	private changeSubscription: Subscription;

	public remark = '';
	public blacklist = false;
	public labelID: string;
	public canEdit = true;

	control = new FormControl('');
	filteredData: Adr[] = [];

	@ViewChild('input') elInput: ElementRef;

	constructor(
		public dataService: DataService,
		public tools: ToolService,
	) {
		this.labelID = this.label;

		if (ToolService.currentUser.role < Const.roleManager &&
			ToolService.currentUser.groups.toLowerCase().indexOf('rezeption') < 0 &&
			ToolService.currentUser.groups.toLowerCase().indexOf('bacoffice') < 0) {
			this.canEdit = false;
		}
	}

	// Function prototypes
	onChange = (value: number) => { };
	onTouched = () => { };

	ngAfterViewInit() {
		this.changeSubscription = this.control.valueChanges.pipe(
			switchMap(searchString => this.dataService.findAdr(searchString, this.adrtype))
		).subscribe(data => {
			this.filteredData = data;
		});
	}

	ngOnDestroy() {
		this.changeSubscription.unsubscribe();
	}

	adr2String(adr: Adr, allInfo = true): string {
		if (!adr) { return ''; }

		let text = adr.name;
		text = ToolService.append(text, adr.firstname);
		text = ToolService.append(text, adr.name1);
		if (allInfo && adr.agencyName) { text = ToolService.append(text, `(${adr.agencyName})`); }
		text = ToolService.append(text, adr.city, ', ');
		if (allInfo && adr.country && adr.country !== ToolService.country) { text += `(${adr.country})`; }

		return text;
	}

	setDisplayVal(adr: Adr) {
		let displayVal = '';
		let remark = '';
		let blacklist = false;
		let labelID = this.label;

		if (adr) {
			displayVal = this.adr2String(adr, false);
			switch (this.usage) {
				case 'wellness':
					remark = adr.remarkSpa?.split(/\r?\n/)[0];
					break;
				case 'rest':
				case 'restaurant':
					remark = adr.remarkRest?.split(/\r?\n/)[0];
					break;
				default:
					remark = adr.remark?.split(/\r?\n/)[0];
			}
			labelID = `${this.label} (ID:${adr.id})`;

			//tooltip = `${adr.name} ${adr.firstname}\n${adr.name1} ${adr.agencyName || ''}\n${adr.street} ${adr.street1}\n${adr.zipcode}-${adr.city}`;
			blacklist = adr.blacklist;
		}

		this.blacklist = blacklist;
		this.remark = remark;
		this.labelID = labelID;
		this.control.patchValue(displayVal, { emitEvent: false });
	}

	setValue(adr: Adr) {
		if (this.filteredData.length === 0) { return; } // HACK fix for doubleclick in roomplan over guest field

		this.value = adr.id;

		this.onTouched();
		this.onChange(this.value);
		this.setDisplayVal(adr);

		this.control.markAsTouched();
	}

	async writeValue(id) {
		if (id) {
			await this.dataService.getAdr(id).toPromise()
				.then(adr => this.setDisplayVal(adr))
				.catch(() => this.control.patchValue('*** Unbekannt (' + id + ') ***', { emitEvent: false }));
			this.value = id;
		} else {
			this.setDisplayVal(null)
		}
	}

	async onFocus(e) {
		this.filteredData = [];
		e.target.select();
	}

	onBlur() {
		if (this.control.value === '' && !!this.value) { // !!value = against double click
			this.value = 0;
			this.onChange(0);
			this.setDisplayVal(null)
		}
	}

	async onEdit(newAdr: boolean) {
		const id = +this.value > 0 ? +this.value : (this.adrtype > 0 ? -this.adrtype : 0);
		const data = { id: id, search: this.control.value };

		const resultID = await this.tools.openDialog('base/adrdialog', newAdr ? {} : data);
		if (resultID) {
			await this.writeValue(resultID);
			this.onChange(this.value);
		}
	}

	focus() {
		setTimeout(() => this.elInput.nativeElement.focus());
	}

	registerOnChange(fn: (_: number) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (isDisabled) {
			this.control.disable();
		} else {
			this.control.enable();
		}
	}
}


@Component({
	selector: 'fo-art-input',
	template: `
		<mat-form-field class="full-width" [style.width]="width" [floatLabel]="floatLabel">
			<mat-label>{{ label }}</mat-label>
			<input matInput [matAutocomplete]="auto" [formControl]="control" (blur)="onBlur()" (focus)="onFocus($event)" id='fo-art-input'>
			<button mat-icon-button matSuffix type="button" *ngIf="showEdit === true"  (click)="onEdit()" [disabled]="!value" tabindex="-1">
				<mat-icon>edit</mat-icon>
			</button>
		</mat-form-field>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setValue($event.option.value)">
			<mat-option *ngFor="let art of filteredData" [value]="art">
				{{ art2String(art) }}
			</mat-option>
		</mat-autocomplete>
	`,
	styles: [],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ArtInputComponent),
			multi: true
		}
	]
})
export class ArtInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

	@Input()
	label: string;

	@Input()
	value: number;

	@Input()
	width: string;

	@Input()
	usage: string;

	@Input()
	floatLabel: string;

	@Input()
	showEdit = true;

	private disabled = false;
	private changeSubscription: Subscription;

	control = new FormControl('');
	filteredData = [];
	constructor(
		private dataService: DataService,
		private tools: ToolService,
	) { }

	// Function prototypes
	onChange = (value: number) => { };
	onTouched = () => { };

	ngAfterViewInit() {
		let usage = 0;
		switch (this.usage) {
			case 'hotel':
				usage = 1;
				break;
			case 'rest':
			case 'restaurant':
				usage = 2;
				break;
			case 'wellness':
				usage = 3;
				break;
		}

		this.changeSubscription = this.control.valueChanges.pipe(
			switchMap(searchString => this.dataService.findArticle(searchString, usage))
		).subscribe(data => {
			this.filteredData = data;
		});
	}

	ngOnDestroy() {
		this.changeSubscription.unsubscribe();
	}

	art2String(art: Article) {
		let text = art.name;
		if (art.id > 0 && art.price > 0 && art.flagVariablePrice === false) {
			text += ` (${ToolService.formatNumber(DataService.getArticlePrice(art, Date.today())) })`;
		}

		return text;
	}

	setValue(art: Article) {
		this.value = art.id;

		this.onTouched();
		this.onChange(this.value);
		this.control.patchValue(art.name, { emitEvent: false });
	}

	async writeValue(id) {
		let displayVal = '';
		if (id) {
			await this.dataService.getArticle(+id).toPromise()
				.then(art => displayVal = art.name)
				.catch(() => displayVal = '*** Unbekannt (' + id + ') ***');
		}

		this.value = id;
		this.control.patchValue(displayVal, { emitEvent: false });
	}

	onFocus(e) {
		this.filteredData = [];
		e.target.select();
	}

	onBlur() {
		if (this.control.value === '' && !!this.value) { // !!value = against double click
			this.value = 0;
			this.onChange(0);
			this.control.patchValue('', { emitEvent: false });
		}
	}


	async onEdit() {
		const result = await this.tools.openDialog('base/artdialog', +this.value);
		if (result != null) {
			await this.writeValue(result);
			this.onChange(this.value);
		}
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (isDisabled) {
			this.control.disable();
		} else {
			this.control.enable();
		}
	}

}

@Component({
	selector: 'fo-booking-input',
	template: `
		<mat-form-field class="full-width" [style.width]="width" [floatLabel]="floatLabel">
			<mat-label>{{ label }}</mat-label>
			<input matInput [matAutocomplete]="auto" [formControl]="control" (blur)="onBlur()" (focus)="onFocus($event)">
			<button *ngIf="showEdit === true" matSuffix mat-icon-button type="button" (click)="onEdit()" [disabled]="!value" tabindex="-1">
				<mat-icon>edit</mat-icon>
			</button>
		</mat-form-field>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setValue($event.option.value)">
			<mat-option *ngFor="let data of filteredData" [value]="data">
				{{ data.text }}
			</mat-option>
		</mat-autocomplete>
	`,
	styles: [],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => BookingInputComponent),
			multi: true
		}
	]
})
export class BookingInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

	@Input()
	label: string;

	@Input()
	value: number;

	@Input()
	width: string;

	@Input()
	floatLabel: string;

	@Input()
	showEdit = true;

	private disabled = false;
	private changeSubscription: Subscription;

	control = new UntypedFormControl(0);
	filteredData: SearchResult[] = [];

	constructor(
		private dataService: DataService,
		private tools: ToolService,
	) { }

	// Function prototypes
	onChange = (value: number) => { };
	onTouched = () => { };

	ngAfterViewInit() {
		this.changeSubscription = this.control.valueChanges.pipe(
			switchMap(searchString => this.dataService.getSearchResult(searchString, 'booking'))
		).subscribe(data => {
			this.filteredData = data;
		});
	}

	ngOnDestroy() {
		this.changeSubscription.unsubscribe();
	}

	setValue(result: SearchResult) {
		this.value = result.id;

		this.onTouched();
		this.onChange(this.value);
		this.control.patchValue(result.text, { emitEvent: false });
	}


	async writeValue(bid) {
		let displayVal = '';

		if (bid) {
			await this.dataService.getSearchResult('' + bid, 'booking').toPromise()
				.then(s => displayVal = s[0].text)
				.catch(() => displayVal = '*** Unbekannt (' + bid + ') ***');

			this.value = bid;
		} else {
			this.value = 0;
        }
		this.control.patchValue(displayVal, { emitEvent: false });
	}

	onBlur() {
		if (this.control.value === '' && !!this.value) { // !!value = against double click
			this.value = 0;
			this.onChange(0);
			this.control.patchValue('', { emitEvent: false });
		}
	}

	onFocus(e) {
		this.filteredData = [];
		e.target.select();
	}

	async onEdit() {
		const result = await this.tools.openDialog('hotel/bookingdialog', +this.value);
		if (result) {
			await this.writeValue(result);
			this.onChange(this.value);
		}
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (isDisabled) {
			this.control.disable();
		} else {
			this.control.enable();
		}
	}

}


@Component({
	selector: 'fo-account-input',
	template: `
		<mat-form-field class="full-width" [subscriptSizing]="subscriptSizing">
			<mat-label>{{ label }}</mat-label>
			<input matInput [matAutocomplete]="auto" [formControl]="control" (blur)="onBlur()" (focus)="onFocus($event)" spellcheck="false">
			<button *ngIf="showEdit === true" matSuffix mat-icon-button type="button" (click)="onEditClicked()" tabindex="-1" [disabled]="!canEdit">
				<mat-icon>edit</mat-icon>
			</button>
			<mat-icon matPrefix class="icon">{{ value?.icon }}</mat-icon>
			<mat-hint>{{ hint }}</mat-hint>
		</mat-form-field>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setValue($event.option.value)">
			<mat-option *ngFor="let data of filteredData" [value]="data" [class.locked]="data.flags === 1">
				<mat-icon>{{data.icon}}</mat-icon>{{ data.text}}
			</mat-option>
		</mat-autocomplete>
	`,
	styles: [
		'.icon {font-size: 24px !important; height: 24px !important;width: 24px !important;	margin-right:4px;}',
		'.locked {color: #808080}'
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AccountInputComponent),
			multi: true
		}
	]
})
export class AccountInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

	@Input()
	label: string;

	@Input()
	subscriptSizing = '';

	@Input()
	value: SearchResult;

	@Input()
	showEdit = true;

	@Input()
	searchOptions = 'booking adr';

	@Input()
	hint = '';

	private disabled = false;
	private changeSubscription: Subscription;

	control = new FormControl('');
	filteredData: SearchResult[] = [];
	canEdit = true;

	constructor(
		private dataService: DataService,
		private router: Router,
		private tools: ToolService,
	) {
		const user = ToolService.currentUser;
		if (user?.role < Const.roleManager && user.groups.toLowerCase().indexOf('rezeption') < 0 && user.groups.toLowerCase().indexOf('backoffice') < 0) {
			this.canEdit = false;
		}
	}

	// Function prototypes
	onChange = (value: SearchResult) => { };
	onTouched = () => { };

	ngAfterViewInit() {
		this.changeSubscription = this.control.valueChanges.pipe(
			switchMap(searchString => this.dataService.getSearchResult(searchString, this.searchOptions))
		).subscribe(data => {
			this.filteredData = data;
		});
	}

	ngOnDestroy() {
		this.changeSubscription.unsubscribe();
	}

	setValue(result: SearchResult) {
		this.value = result;

		this.onTouched();
		this.onChange(this.value);
		this.control.patchValue(result.text, { emitEvent: false });
	}

	async writeValue(searchResult: SearchResult) {
		let displayVal = '';

		if (searchResult) {
			if (!searchResult.text) {
				const result = await this.dataService.getSearchResult('' + searchResult.id, searchResult.type === Const.searchTypeBooking ? 'booking' : 'adr').toPromise();
				if (result.length > 0) { searchResult = result[0]; }
			}

			this.value = searchResult;
			displayVal = searchResult.text;
		}

		this.value = searchResult;
		this.control.patchValue(displayVal, { emitEvent: false });
	}


	onFocus(e) {
		this.filteredData = [];
		e.target.select();
	}

	onBlur() {
		if (this.control.value === '' && !!this.value) { // !!value = against double click
			this.value = null;
			this.onChange(null);
			this.control.patchValue('', { emitEvent: false });
		}
	}

	async onEditClicked() {
		if (!this.value || this.value.type === Const.searchTypeAdr) {

			const data = { id: this.value?.id, search: this.control.value };
			const result = await this.tools.openDialog('base/adrdialog', data);
			if (!result) { return; }

			const adr = await this.dataService.getAdr(+result).toPromise();
			this.value = {
				text: `${adr.name} ${adr.firstname}, ${adr.city}`,
				route: '/base/adr/' + result,
				icon: 'person_outline',
				id: adr.id,
				type: Const.searchTypeAdr,
				custID: adr.id,
			} as SearchResult;

			await this.writeValue(this.value);
			this.onChange(this.value);
		} else {
			this.router.navigateByUrl(this.value.route);
		}
	}


	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (isDisabled) {
			this.control.disable();
		} else {
			this.control.enable();
		}
	}
}

