import { Location } from '@angular/common';
import { OnInit } from '@angular/core';
import { Component, OnDestroy } from '@angular/core';
import { Const } from '../../models/constants';
import { DataService, FinanceService, SignalRService, ToolService } from '../../_services';

@Component({
	selector: 'app-ec-slave',
	template: `
<div class="touch-container" ngClass.xs="touch-container-xs">
	<div fxLayout>
		<div fxFlex></div>
		<div><mat-icon (click)="location.back()">clear</mat-icon></div>
	</div>
	<div class="caption" [class.error]="statusError">{{ status }}</div>
	<div class="footer ">{{ tid }}</div>
</div>`,
	styles: [
		'.caption { width:100%; text-align:center; padding-top:3em; font-size: 24px; }',
		'.footer { font-size: 0.6em; line-height: 0.6em; position: absolute; left:.5em; bottom:.5em; }',
		'.error { color: red; }'
	]
})

export class ECSlaveComponent implements OnInit, OnDestroy {
	status = 'Warte auf Terminal';
	statusError = true;
	tid = '';

	constructor(
		private dataService: DataService,
		private signalRService: SignalRService,
		private financeService: FinanceService,
		private tools: ToolService,
		public location: Location,
	) { }

	ngOnInit() {

		const tid = +localStorage.getItem('ecTerminal');
		if (tid > 0) {
			this.tid = 'TID: ' + tid;
		}

		const timerID = setInterval(() => {
			if (localStorage.getItem('myPOS') === '1') {
				this.showMessage('EC-Terminal bereit');
				clearInterval(timerID);
			}
			if (!this.tools.hasModule('ec')) {
				this.showMessage(`Das Modul 'ec' ist nicht installiert`, true);
				return;
			}
		}, 500); 

		this.signalRService.connection.on('ecCommand', async(data) => {
			const terminalID = this.financeService.getTerminalID();

			if (+data.deviceID !== terminalID) { return; }

			if (data.route === 'P') {
				const amount = ToolService.round(data.data1 / 100, 2);
				this.showMessage('Zahlung ' + ToolService.formatCurrency(amount));

				this.financeService.ecPay(data.id, amount, 0, Const.pcHotel, (data1) => this.paymentDone(data1), () => this.paymentDone(), data.userID).subscribe();
			} 
			if (data.route === 'C') {
				const journal = await this.dataService.getJournal(data.id).toPromise()
					.catch(err => this.tools.showError(err));
				if (!journal) { return; }

				this.showMessage('Storno ' + ToolService.formatCurrency(journal.price));

				this.financeService.ecReversal(journal, (data1) => this.paymentDone(data1));
			} 
		});

	}

	ngOnDestroy() {
		this.signalRService.connection.off('ecCommand');
	}

	showMessage(message: string, isError = false) {
		this.status = message;
		this.statusError = isError;
    }

	paymentDone(data = null) {
		this.showMessage('EC-Terminal bereit');
	}
}
