import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Message, Recipient, MessageRecipient, IDictionary } from 'app/models/models';
import { ToolService } from './tool.service';
import { DataService } from './data.service';
import { SwPush } from '@angular/service-worker';
import { Const } from '../models/constants';

const endpointMessage = '/api/message/';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};

interface MsgReadStatus {
	hash: number;
	exist: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class MessageService {

	constructor(
		private http: HttpClient,
		private tools: ToolService,
		private dataService: DataService,
		private swPush: SwPush,
	) {
		const mr = localStorage.getItem('messages-read');
		if (mr) {
			const hashes = JSON.parse(mr);
			this._messagesRead = hashes.map(h => ({ hash: h, exist: false }));
		}
	}
	public static statusText = ['offen', 'in Arbeit', 'erledigt', 'in Arbeit von'];
	public static messageTypeText = ['Nachricht', 'Aufgabe', 'Trace'];


	public static mtMessage = 0;
	public static mtTask = 1;
	public static mtTrace = 2;
	public static mtChat = 3;

	public static msOpen = 0;
	public static msWork = 1;
	public static msDone = 2;
	public static msOther = 3;

	private static _crcTable = [];

	public allRecipientList: Recipient[];
	private _messagesRead: MsgReadStatus[] = [];
	private _recipientCache: IDictionary = [];

	private static makeCRCTable() {
		let c;
		for (let n = 0; n < 256; n++) {
			c = n;
			for (let k = 0; k < 8; k++) {
				c = ((c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
			}
			this._crcTable.push(c);
		}
	}

	static crc32(str: string): number {
		if (!this._crcTable.length) {
			this.makeCRCTable();
		}
		let crc = 0 ^ (-1);

		for (let i = 0; i < str.length; i++) {
			crc = (crc >>> 8) ^ this._crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
		}

		return (crc ^ (-1)) >>> 0;
	}


	/////////////////////////////////
	// Helpers
	init(): Observable<any> {
		return new Observable(obs => {
			this.getAllRecipients().subscribe(list => {
				this.allRecipientList = list;
				obs.next(true);
				obs.complete();
			});
		});
	}


	getMessageStatus(message: Message): number {
		if (message == null || message.type === MessageService.mtMessage) {
			return -1;
		}

		const recipient = this.getRecipient(message);
		if (recipient == null) { return -1; }

		return recipient.status;
	}


	getRecipient(message: Message, userID = 0): MessageRecipient {
		if (message == null || message.recipientList == null) {
			return null;
		}

		if (userID === 0) {
			userID = ToolService.currentUser?.id;
		}

		return message.recipientList.find(mr => message.type === MessageService.mtChat || mr.userID === userID);
	}

	/////////////////////////////////
	// Messages
	getMessages(filter: string): Observable<Message[]> {
		return this.http.get<Message[]>(`${endpointMessage}filter/${filter}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getMessage(id: number): Observable<Message> {
		return this.http.get<Message>(endpointMessage + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getLatestMessages(): Observable<Message[]> {
		return this.http.get<Message[]>(endpointMessage + 'latest').pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	addMessage(data: Message): Observable<any> {
		return this.http.post(endpointMessage, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateMessage(id: number, data: Message): Observable<any> {
		data.id = id;
		return this.http.put(endpointMessage + id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	sendMessage(messageID: number): Observable<any> {
		return this.http.put(endpointMessage + 'send/' + messageID, null, httpOptions);
	}

	deleteMessage(id: number): Observable<any> {
		return this.http.delete(endpointMessage + id, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	setStatus(id: number, status: number): Observable<any> {
		return this.http.put(endpointMessage + 'status/' + id + '/' + status, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}


	/////////////////////////////////
	// Recipients
	updateRecipient(id, data: MessageRecipient): Observable<any> {
		data.id = id;
		return this.http.put(endpointMessage + 'recipient/' + id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	/////////////////////////////////
	// Infos

	getCurrentUserName() {
		return ToolService.currentUser?.name || 'n/a';
	}

	getAllMessageStatus(message: Message) {
		if (message == null || message.type === MessageService.mtMessage || !message.recipientList) {
			return -1;
		}

		let status = MessageService.msOpen;
		for (let i = 0; i < message.recipientList.length; i++) {
			const r = message.recipientList[i];

			if (r.status === MessageService.msWork) {
				status = r.userID === ToolService.currentUser.id ? MessageService.msWork : MessageService.msOther;
			}

			if (r.status === MessageService.msDone) {
				status = MessageService.msDone;
				break;
			}
		}

		return status;
	}

	getAllMessageStatusText(message: Message): Observable<string> {
		if (!message || message.type === MessageService.mtMessage || !message.recipientList) {
			return new Observable(obs => obs.next(''));
		}

		let userID = 0;
		let status = MessageService.msOpen;
		for (let i = 0; i < message.recipientList.length; i++) {
			const r = message.recipientList[i];

			if (r.status === MessageService.msWork) {
				status = r.userID === ToolService.currentUser.id ? MessageService.msWork : MessageService.msOther;
				userID = r.userID;
			}

			if (r.status === MessageService.msDone) {
				status = MessageService.msDone;
				userID = r.userID;
				break;
			}
		}
		return new Observable(obs => {
			this.getRecipientName(userID).subscribe(userName => {
				let text = status < 0 ? '' : MessageService.statusText[status]
				text += userID !== 0 && userID !== ToolService.currentUser.id ? ' von ' + userName : '';
			});
		});
	}

	getRecipientName(nameOrID: number|string): Observable<string> {
		let name = '*** Unbekannt ***';
		let r: Recipient;
		return new Observable(obs => {
			if (typeof (nameOrID) === 'string') {
				r = this.allRecipientList.find(r => r.name === nameOrID);
			}

			const id = + nameOrID;
			if (id > 0) {
				r = this.allRecipientList.find(r => r.id === id);
			}

			if (id < 0) {
				const cachedName = this._recipientCache[id];
				if (cachedName) {
					obs.next(cachedName);
					obs.complete();
					return;
				}

				const result = this.http.get<any>(endpointMessage + 'recipient/' + id);
				result.subscribe(data => {
					this._recipientCache[id] = data.name;
					obs.next(data.name);
					obs.complete();
				}, () => {
					obs.next(name);
					obs.complete();
				});
			} else {
				if (r) { name = r.name; }
				obs.next(name);
				obs.complete();
			}

		});
	}


	findRecipients(search: string | Recipient): Observable<Recipient[]> {
		let recipients = [];
		return new Observable(obs => {
			if (typeof search === 'string') {
				if (search) {
					recipients = this.allRecipientList.filter(recipient => recipient.name.toLowerCase().startsWith(search.toLowerCase()) === true);
				}

				if (search.length > 2) {
					const result = this.dataService.findAdr(search, -1, 20);
					result.subscribe(addrs => {
						addrs.forEach(adr => {
							recipients.push({ name: `${adr.name} ${adr.firstname}, ${adr.city}`, id: -adr.id, members: '', color: '' } as Recipient);
						});
						obs.next(recipients);
						return;
					});
				}
			}
			obs.next(recipients);
		});
	}

	findRecipient(search: string): Recipient {
		if (search == null || typeof search !== 'string') {
			return null;
		}

		return this.allRecipientList.find(recipient => recipient.name.toLowerCase() === search.toLowerCase());
	}


	isCreator(message: Message): boolean {
		if (!message) { return false; }
		return message.creationUser === ToolService.currentUser.id;
	}

	isUnread(message: Message, userID?: number) {
		const recipient = this.getRecipient(message, userID);
		if (recipient) {
			if (recipient.status === MessageService.msDone) { return false; }
			return new Date(recipient.readDate) < new Date(1980, 1, 1);
		}

		// other message like news or reminder
		const msghash = MessageService.crc32(message.subject + message.creationDate + message.route);
		const status = this._messagesRead.find(s => s.hash === msghash);
		if (!status) { return true; }

		// Mark as existing
		status.exist = true;
		return false;
	}


	markMessageAsRead(message: Message): void {
		message.isUnread = false;
		const recipient = this.getRecipient(message);
		if (recipient) {
			if (new Date(recipient.readDate).getTime() < new Date(1980, 1, 1).getTime()) {
				recipient.readDate = new Date();
				this.updateRecipient(recipient.id, recipient).subscribe();
			}
			return;
		}

		// other message like news or reminder
		const msghash = MessageService.crc32(message.subject + message.creationDate + message.route);
		if (!this._messagesRead.find(s => s.hash === msghash)) {
			this._messagesRead.push({ hash: msghash, exist: true });
			this._messagesRead = this._messagesRead.filter(st => st.exist);
			localStorage.setItem('messages-read', JSON.stringify(this._messagesRead.map(st => st.hash)));
		}
	}

	getAllRecipients(): Observable<Recipient[]> {
		const recipients = [];
		return new Observable(obs => {
			this.tools.settingArray('global', 'groups').subscribe(groups => {
				let iColor = 1;
				groups.forEach(g => {
					const r: Recipient = { name: g, id: 0, color: Const.colors[iColor], members: '' };
					recipients.push(r);
					iColor++;
				});

				this.dataService.getUsers().subscribe(users => {
					users.forEach(u => {
						const r: Recipient = { name: u.name, id: u.id, color: '', members: '' };
						recipients.push(r);

						// fill group members
						const userGroups = u.groups.split('|');
						userGroups.forEach(group => {
							const found = recipients.find(g => g.name.toLowerCase() === group.toLowerCase() && g.id === 0);
							if (found) {
								found.members += found.members === '' ? u.name : ', ' + u.name;
							}
						});

						obs.next(recipients);
						obs.complete();
					});
				});
			});
		});
	}

	async buildRecipientList(recipients: string): Promise<Recipient[]> {
		const recipientList = [];

		const arr = (recipients || '').split('|');
		for (let i = 0; i < arr.length; i++)
		{
			const search = arr[i];
			let r: Recipient;

			if (+search > 0) {
				r = this.allRecipientList.find(recipient => recipient.id === +search);
			} else if (+search < 0) {
				const data = await this.http.get<any>(endpointMessage + 'recipient/' + search).toPromise()
					.catch(() => { });
				if (data) {
					r = { name: data.name, id: +search, color: '', members: '' } as Recipient;
				}
			} else {
				r = this.allRecipientList.find(recipient => recipient.name === search);
			}

			if (r) {
				recipientList.push(r);
			}
		}

		return recipientList;
	}


	/////////////////////////////////
	// Push
	sendPushMessage(message: Message): Observable<any> {
		return this.http.post(endpointMessage + 'push', JSON.stringify(message), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	private ab2base64(arrayBuffer) {
		const base64 = btoa(
			new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
		);
		return base64;
	}

	requestPushSubscription() {
		/*
		Public Key
		BMPa7MBxrkooQsKYCIs9Y-mDGmkTztHrEYVEjbZMNt3bqyy4a1v8QREyFj2B87cYyPuk7Wz99t6rl_Pr7shim6w
		Private Key
		fa9VSUkaVXsTxQIYyxzV8gao_1WdNyhSeNEFNtipFJ8
		*/
		if (!ToolService.currentUser) {
			console.warn('Cannot save push subscription without user loggedin.');
			return;
		}

		const key = 'BMPa7MBxrkooQsKYCIs9Y-mDGmkTztHrEYVEjbZMNt3bqyy4a1v8QREyFj2B87cYyPuk7Wz99t6rl_Pr7shim6w';
		this.swPush.requestSubscription({ serverPublicKey: key })
			.then(async sub => {
				const p256dh = this.ab2base64(sub.getKey('p256dh'));
				const auth = this.ab2base64(sub.getKey('auth'));
				localStorage.setItem('p256dh', p256dh);

				if (this.tools.isDebug) {
					console.log('push Subscription', JSON.stringify(sub));
				}

				const subscription = { endPoint: sub.endpoint, p256dh: p256dh, auth: auth };
				await this.http.post(endpointMessage + 'SaveSubscription', JSON.stringify(subscription), httpOptions).toPromise()
					.catch(ToolService.handleHttpError);
			},
				err => {
					console.error('error registering for push', err);
				});
	}

	unsubscribePushSubscription() {
		this.swPush?.unsubscribe();
	}

}

