﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿﻿<div class="container center" [class.hidden]="isSuccessful">
	<mat-card ngClass="pin-card" ngClass.xs="mat-card-xs" (keydown.enter)="onSavePin()">

		<mat-card-title>
			<div h2 i18n fxFlex>Bitte geben Sie Ihren PIN ein</div>
			<div><button mat-icon-button type="button" tabindex="-1" (click)="onAppSettings()" *ngIf="tools.enviroment === 'app' "><mat-icon>settings</mat-icon></button></div>
		</mat-card-title>

		<mat-card-content>
			<div class="circles">
				<mat-icon *ngFor="let i of pinDef"[style.color]="pin.length > i ? '#000':'#e0e0e0'">lens</mat-icon>
			</div>

			<div class="pinpad">
				<mat-grid-list cols="3" rowHeight="1:1" gutterSize="1em">
					<mat-grid-tile *ngFor="let b of buttons">
						<button mat-fab class="pinpad-button" (click)="handleInput(b.action)" [disabled]="isLoading" tabindex="-1"><mat-icon *ngIf=b.icon>{{b.icon}}</mat-icon>{{b.text}}</button>
					</mat-grid-tile>
				</mat-grid-list>
			</div>

			<mat-form-field *ngIf="setPin">
				<mat-label>Passwort</mat-label>
				<input matInput maxLength="80" type="password" [(ngModel)]="password" required>
			</mat-form-field>
		</mat-card-content>

		<mat-card-actions fxLayoutGap=".5em">
			<button mat-raised-button color="primary" (click)="onSavePin()" *ngIf="setPin" [disabled]="pin.length < pinDef.length || password.length < 4" i18n>PIN speichern</button>
			<button mat-raised-button color="primary" (click)="location.back()" *ngIf="setPin" i18n>Zurück</button>
			<button mat-raised-button routerLink="/login" *ngIf="!setPin" i18n>Anmelden mit Passwort</button>
		</mat-card-actions>
	</mat-card>
</div>
