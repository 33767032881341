import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import {  BroadcastData, User } from '../../models/models';
import { AuthenticationService, DataService, MessageService, SignalRService, ToolService } from '../../_services';

interface ChatMessage {
	senderID: number;
	recipientID: number;
	text: string,
	creationDate: Date,
}

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.css']
})

export class ChatComponent implements OnInit, OnDestroy, OnChanges {
	public messages: ChatMessage[] = [];
	public userList: User[] = [];
	public userID = 0;
	public recipientID = 0;
	public text = "";

	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	private timerID;
	@ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;
	@ViewChild('input') input: ElementRef;


	constructor(
		public authenticationService: AuthenticationService,
		public signalRService: SignalRService,
		public dataService: DataService,
		public tools: ToolService,
	) { }

	ngOnInit() {
		this.signalRService.connection.on('chatMessage', (message) => {
			this.addMessage(message);
		});

		this.authenticationService.currentUser.subscribe(user => {
			this.userID = user?.id || 0;
			if (!user) { this.visible = false; }
		});

		this.buildUserList();
		this.signalRService.connectionChange.subscribe(() => this.buildUserList());
	}

	ngOnDestroy() {
		clearInterval(this.timerID);
		this.signalRService.connection.off('chatMessage');
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.visible.currentValue) {
			const json = sessionStorage.getItem('chat');
			if (json) {
				try {
					this.messages = JSON.parse(json) || [];
				} catch (e) {
				}
			}

			this.messages.forEach(m => m.creationDate = new Date(m.creationDate));

			this.recipientID = this.messages.find(m => m.recipientID !== this.userID)?.recipientID;
			setTimeout(() => this.perfectScroll.directiveRef.scrollToBottom());
		} else {
			this.saveState();
		}
	}

	saveState() {
		const messages = this.messages.filter(m => m.creationDate.isToday());
		const json = JSON.stringify(messages);
		sessionStorage.setItem('chat', json);
	}

	addMessage(data: BroadcastData) {
		if (data.userID !== this.userID && data.senderID !== this.userID) { return; }
		const message = { senderID: data.senderID, recipientID: data.userID, text: data.route, creationDate: new Date(data.from) } as ChatMessage;

		if (data.senderID !== this.userID) {
			this.recipientID = message.senderID;
		}

		this.messages.push(message);
		this.visible = true;

		setTimeout(() => this.perfectScroll.directiveRef.scrollToBottom());
	}

	onSend() {
		if (!this.userID || !this.recipientID || !this.text) { return; }
		const message = { userID: this.recipientID, senderID: this.userID, route: this.text, from: new Date() } as BroadcastData;
		this.dataService.sendBroadcast('chatMessage', message).subscribe();

		this.text = "";
		setTimeout(() => this.input.nativeElement.focus());
	}

	onClose() {
		this.visible = false;
		this.visibleChange.emit(false);
	}

	onUser(userID: number) {
		if (!userID) { return; } 
		this.recipientID = userID;
		setTimeout(() => this.input.nativeElement.focus());
	}

	async buildUserList() {
		if (this.userID <= 0) { return;  }
		const users: User[] = [];
		const allUsers: User[] = (await this.dataService.getUsers().toPromise()).filter(u => u.enabled);

		this.signalRService.connections.forEach(connection => {
			if (connection.local) { return; }

			const user = allUsers.find(u => u.name === connection.userName);
			if (user) {
				users.push(user);
			}
		});

		this.userList = users;
	}

}
