import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileReaderEvent, FileStore, Setting } from 'app/models/models';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { Const } from '../models/constants';
import { ImageService } from './image.service';

const endpoint = '/api/settings/';
const endpointFileStore = '/api/fileStore/';
const endpointInvoice = '/api/invoice/';
const endpointFiscal = '/api/fiscal/';


const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};

const cacheBusterSettings$ = new Subject<void>();
const cacheBusterFiles$ = new Subject<void>();

@Injectable({
	providedIn: 'root'
})
export class SettingService {
	constructor(private http: HttpClient) { }

	private extractData(res: Response) {
		const body = res;
		return body || {};
	}

	getSettingsNoCache(): Observable<any> {
		return this.http.get(endpoint).pipe(
			map(this.extractData));
	}

	@Cacheable({
		cacheBusterObserver: cacheBusterSettings$,
	})
	getSettings(): Observable<any> {
		return this.http.get(endpoint).pipe(
			map(this.extractData));
	}

	getSettingNoCache(section: string, key: string): Observable<Setting> {
		return this.http.get<Setting>(endpoint + section + '/' + key);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterSettings$
	})
	updateSetting(section: string , key: string , value: string): Observable<any> {
		const s = { section: section, key: key, value: value } as Setting;

		return this.http.put(endpoint + section + '/' + key, JSON.stringify(s), httpOptions);
	}

	/*
	@CacheBuster({
		cacheBusterNotifier: cacheBusterSettings$
	})
	updateAllSettings(data): Observable<any> {
		return this.http.put(endpoint, JSON.stringify(data), httpOptions);
	}
	*/

	@CacheBuster({
		cacheBusterNotifier: cacheBusterSettings$
	})
	deleteSetting(section: string, key: string): Observable<any> {
		return this.http.delete(endpoint + section + '/' + key, httpOptions);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterSettings$
	})
	clearSettingCache(): Observable<any> {
		return new Observable<any>(obs => {
			obs.next(true);
			obs.complete();
		});
	}

	// files
	@Cacheable({
		cacheBusterObserver: cacheBusterFiles$,
	})
	getFiles(type = -1, thumbSize = 64): Observable<FileStore[]> {
		return this.http.get<FileStore[]>(`${endpointFileStore}?type=${type}&thumbSize=${thumbSize}`);
	}

	@Cacheable({
		cacheBusterObserver: cacheBusterFiles$,
		maxCacheCount: 20
	})
	getFile(idOrName: number | string): Observable<FileStore> {
		return this.http.get<FileStore>(endpointFileStore + idOrName);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterFiles$
	})
	addFile(data: FileStore): Observable<any> {
		return this.http.post(endpointFileStore, JSON.stringify(data), httpOptions);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterFiles$
	})
	updateFile(id: number, data: FileStore): Observable<any> {
		return this.http.put(endpointFileStore + id, JSON.stringify(data), httpOptions);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterFiles$
	})
	deleteFile(id: number): Observable<any> {
		return this.http.delete(endpointFileStore + id, httpOptions);
	}


	@CacheBuster({
		cacheBusterNotifier: cacheBusterFiles$
	})
	scaleFile(id: number, width: number): Observable<any> {
		return this.http.put(`${endpointFileStore}scale/${id}/${width}`, null, httpOptions);
	}

	uploadFile(file: File, filename?: string): Observable<any> {
		return new Observable(obs => {
			const reader = new FileReader();
			if (!filename) {
				filename = file.name;
			}

			let type = file.type.startsWith('image') ? Const.ftImage : Const.ftText;
			if (file.name.indexOf('.svg') > 0) { type = Const.ftSvg; }
			if (file.name.indexOf('.template') > 0) { type = Const.ftMailtemplate; }
			if (file.name.indexOf('.analysis') > 0) { type = Const.ftAnalysis; }
			if (file.name.indexOf('.ts') > 0) { type = Const.ftScript; }

			const fileStore = {
				name: filename,
				type: type,
				description: ''
			} as FileStore;

			// resize Images
			if (type === Const.ftImage) {
				ImageService.loadImageResize(file, 800, 800).subscribe(img => {
					fileStore.content = img;
					this.addFile(fileStore).subscribe(data => obs.next(data));
				});

			} else {
				reader.onload = async (fileReaderEvent: ProgressEvent) => {
					const content = (<FileReaderEvent>fileReaderEvent).target.result;
					fileStore.content = content;
					this.addFile(fileStore).subscribe(data => obs.next(data));
				};
				type === Const.ftImage ? reader.readAsDataURL(file) : reader.readAsText(file);
			}

		});
	}


	// Fiscal
	getFiscalInfo(): Observable<any> {
		return this.http.get(endpointFiscal).pipe(
			map(this.extractData));
	}

	fiscalRegisterCert(): Observable<any> {
		return this.http.post(endpointFiscal + 'cert', null, httpOptions).pipe(
			map(this.extractData));
	}

	fiscalRegisterCashBox(): Observable<any> {
		return this.http.post(endpointFiscal + 'cashbox', null, httpOptions).pipe(
			map(this.extractData));
	}

	fiscalCheckSignature(invoice): Observable<any> {
		return this.http.put(endpointFiscal + 'check', JSON.stringify(invoice), httpOptions).pipe(
			map(this.extractData));
	}

	fiscalCreateTSS(): Observable<any> {
		return this.http.post(endpointFiscal + 'tss', null).pipe(
			map(this.extractData));
	}



	// Invoice
	closeMonthReceipt(invID: number): Observable<any> {
		return this.http.put(endpointInvoice + invID + '?monthReceipt=true', null, httpOptions).pipe(
			map(this.extractData));
	}

}



