import { Component, OnInit, HostListener } from '@angular/core';
import { Wiki } from 'app/models/models';
import { ToolService, DataService } from 'app/_services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-welcome',
	template: `
<mat-card>
	<mat-card-title>{{ caption }}</mat-card-title>
	<mat-card-content>
		<div [innerHTML]="wiki.text |safeHtml" class="markup"></div>
		<mat-checkbox [(ngModel)]="showAtStartup" (change)="showAtStartupChange()">Diese Seite beim Start zeigen</mat-checkbox>
	</mat-card-content>
</mat-card>
`,
	styles: ['']
})
export class WelcomeComponent implements OnInit {
	wiki = {text:''} as Wiki;
	caption = '';
	showAtStartup: boolean;
	wiz: string;

	constructor(
		private tools: ToolService,
		private dataService: DataService,
		private router: Router,
	) { }


	ngOnInit() {
		const result = this.dataService.getWikiFOIDHTML('welcome');

		this.tools.settingString('common', 'wizard').subscribe(para => {
			this.wiz = para;
			this.showAtStartup = this.wiz.indexOf('app-welcome') < 0;
		});

		result.subscribe(data => {
			this.wiki = data;
			this.caption = data.name;
		}, () => {
			this.wiki.text = '';
			this.caption = '\'welcome\' konnten nicht gefunden werden.';
		});
	}

	@HostListener('click', ['$event'])
	onClicked(event: MouseEvent) {
		if (event.ctrlKey || event.metaKey) {
			return;
		}

		let elm = event.target as HTMLElement;
		while (elm.nodeName.toLowerCase() !== 'a') {
			if (elm === document.body || !(elm = elm.parentElement)) {
				return;
			}
		}

		let href = (elm as HTMLAnchorElement).href;
		href = href.replace(/^https?:\/\//, '').replace('www.', '').replace(window.location.host, '');

		if (!href || elm.getAttribute('target') || /^\s*(javascript|mailto):/i.test(href) || href.startsWith('#') || href.startsWith('/#') || !href.startsWith('/')) {
			return;
		}

		event.preventDefault();

		this.router.navigate([href]);
	}


	showAtStartupChange() {
		if (this.showAtStartup === false) {
			if (this.wiz.indexOf('app-welcome') < 0) { this.wiz += ';app-welcome'; }
		} else {
			this.wiz = this.wiz.replace(';app-welcome', '');
		}

		this.tools.updateSetting('common', 'wizard', this.wiz).subscribe();
	}
}
