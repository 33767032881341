import { Component, Inject, OnInit,  OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BroadcastData } from 'app/models/models';
import { SignalRService, ToolService } from '../../_services';


@Component({
	selector: 'ec-status',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>EC-Terminal aktiv ({{ amount }})</h4>
<mat-dialog-content [style.background-color]="background">
	<h4 style="text-align:center">{{ status }}</h4>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button [mat-dialog-close]="success" *ngIf="canClose">Schließen</button>
</mat-dialog-actions>
`,
	styles: []
})
export class EcStatusComponent implements OnInit, OnDestroy {
	public status = 'Initialisierung...';
	public canClose = false;
	public success = false;
	public background = '';
	public amount = '';

	constructor(
		private signalRService: SignalRService,
		public dialogRef: MatDialogRef<EcStatusComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.amount = ToolService.formatNumber(data.amount, 2);
	}

	ngOnInit() {
		const myDeviceID = '' + Math.max(1, + localStorage.getItem('ecTerminal'));

		this.signalRService.connection.on('ecStatus', (data: BroadcastData) => {
			console.log('ecStatus: ' + data.route);
			if (!data) { return; }
			if (data.deviceID && myDeviceID && data.deviceID !== myDeviceID) { return; }

			this.status = data.route;
			if (data.id) { // ID != 0 = result
				this.background = data.active ? '#a5d6a7' : '#ef9a9a';
				this.success = data.active;

				if (!data.active && !data.route) { data.route = 'Abbruch'; }
				this.status = data.route;
				this.canClose = true;

				// mypos (close imidiately)
				const timeout = this.success ? 500 : 4000;
				setTimeout(() => this.dialogRef.close(this.success), timeout);
			}
		});
	}

	ngOnDestroy() {
		this.signalRService.connection.off('ecStatus');
	}
}

