import { Component, OnInit, HostBinding, ViewChild, ElementRef, Inject, OnDestroy, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute} from '@angular/router';
import { Location } from '@angular/common';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthenticationService, DataService, ToolService, MessageService, HotelService, SettingService, ReceiptService, FullScreenService, SignalRService, FinanceService } from '../../_services';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BroadcastData, Message, SignalRUser } from '../../models/models';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { environment } from '../../../src/environments/environment';
import { Title } from '@angular/platform-browser';
import { Const } from '../../models/constants';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { animate, animateChild, animation, AnimationReferenceMetadata, AnimationTriggerMetadata, AUTO_STYLE, group, keyframes, query, style, transition, trigger, useAnimation } from '@angular/animations';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

/* enable debug of subscriptions
 npm install observable-profiler
if (!environment.production) {
	setup(Observable);
}

onActivate() {
	track();
}

onDeactivate(component: object) {
	const subscribers = track(false);
	printSubscribers({
		subscribers,
		prefix: component.constructor.name,
		timeout: 200,
	});
}

<router-outlet (activate)="onActivate()" (deactivate)="onDeactivate($event)"></router-outlet>
*/


@Component({
	selector: 'app-news',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>{{ message.subject }}</h4>
<div mat-dialog-content>
	<p>{{ message.text }}</p>
	<span *ngIf="this.message.route">
		<a routerLink="" (click)="onMore()" tabindex="-1">Mehr Info...</a>
	</span>
	<mat-divider></mat-divider>
	<div>Vom {{ message.creationDate | dateTime }}</div>
</div>
<div mat-dialog-actions>
	<button mat-raised-button color="primary" [mat-dialog-close]="">Schließen</button>
</div>
`,
	styles: ['']
})
export class ShowNewsComponent  {
	message: Message;

	constructor(
		public dialogRef: MatDialogRef<ShowNewsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private router: Router,
	) {
		this.message = data;
	}

	onMore() {
		this.dialogRef.close();

		if (this.message.route.startsWith('http')) {
			window.open(this.message.route, '_blank');
		} else {
			this.router.navigate([this.message.route]);
		}
	}
}

/////////////////////////
// Animation
export interface IAnimationOptions {
	anchor?: string;
	duration?: number;
	delay?: number;
	animateChildren?: 'before' | 'together' | 'after' | 'none';

	direction?: '<=>' | '=>';
	scale?: number;
}

export function useAnimationIncludingChildren(ani: AnimationReferenceMetadata, options?: IAnimationOptions) {
	return [
		...(options && options.animateChildren === 'before' ? [query('@*', animateChild(), { optional: true })] : []),
		group([
			useAnimation(ani),
			...(!options || !options.animateChildren || options.animateChildren === 'together'
				? [query('@*', animateChild(), { optional: true })]
				: [])
		]),
		...(options && options.animateChildren === 'after' ? [query('@*', animateChild(), { optional: true })] : [])
	];
}

const pulse = () =>
	animation([
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({ visibility: AUTO_STYLE, transform: 'scale3d(1, 1, 1)', easing: 'ease', offset: 0 }),
				style({ transform: 'scale3d({{scale}}, {{scale}}, {{scale}})', easing: 'ease', offset: 0.5 }),
				style({ transform: 'scale3d(1, 1, 1)', easing: 'ease', offset: 1 })
			])
		)
	]);

const DEFAULT_DURATION = 1000;
const DEFAULT_SCALE = 1.25;

export function pulseAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
	return trigger((options && options.anchor) || 'pulse', [
		transition(`0 ${(options && options.direction) || '<=>'} 1`, [...useAnimationIncludingChildren(pulse(), options)], {
			params: {
				delay: (options && options.delay) || 0,
				duration: (options && options.duration) || DEFAULT_DURATION,
				scale: (options && options.scale) || DEFAULT_SCALE
			}
		})
	]);
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	animations: [pulseAnimation({ duration: 2000 })]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	public now: Date = new Date();
	public tick = 0;
	public sleeping = false;
	public dragging = false;
	public autoLogout = 0;
	public userName = '';
	public userID = 0;

	public window = window;
	public screen = screen;

	public navMode = 'side';
	public hideSideNavManual = false;
	public isDisplay = false;
	public isMyPOS = false;
	public isDemo = false;
	public hasPin = false;
	public showChat = false;

	searchData: Observable<any>;
	searchCtrl = new FormControl();
	searchOpen = false;
	searchModeXS = false;

	notifications: Message[];
	notificationCount = 0;
	messageCount = 0;
	messageInitial = true;

	private pushRequested = false;

	public animState = false;
	private animCounter = 1;
	private scrollSubscription: Subscription;

	@HostBinding('class') componentCssClass;
	@ViewChild('search') searchInput: ElementRef;
	@ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;

	constructor(
		public authenticationService: AuthenticationService,
		public tools: ToolService,
		public router: Router,
		public route: ActivatedRoute,
		private location: Location,
		private cd: ChangeDetectorRef,
		public hotelService: HotelService,
		public messageService: MessageService,
		public signalRService: SignalRService,
		private dataService: DataService,
		private financeService: FinanceService,
		private settingService: SettingService,
		private overlayContainer: OverlayContainer,
		private swUpdate: SwUpdate,
		private titleService: Title,
		private dialog: MatDialog,
		private fullScreenService: FullScreenService,
		private receipt: ReceiptService, // DO NOT REMOVE !! (load printer names)
	) {
	}

	async ngOnInit() {
		this.startSignalRConnection();
		this.onSetTheme(localStorage.getItem('currentTheme'));
		this.autoLogout = +localStorage.getItem('autologout');

		const dbName = AuthenticationService.getDbName();
		this.isDemo = !dbName || dbName.startsWith('DEMO');

		this.authenticationService.currentUser.subscribe(user => {
			this.userID = user?.id || 0;
			this.userName = user?.name || '';

			if (!user) { return; }
			this.hasPin = !!AuthenticationService.getPin(user.login);

			this.gotoHomeRoute(); // redirect to hote/rest app

			if (environment.production && this.pushRequested === false) {
				this.pushRequested = true;
				this.messageService.requestPushSubscription();
			}
		});

		// Auto Close and loading indicator
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.tools.isLoading = true;
			} else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
				this.tools.isLoading = false;
			}

			if (event instanceof NavigationEnd && this.navMode === 'over') {
				this.tools.showSideNav.next(false);
			}
		});


		// Hide Sidenav / Toolbar
		combineLatest([this.tools.isMobile, this.tools.isTablet, this.authenticationService.currentUser, this.router.events]).subscribe(([mobile, tablet, user, router]) => {
			this.checkFullScreen(this.location.path());

			this.tools.showSideNav.next(mobile === false && tablet === false && user != null && this.isDisplay === false && this.hideSideNavManual === false);
			this.navMode = mobile || tablet ? 'over' : 'side';
			this.searchOpen = !mobile;
			this.searchModeXS = mobile;
		});

		// Seatch Autocomplete
		this.searchData = this.searchCtrl.valueChanges
			.pipe(
				switchMap(value => this.dataService.getSearchResult(value, '* settings'))
			);

		// Main Timer
		this.resetIdleTimer();
		setInterval(() => {
			this.now = new Date();

			this.checkIdleTimer();

			// Check for tasks every 3 minutens
			if (!this.sleeping && ToolService.currentUser && this.tick % (60 * 3) === 0 && this.isDisplay === false) {
				this.getLatestMessages();
			}

			this.tick++;
		}, 1000);

		// after a while
		setTimeout(() => {

			// Check for Updates
			if (this.tools.enviroment === 'pwa') {
				const updatesAvailable = this.swUpdate.versionUpdates.pipe(
					filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
					map(() => 'UPDATE_AVAILABLE')
				);

				updatesAvailable.subscribe(() => {
					this.tools.showMessage('Es steht ein Update zur Verfügung', 'system_update_alt', () => window.location.reload());
				});
			}

			SignalRService.getLocalIp().then(ip => localStorage.setItem('localIP', '' + ip));

			document.body.addEventListener('click', () => this.resetIdleTimer());
			document.body.addEventListener('keydown', () => this.resetIdleTimer());

			this.titleService.setTitle('Front Office Cloud - ' + this.tools.license.name);

			if (this.tools.enviroment === 'app') {
				location.href = 'https://app/ready';
			}

			this.isMyPOS = this.tools.hasModule('ec') && localStorage.getItem('myPOS') === '1'
		}, 3000);


		// handle messages from native app
		window.addEventListener('appmsg', async (event) => {
			const data = (event as CustomEvent).detail;
			// console.log('AppCmd:', data);
			switch (data.cmd) {
				case 'home':
					this.gotoHomeRoute(true);
					break;
				case 'logout':
					this.onLogout();
					break;
				case 'login':
					if (ToolService.currentUser) {
						await this.onLogout();
					}

					this.resetIdleTimer();
					await this.authenticationService.login(data.username, data.password, '').toPromise();
					this.gotoHomeRoute();
					break;
				case 'mypos':
					this.financeService.ecMyPOSResult(data.data).subscribe();
					break;
				case 'scan':
					this.tools.startScan();
					break;
				case 'scanResult':
					const barcode = data.data as string;

					if (barcode?.startsWith('hc1:') || barcode?.startsWith('HC1:')) {
						this.tools.startScan('check', barcode);
					} else {
						this.tools.scanResult.emit(barcode);
					}
					break;
			}
		});

		// Scroll event
		this.scrollSubscription = this.tools.scrollTo.subscribe(target => {
			this.perfectScroll.directiveRef.scrollToElement(target, 0, 300);
		});
	}

	ngAfterViewInit() {
		this.authenticationService.emitStoredLogin();
		this.cd.detectChanges();
	}

	ngOnDestroy() {
		this.scrollSubscription?.unsubscribe();
		this.stopSignalRConnection();
	}

	animDone() {
		if (this.animCounter % 6) {
			this.animState = !this.animState;
		}
		this.animCounter++;
	}

	isFullScreen() {
		return window.innerHeight === screen.height;
	}

	checkFullScreen(path: string) {
		this.isDisplay = path.indexOf('/display') >= 0 || path.indexOf('/kitchen') >= 0 || path.indexOf('/remote') >= 0;

		// Fullscreen mode remove header in restaurant
		/*this.isRestFullScreen = false;
		if (path.indexOf('/restaurant') >= 0 && this.isFullScreen()) {
			this.isRestFullScreen = true;
			this.showSideNav = false;
			this.hideSideNavManual = true;
		}
		*/
	}

	async startSignalRConnection() {
		// setup SignalR
		await this.signalRService.startConnection();

		this.signalRService.connection.on('reloadMessages', (data) => {
			this.getLatestMessages(data);
		});

		this.signalRService.connection.on('reloadData', (data) => {
			switch (data) {
				case 'setting':
					this.settingService.clearSettingCache().subscribe();
					break;
				case 'art':
					this.dataService.clearArticleCache().subscribe();
					break;
				case 'user':
					this.dataService.clearUserCache().subscribe();
					break;
			}
		});

		this.signalRService.connection.on('remoteMouseClick', (data) => {
			if (!this.isDisplay) {
				const coords = data.route.split(',');
				const x = +coords[0] * window.innerWidth / 10000;
				const y = +coords[1] * window.innerHeight / 10000;

				const d = document.createElement('div');
				d.className = 'click-effect';
				d.style.left = x + 'px';
				d.style.top = y + 'px';
				document.body.appendChild(d);
				d.addEventListener('animationend', () => d.parentElement.removeChild(d));
			}
		});
	}

	stopSignalRConnection() {
		this.signalRService.connection.off('reloadMessages');
		this.signalRService.connection.off('reloadData');
		this.signalRService.connection.off('remoteMouseClick');
		this.signalRService.stopConnection();
	}

	async gotoHomeRoute(force = false) {
		const user = ToolService.currentUser;
		if (!user) { return; }

		const path = this.location.path();
		if (force === false && path && !path.startsWith('/login') /*&& path.endsWith('?returnUrl=%2F') === false*/) {  // nicht wenn direkt ein URL aufgerufen wird
			return;
		}


		// Redirect to user group-route
		const arr = user.groups.split('|');
		for (let i = 0; i < arr.length; i++) {
			let group = arr[i];
			if (group.startsWith('/')) {
				this.hideSideNavManual = true; // No menu if user is redirected to group

				this.tools.navigateByUrlAlways(group);
				return;
			}
		}

		// Welcome Page ?
		const wizard = await this.tools.settingString('common', 'wizard').toPromise();
		if (wizard.indexOf('app-welcome') < 0) {
			this.tools.navigateAlways(['/welcome']);
			return;
		}

		// redirect to homepage
		const routes = ['dashboard', 'rezeption', 'restaurant', 'booking/mice', 'wellness', 'message', 'housekeeping', 'ec'];
		const roles = ['xx', 'rezeption', 'kellner', 'wellness', 'message', 'housekeeping', ''];
		let appID = +localStorage.getItem('home');
		if (appID === 0 && user.role < Const.roleManager) {
			for (let i = 0; i < roles.length; i++) {
				if (user.groups.toLowerCase().indexOf(roles[i]) >= 0) {
					appID = i;
					break;
				}
			}

			// Houskeepting -> Message
			if (appID === 0 && this.tools.hasModule('msg')) { appID = 4; }

			// Hide Sidenav on special routes
			if ([2, 3, 5].indexOf(appID) >= 0) {
				this.hideSideNavManual = true;
			}
		}

		this.router.navigate([appID === 1 ? this.hotelService.hotelRoute : routes[appID]], { replaceUrl: true });
	}

	autocompleteSelected(route: string) {
		this.searchCtrl.patchValue('');

		// do not change to always
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigateByUrl(route));
	}

	// Messages
	getLatestMessages(data: BroadcastData = null) {
		if (!ToolService.currentUser || this.sleeping) { return; }

		if (data?.route.startsWith('/bookings') && ToolService.currentUser?.groups.toLowerCase().indexOf('rezeption') < 0) { return; }
		if (data && data.route.startsWith('/bookings') === false && (',' + data.route).indexOf(',' + ToolService.currentUser.id) < 0) { return; }

		const result = this.messageService.getLatestMessages();
		result.subscribe(messages => {
			this.notifications = messages;

			let topNews: Message = null;
			let notificationCount = 0;
			let messageCount = 0;
			const oldCount = this.notificationCount;

			this.notifications.forEach(message => {
				message.isUnread = this.messageService.isUnread(message, ToolService.currentUser.id);
				if (message.isUnread) {
					if (!topNews && this.messageInitial && message.icon === 'message' && message.type === 2) {
						topNews = message;
					} else {
						notificationCount++;
					}
					if (message.route.startsWith('/message')) { messageCount++ }
				}
			});
			this.notificationCount = notificationCount;
			this.messageCount = messageCount;
			this.messageInitial = false;

			// specific message ?
			if (data && data.id > 0) {
				this.messageService.getMessage(data.id).subscribe(async message => {
					if (message && message.modifyUser !== ToolService.currentUser.id) {
						this.tools.playAudio('ding.mp3');
						if (await this.tools.confirmBox(message.subject + '<br>Nachricht öffnen ?', 'Neue Nachricht').toPromise() === 'Ok') {
							this.router.navigate(['/message', data.id]);
						}
					}
				});
			} else if (topNews) {
				this.messageService.markMessageAsRead(topNews);
				this.showNews(topNews);
			} else if (oldCount > 0 && notificationCount > oldCount) {
				this.tools.playAudio('low-beep.mp3');
			}
		});
	}

	checkIdleTimer() {
		const last = +localStorage.getItem('lastAction');
		if (!last) { return; }

		let timeout = 15 * 60;
		switch (this.autoLogout) {
			case 1:
				timeout = 1 * 60;
				break;
			case 2:
				timeout = 5 * 60;
				break;
			case 3:
				break;
		}

		const now = Date.now();
		const diff = Math.floor((now - last) / 1000);

		if (ToolService.currentUser && diff > timeout) {
			if (this.autoLogout) {
				console.log(`Auto logoff after ${diff} seconds of inactivity`);
				this.onLogout();
			} else if (!this.sleeping) {
				console.log(`Going to sleep after ${diff} seconds of inactivity`);
				this.sleeping = true;
			}
		}
	}

	resetIdleTimer() {
		localStorage.setItem('lastAction', '' + Date.now());
		if (this.sleeping) {
			this.sleeping = false;
			this.tick = -2;
		}
	}

	showNews(message: Message) {
		this.dialog.open(ShowNewsComponent, { width: '600px', maxWidth: '100vw', data: message });
	}

/*	private btWaiterLockDevice = null;
	private btWaiterLockCharacteristic = null;

	async connectBTWaiterLock() {

		const keyService = '7b83ed4b-c859-47af-b59c-d57461009c81';
		const keyCharacteristic = '4189e016-6cca-48ab-a510-4d3b4895a788';

		const btNavigator: any = navigator;
		if (!btNavigator.bluetooth) {
			this.tools.showError('Dieser Browser unterstütz kein Bluetooth-Kellnerschloss.');
			return;
		}

		if (this.btWaiterLockDevice == null) {
			const options = {
				filters: [{ namePrefix: 'Addimat' }, ],
				optionalServices: [keyService],
			};
			this.btWaiterLockDevice = await btNavigator.bluetooth.requestDevice(options)
				.catch(err => { this.tools.showError(err); });

			if (this.tools.isDebug) { console.log('[BLE::Info] Found ' + this.btWaiterLockDevice.name); }
		}

		const gatt = await this.btWaiterLockDevice.gatt.connect()
			.catch(err => { this.tools.showError(err); });
		if (this.tools.isDebug) { console.log('[BLE::Info] GATT info %o', gatt); }

		const primaryService = await gatt.getPrimaryService(keyService);
		if (this.tools.isDebug) { console.log('[BLE::Info] Primary Service info %o', primaryService); }

		this.btWaiterLockCharacteristic = await primaryService.getCharacteristic(keyCharacteristic);
		if (this.tools.isDebug) { console.log('[BLE::Info] Characteristic info %o', this.btWaiterLockCharacteristic); }

		await this.btWaiterLockCharacteristic.startNotifications();
		if (this.tools.isDebug) { console.log('[BLE::Info] Notifications started'); }

		this.btWaiterLockCharacteristic.addEventListener('characteristicvaluechanged', this.onWaiterLockEvent);
	}

	onWaiterLockEvent(event) {
		if (this.tools.isDebug) { console.log('[BLE::Info] WaiterLock Notification %o', event.target.value); }
	}
	*/

	async saveClickMe(file: File) {
		const buffer  = await ToolService.readFile(file).catch((err) => {
			this.tools.showError(err);
			return;
		});

		const xml = new TextDecoder().decode(buffer as any);

		const result = this.dataService.saveClickMe(xml);
		this.tools.handleResult(result, (data) => this.router.navigate(['booking', data.id]));


	}

	onReload() {
		document.location.reload();
    }

	async onLogout() {
		// this.tools.showSideNav.next(false);
		this.notifications = [];
		this.notificationCount = 0;
		this.signalRService.disconnect();

		const hasPin = !!AuthenticationService.getPin(ToolService.currentUser.login);
		await this.router.navigate([hasPin ? '/login/pin' : '/login/']);
	}

	onToggleDebug() {
		this.tools.isDebug = !this.tools.isDebug;
		this.tools.updateSetting('global', 'debug', '' + this.tools.isDebug).subscribe();
	}

	onToggleFullScreeen() {
		const makeFullScreen = !this.isFullScreen();
		this.fullScreenService.setFullScreen(makeFullScreen);
		setTimeout(() => this.checkFullScreen(this.location.path()), 150);
	}

	onReloadPage() {
		this.tools.navigateAlways([this.router.url]);
	}

	onToggleSidenav() {
		this.hideSideNavManual = this.tools.showSideNav.value;
		this.tools.showSideNav.next(!this.tools.showSideNav.value);
	}

	onSearch() {
		this.searchInput.nativeElement.focus();
	}

	onToggleSearch() {
		this.searchOpen = !this.searchOpen;
		if (this.searchOpen) {
			setTimeout(() => this.searchInput.nativeElement.focus(), 200);
		}
	}

	onSearchClear() {
		if (this.searchModeXS && this.searchOpen) {
			this.searchOpen = false;
		}
		this.searchCtrl.patchValue('');
	}


	onHelpContent() {
		let currRoute = this.router.url;
		if (currRoute.indexOf('?') > 0) {
			currRoute = currRoute.substr(0, currRoute.indexOf('?'));
		}
		this.router.navigate(['/wiki'], { queryParams: { url: currRoute } });
	}

	onHelpMain() {
		this.tools.navigateAlways(['/wiki']);
	}

	onSetTheme(theme: string) {
		if (!theme) { return; }

		let oldTheme = localStorage.getItem('currentTheme');
		if (!oldTheme) {
			oldTheme = 'default-theme';
		}

		this.tools.theme = theme;
		localStorage.setItem('currentTheme', theme);

		this.overlayContainer.getContainerElement().classList.remove(oldTheme);
		this.overlayContainer.getContainerElement().classList.add(theme);
		this.componentCssClass = theme;
	}

	onNotification(message: Message) {
		if (message.isUnread) { this.notificationCount--; }
		this.messageService.markMessageAsRead(message);

		// News support
		if (message.icon === 'message' || message.icon === 'warning') {
			this.showNews(message);
			return;
		}

		const route = message.route.startsWith('/') ? message.route : '/message/' + message.id;
		if (route.startsWith('message')) {
			this.messageCount = 0
		}
		this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() =>
			this.router.navigateByUrl(route));
	}


	onDrop(files: FileList) {
		if (files.length < 1) { return; }

		const file = files[0];
		const parts = file.name.split('.');
		const extension = parts[parts.length - 1].toLowerCase();

		switch (extension) {
			case 'book':
				this.saveClickMe(file);
				return;
			case 'pdf':
			case 'msg':
				this.tools.dropFile(file);
				return;
        }
	}

	private openRemoteStream(connectionId: string, canvas = true) {
		const replace = this.router.url.startsWith('/remote');
		this.tools.navigateAlways([canvas ? '/remote/c' : '/remote', connectionId], { replaceUrl: replace });
	}

	async onStartVideo(shareDesktop = true) {
		if (this.signalRService.connections.find(c => c.local && c.roomID === 'FORoom')) {
			await this.signalRService.quitVideoconference();
		}

		await this.signalRService.initVideoconference(shareDesktop);
		const remoteConnection = this.signalRService.connections.find(c => !c.local && c.roomID === 'FORoom');
		if (remoteConnection?.canvasID) {
			setTimeout(() => this.openRemoteStream(remoteConnection.connectionID, true), 300);
        }
	}

	onShowChat() {
		this.showChat = true;
	}

	onUserProfile() {
		this.router.navigate(['base/user', ToolService.currentUser.id]);
	}

	onCreatePin() {
		this.hasPin = true;
		this.router.navigate(['login/pin/set']);
    }

	onVideoClicked(peer: SignalRUser) {
		if (this.dragging) {
			this.dragging = false;
			return;
		}

		this.openRemoteStream(peer.connectionID, false);
	}

	onVideoButton(peer: SignalRUser) {
		if (peer.local) {
			this.signalRService.quitVideoconference();
		} else {
			this.openRemoteStream(peer.connectionID);
		}
	}

}
