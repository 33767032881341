import { Directive, HostListener, ElementRef, OnInit, ChangeDetectorRef, SimpleChanges, OnChanges, Input, Injectable, forwardRef } from '@angular/core';
import { FormGroup, AbstractControl, NgControl, ValidationErrors, ValidatorFn, NgModel, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { Observable } from 'rxjs';
import { ToolService } from './tool.service';


const allowedKeys = ['Backspace', 'Delete', 'Enter', 'Escape', 'Tab', 'Home', 'End', 'Left', 'Right', 'ArrowLeft', 'ArrowRight'];

@Injectable()
export class FormService {
	static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
		const config = {
			'required': 'Bitte geben Sie einen Wert ein.',
			'creditcard': 'Bitte geben Sie eine gültige Kreditkartennummer ein.',
			'minlength': `Bitte geben Sie mindestens ${validatorValue.requiredLength} Zeichen ein.`,
			'maxlength': `Bitte geben Sie maximal ${validatorValue.requiredLength} Zeichen ein.`,
			'email': 'Bitte geben Sie eine gültige EMail Adresse ein.',
			'invalidPassword': 'Ungültiges Passwort. Das Passwort muss mindestens 6 Zeichen lang sein und eine Nummer enthalten.',
			'notmatching' : 'Passwort und Wiederholung stimmen nicht überein.',
			'min': `Geben Sie einen Wert größer gleich ${validatorValue.min} ein.`,
			'max': `Geben Sie einen Wert kleiner gleich ${validatorValue.max} ein.`,
			'invalidDate': 'Bitte geben Sie ein gültiges Datum ein.'
		};

		return config[validatorName];
	}

	static validationMsg(form: FormGroup, fieldName: string): string {
		const control = form.get(fieldName);

		for (const propertyName in control.errors) {
			if (control.errors.hasOwnProperty(propertyName) && control.touched) {
				return FormService.getValidatorErrorMessage(propertyName, control.errors[propertyName]);
			}
		}

		return null;
	}

	static comparePasswordValidator(compareField: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value == null) {
				return null;  // don't validate empty values to allow optional controls
			}

			let valid = true;
			if (control.parent) {
				if (control.parent.controls[compareField].value !== control.value) {
					valid = false;
				}
			}
			return !valid ? { 'notmatching': true } : null;
		};
	}

	static creditCardValidator(control: FormControl) {
		// Visa, MasterCard, American Express, Diners Club, Discover, JCB
		if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
			return null;
		} else {
			return { 'creditcard': true };
		}
	}

	static emailValidator(control: FormControl) {
		// RFC 2822 compliant regex
		if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
			return null;
		} else {
			return { 'email': true };
		}
	}

	static passwordValidator(control: FormControl) {
		// {6,100}           - Assert password is between 6 and 100 characters
		// (?=.*[0-9])       - Assert a string has at least one number
		if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
			return null;
		} else {
			return { 'invalidPassword': true };
		}
	}

	static dateRequired(control: FormControl) {
		const d = ToolService.parseDate(control.value);
		if (d && d.getFullYear() >= 1900) {
			return null;
		} else {
			return { 'invalidDate': true };
		}
	}

	static dateValidator(control: FormControl) {
		const d = ToolService.parseDate(control.value);
		if (d) {
			return null;
		} else {
			return { 'invalidDate': true };
		}
	}

	// get all values of the formGroup, loop over them
	// then mark each field as touched
	static markFormGroupTouched(formGroup: FormGroup) {
		for (const key in formGroup.controls) {
			if (formGroup.controls[key]) {
				formGroup.controls[key].markAsTouched();
			}
		}
	}

	static beautify(value: string): string {
		value = value.trim();
		const start = value.charAt(0);
		if ((start >= 'a' && start <= 'z' && value.charAt(1) !== '.') || ['ä', 'ö', 'ü'].indexOf(start) >= 0) { // z.Hd. !!
			value = start.toUpperCase() + value.substr(1);
		}

		return value;
	}

/*	static slugify(value: string): string {
		const trChars = {
			'äÄáÁ': 'a',
			'éÉ': 'e',
			'íÍ': 'i',
			'öÖóÓ': 'o',
			'üÜúÚ': 'u',
			'ñÑ': 'n',
			'ß': 's'
		};
		for (const key of Object.keys(trChars)) {
			value = value.replace(new RegExp('[' + key + ']', 'g'), trChars[key]);
		}
		value = value
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-')           // Replace spaces with -
			.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
			.replace(/\-\-+/g, '-')         // Replace multiple - with single -
			.replace(/^-+/, '')             // Trim - from start of text
			.replace(/-+$/, '');            // Trim - from end of text

		return value;
	}

*/

	static setValue(form: FormGroup, data: Observable<any>, successFunction?: Function, errorFunction?: Function) {
		data.subscribe(d => {
			for (const key in d) {
				if (form.controls[key]) {
					form.controls[key].setValue(d[key], { emitEvent: false });
				}
			}

			if (successFunction) {
				successFunction(d);
			}
		},
		(err) => {
			if (errorFunction) {
				errorFunction(err);
			}
		});

	}

	static getValue(form: FormGroup, beautify = true) {
		const ignoreFields = ['password', 'confirm_password', 'email', 'itemID', 'unit', 'ccToken'];

		const value = form.getRawValue();
		if (beautify) {
			for (const key of Object.keys(value)) {
				if (typeof value[key] === 'string' && ignoreFields.indexOf(key) < 0) {
					value[key] = FormService.beautify(value[key]);
				}
			}
		}
		return value;
	}


}

@Directive({ selector: '[focusInitial]' })
export class FocusDirective implements OnInit, OnChanges {
	constructor(
		private elementRef: ElementRef,
		private tools: ToolService,
	) {	}

	ngOnInit() {
		if (this.tools.plattform !== 'windows') { return; } // don´t change focus on Android, to no show virtual keyboard on load
		window.setTimeout(() => {
			let el = this.elementRef.nativeElement;
			if (el.childElementCount > 0) { // Adr-Input Control,...
				el = el.querySelector('input');
			}
			el.focus();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.tools.plattform !== 'windows') { return; } // don´t change focus on Android,...

		if (changes['focus'] && changes['focus'].currentValue === true) {
			this.elementRef.nativeElement.focus();
		}
	}
}

@Directive({
	selector: 'input[currencyInput]',
	providers: [
		{ provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: CurrencyInputDirective },
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CurrencyInputDirective),
			multi: true,
		}
	]
})
export class CurrencyInputDirective {
	private el;
	private _value: number;

	@Input() decimalPoints = 2;

	constructor(private elementRef: ElementRef<HTMLInputElement>,
	) {
		this.el = this.elementRef.nativeElement;
	}

	// Function prototypes
	onChange = (value: number) => { };
	onTouched = () => { };

	@HostListener('input', ['$event.target.value'])
	onInput(value) {
		this._value = ToolService.parseNumber(value, this.decimalPoints);
		this.onChange(this._value); // here to notify Angular Validators
	}

	@HostListener('blur')
	_onBlur() {
		this.el.value = ToolService.formatNumber(this._value, this.decimalPoints);
	}

	@HostListener('focus', ['$event'])
	onFocus(event) {
		if (event.which === 9) {
			return false;
		}
		this.elementRef.nativeElement.select();
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event) {
		const e = event as KeyboardEvent;
		if (allowedKeys.indexOf(e.key) !== -1 || ['-', ',', '.'].indexOf(e.key) !== -1) { return; }
		if (e.ctrlKey && ['a', 'c', 'v', 'x'].indexOf(e.key)) { return; }
		if (!e.shiftKey && e.key >= '0' && e.key <= '9') { return; }

		e.preventDefault();
	}


	writeValue(value: any) {
		this._value = value;
		this.el.value = ToolService.formatNumber(this._value, this.decimalPoints);
	}

	registerOnChange(fn: (_: number) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
}

@Directive({ selector: '[numberInput]' })
export class NumberInputDirective {
	private el;

	@Input() min = -1e9;
	@Input() max = 1e9;

	constructor(
		private elementRef: ElementRef,
	) {
		this.el = this.elementRef.nativeElement;
	}


	@HostListener('focus', ['$event'])
	onFocus(event) {
		if (event.which === 9) { return false; }
		this.el.select();
	}

	@HostListener('blur', ['$event.target.value'])
	onBlur(value) {
		if (+value !== -1e9 && +value < this.min) {
			value = this.min;
		}
		if (+value !== 1e9 && +value > this.max) {
			value = this.max;
		}
		this.el.value = +value;
	}


	@HostListener('keydown', ['$event'])
	onKeyDown(event) {
		const e = event as KeyboardEvent;
		if (allowedKeys.indexOf(e.key) !== -1 || e.key ===  '-') { return; }
		if (e.ctrlKey && ['a', 'c', 'v', 'x'].indexOf(e.key) >= 0) { return; }
		if (!e.shiftKey && e.key >= '0' && e.key <= '9') { return; }

		e.preventDefault();
	}
}

@Directive({ selector: '[numberPercentInput]' })
export class NumberPercentInputDirective implements OnInit {
	private el;

	constructor(
		private elementRef: ElementRef,
		private control: NgControl,
		private cd: ChangeDetectorRef,
	) {
		this.el = this.elementRef.nativeElement;
	}

	ngOnInit() {
		// Template based form
		const ngModel = this.control as NgModel;
		if (ngModel.model) {
			ngModel.update.emit(ToolService.formatPercent(ngModel.model));
			this.cd.detectChanges();
		} else {
			if (typeof this.control.value === 'number') {
				this.el.value = ToolService.formatPercent(this.control.value);
			}
		}
	}


	@HostListener('focus', ['$event'])
	onFocus(event) {
		if (event.which === 9) { return false; }
		this.el.select();
	}

	@HostListener('blur', ['$event.target.value'])
	onBlur(value) {
		this.el.value = ToolService.formatPercent(value);
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event) {
		const e = event as KeyboardEvent;
		if (allowedKeys.indexOf(e.key) !== -1 || ['-', ',', '.', '%'].indexOf(e.key) !== -1) { return; }
		if (e.ctrlKey && ['a', 'c', 'v', 'x'].indexOf(e.key) >= 0) { return; }
		if (!e.shiftKey && e.key >= '0' && e.key <= '9') { return; }

		e.preventDefault();
	}
}

@Directive({ selector: '[dateInput]' })
export class DateInputDirective {
	private el: any;

	constructor(private elementRef: ElementRef,	) {
		this.el = this.elementRef.nativeElement;
	}

	@HostListener('focus', ['$event.target.value', '$event'])
	onFocus(value, event) {
		if (event.which === 9) {
			return false;
		}
		this.el.select();
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event) {
		const e = event as KeyboardEvent;
		if (allowedKeys.indexOf(e.key) !== -1 || e.key === '.') { return; }
		if (e.ctrlKey && ['a', 'c', 'v', 'x'].indexOf(e.key) >= 0) { return; }
		if (!e.shiftKey && e.key >= '0' && e.key <= '9') { return; }

		e.preventDefault();
	}
}

@Directive({ selector: '[keyInput]' })
export class KeyInputDirective {
	private el;

	constructor(
		private elementRef: ElementRef,
	) {
		this.el = this.elementRef.nativeElement;
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event) {
		const e = event as KeyboardEvent;
		if (allowedKeys.indexOf(e.key) !== -1 || e.key === '-') { return; }
		if (e.ctrlKey && ['a', 'c', 'v', 'x'].indexOf(e.key) >= 0) { return; }
		if (!e.shiftKey && e.key >= '0' && e.key <= '9') { return; }
		if (e.key >= 'A' && e.key <= 'Z' || 'ÄÖÜ'.indexOf(e.key) !== -1) { return; }
		if ((e.key >= 'a' && e.key <= 'z') || 'äöü'.indexOf(e.key) !== -1) {
			e.preventDefault();
			e.target['value'] += e.key.toUpperCase();
			const evt = new Event('input', { "bubbles": false, "cancelable": true });
			e.target.dispatchEvent(evt);
			return;
		}
		if (e.key === ' ') {
			e.preventDefault();
			e.target['value'] += '_';
			const evt = new Event('input', { "bubbles": false, "cancelable": true });
			e.target.dispatchEvent(evt);
			return;
		}

		e.preventDefault();
	}
}


/*
@Directive({
	selector: '[keyInput]',
	host: { '(input)': '$event'	}
})
export class KeyInputDirective {

	lastValue: string;

	constructor(public ref: ElementRef) { }

	@HostListener('keydown', ['$event'])
	onKeyDown(event) {
		const e = event as KeyboardEvent;
		if (allowedKeys.indexOf(e.key) !== -1) { return; }
		if (e.ctrlKey && ['a', 'c', 'v', 'x'].indexOf(e.key) >= 0) { return; }
		if (!e.shiftKey && e.key >= '0' && e.key <= '9') { return; }
		if (e.key >= 'a' && e.key <= 'z') { return; }
		if ([' ', 'ä', 'ö', 'ü', 'Ä', 'Ö', 'Ü'].indexOf(e.key) >= 0) { return; }

		e.preventDefault();
	}

	@HostListener('input', ['$event'])
	onInput($event) {
		const start = $event.target.selectionStart;
		const end = $event.target.selectionEnd;
		let val = $event.target.value.toUpperCase().replace(/ /g, '_');

		// $event.target.value = $event.target.value.toUpperCase();
		$event.target.setSelectionRange(start, end);
		$event.preventDefault();

		if (!this.lastValue || (this.lastValue && val.length > 0 && this.lastValue !== val)) {
			this.lastValue = this.ref.nativeElement.value = val;

			const evt = document.createEvent('HTMLEvents');
			evt.initEvent('input', false, true);
			event.target.dispatchEvent(evt);
		}
	}
}

*/
