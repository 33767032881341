import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, ToolService } from '../../_services';
import { PinStore, User } from '../../models/models';
import { Location } from '@angular/common';
import * as MD5 from 'md5';

@Component({
	selector: 'app-pin',
	templateUrl: './pin.component.html',
	styles: [
		'.pin-card { margin: 4em auto 0 auto; width:400px; }',
		'.circles { text-align:center; margin-top:2em; }',
		'.pinpad {width: 240px; margin: 0 auto;}',
		'.pinpad-button {font-size:2em!important; background-color:#fff;box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 3px 0px rgba(0, 0, 0, 0.13), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);}',
	]
})
export class PinLoginComponent implements OnInit {
	buttons = [
		{ action: '1', text: '1' },
		{ action: '2', text: '2' },
		{ action: '3', text: '3' },
		{ action: '4', text: '4' },
		{ action: '5', text: '5' },
		{ action: '6', text: '6' },
		{ action: '7', text: '7' },
		{ action: '8', text: '8' },
		{ action: '9', text: '9' },
		{ action: '', text: ''  },
		{ action: '0', text: '0' },
		{ action: 'Backspace', icon: 'backspace' },
	];

	pinDef = [0, 1, 2, 3];

	pin = '';
	password = '';

	isLoading = false;
	isSuccessful = false;
	setPin = false;

	pinStore: PinStore[] = [];

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		public location: Location,
		private authenticationService: AuthenticationService,
		public tools: ToolService,
	) {
	}


	ngOnInit() {
		this.setPin = this.route.snapshot.url[this.route.snapshot.url.length - 1].path === 'set' && !!ToolService.currentUser;
		if (this.setPin) {
		} else {
			this.authenticationService.logout();
		}

		this.pinStore = AuthenticationService.getPinStore();
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if ((event.target as any).tagName !== 'INPUT') {
			this.handleInput(event.key);
		}
	}

	handleInput(ch: string) {
		if (ch === 'Backspace') {
			this.pin = this.pin.substr(0, this.pin.length - 1);
		} else if (ch >= '0' && ch <= '9') {
			if (this.pin.length < this.pinDef.length) {
				this.pin += ch;
			}
		} else {
			return;
		}

		if (this.pin.length >= this.pinDef.length) {
			this.setPin ? this.checkPin() : this.doLogin();
		}
	}

	doLogin() {
		const pinHash = MD5(this.pin);
		const ps = this.pinStore.find(p => p.pinHash === pinHash);
		this.pin = '';

		if (!ps) {
			this.tools.showError('PIN nicht gefunden oder falsch.');
			return;
		}

		this.isLoading = true;
		const result = this.authenticationService.login(ps.login, ps.passwordHash, null);
		result.subscribe(() => {
			this.isLoading = false;
			this.isSuccessful = true;
		},
		err => {
			this.isLoading = false;

			// Delete PIN
			AuthenticationService.deletePin(ps.login);

			const errMsg = ToolService.getHttpErrorMessage(err) + ' Der PIN wurde auf diesem Arbeitsplatz gelöscht.';
			this.tools.showError(errMsg);
		});
	}

	checkPin() {
		const pinHash = MD5(this.pin);
		if (this.pinStore.find(ps => ps.pinHash === pinHash && ToolService.currentUser?.login !== ps.login)) {
			this.pin = '';
			this.tools.showError('Dieser Pin ist schon für einen anderen Benutzer registriert.');
		}
	}


	onSavePin() {
		if (!this.setPin || !ToolService.currentUser) { return; }

		const ps = { login: ToolService.currentUser.login, pinHash: MD5(this.pin), passwordHash: MD5(this.password) } as PinStore;
		AuthenticationService.updatePin(ps, this.pinStore);
		this.tools.showSuccess('Der PIN wurde auf diesem Arbeitsplatz erfolgreich gespeichert.');
		this.location.back();
	}

	onAppSettings() {
		location.href = 'https://app/settings';
	}


}


