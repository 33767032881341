import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ToolService } from './tool.service';

export interface ReceiptPrinterInfo {
	name: string;
	url: string;
	print: number;
}

/* ========================================================================= */
@Injectable()
export class ReceiptService {

	constructor(
		private tools: ToolService,
		private dataService: DataService,
	) {
		this.tools.printLoadPrinters = this.loadPrinters.bind(this);
	}

	public static receiptPrinter: ReceiptPrinterInfo = { name: 'Bondrucker', url: '', print: 0 };
	public static passPrinter: ReceiptPrinterInfo = { name: 'Passdrucker', url: '', print: 0 };
	public static orderPrinter: ReceiptPrinterInfo = { name: 'Abruf', url: '', print: 0 };
	public static finishPrinter: ReceiptPrinterInfo = { name: 'Ausgabe', url: '', print: 0 };
	public static kitchenPrinters: ReceiptPrinterInfo[] = [
		{ name: 'Küchendrucker 1', url: '', print: 0 },
		{ name: 'Küchendrucker 2', url: '', print: 0 },
		{ name: 'Küchendrucker 3', url: '', print: 0 },
		{ name: 'Küchendrucker 4', url: '', print: 0 }
	];
	public static printerMapping = [];

	// Printer ID´s
	public static pidNone= -1000;
	public static pidPass = -1;
	public static pidOrder = -2;
	public static pidFinish = -3;
	public static pidReceipt = 1;
	public static pidKitchen1 = 2;
	private usbDevice = null;
	private btDevice = null;

	public lastReceipt = '';
	public lastReceipts = [];

	static getPrinterName(printerID: number) {
		let name = '';
		if (printerID === ReceiptService.pidReceipt) {
			name = ReceiptService.receiptPrinter.name;
		} else if (printerID === ReceiptService.pidPass) {
			name = ReceiptService.passPrinter.name;
		} else if (printerID === ReceiptService.pidOrder) {
			name = ReceiptService.orderPrinter.name;
		} else if (printerID === ReceiptService.pidFinish) {
			name = ReceiptService.finishPrinter.name;
		} else if (printerID >= ReceiptService.pidKitchen1 && printerID < ReceiptService.kitchenPrinters.length + ReceiptService.pidKitchen1) {
			name = ReceiptService.kitchenPrinters[printerID - ReceiptService.pidKitchen1].name;
		}

		return name;
	}

	static mapPrinter(printerID: number, restaurantID: number) {
		const mapping = ReceiptService.printerMapping.find(m => m.source === printerID && m.restaurant == restaurantID);
		return mapping ? mapping.target : printerID;
	}

	static getPrinter(printerID: number): string {
		// Get global printers
		let ip = '';
		if (printerID === ReceiptService.pidReceipt) {
			ip = ReceiptService.receiptPrinter.url;
		} else if (printerID === ReceiptService.pidPass) {
			ip = ReceiptService.passPrinter.url;
		} else if (printerID === ReceiptService.pidOrder) {
			ip = ReceiptService.orderPrinter.url;
		} else if (printerID === ReceiptService.pidFinish) {
			ip = ReceiptService.finishPrinter.url;
		} else if (printerID >= ReceiptService.pidKitchen1 && printerID < ReceiptService.kitchenPrinters.length + ReceiptService.pidKitchen1) {
			ip = ReceiptService.kitchenPrinters[printerID - ReceiptService.pidKitchen1].url;
		}

		if (printerID === ReceiptService.pidReceipt) {
			const printerType = +localStorage.getItem('printer-type');
			switch (printerType) {
				case 0:
					break;
				case 1:
					ip = localStorage.getItem('printer-name');
					break;
				case 2:
					ip = 'bt';
					break;
				case 3:
					ip = 'usb';
					break;
				case 4:
					ip = 'mypos';
					break;
				case 5:
					ip = 'win';
					break;
				case 6:
					ip = 'none';
					break;
			}
		} /* in loadPrinters else {
			const json = localStorage.getItem('printer-map');
			if (json) {
				const mappings = JSON.parse(json);
				const mapping = mappings.find(m => m.id === printerID - 2);
				if (mapping) { ip = mapping.name; }
			}
		}
		*/

		return ip || '';
	}

	static getPrinterWidth(printerID: number): number {
		const printer = ReceiptService.getPrinter(printerID);
		let type = printer.split(',')[1];
		if (!type) { type = printer; }

		if (+type > 0) { return +type; } // direct characters

		switch (type) {
			case 'bt':
			case 'p20':
			case 't88':
			case 'bixolon':
				return 42;
			case 'u220':
			case 'mypos':
				return 32;
			default:
				return 46;
		}
	}

	async buildHtml(receipt: string) {
		let html = '';

		const rows = receipt.split(/\r?\n/);
		for (let i = 0; i < rows.length; i++) {
			let row = rows[i];
			let cmd = '';

			if (row.substr(0, 1) === '<') {
				cmd = 'xml';
			} else {
				const iPos = row.indexOf(':');
				if (iPos > 0 && iPos <= 2 && row[0] > '9') {
					cmd = row.substr(0, iPos);
					row = row.substr(iPos + 1);
				}
			}

			switch (cmd) {
				case 'im':
					html += `<img src="${row}">`;
					break;

				case 'qr':
					const imgData = await this.dataService.getQRCode(row).toPromise();
					html += `<img src="${imgData}">`;
					break;

				case 'h1':
					html += `<span style='font-size:200%'>${row}</span><br>`;
					break;

				case 'h2':
					html += `<span style='display:block; transform-origin:0 0; transform:scaleY(2);'>${row}</span><br>`;
					break;

				case 'b':
					html += `<b>${row}</b><br>`;
					break;

				default:
					html += row + '<br>';
			}
		}

		return html;
	}

	private async buildBase64Img(base64EncodedImage: string, grayscale: boolean): Promise<any> {
		return new Promise(async resolve => {
			const bits = grayscale ? 4 : 1;

			const image = new Image();
			image.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = image.width;
				canvas.height = image.height;

				const context = canvas.getContext('2d');
				context.drawImage(image, 0, 0);

				const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
				const paddedWidth = Math.ceil(imageData.width / 8 * bits) * 8 / bits;
				const bytes = new Uint8Array(paddedWidth * imageData.height / 8 * bits); // 1 bit (bw) or  4 bit (16 grays)

				for (let y = 0; y < imageData.height; y++) {
					for (let x = 0; x < imageData.width; x++) {
						const index = y * imageData.width + x;
						const byteIndex = Math.floor((y * paddedWidth + x) / 8 * bits);

						if (grayscale) {
							const brightness = (2 * imageData.data[index * 4] + imageData.data[index * 4 + 1] + 3 * imageData.data[index * 4 + 2]) / 6 / 16;
							const highByte = 1 - (index % 2);
							const mask = highByte ? brightness << 4 : brightness;
							bytes[byteIndex] |= mask;
						} else {
							if (imageData.data[index * 4] === 0) { // RGBA
								const bitInByteIndex = 7 - (index % 8);
								const mask = (1 << bitInByteIndex);
								bytes[byteIndex] |= mask;
							}
						}
					}
				}

				let data = '';
				for (let i = 0; i < bytes.byteLength; i++) {
					data += String.fromCharCode(bytes[i]);
				}

				const s = btoa(data);
				resolve({ data: s, width: imageData.width, height: imageData.height, grayscale: grayscale });
			};

			image.src = base64EncodedImage;
		});
	}

	loadPrinters() {
		this.tools.settingString('print', 'receipt').subscribe(s => ReceiptService.receiptPrinter.url = s);
		this.tools.settingString('print', 'pass').subscribe(s => ReceiptService.passPrinter.url = s);
		this.tools.settingString('print', 'order').subscribe(s => ReceiptService.orderPrinter.url = s);
		this.tools.settingString('print', 'finish').subscribe(s => ReceiptService.finishPrinter.url = s);
		this.tools.settingObj('print', 'kitchen').subscribe(async s => {
			if (s && s.length > 0) {
				ReceiptService.kitchenPrinters = s;
			}

			ReceiptService.kitchenPrinters.forEach(p => p.print = p.print || 0);

			// Load mappings
			this.tools.settingString('print', 'mapping').subscribe(s => {
				if (!s) { return; }
				const json = JSON.parse(s);
				ReceiptService.printerMapping = json;
			});

			// load local settings
			const json = localStorage.getItem('printer-map');
			if (json) {
				const mappings = JSON.parse(json);
				mappings.forEach(m => {
					if (!m.id) { return; }
					ReceiptService.kitchenPrinters[m.id].url = m.name;
					ReceiptService.kitchenPrinters[m.id].print = m.print || 0;
				});
			}
		});
	}

	async printReceipt(printerID: number | string, receipt: string, printLogo = true) {

		if (!receipt) { return; }

		let ip = '';
		let sound = 'a'
		if (typeof printerID === 'string') {
			ip = printerID;
		} else {
			ip = ReceiptService.getPrinter(printerID);
			sound = String.fromCharCode(97 + Math.max((printerID -1) % 5, 0));
		}

		this.lastReceipt = receipt;

		const rows = receipt.split(/\r?\n/);
		const receiptTitle = ToolService.formatTime(new Date()) + ': ' + (rows.find(r => r.startsWith('h1:'))?.replace('h1:', '') || '');

		// Store in history
		if (this.lastReceipts.find(l => l.receipt === receipt) == null) {
			if (this.lastReceipts.length > 10) {
				this.lastReceipts.splice(0, 1);
			}

			this.lastReceipts.push({ name: receiptTitle, receipt: receipt, printer: ip, logo: printLogo });
		}

		// ip format foprinter1,P20
		let printerType = '';
		const arr = ip.split(',');
		if (arr.length > 1) {
			ip = arr[0];
			printerType = arr[1];
		}

		if (ip === 'none') { return; }
		if (!ip || ip == 'display') { // send to display box
			const html = await this.buildHtml(receipt);
			this.tools.msgBox(html, 'Belegdruck ' + ReceiptService.getPrinterName(+printerID), 'receipt-display', '380px').subscribe();
			return;
		}


		if (ip === 'win') { return this.printWindows(receipt, printLogo); }
		if (ip.startsWith('usb')) { return this.printEscPOS(receipt, false); }
		if (ip.startsWith('bt')) { return this.printEscPOS(receipt, true); }
		if (ip.startsWith('mypos')) { return this.printMyPOS(receipt); }

		let body = '<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">';

		if (printLogo) {
			const logo = await this.tools.getFile('receiptLogo.*').toPromise()
				.catch(() => { });

			if (logo) {
				const grayscale = logo.name.indexOf('.jpg') > 0;
				const imgData = await this.buildBase64Img(logo.content, grayscale);

				body += `<image width="${imgData.width}" height="${imgData.height}" align="center" mode="${imgData.grayscale ? 'gray16' : 'mono'}">${imgData.data}</image>`;
			} else {
				body += '<logo key1="48" key2="48" align="center" />';
			}
		}

		const font = printerType === 'P20' ? 'e' : 'a';
		body += `<text lang="de" smooth="true" align="left" font="font_${font}">&#10;</text>`;

		for (let i = 0; i < rows.length; i++) {
			let row = rows[i];
			let cmd = '';
			if (row.substr(0, 1) === '<') {
				cmd = 'xml';
			} else {
				const iPos = row.indexOf(':');
				if (iPos > 0 && iPos <= 2 && row[0] > '9') {
					cmd = row.substr(0, iPos);
					row = ToolService.escapeHTML(row.substr(iPos + 1));
				}
			}

			switch (cmd.substr(0, 2)) {
				case 'be':
					body += `<sound pattern="pattern_${ sound }" />`;
					break;
				case 'dr':
					body += '<pulse />';
					break;
				case 'qr':
					body += `<symbol type="qrcode_model_2" align="center" width="4">${row}&#10;</symbol>`;
					break;
				case 'h1':
					body += `<text dw="true" dh="true" >${row}&#10;</text><text dw="false" dh="false"/>`;
					break;
				case 'h2':
					body += `<text dh="true" >${row}&#10;</text><text dh="false"/>`;
					break;
				case 'xm':
					body += row;
					break;
				case 'im':
					const imgData = await this.buildBase64Img(row, true);
					body += `<image width="${imgData.width}" height="${imgData.height}" align="center" mode="${imgData.grayscale ? 'gray16' : 'mono'}">${imgData.data}</image>`;
					break;
				default:
					// normal text
					let align = 'left';
					if (cmd.indexOf('c') >= 0) {
						align = 'center';
					} else if (cmd.indexOf('r') >= 0) {
						align = 'right';
					}

					let bold = 0;
					if (cmd.indexOf('b') >= 0) { bold = 1; }

					body += `<text align="${align}" em="${bold}">${row}&#10;</text>`;
					break;
			}

		}

		if (printerType === 'bixolon') {
			for (let i = 0; i < 5; i++) { body += '<text>&#10;</text>'; }
		}

		body += '<cut type="feed" /></epos-print>';

		// Send print document
		const printers = ip.split(';'); // strip type
		printers.forEach(printer => {
			if (printer && printer.indexOf('.') < 0) { printer += '.foprinter.com'; }

			const url = `https://${printer}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
			let req =
				'<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
				'<s:Header>' +
				'<parameter xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">' +
				'<devid>local_printer</devid>' +
				'<timeout>10000</timeout>' +
				'<printjobid>FOCloud</printjobid>' +
				'</parameter>' +
				'</s:Header>' +
				'<s:Body>' + body + '</s:Body>' +
				'</s:Envelope>';

			if (this.tools.isDebug) { console.log(`Print to ${printer} (ID: ${printerID})`); }

			fetch(url, {
				method: 'post',
				body: req,
				mode: 'cors'
			})
				.then(response => response.text())
				.then(xml => {
					if (this.tools.isDebug) { console.log('Print result:', xml); }

					const start = xml.indexOf('status="') + 8;
					const status = +xml.substring(start, xml.indexOf('"', start));

					if (!ToolService.testFlag(status, 0x2)) {
						const msg = `Fehler beim Drucken auf ${printer}: ${this.statusText(status)}`;
						// debug.response += '\r\n' + msg;
						this.tools.showError(msg);
						console.error(msg);
					} else {
						return new Promise((resolve, reject) => resolve('Druchbefehl erfolgreich gesendet.'));
						// success !!
					}
				})
				.catch((err) => {
					const msg = `Fehler beim Drucken auf '${printer}': ${err}`;
					this.tools.showError(msg);
					console.error(msg);
				});
		});
	}

	private statusText(status: number): string {
		let msg = '';

		if (ToolService.testFlag(status, 0x1)) {
			msg = ToolService.append(msg, 'Keine Antwort vom Drucker.');
		}
		if (ToolService.testFlag(status, 0x20)) {
			msg = ToolService.append(msg, 'Abdeckung ist geöffnet.');
		}
		if (ToolService.testFlag(status, 0x100)) {
			msg = ToolService.append(msg, 'Drucker wartet auf online recovery.');
		}
		if (ToolService.testFlag(status, 0x400)) {
			msg = ToolService.append(msg, 'Mechanischer Druckerfehler.');
		}
		if (ToolService.testFlag(status, 0x800)) {
			msg = ToolService.append(msg, 'Mechanischer Cutterfehler.');
		}
		if (ToolService.testFlag(status, 0x20000)) {
			msg = ToolService.append(msg, 'Papierrolle ist beinahe verbraucht.');
		}
		if (ToolService.testFlag(status, 0x20000)) {
			msg = ToolService.append(msg, 'Kein Papier im Drucker.');
		}
		return msg;
	}

	private str2ansiBuffer(s: string): Buffer {
		const bytes = [];

		// CP 1252
		for (let i = 0; i < s.length; i++) {
			switch (s[i]) {
				case 'Ä': bytes.push(196); break;
				case 'Ö': bytes.push(214); break;
				case 'Ü': bytes.push(220); break;
				case 'ä': bytes.push(228); break;
				case 'ö': bytes.push(246); break;
				case 'ü': bytes.push(252); break;
				case 'ß': bytes.push(223); break;
				case '\u20ac': bytes.push(128); break;
				default: bytes.push(s.charCodeAt(i) & 0xff);
			}
		}

		return Buffer.from(bytes);
	}

	private toBinary(string) {
		const codeUnits = new Uint8Array(string.length);
		for (let i = 0; i < codeUnits.length; i++) {
			codeUnits[i] = string.charCodeAt(i);
		}
		return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
	}

	private async printWindows(receipt: string, printLogo: boolean) {
		const logo = await this.tools.getFile('receiptLogo.*').toPromise().catch(() => { });
		const imgLogo = printLogo && logo ? `<img src="${logo.content}">` : '';
		const body = await this.buildHtml(receipt);

		const html = `
<style>
    .receipt-container { 
		width:340px; 
		margin:20mm; 
		font-family: 'Lucida Console', Monaco, monospace;white-space: pre-wrap; 
		font-size:12px;	
	}
    img { display:block; margin:0 auto; width:60%; }
</style>
<div class="receipt-container">
${imgLogo}
${body}
</div>`;

		ToolService.print(html);
		return;
    }

	private async printMyPOS(receipt: string) {
		if (this.tools.enviroment !== 'app') { return; }

		const fs = await this.tools.getFile('receiptLogo.*').toPromise()
			.catch(() => { });

		if (fs) {
			const start = fs.content.indexOf(',');
			const img = fs.content.substr(start + 1);
			receipt = `im:${img}\r\n` + receipt;
		}

		let base64 = this.toBinary(receipt);
		base64 = base64.replace(/\+/g, '-');
		base64 = base64.replace(/\//g, '_');
		location.href = 'https://app/myposprint/' + base64;
	}


	private async printEscPOS(receipt: string, bluetooth: boolean) {
		(window as any).global = window;
		(window as any).global.Buffer = (window as any).global.Buffer || require('buffer').Buffer;

		let buffer = Buffer.from([0x1b, 0x74, 16]); // cp-1252

		const logo = await this.tools.getFile('receiptLogo.*').toPromise()
			.catch(() => { });

		if (logo) {
			const grayscale = logo.name.indexOf('.jpg') > 0;
			const imgData = await this.buildBase64Img(logo.content, grayscale);

			buffer = Buffer.concat([buffer, Buffer.from([0x1d, 0x76, 0x30, 48, (imgData.width >> 3) & 0xff, 0x00, imgData.height & 0xff, (imgData.height >> 8) & 0xff])]);
			const bytes = atob(imgData.data);
			buffer = Buffer.concat([buffer, Buffer.from(bytes, 'binary'), Buffer.from('\r\n')]);
		} else {
			buffer = Buffer.concat([buffer, Buffer.from([0x1d, 0x28, 0x4c, 0x06, 0x00, 0x30, 0x45, 0x30, 0x30, 0x01, 0x01])]); // GS(L .. print NV graphic
		}

		// on bluetooth select small font
		const font = bluetooth ? Buffer.from([0x1b, 0x21, 0x01]) : Buffer.from([0x1b, 0x21, 0x00]); // Esc!1 select Font 1/2
		buffer = Buffer.concat([buffer, font]);

		const rows = receipt.split(/\r?\n/);
		rows.forEach(row => {
			let cmd = '';
			const iPos = row.indexOf(':');
			if (iPos > 0 && iPos < 4) {
				cmd = row.substr(0, iPos);
				row = row.substr(iPos + 1);
			}

			switch (cmd.substr(0, 2)) {
				case 'qr':
					const l = row.length + 3;
					buffer = Buffer.concat([buffer,
						Buffer.from([0x10, 0x1b, 0x61, 0x31]),
						Buffer.from([0x1d, 0x28, 0x6b, l % 256, l / 256, 0x31, 0x50, 0x30]),
						this.str2ansiBuffer(row),
						Buffer.from([0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x51, 0x30])]);
					break;
				case 'h1':
					const largeFont = bluetooth ? Buffer.from([0x1b, 0x21, 0x31]) : Buffer.from([0x1b, 0x21, 0x30]);
					buffer = Buffer.concat([buffer, largeFont, this.str2ansiBuffer(row), font]); // Esc! 48
					break;
				case 'h2':
					const highFont = bluetooth ? Buffer.from([0x1b, 0x21, 0x11]) : Buffer.from([0x1b, 0x21, 0x10]);
					buffer = Buffer.concat([buffer, highFont, this.str2ansiBuffer(row), font]); // Esc! 16
					break;
				default:
					let align = '\x1ba0';
					if (cmd.indexOf('c') >= 0) {
						align = '\x1ba1';
					} else if (cmd.indexOf('r') >= 0) {
						align = '\x1ba2';
					}

					let bold = '\x1bE0';
					if (cmd.indexOf('b') >= 0) { bold = '\x1bE1'; }

					buffer = Buffer.concat([buffer, Buffer.from(align + bold), this.str2ansiBuffer(row), Buffer.from('\r\n')]);
					break;
			}
		});

		buffer = Buffer.concat([buffer, Buffer.from('\r\n\r\n\r\n\x1bi')]);

		if (bluetooth) {// small font
			buffer = Buffer.concat([Buffer.from([0x1b, 0x21, 0x01]), buffer]); // Esc!1 select Font 2
		}

		// App Printing
		if (bluetooth && this.tools.enviroment === 'app') {
			let base64 = buffer.toString('base64');
			base64 = base64.replace(/\+/g, '-');
			base64 = base64.replace(/\//g, '_');
			location.href = 'https://app/print/' + base64;
			return;
		}

		if (bluetooth) {
			const btNavigator: any = navigator;
			if (!btNavigator.bluetooth) {
				this.tools.showError('Dieser Browser unterstütz Bluetooth-Druck nicht.');
				return;
			}

			if (this.btDevice == null) {
				const options = {
					// acceptAllDevices: true,
					filters: [{ namePrefix: 'SPP-R200' }, { namePrefix: 'BlueTooth Printer' }, ],
					optionalServices: ['generic_access', '49535343-fe7d-4ae5-8fa9-9fafd205e455']
				};
				this.btDevice = await btNavigator.bluetooth.requestDevice(options)
					.catch(err => this.tools.showError(err));

				if (this.tools.isDebug) { console.log('[BLE::Info] Found ' + this.btDevice?.name); }
			}

			if (this.btDevice == null) { return; }

			const gatt = await this.btDevice.gatt.connect()
				.catch(err => this.tools.showError(err));
			if (this.tools.isDebug) { console.log('[BLE::Info] GATT info %o', gatt); }
			if (!gatt) { return; }

			let printService = '49535343-fe7d-4ae5-8fa9-9fafd205e455'; // Mumbuy
			let printCharacteristic = '49535343-8841-43f4-a8d4-ecbe34729bb3'; // mumbuy
			let chunkSize = 256;

			// Bixolon specific parameter
			if (this.btDevice.name.startsWith('SPP-R200')) {
				printService = '00005500-d102-11e1-9b23-74f07d000000'; // bixolon
				printCharacteristic = '00005501-d102-11e1-9b23-74f07d000000'; // bixolon
				chunkSize = 44;
			}

			const primaryService = await gatt.getPrimaryService(printService);
			if (this.tools.isDebug) { console.log('[BLE::Info] Primary Service info %o', primaryService); }

			const characteristic = await primaryService.getCharacteristic(printCharacteristic);
			if (this.tools.isDebug) { console.log('[BLE::Info] Characteristic info %o', characteristic); }

			for (let i = 0; i < buffer.length; i += chunkSize) {
				await characteristic.writeValue(buffer.slice(i, Math.min(i + chunkSize, buffer.length)));
			}

		} else {
			const usbNavigator: any = navigator;
			if (!usbNavigator.usb) {
				this.tools.showError('Dieser Browser unterstütz USB-Druck nicht.');
				return;
			}

			if (this.usbDevice == null) {
				if (this.usbDevice == null) { // Already connected ?
					const devices: any[] = await usbNavigator.usb.getDevices();
					this.usbDevice = devices.find(d => d.vendorId === 1208);
				}

				if (this.usbDevice == null) {
					this.usbDevice = await usbNavigator.usb.requestDevice({ filters: [{ vendorId: 1208 }] })
						.catch(err => { this.tools.showError(err); });
				}

				await this.usbDevice.open()
					.catch(err => { this.tools.showError(err); });

				await this.usbDevice.selectConfiguration(1);
				await this.usbDevice.claimInterface(this.usbDevice.configuration.interfaces[0].interfaceNumber)
					.catch(err => { this.tools.showError(err); });
			}

			this.usbDevice.transferOut(1, buffer)
				.catch(err => { this.tools.showError(err); });
		}
	}


	printLastReceipt(index = -1) {
		let receipt: string = null;
		let printer = ReceiptService.pidReceipt;
		let logo = true;
		if (index >= 0) {
			receipt = this.lastReceipts[index]?.receipt;
			printer = this.lastReceipts[index]?.printer;
			logo = this.lastReceipts[index]?.logo;
		} else {
			receipt = this.lastReceipt;
		}

		if (!receipt) { return; }
		this.printReceipt(printer, receipt, logo);
	}

}
