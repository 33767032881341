import { Component, Inject, OnInit } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageService, ToolService } from '../../_services';


@Component({
	selector: 'fo-image-optimize',
	template: `
<h4 mat-dialog-title mat-dialog-title-draggable>Bild optimieren</h4>
<mat-dialog-content (keydown.enter)="onOk()" (keydown.escape)="dialogRef.close(null)">
	<input type="file" (change)="fileChangeEvent($event)" style="display:none"/>
	<div class="row" *ngIf="!profilePic">
		<mat-form-field class="full-width col">
			<mat-label>Verwendung</mat-label>
			<mat-select [(ngModel)]="usageWidth" (selectionChange)="adjustValues()">
				<mat-option *ngFor="let u of usages" [value]="u.width"> {{ u.name }}</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="full-width col">
			<mat-label>Seitenverhältnis</mat-label>
			<mat-select [(ngModel)]="ratio" (selectionChange)="adjustValues()">
				<mat-option *ngFor="let r of ratios" [value]="r.value"> {{ r.name }}</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="full-width col">
			<mat-label>Breite (px)</mat-label>
			<input matInput [(ngModel)]="width" (change)="adjustValues()" [disabled]="usageWidth !== 0">
		</mat-form-field>

		<mat-form-field class="full-width col">
			<mat-label>Höhe (px)</mat-label>
			<input matInput [(ngModel)]="height" (selection)="adjustValues()" [disabled]="ratio !== 0">
		</mat-form-field>
	</div>

	<div class="row">
		<div class="col">
			<image-cropper
				[imageChangedEvent]="imageChangedEvent"
				[imageBase64]="sourceImage"
				format="jpeg"
				[imageQuality]="90"
				[maintainAspectRatio]="this.ratio !== 0"
				[aspectRatio]="this.ratio ? this.ratio : this.width / this.height"
				[resizeToWidth]="this.width"
				[onlyScaleDown]="true"
				[roundCropper]="profilePic"
				[transform]="transform"
				(imageCropped)="imageCropped($event)"
				(imageLoaded)="imageLoaded()"
				(cropperReady)="cropperReady()"
				(loadImageFailed)="loadImageFailed()"
			></image-cropper>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<div fxFlex>
		<button mat-raised-button color="primary" (click)="onOk()">Ok</button>
		<button mat-raised-button color="primary" [mat-dialog-close]="">Abbrechen</button>
	</div>

	<button mat-icon-button tabindex="-1" (click)="onZoomIn()"><mat-icon>zoom_in</mat-icon></button>
	<button mat-icon-button tabindex="-1" (click)="onZoomOut()"><mat-icon>zoom_out</mat-icon></button>
</mat-dialog-actions>

`,
})
export class ImageOptimizeComponent implements OnInit {
	imageChangedEvent: any;

	sourceImage = '';
	croppedImage = '';

	usageWidth = 0;
	ratio = 1;

	width = 0;
	height = 0;

	srcWidth = 0;
	srcHeight = 0;

	transform: ImageTransform = {};
	scale = 1;

	usages = [
		{ name: 'Titelbild', width: 600, },
		{ name: 'Normale Größe', width: 300, },
		{ name: 'Klein', width: 200, },
		{ name: 'Profilbild', width: 150, },
		{ name: 'Icon', width: 40, },
		{ name: 'Benutzerdefiniert', width: 0, }];

	ratios = [
		{ name: 'Quadratisch (1:1)', value: 1 },
		{ name: 'Querformat (3:2)', value: 3 / 2 },
		{ name: 'Querformat (4:3)', value: 4 / 3 },
		{ name: 'Hochformat (2:3)', value: 2 / 3 },
		{ name: 'Hochformat (3:4)', value: 3 / 4 },
		{ name: 'Benutzerdefiniert', value: 0 }];

	profilePic = false;

	constructor(
		public tools: ToolService,
		public dialogRef: MatDialogRef<ImageOptimizeComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.sourceImage = data.image;
		this.profilePic = (data.options === 'profile');
	}

	ngOnInit() {
		const img = new Image();
		img.src = this.sourceImage;
		img.onload = () => {
			this.srcWidth = img.width;
			this.srcHeight = img.height;

			this.usageWidth = Math.min(this.srcWidth, 600);
			this.ratio = this.srcWidth / this.srcHeight;
			if (!this.ratios.find(r => r.value === this.ratio)) { this.ratio = 0; }
			if (this.profilePic) { this.ratio = 1; }
			this.adjustValues();
		};
	}

	adjustValues() {
		const ratio = this.ratio ? this.ratio : this.srcWidth / this.srcHeight;
		if (this.usageWidth) {
			this.width = this.usageWidth;
			this.height = Math.round(this.usageWidth / ratio);
		} else if (!this.width) {
			this.width = this.srcWidth;
			this.height = this.srcHeight;
		} else {
			this.height = Math.round(this.width / ratio);
		}
	}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		// show cropper
	}

	cropperReady() {
		// cropper ready
	}

	loadImageFailed() {
		// show message
	}

	onZoomOut() {
		this.scale -= .1;
		this.transform = {
			...this.transform,
			scale: this.scale
		};
	}

	onZoomIn() {
		this.scale += .1;
		this.transform = {
			...this.transform,
			scale: this.scale
		};
	}

	async onOk() {
		// resize always (HACK: ngx-cropper problem)
		this.croppedImage = await ImageService.imageResize(this.croppedImage, this.width, this.height).toPromise();


		this.dialogRef.close(Promise.resolve(this.croppedImage));
	}

}
