import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NonNullableFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../_services';
import { ToolService, AuthenticationService } from '../../_services';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styles: ['.login-card {	margin:4em auto 0 auto; width:400px;}', '.login-card-xs {margin:4em auto 0 auto; width:100%; border: none;	box-shadow: none; }']
})
export class LoginComponent implements OnInit, AfterViewInit {

	form: FormGroup;

	isLoading = false;
	isSuccessful = false;
	phase = 0;
	hasPIN = false;
	returnUrl: string;
	hide = true;

	@ViewChild('login') loginField: ElementRef;
	@ViewChild('securityCode') scField: ElementRef;

	/** login ctor */
	constructor(
		private formBuilder: NonNullableFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		public tools: ToolService,
	) { }

	validationMsg(fieldName: string): string {
		return FormService.validationMsg(this.form, fieldName);
	}

	ngOnInit() {
		// built contact form
		this.form = this.formBuilder.group({
			login: ['', [Validators.required]],
			password: ['', [Validators.required]],
			securityCode: [''],
		});

		// reset login status
		this.authenticationService.logout();

		// get return url from route parameters or default to '/'
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

		this.hasPIN = AuthenticationService.getPinStore().length > 0;

	}

	ngAfterViewInit() {
		this.setPhase(0);
	}

	setPhase(p) {
		this.phase = p;
		if (this.phase === 0) {
			this.form.get('securityCode').patchValue('');
			this.form.get('password').patchValue('');
		}
		setTimeout(() => (p ? this.scField : this.loginField).nativeElement.focus());
	}

	onSubmit(formData: any) {
		if (this.form.invalid) {
			return;
		}

		this.isLoading = true;
		this.authenticationService.login(formData.value['login'], formData.value['password'], formData.value['securityCode'])
			.subscribe(result => {
				this.isLoading = false;

				if (result?.message) {
					this.tools.showSuccess(result?.message);
				} else if (result?.token) {
					this.isSuccessful = true;
					this.router.navigate([this.returnUrl]);
				}

				if (result?.needSC) { this.setPhase(1); }
			},
				err => {
					this.isLoading = false;
					this.setPhase(0);
					this.tools.showError(err);
				});

	}

	onAppSettings() {
		location.href = 'https://app/settings';
	}
}
