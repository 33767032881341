import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Booking, Room, Rates, RatesChange, Message, PriceInfo, IDictionary, AccountInfo, Arrangement, DocJournal, DateEntry } from '../models/models';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { map, catchError } from 'rxjs/operators';
import { ToolService } from './tool.service';
import { Const } from '../models/constants';

const endpoint = '/api/bookings/';
const endpointRooms = '/api/rooms/';
const endpointCM = '/api/channelManager/';
const endpointRates = '/api/rates/';
const endpointAdr = '/api/adr/';
const endpointJournal = '/api/journal/';
const endpointExternal = '/api/external/';
const endpointArrangements = '/api/arrangements/';
const endpointMessage = '/api/message/';
const endpointDates = '/api/dates/';


const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};

const cacheBusterRoom$ = new Subject<void>();
const cacheBusterArrangement$ = new Subject<void>();

@Injectable({
	providedIn: 'root'
})
export class HotelService {

	constructor(
		private http: HttpClient,
		private tools: ToolService
	) {
		this.tools.settingObj('hotel', 'bookingstatus').subscribe(para => {
			HotelService.bookingStatus = [];
			for (let i = 0; i < para.length; i++) {
				HotelService.bookingStatus.push({ name: para[i].name, color: para[i].color ? para[i].color : Const.colors[i] });
			}
		});

		this.hotelRoute = HotelService.getHotelRoute();
	}

	public static roomStatus = [
		{ name: 'Sauber', icon: 'lens', color: '#a5d6a7' },
		{ name: 'Kontrolliert', icon: 'lens', color: '#79fd7d' },
		{ name: 'Reinigen!', icon: 'lens', color: '#ffba57' },
		{ name: 'Message', icon: 'mail_outline', color: '' },
		{ name: 'Gepäck', icon: 'luggage', color: '' },

/*		{ name: 'Telefon C/I', icon: 'local_phone', color: '' },
		{ name: 'Weckruf', icon: 'alarm_on', color: '' },
*/
		{ name: 'Out of Order', icon: 'warning', color: '#ef9a9a' }];

	public static bookingStatus = [];

	public static bookingStatus1 = [
		{ name: '', icon: '' },
		{ name: 'Angebot versendet', icon: 'send' },
		{ name: 'Angebot Erinnerung versendet', icon: 'notifications_active' },
		{ name: 'Bestätigung versendet', icon: 'contact_mail' },
		{ name: 'Buchung bestätigt', icon: 'check_circle_outline' },
		{ name: 'PreCheckin versendet', icon: 'map' },
		{ name: 'PostCheckout versendet', icon: 'star_rate' },
		{ name: 'Inaktiv', icon: 'do_disturb' },
	];

	public static occColors = ['#fff', '#ccffcc', '#ccff96', '#fefb8d', '#ffe479', '#ffb380', '#ff0000', '#bf0000'];

	public static noShow = ['NoShow', 'Cancel', 'PM'];

	public hotelRoute: string;

	static getHotelRoute(): string {
		let storedHotelLink = '';

		const s = localStorage.getItem('hotel-state');
		if (s) {
			storedHotelLink = JSON.parse(s)[0];
		}

		let route = 'booking/';
		switch (storedHotelLink) {
			case 'b':
				route += 'list';
				break;
			case 'c':
				route += 'cat';
				break;
			default:
				route += 'plan';
				break;
		}
		return route;
	}

	static checkArrangement(booking: Booking, room: Room, arr: Arrangement): string {
		let warning = '';
		if (!arr || isNaN(booking.from.getTime()) || isNaN(booking.to.getTime())) { return; }

		const days = ToolService.dateDiff(booking.from, booking.to);

		if (arr.fromDays > 0 && arr.fromDays > days) { warning = `Das gewählte Arrangement gilt erst ab ${arr.fromDays} Tagen`; }
		if (arr.toDays > 0 && arr.toDays < days) { warning = `Das gewählte Arrangement gilt nur bis zu ${arr.fromDays} Tagen`; }
		if (arr.dateRange) {
			let fit = false;
			const dateRange = arr.dateRange.replace(/(\,|\;)/g, '|');
			const dates = dateRange.split('|');
			for (let i = 0; i < dates.length; i++) {
				const d = dates[i].split('-');
				if (d.length !== 2) { continue; }
				const from = ToolService.parseDate(d[0])?.getTime();
				const to = ToolService.parseDate(d[1])?.getTime();
				const bf = ToolService.parseDate(booking.from).getTime();
				const bt = ToolService.parseDate(booking.to).getTime();
				if (bf < from || bf > to || bt < from || bt > to) {
					warning = 'Das gewählte Arrangement ist nur in folgenden Zeiträumen buchbar: ' + arr.dateRange.replace(/\|/g, ', ');
				} else {
					fit = true;
				}
			}
			if (fit) { warning = ''; }
		}

		if (room && arr.house !== undefined && arr.house >= 0) {
			if (room.house !== arr.house) {
				warning = `Das gewählte Arrangement ist nur im Haus ${ arr.house } gültig.`;
			}
		}

		return warning;
	}



	static getHumanPersCat(persCats, booking: Booking, includeCat0 = false) {
		const counts: IDictionary = [];

		for (let i = 0; i < persCats.length; i++) {
			counts[i] = 0;
		}

		for (let i = 0; i < booking.persCount; i++) {
			const p = +(booking.persCat || '')[i];
			counts[p]++;
		}

		let result = '';
		for (let i = includeCat0 ? 0 : 1; i < persCats.length; i++) {
			if (counts[i] > 0) {
				const name = persCats[i].name;

				result = ToolService.append(result, `${counts[i]} ${name}`, ', ');
			}
		}

		return result;
	}

	static getPersCat(persCat: string, index: number) {
		const c = persCat.charCodeAt(index);
		return c > 57 ? c - 55 : c - 48;
	}

	static getTooltip(info: PriceInfo) {
		if (!info) { return ''; }

		let tooltip =
`Preis: ${ToolService.formatCurrency(info.total)}
Inkl. Taxe: ${ToolService.formatCurrency(info.grandTotal)}
Logis: ${ToolService.formatCurrency(info.lodging)}
Anzahlung: ${ToolService.formatCurrency(info.deposit)}
Rabatt: ${ToolService.formatPercent(info.discount)}%
Auslastung: ${info.occupation}%
Zimmer verfügbar: ${info.free}
`;
		if (info.allotmentID > 0) {
			tooltip += `Kontingent ${info.allotmentID}, ${info.allotmentRemain} Zimmer verfügbar`;
		}
		return tooltip;
	}

	static getOccupationColor(occ: number): string {
		const ci = Math.round(occ / 20);
		const color = ci < HotelService.occColors.length ? HotelService.occColors[ci] : HotelService.occColors[HotelService.occColors.length - 1];
		return color;
	}

	static formatBookingLine(booking: Booking): string {
		const info = `${booking.text.split('|'[0])} ${booking.room} (${ToolService.dateRange2Str(booking.from, booking.to)})`;
		return info;
	}

	static renderBooking(e) {
		if (e.bid) { return; }

		if (e.resource === 'events') {
			return;
		}

		if (e.color > 0) {
			e.barColor = Const.colors[e.color];
		} else {
			e.barHidden = true;
		}

		if (!Const.hotelColors[Const.clrBooking]) { // empty for full bar filling
			e.backColor = e.color > 0 ? Const.colors[e.color] : '#ffffff';
		} else {
			e.backColor = Const.hotelColors[Const.clrBooking];
		}

		if (ToolService.testFlag(e.flags, Const.bfTentative)) { e.backColor = Const.hotelColors[Const.clrCheckTentative]; }
		if (ToolService.testFlag(e.flags, Const.bfCheckIn)) { e.backColor = Const.hotelColors[Const.clrCheckIn]; }
		if (ToolService.testFlag(e.flags, Const.bfOffer)) { e.backColor = Const.hotelColors[Const.clrOffer]; }
		if (ToolService.testFlag(e.flags, Const.bfAllotment)) { e.backColor = Const.hotelColors[Const.clrAllotment]; e.cssClass = 'allotment'; }
		if (ToolService.testFlag(e.flags, Const.bfOwn)) { e.backColor = null; e.cssClass = 'own-back'; }
		if (ToolService.testFlag(e.flags, Const.bfCheckOut)) {
			e.backColor = Const.hotelColors[Const.clrCheckOut];
			e.moveDisabled = true;
			e.resizeDisabled = true;
		}

		// Areas
		const days = ToolService.dateDiff(e.start, e.end);
		const areas = [];

		// Icon Area
		let showIcon = false;
		let iconsHTML = '<div style="font-size: 8pt;">';

		if (e.groupID > 0) {
			iconsHTML += `<i class="material-icons icon12">${ToolService.testFlag(e.flags, Const.bfPaymaster) ? 'account_balance' : 'group'}</i>`;
			showIcon = true;
		}

		if (ToolService.testFlag(e.flags, Const.bfSub) && days > 2) {
			iconsHTML += `<i class="material-icons icon12">recent_actors</i>`;
			showIcon = true;
		}

		if (ToolService.testFlag(e.flags, Const.bfLocked) || e.status > 0 && (!showIcon || days > 1)) {
			const color = HotelService.bookingStatus[e.status] ? HotelService.bookingStatus[e.status].color : '#000';
			iconsHTML += `<i class="material-icons icon12" style="color: ${color}">${ToolService.testFlag(e.flags, Const.bfLocked) ? 'lock' : 'lens'}</i>`;
			showIcon = true;
		}

		if (ToolService.testFlag(e.flags, Const.bfHasDeposit) && (!showIcon || days > 1)) {
			iconsHTML += '<i class="material-icons icon12">monetization_on</i>';
			showIcon = true;
		}

		if (e.status1 > 0 && (!showIcon || days > 1)) {
			iconsHTML += `<i class="material-icons icon12">${HotelService.bookingStatus1[e.status1].icon}</i>`;
			showIcon = true;
		}

		if (ToolService.testFlag(e.flags, Const.bfHasTraces) && days > 2) {
			iconsHTML += '<i class="material-icons icon12">info_outline</i>';
			showIcon = true;
		}
		iconsHTML += '</div>';

		if (showIcon) { areas.push({ bottom: 1, right: 4, html: iconsHTML }); }


		// customize the reservation HTML: text, start and end dates
		let eventClass = 'fo_event_text0';
		if (showIcon) { eventClass = days > 2 ? 'fo_event_text2' : 'fo_event_text1'; }
		const count = e.count > 1 ? e.count + '*' : '';
		e.html = `<div class='${eventClass}'>${count}${e.text} <i>${e.groupText || ''}</i></div>`;

		// Set font collor depending on background
		if (e.backColor) {
			var r = parseInt(e.backColor.substring(1, 3), 16);
			var g = parseInt(e.backColor.substring(3, 5), 16);
			var b = parseInt(e.backColor.substring(5, 7), 16);
			var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
			e.fontColor = (yiq >= 128) ? 'black' : 'white';
		}

		if (ToolService.testFlag(e.flags, Const.bfSplitStart)) {
			areas.push({ top: -1, left: 0, html: '<i class="material-icons icon12 flipX">play_arrow</i>' });
		}

		let x = 4;
		if (ToolService.testFlag(e.flags, Const.bfSplitEnd)) {
			areas.push({ top: -1, right: 0, html: '<i class="material-icons icon12">play_arrow</i>' });
			x = 12;
		}

		// Personenzahl
		if (days > 1 && !ToolService.testFlag(e.flags, Const.bfAllotment)) {
			areas.push({ top: -3, right: x, html: `<span style="font-size:9px">${e.persText}<span>` });
		}

		e.areas = areas;
	}


	private extractData(res: Response) {
		const body = res;
		return body || {};
	}

	private easterDate(year: number): Date {
		const a = year % 19;
		const b = year % 4;
		const c = year % 7;
		const k = Math.floor(year / 100);
		const p = Math.floor((8 * k + 13) / 25);
		const q = Math.floor(k / 4);

		const M = (15 + k - p - q) % 30;
		const N = (4 + k - q) % 7;
		const d = (19 * a + M) % 30;
		const e = (2 * b + 4 * c + 6 * d + N) % 7;
		let easter = 22 + d + e;
		if (d + e === 35) {
			easter = 50;
		} else if (d === 28 && e === 6 && (11 * M + 11) % 30 < 19) {
			easter = 49;
		}

		// Sibi 3.5.2020 ?? !!! easter -= 1;
		return easter <= 31 ? new Date(year, 2, easter) : new Date(year, 3, easter - 31);
	}

	buildHolidays() {
		const holidaysA = [
			{ name: 'Neujahr', day: 1, month: 1 },
			{ name: 'Heilige Drei Könige', day: 6, month: 1 },
			{ name: 'Aschermittwoch', offset: - 46 },
			{ name: 'Ostern', offset: 0 },
			{ name: 'Ostermontag', offset: 1},
			{ name: 'Staatsfeiertag', day: 1, month: 5 },
			{ name: 'Christi Himmelfahrt', offset: 39},
			{ name: 'Pfingstmontag', offset: 50 },
			{ name: 'Fronleichnam', offset: 60 },
			{ name: 'Maria Himmelfahrt', day: 15, month: 8 },
			{ name: 'Nationalfeiertag', day: 26, month: 10 },
			{ name: 'Allerheiligen', day: 1, month: 11 },
			{ name: 'Maria Emfpängnis', day: 8, month: 12 },
			{ name: 'Heilig Abend', day: 24, month: 12 },
			{ name: 'Weihnachten', day: 25, month: 12 },
			{ name: 'Stefanitag', day: 26, month: 12 }
		];

		const holidaysD = [
			{ name: 'Neujahr', day: 1, month: 1 },
			{ name: 'Karfreitag', offset: -2 },
			{ name: 'Ostersonntag', offset: 0 },
			{ name: 'Ostermontag', offset: 1 },
			{ name: 'Tag der Arbeit', day: 1, month: 5 },
			{ name: 'Christi Himmelfahrt', offset: 39 },
			{ name: 'Pfingstmontag', offset: 50 },
			{ name: 'Tag der deutschen Einheit', day: 3, month: 10 },
			{ name: '1. Weihnachtsfeiertag', day: 25, month: 12 },
			{ name: '2. Weihnachtsfeiertag', day: 26, month: 12 }
		];


		const holidaysCH = [
			{ name: 'Neujahrstag', day: 1, month: 1 },
			{ name: 'Berchtoldstag', day: 2, month: 1 },
			{ name: 'Heilige Drei Könige', day: 6, month: 1 },
			{ name: 'Josefstag', day: 19, month: 3 },
			{ name: 'Karfreitag', offset: -2 },
			{ name: 'Ostern', offset: 0 },
			{ name: 'Ostermontag', offset: 1 },
			{ name: 'Tag der Arbeit', day: 1, month: 5 },
			{ name: 'Auffahrt', offset: 39 },
			{ name: 'Pfingstmontag', offset: 50 },
			{ name: 'Fronleichnam', offset: 60 },
			{ name: 'Bundesfeier', day: 1, month: 8 },
			{ name: 'Mariä Himmelfahrt', day: 15, month: 8 },
			{ name: 'Allerheiligen', day: 1, month: 11 },
			{ name: 'Mariä Emfpängnis', day: 8, month: 12 },
			{ name: 'Weihnachten', day: 25, month: 12 },
			{ name: 'Stefanstag', day: 26, month: 12 }
		];

		let holidays;
		switch (ToolService.country) {
			case 'CH':
				holidays = holidaysCH;
				break;
			case 'D':
				holidays = holidaysD;
				break;
			default:
				holidays = holidaysA;
				break;
		}

		const result = [];
		const currentYear = Date.today().getFullYear();
		for (let y = currentYear - 1; y < currentYear + 3; y++) {
			const e1 = this.easterDate(y);
			holidays.forEach(h => {
				let date;
				if (h.offset !== undefined) {
					date = e1.addDays(h.offset);
				} else {
					date = new Date(y, h.month - 1, h.day);
				}
				result.push({ name: h.name, date: date });
			});

		}

		return result;
	}

	// Some Adrs ?
	getAdr(id): Observable<any> {
		if (id === 0) { return new Observable(null); }

		return this.http.get(endpointAdr + id).pipe(
			map(this.extractData));
	}

	getAdrEmail(id): Observable<any> {
		return this.http.get(`${endpointAdr}email/${id}`).pipe(
			map(this.extractData));
	}

	// Bookings
	getBookingsRange(fromDate: Date, toDate: Date, house = -1, filter = 0): Observable<Booking[]> {
		return this.http.get<Booking[]>(`${endpoint}date/${fromDate.toISODateString()}/${toDate.toISODateString()}?house=${house}&filter=${filter}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getBookingsFilter(filter: string, date: Date = null): Observable<Booking[]> {
		const d = date ? date.toISODateString() : '';
		return this.http.get<Booking[]>(`${endpoint}filter/${filter}?date=${d}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getBooking(id: number, includeAddOns = false): Observable<Booking> {
		return this.http.get<Booking>(`${endpoint}${id}?includeAddOns=${includeAddOns}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getBookingParts(id: number): Observable<Booking[]> {
		return this.http.get<Booking[]>(`${endpoint}parts/${id}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getBookingTraces(bid: number): Observable<Message[]> {
		return this.http.get<Message[]>(`${endpointMessage}booking/${bid}?type=2`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getBookingAccount(bid: number): Observable<AccountInfo> {
		return this.http.get<AccountInfo>(`${endpoint}account/${bid}`);
	}

	getPaymaster(groupID: number): Observable<Booking> {
		return this.http.get<Booking>(`${endpoint}paymaster/${groupID}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getBookingsRefID(refID: string): Observable<Booking[]> {
		return this.http.get<Booking[]>(endpoint + 'refid/' + refID.replace(/\//g, '§') ).pipe(
			catchError(ToolService.handleHttpError)
		);
	}
	/*
	getBookingsAdr(custID: number, date: Date): Observable<Booking[]> {
		return this.http.get<Booking[]>(`${endpoint}adr/${custID}/${date?.toISODateString()}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}
	*/
	getGroupBookings(groupID: number, calcPrice: boolean): Observable<Booking[]> {
		return this.http.get<Booking[]>(`${endpoint}group/${groupID}?calcPrice=${calcPrice}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getAllotments(booking: Booking): Observable<Booking[]> {
		return this.http.put<Booking[]>(`${endpoint}allotments`, JSON.stringify(booking), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getAllotmentBookings(id: number): Observable<Booking[]> {
		return this.http.get<Booking[]>(`${endpoint}allotments/${id}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getLatestUpdate(): Observable<any> {
		return this.http.get(endpoint + 'latestUpdate').pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	transmitGuestCardData(bid: number): Observable<any> {
		return this.http.get(`${endpoint}guestcard/${bid}`);
	}

	addBooking(data: Booking): Observable<any> {
		return this.http.post(endpoint, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateBooking(id: number, data: Booking): Observable<any> {
		data.id = id;
		return this.http.put(endpoint + id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getPrice(data: Booking): Observable<PriceInfo> {
		data.from = data.from ? data.from : new Date();
		data.to = data.to ? data.to : new Date();
		data.optionDate = new Date(); // hack

		return this.http.put<PriceInfo>(endpoint + 'price', JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	// Booking Engine
	getPriceAll(data: Booking): Observable<PriceInfo[]> {
		return this.http.put<PriceInfo[]>(endpoint + 'prices', JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	setNextRegID(booking): Observable<any> {
		return this.http.put(endpoint + 'nextRegID', booking, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getStatusItems(booking: Booking, includeTags): any[] {
		if (!booking) { return []; }

		const items = [];
		if (ToolService.testFlag(booking.flags, Const.bfCheckIn)) {
			items.push({ text: 'CheckIn', icon: 'lens', color: Const.hotelColors[Const.clrCheckIn] });
		} else if (ToolService.testFlag(booking.flags, Const.bfCheckOut)) {
			items.push({ text: 'CheckOut', icon: 'lens', color: Const.hotelColors[Const.clrCheckOut] });
		} else if (booking.color > 0) {
			items.push({ text: 'Farbe ' + booking.color, icon: 'panorama_fish_eye', color: Const.colors[booking.color] });
		}

		if (ToolService.testFlag(booking.flags, Const.bfOwn)) {
			items.push({ text: 'Eigenbelegung', icon: '' });
		}
		if (ToolService.testFlag(booking.flags, Const.bfOffer) && booking.status1 !== Const.bsOfferReminder) {
			items.push({ text: 'Angebot', icon: 'local_offer' });
		}

		if (ToolService.testFlag(booking.flags, Const.bfNewOnlineBooking)) {
			items.push({ text: 'Neue Online Buchung', icon: 'language' });
		}

		if (ToolService.testFlag(booking.flags, Const.bfNewOfferAccepted)) {
			items.push({ text: 'Angebot angenommen', icon: 'gavel' });
		}

		if (ToolService.testFlag(booking.flags, Const.bfHasDeposit)) {
			items.push({ text: 'Anzahlung erhalten', icon: 'monetization_on' });
		}

		if (ToolService.testFlag(booking.flags, Const.bfSettleLodging)) {
			items.push({ text: 'Logis abgerechnet', icon: '' });
		} else if (ToolService.testFlag(booking.flags, Const.bfPackageBooked)) {
			items.push({ text: 'Package gebucht', icon: '' });
		}

		if (ToolService.testFlag(booking.flags, Const.bfLocked)) {
			items.push({ text: 'Fixiert', icon: 'lock' });
		}
		if (ToolService.testFlag(booking.flags, Const.bfHasTraces)) {
			items.push({ text: 'Traces', icon: 'timeline' });
		}
		if (ToolService.testFlag(booking.flags, Const.bfTentative)) {
			items.push({ text: 'Vorläufig', icon: '' });
		}
		if (ToolService.testFlag(booking.flags, Const.bfAllotment)) {
			items.push({ text: 'Kontingent', icon: '' });
		}
		if (ToolService.testFlag(booking.flags, Const.bfSub)) {
			items.push({ text: 'Zusatzbuchung', icon: '' });
		}

		if (booking.status > 0 && HotelService.bookingStatus[booking.status]) {
			items.push({ text: HotelService.bookingStatus[booking.status].name, icon: '' });
		}

		if (booking.status1 > 0 && HotelService.bookingStatus1[booking.status1]) {
			items.push({ text: HotelService.bookingStatus1[booking.status1].name, icon: HotelService.bookingStatus1[booking.status1].icon });
		}

		if (includeTags) {
			const tags = booking.tags.split('|');
			tags.forEach(t => {
				items.push({ text: t, icon: '' });
			});
		}

		return items;
	}

	getStatusText(booking: Booking, includeTags = false): string {
		const items = this.getStatusItems(booking, includeTags);
		return items.map(i => i.text).filter(t => !t.startsWith('Farbe')).join(', ');
	}

	async checkRegFormFields(adr, booking): Promise<string> {
		let manatoryFields = '';
		const adrFields = await this.tools.settingArray('hotel', 'mandatory').toPromise();
		if (!adrFields.length) { return new Promise(resolve => resolve('')); }

		const adrEntries = Object.entries(adr);
		adrFields.forEach(field => {
			const entry = adrEntries.find(o => o[0].toLowerCase() === field.toLowerCase());
			if (entry && (!entry[1] || entry[1] === '1899-12-30T00:00:00')) {
				manatoryFields = ToolService.append(manatoryFields, field, ', ');
			}
		});

		// Birthdate at subadr
		if (adrFields.indexOf('birthdate') >= 0) {
			let mask = 2;
			for (let i = 0; i < Math.min(booking.persCount, adr.subAdrs.length); i++) {
				const sub = adr.subAdrs[i];
				if (ToolService.testFlag(booking.persSelect, mask) && sub.birthdate.toISODateString() === '1899-12-30T00:00:00') {
					manatoryFields = ToolService.append(manatoryFields, 'Birthdate (subadr)', ', ');
				}
				mask *= 2;
			}
		}

		let error = '';

		// CheckPersCount, HACK not ready for groups yet
		if (booking.groupID <= 0) {
			let persSelected = 0;
			let mask = 2;
			for (let i = 0; i < adr.subAdrs.length; i++) {
				if (ToolService.testFlag(booking.persSelect, mask)) {
					persSelected++;
				}
				mask *= 2;
			}

			if (persSelected + 1 !== booking.persCount) {
				error = `Die Anzahl der Reisenden (${persSelected + 1}) stimmt nicht mit der Personenzahl der Buchung überein (${booking.persCount})`;
			}
		}

		if (manatoryFields) {
			error = ToolService.append(error, 'Folgende Felder sind ungültig: ' + manatoryFields);
		}
		return new Promise(resolve => resolve(error));
	}



	moveBooking(id: number, room: string, from: Date = null, to: Date = null, allowOverlap = false, guestID = -1): Observable<any> {
		const f = from ? new Date(from) : new Date(1899, 11, 30);
		const t = to ? new Date(to) : new Date(1899, 11, 30);

		return this.http.put(endpoint + `move/${id}/${room}/${f.toISODateString()}/${t.toISODateString()}?guestID=${guestID}&allowOverlap=${allowOverlap}`, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	moveBookingResource(id: number, room: string, from: Date, to: Date, allowOverlap = false): Observable<any> {
		return this.http.put(endpoint + `move/${id}/${room}/${from.toISODateTimeString()}/${to.toISODateTimeString()}?allowOverlap=${allowOverlap}`, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	deleteBooking(id: number, mode: 'delete' | 'cancel' | 'noshow' = 'delete'): Observable<any> {
		return this.http.delete(`${endpoint}${id}?mode=${mode}`, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	checkInBooking(id: number): Observable<any> {
		return this.http.put(endpoint + 'checkin/' + id, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	undoCheckIn(id: number): Observable<any> {
		return this.http.delete(endpoint + 'checkin/' + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	checkOutBooking(id: number): Observable<any> {
		return this.http.put(endpoint + 'checkout/' + id, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	undoCheckOut(id: number): Observable<any> {
		return this.http.delete(endpoint + 'checkout/' + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	settleLodging(id: number): Observable<any> {
		return this.http.put(`${endpoint}checkout/${id}?settleLodging=true`, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	bookPackage(id: number): Observable<any> {
		return this.http.put(`${endpoint}package/${id}`, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	undoSettleLodging(id: number): Observable<any> {
		return this.http.delete(endpoint + 'settleLodging/' + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	acceptOffer(id: number): Observable<any> {
		return this.http.put(endpoint + 'acceptOffer/' + id, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	acceptOfferRefID(refID: string): Observable<any> {
		return this.http.put(`${endpoint}acceptOffer/${encodeURIComponent(refID)}`, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	undoAcceptOffer(id: number): Observable<any> {
		return this.http.delete(endpoint + 'acceptOffer/' + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	takeAllotment(id: number): Observable<any> {
		return this.http.put(endpoint + 'takeAllotment/' + id, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	undoTakeAllotment(id: number): Observable<any> {
		return this.http.delete(endpoint + 'takeAllotment/' + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getOldInvID(id: number): Observable<any> {
		return this.http.get(`${endpoint}invid/${id}`).pipe(
			map(this.extractData));
	}

	createInvID(id: number, re: number): Observable<any> {
		return this.http.put(`${endpoint}invid/${id}/${re}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	transmitTaxData(bid = 0, cancel = false): Observable<any> {
		return this.http.put(`${endpoint}transmitTaxData/${bid}?cancel=${cancel}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	getRegFormsPdf(start: Date, end: Date): Observable<HttpEvent<Blob>> {
		return this.http.request(new HttpRequest('GET', `${endpoint}regforms/${start.toISODateString()}/${end.toISODateString()}`, null, { reportProgress: true, responseType: 'blob' })).pipe(
			catchError(ToolService.handleHttpErrorBlob));
	}

	groupBookings(bids: number[]): Observable<any> {
		return this.http.put(endpoint + 'group', bids, httpOptions).pipe(
			map(this.extractData));
	}

	ungroupBookings(bid: number, onlyCurrent = false): Observable<any> {
		return this.http.delete(`${endpoint}group/${bid}?onlyCurrent=${onlyCurrent}`).pipe(
			map(this.extractData));
	}

	setPaymaster(groupID: number, bid: number): Observable<any> {
		return this.http.put(`${endpoint}paymaster/${groupID}/${bid}`, httpOptions).pipe(
			map(this.extractData));
	}

	splitBooking(bid: number, day: number): Observable<any> {
		return this.http.put(endpoint + `split/${bid}/${day}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	mergeBookings(bid1: number, bid2: number): Observable<any> {
		return this.http.delete(endpoint + `split/${bid1}/${bid2}`).pipe(
			map(this.extractData));
	}

	swapBookings(bid1: number, bid2: number): Observable<any> {
		return this.http.delete(endpoint + `swap/${bid1}/${bid2}`).pipe(
			map(this.extractData));
	}

	transmitRates(fromDate: Date, toDate: Date, categories: string, houseFilter = -1): Observable<any> {
		const from = fromDate ? fromDate.toISODateString() : '';
		const to = toDate ? toDate.toISODateString() : '';
		return this.http.put(`${endpointCM}rates?fromDate=${from}&toDate=${to}&categories=${categories}&houseFilter=${houseFilter}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	transmitAvailability(houseFilter = -1): Observable<any> {
		return this.http.put(`${endpointCM}avail?houseFilter=${houseFilter}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	transmitAvailabilityRange(fromDate: Date, toDate: Date, categories: string): Observable<any> {
		if (!categories) { categories = 'all'}
		return this.http.put(`${endpointCM}avail/${fromDate.toISODateString()}/${toDate.toISODateString()}/${categories}`, null, httpOptions).pipe(
			map(this.extractData));
	}

	importBookings(houseFilter=-1): Observable<any> {
		return this.http.get(`${endpointCM}import?houseFilter=${houseFilter}`).pipe(
			map(this.extractData));
	}

	importRates(houseFilter = -1): Observable<any> {
		return this.http.get(`${endpointCM}importrates?houseFilter=${houseFilter}`).pipe(
			map(this.extractData));
	}

	getFreeInfo(fromDate: Date, toDate: Date): Observable<any> {
		return this.http.get(`${endpointCM}avail/${fromDate.toISODateString()}/${toDate.toISODateString()}`).pipe(
			map(this.extractData));
	}

	// Rooms
	@Cacheable({
		cacheBusterObserver: cacheBusterRoom$
	})
	getAllRooms(): Observable<Room[]> {
		return this.http.get<Room[]>(endpointRooms).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getRoomsAndCats(): Observable<Room[]> {
		return new Observable(obs => {
			this.getAllRooms().subscribe(data => {
				obs.next(data.filter(r => r.type === Const.rtRoom || r.type === Const.rtCategory || r.type === Const.rtSeminar));
				obs.complete();
			});
		});
	}

	getRooms(type = -1): Observable<Room[]> {
		return new Observable(obs => {
			this.getAllRooms().subscribe(data => {
				obs.next(data.filter(r => type === -1 || r.type === type));
				obs.complete();
			});
		});
	}

	getRoom(roomNumber: string, fromCache = true): Observable<Room> {
		if (!roomNumber) {
			return new Observable(obs => obs.complete());
		}

		if (fromCache) {
			return new Observable(obs => {
				this.getAllRooms().subscribe(data => {
					obs.next(data.find(r => r.number === roomNumber));
					obs.complete();
				});
			});
		}

		// Get NOT from cache
		return this.http.get<Room>(endpointRooms + roomNumber).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getRoomsStatus(): Observable<any> {
		return this.http.get(endpointRooms + 'status').pipe(
			map(this.extractData));
	}

	getRoomStatus(roomNumber): Observable<any> {
		return this.http.get(`${endpointRooms}status/${roomNumber}`).pipe(
			map(this.extractData));
	}

	setRoomStatus(roomNumber: string, status: string|number): Observable<any> {
		return this.http.put(`${endpointRooms}status/${roomNumber}/${status}`, null, httpOptions).pipe(
			map(this.extractData));
	}


	@CacheBuster({
		cacheBusterNotifier: cacheBusterRoom$
	})
	addRoom(data: Room): Observable<any> {
		return this.http.post(endpointRooms, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterRoom$
	})
	updateRoom(id: number, data: Room): Observable<any> {
		return this.http.put(endpointRooms + id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterRoom$
	})
	updateRoomSort(source: string, target:string): Observable<any> {
		return this.http.put(`${endpointRooms}sort/${source}/${target}`, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterRoom$
	})
	deleteRoom(roomNumber: string): Observable<any> {
		return this.http.delete(endpointRooms + roomNumber, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterRoom$
	})
	clearRoomCache(): Observable<any> {
		return new Observable<any>();
	}

	// Arrangements
	@Cacheable({
		cacheBusterObserver: cacheBusterArrangement$
	})
	getArrangements(): Observable<Arrangement[]> {
		return this.http.get<Arrangement[]>(endpointArrangements).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getAllArrangements(): Observable<Arrangement[]> {
		return this.http.get<Arrangement[]>(endpointArrangements + 'all').pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getArrangement(arrangementID: string, fromCache = true): Observable<Arrangement> {
		if (fromCache) {
			return new Observable(obs => {
				this.getArrangements().subscribe(data => {
					obs.next(data.find(r => r.arrangementID === arrangementID));
					obs.complete();
				});
			});
		}

		// Get NOT from cache
		return this.http.get<Arrangement>(`${endpointArrangements}${arrangementID}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getArrangementID(id: number): Observable<Arrangement> {
		return this.http.get<Arrangement>(`${endpointArrangements}${id}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArrangement$
	})
	addArrangement(data: Arrangement): Observable<any> {
		return this.http.post(endpointArrangements, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArrangement$
	})
	updateArrangement(id: number, data: Arrangement): Observable<any> {
		return this.http.put(endpointArrangements + id, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArrangement$
	})
	updateArrangementSort(source: string, target: string): Observable<any> {
		return this.http.put(`${endpointArrangements}sort/${source}/${target}`, null, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArrangement$
	})
	deleteArrangement(id: number): Observable<any> {
		return this.http.delete(endpointArrangements + id, httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheBusterArrangement$
	})
	clearArrangementCache(): Observable<any> {
		return new Observable<any>();
	}

	// Rates
	getRates(fromDate: Date, toDate: Date, category: string, arrangementID: string): Observable<Rates[]> {
		return this.http.get<Rates[]>(`${endpointRates}${fromDate.toISODateString()}/${toDate.toISODateString()}/${category}/${arrangementID}`);
	}

	getRate(id: number): Observable<Rates> {
		return this.http.get<Rates>(endpointRates + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateRates(data: RatesChange): Observable<any> {
		return this.http.put(endpointRates, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getRateCodes(fromDate: Date, toDate: Date): Observable<any[]> {
		return this.http.get<any[]>(`${endpointRates}codes/${fromDate.toISODateString()}/${toDate.toISODateString()}`);
	}

	updateRateCodes(data: RatesChange): Observable<any> {
		return this.http.put(endpointRates + 'codes', JSON.stringify(data), httpOptions);
	}

	getCategoryFilter(fromDate: Date, toDate: Date, category:string): Observable<any[]> {
		return this.http.get<any[]>(`${endpointRates}filter/${fromDate.toISODateString()}/${toDate.toISODateString()}/${category}`);
	}

	updateCategoryFilter(data: RatesChange): Observable<any> {
		return this.http.put(endpointRates + 'filter', JSON.stringify(data), httpOptions);
	}

	// Journal
	calcAccount(invID: number): Observable<any> {
		return this.http.get(`${endpointJournal}calc/${invID}`).pipe(
			map(this.extractData));
	}

	// Misc
	newKey(booking: Booking, options: string): Observable<any> {
		const encoderID = localStorage.getItem('keyEncoder');

		return this.http.post(`${endpointExternal}newKey?encoderID=${encoderID}&options=${options}`, JSON.stringify(booking), httpOptions).pipe(
			map(this.extractData));
	}

	deleteKey(booking: Booking): Observable<any> {
		const encoderID = localStorage.getItem('keyEncoder');

		return this.http.post(`${endpointExternal}deleteKey?encoderID=${encoderID}`, JSON.stringify(booking), httpOptions).pipe(
			map(this.extractData));
	}

	// Dates
	getDates(bid: number): Observable<DateEntry[]> {
		return this.http.get<DateEntry[]>(`${endpointDates}booking/${bid}`).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	getDate(id: number): Observable<DateEntry> {
		return this.http.get<DateEntry>(endpointDates + id).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	addDate(data: DateEntry): Observable<any> {
		return this.http.post(endpointDates, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	updateDate(id: number, data: DateEntry): Observable<any> {
		data.id = id;
		return this.http.put(`${endpointDates}${id}`, JSON.stringify(data), httpOptions).pipe(
			catchError(ToolService.handleHttpError)
		);
	}

	deleteDate(id: number): Observable<any> {
		return this.http.delete(endpointDates + id).pipe(
			map(this.extractData));
	}

	moveDate(id: number, date: Date, length: number, parallel = false): Observable<any> {
		return new Observable(obs => {
			this.getDate(id).subscribe(d => {
				if (date) { d.date = date; }
				if (length) { d.length = length; }
				d.mode = parallel ? Const.dmParallel : Const.dmNormal;

				this.updateDate(id, d).subscribe(
					res => {
						obs.next(d);
						obs.complete();
					},
					err => {
						obs.error(err);
						obs.complete();
					}
				);
			});
		});
	}

}


