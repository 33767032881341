import { Directive, OnInit, HostListener, TemplateRef, ViewContainerRef, Input, AfterViewInit, OnDestroy, Output } from '@angular/core';
import { Subscription, fromEvent} from 'rxjs';
import { MatDialogRef, MatDialogContainer } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService, ToolService } from '../../_services';
import { Const } from '../../models/constants';


export interface Position {
	x: number;
	y: number;
}

@Directive({
	selector: '[mat-dialog-title-draggable]'
})
export class DialogDraggableTitleDirective implements AfterViewInit {

	private _subscription: Subscription;
	mouseStart: Position;
	mouseDelta: Position;
	offset: Position;

	constructor(
		private MatDialogRef: MatDialogRef<any>,
		private container: MatDialogContainer
	) { }

	ngAfterViewInit() {
		this.offset = this.getOffset();
	}

	@HostListener('mousedown', ['$event'])
	onMouseDown(event: MouseEvent) {
		this.mouseStart = { x: event.pageX, y: event.pageY };

		const mouseup$ = fromEvent(document, 'mouseup');
		this._subscription = mouseup$.subscribe(() => this.onMouseup());

		const mousemove$ = fromEvent(document, 'mousemove')
			.pipe(takeUntil(mouseup$))
			.subscribe((e: MouseEvent) => this.onMouseMove(e));

		this._subscription.add(mousemove$);
	}

	onMouseMove(event: MouseEvent) {
		this.mouseDelta = { x: (event.pageX - this.mouseStart.x), y: (event.pageY - this.mouseStart.y) };
		this.updatePosition(this.offset.y + this.mouseDelta.y, this.offset.x + this.mouseDelta.x);
	}

	onMouseup() {
		if (this._subscription) {
			this._subscription.unsubscribe();
			this._subscription = undefined;
		}

		if (this.mouseDelta) {
			this.offset.x += this.mouseDelta.x;
			this.offset.y += this.mouseDelta.y;
		}
	}

	private updatePosition(top: number, left: number) {
		this.MatDialogRef.updatePosition({
			top: top + 'px',
			left: left + 'px'
		});
	}

	private getOffset(): Position {
		const box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
		return {
			x: box.left + pageXOffset,
			y: box.top + pageYOffset
		};
	}
}



@Directive({ selector: '[showAuthed]' })
export class ShowAuthedDirective implements OnInit {

	condition: boolean;

	@Input() set showAuthed(condition: boolean) {
		this.condition = condition;
	}

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authenticationService: AuthenticationService,
	) { }

	ngOnInit() {
		this.authenticationService.currentUser.subscribe(user => {
			let role = -1;
			if (user) {
				role = user.role;
			}

			if (role >= Const.roleGuest && this.condition || role < Const.roleGuest && !this.condition) {
				this.viewContainer.createEmbeddedView(this.templateRef);
			} else {
				this.viewContainer.clear();
			}
		});
	}
}

@Directive({ selector: '[showUser]' })
export class ShowUserDirective implements OnInit {

	condition: boolean;

	@Input() set showUser(condition: boolean) {
		this.condition = condition;
	}

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authenticationService: AuthenticationService,
	) { }

	ngOnInit() {
		this.authenticationService.currentUser.subscribe(user => {
			let role = -1;
			if (user) {
				role = user.role;
			}

			if (role >= Const.roleUser && this.condition || role < Const.roleUser && !this.condition) {
				this.viewContainer.createEmbeddedView(this.templateRef);
			} else {
				this.viewContainer.clear();
			}
		});
	}
}

@Directive({ selector: '[showManager]' })
export class ShowManagerDirective implements OnInit {

	condition: boolean;

	@Input() set showManager(condition: boolean) {
		this.condition = condition;
	}

	constructor(
		private tools: ToolService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
	) { }

	ngOnInit() {
		let role = -1;
		if (ToolService.currentUser) {
			role = ToolService.currentUser.role;
		}

		if (role >= Const.roleManager && this.condition || role < Const.roleManager && !this.condition) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}

@Directive({ selector: '[showAdmin]' })
export class ShowAdminDirective implements OnInit {
	condition: boolean;

	@Input() set showAdmin(condition: boolean) {
		this.condition = condition;
	}

	constructor(
		private tools: ToolService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
	) { }

	ngOnInit() {
		let role = -1;
		if (ToolService.currentUser) {
			role = ToolService.currentUser.role;
		}

		if (role >= Const.roleAdmin && this.condition || role < Const.roleAdmin && !this.condition) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}


/* working but not used 
@Directive({
	selector: '[preventDoubleClick]'
})
export class PreventDoubleClickDirective implements OnInit, OnDestroy {
	@Input()
	throttleTime = 500;

	@Output()
	click1 = new EventEmitter();

	private _clicks = new Subject();
	private _subscription: Subscription;

	constructor() { }

	ngOnInit() {
		this._subscription = this._clicks.pipe(
			throttleTime(this.throttleTime)
		).subscribe(e => this.click1.emit(e as any));
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	@HostListener('click', ['$event'])
	clickEvent(event) {
		event.preventDefault();
		event.stopPropagation();
		this._clicks.next(event);
	}
}
*/
