import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DataService, SignalRService } from '../../_services';
import { BroadcastData } from '../../models/models';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-remote-video',
	template: `
<div class="fullscreen-container">
	<div class="primary-shade">
		<h2 class="mat-h2" style="margin:0; padding: 4px 0 0 .5em;" fxFlex>{{ caption }}</h2>
		<button mat-icon-button (click)="onClose()"><mat-icon>clear</mat-icon></button>
	</div>
	<video class="video" style="padding: 0 .5em;" autoplay [muted]="!streamID" playsInline [srcObject]="videoStream" (click)="onClickEffect($event)" #video></video>
</div>`,
	styles: [
		'.video {height:100%; width:auto; left:auto; right: auto; }',
	]
})

export class RemoteVideoComponent implements OnInit, OnDestroy {
	videoStream: MediaStream;

	caption = '';
	streamID = 0;
	canvas = false;
	othersSubscription: Subscription;

	@ViewChild('video') video: ElementRef;

	constructor(
		public signalRService: SignalRService,
		public dataService: DataService,
		public location: Location,
		private route: ActivatedRoute,
	) { }


	ngOnInit() {
		const connectionID = this.route.snapshot.paramMap.get('connectionId');
		this.canvas = this.route.snapshot.url[1].path === 'c';

		const connection = this.signalRService.connections.find(x => x.connectionID === connectionID);

		if (connection) {
			if (this.canvas || !connection.videoStream) {
				this.videoStream = connection.canvasStream;
				this.caption = 'Bildschirm von ' + connection.userName;
			} else {
				this.videoStream = connection.videoStream;
				this.caption = connection.userName;
			}
		}

		this.location.replaceState('/'); // Refresh to homepage

		this.othersSubscription = this.signalRService.othersOnline.subscribe(others => {
			if (others.split(',').length < 2) { this.location.back(); }
		});
	}

	ngOnDestroy() {
		this.othersSubscription.unsubscribe();
	}

	onClose() {
		this.location.back();
	}

	onClickEffect(event: MouseEvent) {

		if (!this.videoStream) {
			return;
		}


		const settings = this.videoStream.getVideoTracks()[0].getSettings();
		const videoWidth = settings.width / window.devicePixelRatio;
		const videoHeight = settings.height / window.devicePixelRatio;
		const rect = this.video.nativeElement.getBoundingClientRect();  // absolute position of element

		const ratio = videoHeight / videoWidth;
		const ratioView = rect.height / rect.width;
		let offsetLeft = 0;
		let offsetTop = 0;

		if (ratio > ratioView) { // vertical video
			const scale = rect.height / videoHeight;
			offsetLeft = (rect.width - (videoWidth * scale) + 0.5) / 2 | 0;
		}

		if (ratio < ratioView) { // horizontal video
			const scale = rect.width / videoWidth;
			offsetTop = (rect.height - (videoHeight * scale) + 0.5) / 2 | 0;
		}

		const width = rect.width - 2 * offsetLeft;
		const height = rect.height - 2 * offsetTop;

		const x = (10000 * (event.offsetX - offsetLeft) / width) | 0;
		const y = (10000 * (event.offsetY - offsetTop) / height) | 0;

		this.dataService.sendBroadcast('remoteMouseClick', { route: x + ',' + y } as BroadcastData).subscribe();
	}





}
